import { useMutation, useQuery } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';

interface ITawkForm {
    details: {
        script: string;
    };
}

const Tawk = () => {
    const tawkPlugin = useQuery<ITawkForm>([], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/tawk-to-chat`);
        return data;
    });

    const tawkPluginMutation = useMutation(async (values: ITawkForm) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/tawk-to-chat`,
            values
        );
        return data;
    });

    const initialValues: ITawkForm = tawkPlugin.isLoading
        ? {
              details: {
                  script: '',
              },
          }
        : {
              details: {
                  script: tawkPlugin.data.details.script,
              },
          };

    const handleSubmit = async (values: ITawkForm) => {
        try {
            await tawkPluginMutation.mutateAsync(values);
            toast.success('Script Updated Successfully');
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    if (tawkPlugin.isLoading) return <PageLoader />;

    return (
        <div>
            {/* <div className='card px-6 pt-5 pb-10'> */}
            <div className='font-bold'>Tawk To Integration</div>

            <div className='card mt-8 px-6 pb-6'>
                {/* <div className='font-semibold'>Credentials</div> */}
                <div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        // validationSchema={ccAvenueCredentialsValidationSchema}
                        // enableReinitialize
                    >
                        {({ setFieldValue, values }: FormikProps<typeof initialValues>) => (
                            <Form>
                                <div className='grid grid-cols-12 gap-6 mt-8'>
                                    <div className='col-span-12'>
                                        <label className='text-xs mb-2' htmlFor='details.script'>
                                            Tawk To Script
                                        </label>
                                        <textarea
                                            className='form-control'
                                            name='details.script'
                                            rows={10}
                                            value={values.details.script}
                                            onInput={(event: React.FormEvent<HTMLTextAreaElement>) =>
                                                setFieldValue('details.script', event.currentTarget.value)
                                            }
                                        />
                                        <div className='form-error'>
                                            <ErrorMessage name='details.script' />
                                        </div>
                                    </div>
                                </div>

                                <LoaderButton
                                    type='submit'
                                    className='bg-primary text-white text-base font-semibold rounded-lg w-32 mt-8'
                                    // isLoading={credentialUpdateMutation.isLoading}
                                >
                                    Save
                                </LoaderButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default Tawk;
