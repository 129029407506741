import moment from 'moment';
import ReactDOM from 'react-dom';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MenuDotIcon } from 'app/icons';
import { IPaymentNote } from 'app/types';
import update from 'immutability-helper';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import { capFirst } from 'app/utils/common-utils';
import { FC, useLayoutEffect, useRef } from 'react';
import Spinner from 'app/components/Spinner/Spinner';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface PaymentNoteTableProps {
    payments: IPaymentNote[];
    params: string;
    isLoading: boolean;
}

const PaymentNoteTable: FC<PaymentNoteTableProps> = (props) => {
    const { payments, params, isLoading } = props;
    const tableTop = useRef();
    const queryClient = useQueryClient();

    const settlePaymentNoteDialog = useDialog<{
        onConfirm: () => void;
        title: string;
    }>({
        onConfirm: () => {},
        title: '',
    });

    const viewRemarkDialog = useDialog<string>('');

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Pending';
            case 'APPROVED':
                return 'Approved';
            case 'REJECTED':
                return 'Rejected';
        }
    };

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'yellow';
            case 'APPROVED':
                return 'green';
            case 'REJECTED':
                return 'red';
        }
    };

    const rejectPaymentNoteMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${values.subAgency}/payment-notes/${values.paymentNoteId}/reject`
        );

        return data;
    });

    const handleRejectPaymentNote = async (subAgencyId: string, payementNoteId: string) => {
        try {
            const paymentNote = await rejectPaymentNoteMutation.mutateAsync({
                paymentNoteId: payementNoteId,
                subAgency: subAgencyId,
            });

            queryClient.setQueryData(['payment', 'notes', params], (prev: IPaymentNote[]) => {
                const index = prev.findIndex((p) => p._id === payementNoteId);

                return update(prev, {
                    [index]: {
                        $set: paymentNote,
                    },
                });
            });

            queryClient.fetchQuery(['payment', 'notes', params]);

            toast.success('Payment Note rejected.');
        } catch (ex: any) {
            // infoDialog.show(ex.data.message);
            toast.error(ex.data.message);
        } finally {
            // unMappedTransactionDialog.hide();
            settlePaymentNoteDialog.hide();
        }
    };

    const approvePaymentMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${values.subAgency}/payment-notes/${values.paymentNoteId}/approve`,
            { transactionId: values.transactionId }
        );

        return data;
    });

    const handleApprovePaymentNote = async (subAgencyId: string, payementNoteId: string) => {
        try {
            const paymentNote = await approvePaymentMutation.mutateAsync({
                paymentNoteId: payementNoteId,
                subAgency: subAgencyId,
            });

            queryClient.setQueryData(['payment', 'notes', params], (prev: IPaymentNote[]) => {
                const index = prev.findIndex((p) => p._id === payementNoteId);

                return update(prev, {
                    [index]: {
                        $set: paymentNote,
                    },
                });
            });

            queryClient.fetchQuery(['payment', 'notes', params]);

            toast.success('Payment Note approved.');
        } catch (ex: any) {
            // infoDialog.show(ex.data.message);
            toast.error(ex.data.message);
        } finally {
            // unMappedTransactionDialog.hide();
            settlePaymentNoteDialog.hide();
        }
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50" ref={tableTop}>
                            <th className="th-1">Request ID</th>
                            <th className="th-1">Type</th>
                            <th className="th-1">Amount</th>
                            <th className="th-1">Sub Agency</th>
                            <th className="th-1">Remark</th>
                            <th className="th-1">Created at</th>
                            <th className="th-1">Approved By</th>
                            <th className="th-1">Status</th>
                            <th className="th-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && payments.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}
                        {!isLoading &&
                            payments.map((row, i) => (
                                <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                    <td className="td-1">
                                        <div className="font-semibold">{row._id}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{capFirst(row.type)}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{formatCurrency(row.amount)}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold cursor-pointer text-blue-500">
                                            <Link to={`/sub-agencies/${row.subAgency._id}/detials?tab=1`}>
                                                {row.subAgency.name}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div
                                            className="font-semibold cursor-pointer text-blue-500"
                                            onClick={() => {
                                                if (row.remark === '') {
                                                    return toast.error('No Remark found');
                                                }
                                                viewRemarkDialog.show(row.remark);
                                            }}
                                        >
                                            View
                                        </div>
                                    </td>
                                    <td className="font-semibold">
                                        <div>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {row.approvedBy ? (
                                                <div>
                                                    <div>{row.approvedBy.agencyUser?.name}</div>
                                                    <div>
                                                        <p>
                                                            {moment(row.approvedBy.at, 'YYYYMMDDHHmmss').format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </p>
                                                        <p>
                                                            {moment(row.approvedBy.at, 'YYYYMMDDHHmmss').format(
                                                                'hh:mm A'
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                            {getStatusText(row.status)}
                                        </StatusBadge>
                                    </td>
                                    <td className="td-1">
                                        {row.status === 'PENDING' ? (
                                            <Dropdown
                                                target={
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                settlePaymentNoteDialog.show({
                                                                    title: 'approve',
                                                                    onConfirm: () =>
                                                                        handleApprovePaymentNote(
                                                                            row.subAgency._id,
                                                                            row._id
                                                                        ),
                                                                });
                                                            }}
                                                        >
                                                            <div className="text-green-500">Approve</div>
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                settlePaymentNoteDialog.show({
                                                                    title: 'approve',
                                                                    onConfirm: () =>
                                                                        handleRejectPaymentNote(
                                                                            row.subAgency._id,
                                                                            row._id
                                                                        ),
                                                                });
                                                            }}
                                                        >
                                                            <div className="text-red-500">Reject</div>
                                                        </DropdownItem>
                                                    </DropdownList>
                                                }
                                            />
                                        ) : (
                                            <div className="text-gray-900"></div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}

                <SettlePaymentNoteDialog
                    show={settlePaymentNoteDialog.isOpen}
                    onClose={settlePaymentNoteDialog.hide}
                    isLoading={rejectPaymentNoteMutation.isLoading || false}
                    onConfirm={settlePaymentNoteDialog.data.onConfirm}
                    title={settlePaymentNoteDialog.data.title}
                />

                <ViewRemarkDialog
                    onClose={viewRemarkDialog.hide}
                    show={viewRemarkDialog.isOpen}
                    remark={viewRemarkDialog.data}
                />
            </div>
        </>
    );
};

export default PaymentNoteTable;

interface SettlePaymentNoteDialogProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading: boolean;
    title: string;
}

const SettlePaymentNoteDialog: FC<SettlePaymentNoteDialogProps> = (props) => {
    return (
        <Modal className="px-10 py-10" show={props.show} onClose={props.onClose}>
            <div className="text-gray-900 mt-4 text-center">
                Are you sure you want to {props.title} this payment note
            </div>
            <div className="flex justify-center gap-4 mt-6">
                <LoaderButton
                    className="bg-red-500 text-white px-6 text-sm rounded-lg"
                    onClick={props.onConfirm}
                    isLoading={props.isLoading}
                >
                    Yes, Proceed
                </LoaderButton>
                <button className="bg-gray-200 text-gray-600 px-6 text-sm rounded-lg" onClick={props.onClose}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

interface ViewRemarkDialogProps {
    show: boolean;
    onClose: () => void;
    remark: string;
}

const ViewRemarkDialog: FC<ViewRemarkDialogProps> = (props) => {
    return (
        <Modal className="px-10 py-10 min-w-[480px]" show={props.show} onClose={props.onClose}>
            <div className="text-gray-900 font-semibold text-lg my-4">Remark</div>
            <div className="flex ">{props.remark}</div>
        </Modal>
    );
};
