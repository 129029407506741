/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useMemo } from 'react';
import { TRootState } from 'app/store';
import styles from './Navbar.module.scss';
import { ChevronDownIcon } from 'app/icons';
import { authActions } from 'app/store/auth';
import { getInitials } from 'app/utils/common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import SearchBar from '../SearchBar/SearchBar';

interface NavbarProps {}

const Navbar: FC<NavbarProps> = (props) => {
    const { user } = useSelector((state: TRootState) => state.auth);

    const agency = useMemo(() => user.agency, [user]);

    const dispatch = useDispatch();

    const handleLogoutClick = () => {
        dispatch(authActions.logout());
    };

    return (
        <div className={`${styles['navbar']} fixed top-0 left-0 w-full bg-white flex items-center px-8 z-20`}>
            {/* Navbar Brand */}
            <div className="flex items-center">
                <img className="h-6" src="/icons/logo.svg" alt="" />
                <div className="w-[1px] h-[28px] bg-[#D9D9D9] ml-3 mr-2"></div>
                <img className="h-6" src={agency.logoUrl} alt="" />
            </div>

            <div className="ml-12">
                <SearchBar />{' '}
            </div>

            {/* Navbar List */}
            <div className="ml-auto flex items-center gap-8">
                {/* <a href="#" className="relative flex">
                    <img src="/icons/navbar/message.svg" alt="" />

                    <div className="w-5 h-5 rounded-[50%] bg-primary text-[0.65rem] text-white font-medium flex items-center justify-center -ml-2 -mt-2">
                        2
                    </div>
                </a> */}
                {/* <a href="#" className="relative flex">
                    <img src="/icons/navbar/bell.svg" alt="" />
                </a> */}
                <Dropdown
                    target={
                        <a className="relative flex items-center gap-4">
                            <div className="w-9 h-9 bg-primary/10 rounded-full flex items-center justify-center">
                                <div className="text-primary font-semibold">{getInitials(user.name)}</div>
                            </div>
                            <div className="text-[0.7rem] leading-none">
                                <div className="font-semibold text-xs leading-none">{user.name}</div>
                                <div className="font-semibold text-primary mt-1">{agency.name}</div>
                                <div className="font-medium text-gray-500 text-[0.65rem] mt-1 leading-3">
                                    {agency._id}
                                </div>
                            </div>
                            <ChevronDownIcon height={5} />
                        </a>
                    }
                    menu={
                        <DropdownList>
                            <DropdownItem to="/profile">
                                <div className="flex items-center text-gray-700 gap-2">
                                    <img src="/icons/sidebar/settings.svg" alt="" />
                                    <span>Profile</span>
                                </div>
                            </DropdownItem>
                            <DropdownItem to="/my-bills">
                                <div className="flex items-center text-gray-700 gap-2">
                                    <img src="/icons/sidebar/wallet.svg" alt="" />
                                    <span>Billing</span>
                                </div>
                            </DropdownItem>
                            <DropdownItem to="/road-map">
                                <div className="flex items-center text-gray-700 gap-2">
                                    <img src="/icons/sidebar/map.svg" alt="" />
                                    <span>Development Road Map</span>
                                </div>
                            </DropdownItem>

                            <DropdownItem to="/feature-request">
                                <div className="flex items-center text-gray-700 gap-2">
                                    <img src="/icons/sidebar/request.svg" alt="" />
                                    <span>Feature Request</span>
                                </div>
                            </DropdownItem>

                            <DropdownItem onClick={handleLogoutClick}>
                                <div className="flex items-center text-danger gap-2">
                                    <img src="/icons/sidebar/logout.svg" alt="" />
                                    <span>Logout</span>
                                </div>
                            </DropdownItem>
                        </DropdownList>
                    }
                />
            </div>
        </div>
    );
};

export default Navbar;
