const Icon = (props: any) => {
    return (
        <svg width={6} height={8} viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.125.792L4.333 4 1.125 7.208"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
