import http from 'app/config/http';
import { IAirline } from 'app/types';

export const getAirlines = async (query: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/airlines`, {
        params: {
            q: query,
        },
    });
    return data as IAirline[];
};
