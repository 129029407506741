import moment from 'moment';
import http from 'app/config/http';
import { FC, useRef, useState } from 'react';
import { IBankTransaction } from 'app/types';
import Modal from 'app/components/Modal/Modal';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import { TRANSACTION_TYPES } from 'app/utils/constants';
import { formatCurrency } from 'app/utils/currency-utils';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';

interface TransactionSelectModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit: (_id: string, transactionId: string) => Promise<void>;
    isLoading: boolean;
    paymentId: string;
}

const TransactionSelectModal: FC<TransactionSelectModalProps> = (props) => {
    const { show, onClose, onSubmit, isLoading, paymentId } = props;

    const [pageCount, setPageCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [params] = useState<String>(`?page=${currentPage}`);

    const [selectedTransaction, setSelectedTransaction] = useState('');

    const unmappedTransactionQuery = useQuery<IBankTransaction[]>(['transactionUnmapped'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/accounts/unmapped${params}`);
        setPageCount(data.pageCount);

        return data.data;
    });

    const tableTop = useRef();

    const handlePageChange = (val: any) => {
        setSelectedTransaction('');
        setCurrentPage(val.selected + 1);
    };

    const handleSubmit = () => {
        onSubmit(paymentId, selectedTransaction);
    };

    return (
        <Modal className="" show={show} onClose={onClose}>
            <div className="text-2xl font-bold pl-6 pt-6 ">Select Bank Transaction</div>
            <>
                <div className="overflow-auto mt-5">
                    <table className="card-table text-sm">
                        <thead>
                            <tr className="bg-gray-50" ref={tableTop}>
                                <th className="th-1"></th>
                                <th className="th-1">Transaction ID</th>
                                <th className="th-1">Amount</th>
                                <th className="th-1">Bank</th>
                                <th className="th-1">Account No.</th>
                                <th className="th-1">Date</th>
                                <th className="th-1">Type</th>
                                <th className="th-1">Agent</th>
                                <th className="th-1">Remarks</th>
                                <th className="th-1">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!unmappedTransactionQuery.isLoading && unmappedTransactionQuery.data.length === 0 && (
                                <tr>
                                    <td
                                        align="center"
                                        colSpan={10}
                                        className="py-12 font-medium text-base text-[#98A2B3]"
                                    >
                                        - No data available -
                                    </td>
                                </tr>
                            )}
                            {!unmappedTransactionQuery.isLoading &&
                                unmappedTransactionQuery.data.map((row) => (
                                    <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                        <td className="td-1">
                                            <input
                                                type="radio"
                                                name="test"
                                                checked={selectedTransaction === row._id}
                                                onChange={() => setSelectedTransaction(row._id)}
                                            />
                                        </td>

                                        <td className="td-1">
                                            <div className="font-semibold">{row._id}</div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">{formatCurrency(row.balanceUploaded)}</div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">{row.bankAccount.name}</div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">{row.bankAccount.account.number}</div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                {moment(row.transactionDate, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="flex flex-col items-center">
                                                <div className="font-semibold">{TRANSACTION_TYPES[row.type].label}</div>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold whitespace-nowrap">{row.agencyUser.name}</div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold whitespace-nowrap">
                                                {row.transactionRemark}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="min-w-max">
                                                <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                                <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {!unmappedTransactionQuery.isLoading && unmappedTransactionQuery.data.length !== 0 && (
                        <LoaderButton
                            onClick={handleSubmit}
                            isDisabled={selectedTransaction === ''}
                            isLoading={isLoading}
                            type="button"
                            className="text-white bg-primary px-4 py-3 ml-6 mb-6 rounded-md text-base leading-5 font-semibold min-w-[120px] "
                        >
                            Submit
                        </LoaderButton>
                    )}
                    {unmappedTransactionQuery.isLoading && (
                        <div className="flex justify-center items-center min-h-[180px]">
                            <Spinner />
                        </div>
                    )}
                </div>

                {pageCount > 1 && (
                    <div className="px-4 pb-3 ">
                        <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                    </div>
                )}
            </>
        </Modal>
    );
};

export default TransactionSelectModal;
