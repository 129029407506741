import moment from 'moment';
import { FC } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import update from 'immutability-helper';
import useDialog from 'app/hooks/use-dialog';
import Spinner from 'app/components/Spinner/Spinner';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface SubAgencyRequestTableProps {
    subAgencyRequests: any;
    isLoading: boolean;
    params: any;
}

const getStatusClass = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'yellow';
        case 'COMPLETED':
            return 'green';
        case 'CANCELLED':
            return 'red';
    }
};

const getStatusText = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'Pending';
        case 'COMPLETED':
            return 'Completed';
        case 'CANCELLED':
            return 'Cancelled';
    }
};

const SubAgencyRequestTable: FC<SubAgencyRequestTableProps> = (props) => {
    const { subAgencyRequests, isLoading, params } = props;
    const queryClient = useQueryClient();
    const deleteDialog = useDialog<string>();

    const deleteMutation = useMutation(async (values: any) => {
        const { data } = await http.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agency-registrations/${values._id}`
        );
        return data;
    });

    const handleDeleteAgencyRequest = async () => {
        try {
            const _id = deleteDialog.data;
            await deleteMutation.mutateAsync({ _id });
            toast.success('Agency request deleted successfully.');
            const index = subAgencyRequests.findIndex((b: any) => b._id === _id);

            queryClient.setQueryData(['subAgencyRequestListPaginated', params], (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                    // subAgencyRequests: {
                    //     $splice: [[index, 1]],
                    // },
                });
            });
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <>
            <div className="overflow-auto pt-6">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="th-1">Agency Name & Address</th>
                            <th className="th-1">Agency Phone</th>
                            <th className="th-1">Agency Email</th>
                            <th className="th-1">Agent</th>
                            <th className="th-1">Created At</th>
                            <th className="th-1">Status</th>
                            <th className="th-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && subAgencyRequests.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}

                        {!isLoading &&
                            subAgencyRequests.map((row: any, i: number) => (
                                <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                    <td className="td-1">
                                        <div className="font-semibold">{row.subAgencyDetails.name}</div>
                                        <p className="mt-1">{row.subAgencyDetails.address}</p>
                                    </td>

                                    <td className="td-1">
                                        <div className="font-semibold">{row.subAgencyDetails.phoneNumber}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{row.subAgencyDetails.email}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{row.personalDetails.name}</div>
                                        <p className="text-xs">{row.personalDetails.designation}</p>
                                        <p className="text-xs mt-1">{row.personalDetails.phoneNumber}</p>
                                    </td>
                                    <td className="td-1">
                                        <div className="min-w-max">
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                            {getStatusText(row.status)}
                                        </StatusBadge>
                                    </td>
                                    <td className="td-1">
                                        <Dropdown
                                            target={
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a className="dropdown-action">
                                                    <MenuDotIcon />
                                                </a>
                                            }
                                            menu={
                                                <DropdownList>
                                                    <DropdownItem to={`/sub-agency-requests/${row._id}`}>
                                                        View
                                                    </DropdownItem>
                                                    {/* <DropdownItem to={`/sub-agencies/${row._id}/users`}>Users</DropdownItem>
                                            <DropdownItem to={`/sub-agencies/${row._id}/edit`}>Edit</DropdownItem> */}
                                                    {row.status === 'PENDING' && (
                                                        <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                            <span className="text-red-500">Delete</span>
                                                        </DropdownItem>
                                                    )}
                                                </DropdownList>
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}
            </div>
            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this agency registration request?'}
                onClose={deleteDialog.hide}
                onConfirm={handleDeleteAgencyRequest}
                isLoading={deleteMutation.isLoading}
            />
        </>
    );
};

export default SubAgencyRequestTable;
