import React, { useContext } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UseMutationResult } from '@tanstack/react-query';
import { ICommissionPlan } from 'app/enums/commission-plan';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { CommissionPlanTypeEnum } from 'app/enums/commission-plan-type.enum';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { AddCommissionPlanContext } from 'app/modules/shared/context/AddCommissionPlanWrapper';

function AddCommissionParentForm<T>(props: {
    commissionBreakdownValidation: Yup.AnySchema;
    commissionBreakdownInitialValues: any; //fix this
    children: React.ReactNode;
    createCommissionPlanMutation: UseMutationResult<any, unknown, ICommissionPlan<T>, unknown>;
}) {
    const navigate = useNavigate();
    const { createCommissionPlanMutation } = props;
    const { setSelectedFareSupplier, selectedFareSupplier } = useContext(AddCommissionPlanContext);

    const validationSchema = Yup.object({
        name: Yup.string().trim().required('This field is required'),
        commissionFareSupplier: Yup.mixed().oneOf(Object.keys(CommissionPlanEnabledSuppliers)),
        commissionPlanType: Yup.mixed().oneOf([Object.keys(CommissionPlanTypeEnum)]),
        managementChargesPerPassenger: Yup.number()
            .min(0, 'The minimum value you can enter is 0.')
            .required('This field is required'),
        taxRates: Yup.object({
            gstRate: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
            commissionTdsRate: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
        commissionBreakdown: props.commissionBreakdownValidation,
    });

    const initialValues = {
        name: '',
        // commissionFareSupplier: CommissionPlanEnabledSuppliers.TRIP_JACK,
        commissionFareSupplier: selectedFareSupplier,
        commissionPlanType: CommissionPlanTypeEnum.SUB_AGENCY,
        managementChargesPerPassenger: 0,
        taxRates: {
            gstRate: 0,
            commissionTdsRate: 0,
        },
        commissionBreakdown: props.commissionBreakdownInitialValues,
    };

    const handleSubmit = async (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => {
        try {
            await createCommissionPlanMutation.mutateAsync({ ...values });
            navigate('/fare-management/commission-plan');
        } catch (e) {
            toast.error('Some thing went wrong');
        }
    };

    return (
        <div>
            <h4 className='font-bold'>Add Commission Plan</h4>

            <div className='card mt-8 px-6 py-5'>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ errors, setFieldValue }: FormikProps<typeof initialValues>) => (
                        <Form>
                            <div className='grid grid-cols-12 gap-6 mt-6'>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='name'>
                                        Plan Name
                                    </label>
                                    <Field type='text' id='name' name='name' className='form-control' />
                                    <div className='form-error'>
                                        <ErrorMessage name='name' />
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-12 gap-6 mt-6'>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='commissionFareSupplier'>
                                        Fare Supplier
                                    </label>
                                    <Field
                                        as='select'
                                        name='commissionFareSupplier'
                                        type='text'
                                        id='commissionFareSupplier'
                                        className='form-control'
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            setFieldValue('commissionFareSupplier', e.target.value);
                                            setSelectedFareSupplier(e.target.value as CommissionPlanEnabledSuppliers);
                                        }}
                                    >
                                        {Object.keys(CommissionPlanEnabledSuppliers).map((comFareSup: string) => (
                                            <option key={comFareSup} value={comFareSup}>
                                                {createReadableFareSupplierLabel(comFareSup as FareSupplierEnum)}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className='form-error'>
                                        <ErrorMessage name='commissionFareSupplier' />
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-12 gap-6 mt-6'>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='managementChargesPerPassenger'>
                                        Management Charges Per Passenger
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-prepend'>₹</div>
                                        <Field
                                            type='number'
                                            id={`managementChargesPerPassenger`}
                                            name={`managementChargesPerPassenger`}
                                            className='form-control pl-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='managementChargesPerPassenger' />
                                    </div>
                                </div>
                            </div>

                            <div className='grid grid-cols-12 gap-6 mt-6'>
                                <div className='col-span-4'>
                                    <label className='text-xs mb-2' htmlFor='taxRates.gstRate'>
                                        GST Rate on Management Fee
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-postpend'>%</div>
                                        <Field
                                            type='number'
                                            id={`taxRates.gstRate`}
                                            name={`taxRates.gstRate`}
                                            className='form-control pr-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='taxRates.gstRate' />
                                    </div>
                                </div>
                                <div className='col-span-4'>
                                    <label className='text-xs mb-2' htmlFor='taxRates.commissionTdsRate'>
                                        Commission TDS Rate
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-postpend'>%</div>
                                        <Field
                                            type='number'
                                            id={`taxRates.commissionTdsRate`}
                                            name={`taxRates.commissionTdsRate`}
                                            className='form-control pr-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='taxRates.commissionTdsRate' />
                                    </div>
                                </div>
                            </div>
                            {props.children}
                            <div className='mt-12'>
                                <LoaderButton
                                    type='submit'
                                    className='bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4'
                                    isLoading={createCommissionPlanMutation.isLoading}
                                >
                                    Create
                                </LoaderButton>

                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default AddCommissionParentForm;
