import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from 'app/store';
import AppRouter from 'AppRouter';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// Create  a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        },
    },
});

function App() {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <AppRouter />
                <ToastContainer autoClose={3000} hideProgressBar={true} />
            </QueryClientProvider>
        </Provider>
    );
}

export default App;
