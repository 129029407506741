import { ErrorMessage, Field } from 'formik';
import { getTripJacKFareComponentLabel } from 'app/modules/shared/utils/trip-jack-supplier.utils';
import { getFareSupplierFareComponentKeys } from 'app/modules/shared/utils/plan-commission.utils';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import * as Yup from 'yup';
import useCommissionPlan from 'app/modules/shared/hooks/use-commission-plan';
import { ITripJackCommissionPlan } from 'app/enums/trip-jack-commission-plan';
import AddCommissionParentForm from 'app/modules/fare-management/pages/ParentForm/ParentForm';

const TripJackCommissionValidation = Yup.object({
    NCM: Yup.object({
        flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
        percentage: Yup.number()
            .required('This field is required')
            .min(0, 'The minimum value you can enter is 0.')
            .max(100, 'The maximum value you can enter is 100.'),
    }),
});

const TripJackCommissionPlan = () => {
    const { createCommissionPlanMutation } = useCommissionPlan<ITripJackCommissionPlan>();
    const tripJackFareComponentKeys = getFareSupplierFareComponentKeys(CommissionPlanEnabledSuppliers.TRIP_JACK);

    const initialValues = {
        NCM: {
            flat: 0,
            percentage: 0,
        },
    };

    return (
        <AddCommissionParentForm
            commissionBreakdownValidation={TripJackCommissionValidation}
            commissionBreakdownInitialValues={initialValues}
            createCommissionPlanMutation={createCommissionPlanMutation}
        >
            <div className="grid grid-cols-12 gap-6 mt-6">
                {tripJackFareComponentKeys.map((fareComponentKey) => (
                    <div className="col-span-8" key={fareComponentKey}>
                        <div className="grid grid-cols-5 gap-4">
                            <div className="mt-auto">
                                <span className="mb-2">{getTripJacKFareComponentLabel(fareComponentKey)}</span>
                                <div className="form-error" />
                            </div>
                            <div className="col-span-2">
                                <label className="text-xs" htmlFor="">
                                    Flat
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field
                                        type="number"
                                        id={`commissionBreakdown.${fareComponentKey}.flat`}
                                        name={`commissionBreakdown.${fareComponentKey}.flat`}
                                        className="form-control pl-8"
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name={`commissionBreakdown.${fareComponentKey}.flat`} />
                                </div>
                            </div>
                            <div className="col-span-2">
                                <label className="text-xs" htmlFor="">
                                    Percentage
                                </label>
                                <div className="input-group">
                                    <div className="input-group-postpend">%</div>
                                    <Field
                                        type="number"
                                        id={`commissionBreakdown.${fareComponentKey}.percentage`}
                                        name={`commissionBreakdown.${fareComponentKey}.percentage`}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name={`commissionBreakdown.${fareComponentKey}.percentage`} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </AddCommissionParentForm>
    );
};

export default TripJackCommissionPlan;
