import { FC } from 'react';
import StatsCard from 'app/components/StatsCard/StatsCard';

interface SubAgencySeriesTopSectionProps {
    stats: any;
}

const SubAgencySeriesTopSection: FC<SubAgencySeriesTopSectionProps> = (props) => {
    const { stats } = props;

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Sub Agency Series Inventory</h5>
            </div>

            <div className="grid grid-cols-10 gap-6 mt-8">
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Total seats listed'} content={stats.totalSeats} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Total seats sold'} content={stats.totalSoldSeats} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Pending submission'} content={stats.pendingTickets} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Submitted tickets'} content={stats.submittedTickets} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Total tickets'} content={stats.totalTickets} />
                </div>
            </div>
        </>
    );
};

export default SubAgencySeriesTopSection;
