import React, { FC } from 'react';
import Spinner from '../Spinner/Spinner';

interface PageLoaderProps {}

const PageLoader: FC<PageLoaderProps> = (props) => {
    return (
        <div className="fixed top-[var(--navbar-height)] left-[var(--sidebar-width)] right-0 h-[calc(100vh-var(--navbar-height))] flex justify-center items-center">
            <Spinner />
        </div>
    );
};

export default PageLoader;
