import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { MenuDotIcon } from 'app/icons';
import { IStaticPage } from 'app/types';
import moment from 'moment';
import { FC } from 'react';
import { toast } from 'react-toastify';

interface StaticPageTableProps {
    staticPages: IStaticPage[];
    isLoading: boolean;
}

const StaticPageTable: FC<StaticPageTableProps> = (props) => {
    const { isLoading, staticPages } = props;
    const deleteDialog = useDialog<string>();
    const queryClient = useQueryClient();

    const deleteMutation = useMutation(async (values: any) => {
        const { data } = await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/static-page/${values._id}`);

        return data;
    });

    const handleDeleteStaticPage = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Static Page deleted successfully.');

            queryClient.fetchQuery(['StaticPage', 'List']);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50">
                        {/* <th className="th-1">Static Page Id</th> */}
                        <th className="th-1">Name</th>
                        <th className="th-1">Route</th>
                        <th className="th-1">Created At</th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && staticPages.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        staticPages.map((row) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                {/* <td className="td-1">
                                    <Link className="font-semibold text-blue-500" to={`/static-pages/${row._id}/edit`}>
                                        {row._id}
                                    </Link>
                                </td> */}
                                <td className="td-1">{row.name}</td>
                                <td className="td-1">{row.route}</td>
                                <td className="td-1">
                                    <div className="min-w-max">
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>

                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className="dropdown-action">
                                                <MenuDotIcon />
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/static-pages/${row._id}/edit`}>Edit</DropdownItem>
                                                <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                    <span className="text-red-500">Delete</span>
                                                </DropdownItem>
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this page?'}
                onClose={deleteDialog.hide}
                onConfirm={handleDeleteStaticPage}
                isLoading={deleteMutation.isLoading}
            />
        </div>
    );
};

export default StaticPageTable;
