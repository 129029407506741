/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import {
    arrow,
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    Placement,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
} from '@floating-ui/react-dom-interactions';

interface TooltipProps {
    children: ReactNode;
    content: ReactNode;
    placement?: Placement;
    backgroundColor?: string;
    triggers?: string[];
}

const Tooltip: FC<TooltipProps> = (props) => {
    const { children, content, placement: inputPlacement, backgroundColor, triggers } = props;

    const [open, setOpen] = useState<boolean>(false);

    const enabled = useMemo(() => {
        return {
            click: triggers.includes('click'),
            hover: triggers.includes('hover'),
        };
    }, [triggers]);

    const arrowRef = useRef(null);
    const {
        context,
        x,
        y,
        reference,
        floating,
        strategy,
        placement,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: inputPlacement,
        middleware: [flip(), shift(), offset(10), arrow({ element: arrowRef })],
        open: open,
        onOpenChange: setOpen,
    });

    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[placement.split('-')[0]];

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context, { enabled: enabled.click }),
        useHover(context, { enabled: enabled.hover, handleClose: safePolygon() }),
        useDismiss(context),
    ]);

    return (
        <>
            <div ref={reference} className="cursor-pointer relative" {...getReferenceProps()}>
                {children}
            </div>

            <FloatingPortal>
                {open && (
                    <>
                        {content && (
                            <div
                                ref={floating}
                                className="rounded-lg drop-shadow"
                                style={{
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                    width: 'max-content',
                                    backgroundColor: backgroundColor,
                                }}
                                {...getFloatingProps()}
                            >
                                {content}
                                <div
                                    ref={arrowRef}
                                    className="absolute w-4 h-4 rotate-45"
                                    style={{
                                        left: arrowX != null ? arrowX : '',
                                        top: arrowY != null ? arrowY : '',
                                        right: '',
                                        bottom: '',
                                        [staticSide]: '-4px',
                                        backgroundColor: backgroundColor,
                                    }}
                                ></div>
                            </div>
                        )}
                    </>
                )}
            </FloatingPortal>
        </>
    );
};

Tooltip.defaultProps = {
    placement: 'top',
    backgroundColor: '#fff',
    triggers: ['hover'],
};

export default Tooltip;
