import { FC } from 'react';
import http from 'app/config/http';
import { useParams } from 'react-router-dom';
import useDialog from 'app/hooks/use-dialog';
import { useQuery } from '@tanstack/react-query';
import styles from './subAgencyRequestView.module.scss';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import AddSubAgencyRequestDialog from './components/AddSubAgencyRequestModal/AddSubAgencyRequestModal';

const getStatusClass = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'yellow';
        case 'COMPLETED':
            return 'green';
        case 'CANCELLED':
            return 'red';
    }
};

const getStatusText = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'Pending';
        case 'COMPLETED':
            return 'Completed';
        case 'CANCELLED':
            return 'Cancelled';
    }
};

interface SubAgencyRequestViewProps {}

const SubAgencyRequestView: FC<SubAgencyRequestViewProps> = (props) => {
    const { requestId } = useParams();
    const subAgencyAddDialog = useDialog();

    const query = useQuery(['requestAgencyId'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agency-registrations/${requestId}`
        );

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    const reqAgency = query.data;

    const handleViewFile = (type: string) => {
        window.open(`${reqAgency.documents.find((file: any) => file.name === type).url}`);
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Sub Agency Request</h5>
                {reqAgency.status !== 'COMPLETED' && (
                    <button
                        onClick={subAgencyAddDialog.show}
                        className="text-center bg-primary text-white text-sm font-semibold px-8 py-3 rounded-lg cursor-pointer disabled:cursor-default "
                        disabled={reqAgency.status === 'COMPLETED'}
                    >
                        Add Agency
                    </button>
                )}
            </div>

            <div className="card px-6 mt-8 pt-5 pb-10">
                {/* Agency Details */}
                <div>
                    <div className="font-bold">Agency Details </div>
                    <div className="grid grid-cols-12 gap-6 mt-8">
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Agency Name</label>
                            <div className="font-semibold py-2">{reqAgency.subAgencyDetails.name}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Agency Address</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.subAgencyDetails.address}</div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2">GST Number</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.subAgencyDetails.gst.number}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">PAN Number</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.subAgencyDetails.pan.number}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Agency Email</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.subAgencyDetails.email}</div>
                        </div>
                        <div className="col-span-4 flex flex-col">
                            <label className="text-xs mb-2">Request Status</label>
                            <div>
                                <StatusBadge
                                    className="font-semibold py-2 flex gap-2"
                                    color={getStatusClass(reqAgency.status)}
                                >
                                    {getStatusText(reqAgency.status)}
                                </StatusBadge>
                            </div>
                            {/* <div className="">
                                {reqAgency.subAgencyDetails.phoneNumber}
                            </div> */}
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Agency Contact</label>
                            <div className="font-semibold py-2 flex gap-2">
                                {reqAgency.subAgencyDetails.phoneNumber}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Personal Details */}
                <div className="mt-6">
                    <div className="font-bold">Personal Details</div>
                    <div className="grid grid-cols-12 gap-6 mt-8">
                        <div className="col-span-4">
                            <label className="text-xs mb-2">First & Last Name</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.personalDetails.name}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Designation</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.personalDetails.designation}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Email</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.personalDetails.email}</div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2">Mobile Number</label>
                            <div className="font-semibold py-2 flex gap-2">{reqAgency.personalDetails.phoneNumber}</div>
                        </div>
                    </div>
                </div>

                {/* Documents */}
                <div className="">
                    <div className="mt-6 flex gap-4  text-center ">
                        <div className={`${styles['file-card']}   flex justify-center border-none `}>
                            <div className="text-xl font-bold">Documents</div>
                        </div>
                        {/* KYC */}

                        <div className={styles['file-card']}>
                            <div className="text-4 font-bold ">
                                <div className="leading-5">Personal KYC</div>
                                <div className="text-xs font-normal mt-1   "></div>
                            </div>

                            <div className="relative mt-4 w-full">
                                <div
                                    onClick={() => handleViewFile('Personal KYC')}
                                    className="text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis "
                                >
                                    View
                                </div>
                            </div>
                        </div>

                        {/* agency registration */}
                        <div className={styles['file-card']}>
                            <div className="text-4 font-bold ">
                                <div className="leading-5">Agency Registration</div>
                                <div className="text-xs font-normal mt-1   "></div>
                            </div>

                            <div className="relative mt-4 w-full">
                                <div
                                    onClick={() => handleViewFile('Agency Registration')}
                                    className="text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis "
                                >
                                    View
                                </div>
                            </div>
                        </div>
                        {/* pan card */}
                        <div className={styles['file-card']}>
                            <div className="text-4 font-bold ">
                                <div className="leading-5">PAN Card</div>
                                <div className="text-xs font-normal mt-1 "></div>
                            </div>

                            <div className="relative mt-4 cursor-pointer w-full">
                                <div
                                    onClick={() => handleViewFile('PAN Card')}
                                    className="text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis "
                                >
                                    View
                                </div>
                            </div>
                        </div>
                        {/* GST Registration */}
                        {reqAgency.documents.length > 3 && (
                            <div className={styles['file-card']}>
                                <div className="text-4 font-bold ">
                                    <div className="leading-5">GST Registration</div>
                                    <div className="text-xs font-normal mt-1   "></div>
                                </div>

                                <div className="relative mt-4 cursor-pointer w-full">
                                    <div
                                        onClick={() => handleViewFile('GST Registration')}
                                        className="text-4 font-medium text-[#6F0214] cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis"
                                    >
                                        View
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <AddSubAgencyRequestDialog
                    onClose={subAgencyAddDialog.hide}
                    show={subAgencyAddDialog.isOpen}
                    reqAgency={reqAgency}
                />
            </div>
        </>
    );
};

export default SubAgencyRequestView;
