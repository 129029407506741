import React, { FC, useEffect, useRef } from 'react';
// @ts-ignore
import * as PDF417 from 'pdf417-generator';

interface IBarCodeGenerator {
    input: string;
}

const BarCodeGenerator: FC<IBarCodeGenerator> = (props) => {
    const { input } = props;
    const barCodeRef = useRef();

    useEffect(() => {
        PDF417.draw(input, barCodeRef.current, 3.2);
    }, [input]);

    return (
        <>
            <canvas className='h-16' ref={barCodeRef} />
        </>
    );
};

export default BarCodeGenerator;
