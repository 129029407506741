/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'app/components/Modal/Modal';
import useDialog from 'app/hooks/use-dialog';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface ReportsProps {}

const Reports: FC<ReportsProps> = (props) => {
    const dialog = useDialog();

    return (
        <>
            <h5 className="font-bold">Reports</h5>

            <div className="mt-8">
                <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-1">
                        <div className="card px-6 py-5 h-full">
                            <div className="flex items-center gap-2">
                                <img className="h-4" src="/icons/reports/receivable.svg" alt="" />
                                <h6>Receivables</h6>
                            </div>

                            <div className="flex flex-col space-y-3 mt-4">
                                <Link
                                    to="/account/reports/sub-agency-receivables"
                                    className="text-blue-500 font-medium text-sm"
                                >
                                    Sub Agency Balances
                                </Link>
                            </div>
                        </div>
                    </div>{' '}
                    <div className="col-span-1">
                        <div className="card px-6 py-5 h-full">
                            <div className="flex items-center gap-2">
                                <img className="h-4" src="/icons/reports/payable.svg" alt="" />
                                <h6>Payables</h6>
                            </div>

                            <div className="flex flex-col space-y-3 mt-4">
                                <Link
                                    to="/account/reports/my-series-payables"
                                    className="text-blue-500 font-medium text-sm"
                                >
                                    MySeries Balances
                                </Link>

                                <a onClick={dialog.show} className="text-blue-500 font-medium text-sm cursor-pointer">
                                    Fare Supplier Balances
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="px-10 py-10 w-[480px] max-w-full h-[280px] max-h-full flex items-center justify-center"
                show={dialog.isOpen}
                onClose={dialog.hide}
            >
                <h3 className="text-gray-900 text-center">Coming Soon</h3>
            </Modal>
        </>
    );
};

export default Reports;
