// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_navbar__oYrxb {\n  box-shadow: 0px 1px 0px rgba(18, 32, 59, 0.09);\n  height: var(--navbar-height);\n}", "",{"version":3,"sources":["webpack://./src/app/components/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACI,8CAAA;EACA,4BAAA;AACJ","sourcesContent":[".navbar {\n    box-shadow: 0px 1px 0px rgba(18, 32, 59, 0.09);\n    height: var(--navbar-height);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "Navbar_navbar__oYrxb"
};
export default ___CSS_LOADER_EXPORT___;
