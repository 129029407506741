import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createFeatureRequest, deleteFeatureRequest } from 'app/api/services/feature-request.service';
import { getAllFeatureRequestKey } from './feature-request.keys';
import { IRequestFeature } from 'app/types/feature-request';
import { produce } from 'immer';

export const useFeatureRequest = () => {
    const queryClient = useQueryClient();

    const createFeatureRequestMutation = useMutation((payload: FormData) => createFeatureRequest(payload));
    const deleteFeatureRequestMutation = useMutation((requestId: string) => deleteFeatureRequest(requestId));

    const removeFeatureRequestFromCache = (featureRequestId: string) => {
        queryClient.setQueryData<IRequestFeature[]>(getAllFeatureRequestKey(), (prev) => {
            const index = prev.findIndex((p) => p._id === featureRequestId);
            return produce(prev, (draft) => {
                draft.splice(index, 1);
                return draft;
            });
        });
    };

    return { createFeatureRequestMutation, deleteFeatureRequestMutation, removeFeatureRequestFromCache };
};
