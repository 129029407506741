import React from 'react';
import * as Yup from 'yup';

import useCommissionPlan from 'app/modules/shared/hooks/use-commission-plan';
import { ErrorMessage, Field } from 'formik';
import AddCommissionParentForm from 'app/modules/fare-management/pages/ParentForm/ParentForm';

const TravelBoutiqueCommissionPlan = () => {
    const TravelBoutiqueCommissionValidation = Yup.object({
        netCommission: Yup.object({
            flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
            percentage: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
    });

    const initialValues = {
        netCommission: {
            flat: 0,
            percentage: 0,
        },
    };

    const { createCommissionPlanMutation } = useCommissionPlan();

    return (
        <AddCommissionParentForm
            commissionBreakdownValidation={TravelBoutiqueCommissionValidation}
            commissionBreakdownInitialValues={initialValues}
            createCommissionPlanMutation={createCommissionPlanMutation}
        >
            <div className='grid grid-cols-12 gap-6 mt-6'>
                <div className='col-span-8'>
                    <div className='grid grid-cols-5 gap-4'>
                        <div className='mt-auto'>
                            <span className='mb-2'>Net Commission</span>
                            <div className='form-error' />
                        </div>
                        <div className='col-span-2'>
                            <label className='text-xs' htmlFor=''>
                                Flat
                            </label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>₹</div>
                                <Field
                                    type='number'
                                    id={`commissionBreakdown.netCommission.flat`}
                                    name={`commissionBreakdown.netCommission.flat`}
                                    className='form-control pl-8'
                                />
                            </div>
                            <div className='form-error'>
                                <ErrorMessage name={`commissionBreakdown.netCommission.flat`} />
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <label className='text-xs' htmlFor=''>
                                Percentage
                            </label>
                            <div className='input-group'>
                                <div className='input-group-postpend'>%</div>
                                <Field
                                    type='number'
                                    id={`commissionBreakdown.netCommission.percentage`}
                                    name={`commissionBreakdown.netCommission.percentage`}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-error'>
                                <ErrorMessage name={`commissionBreakdown.netCommission.percentage`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AddCommissionParentForm>
    );
};

export default TravelBoutiqueCommissionPlan;
