import * as Yup from 'yup';
import http from 'app/config/http';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IKafilaCredentialForm } from '../../types/kafila-credential-form';

const kafilaCredentialsFormSchema = Yup.object({
    baseUrl: Yup.string().required('This field is required'),
    agentId: Yup.string().required('This field is required'),
    username: Yup.string().required('This field is required'),
    password: Yup.string()
        .required('This field is required')
        .test('password', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
});

interface IKafilaCredentialsFormProps {
    baseUrl: string;
    agentId: string;
    username: string;
    password: string;
}

const KafilaCredentialsForm: FC<IKafilaCredentialsFormProps> = (props) => {
    const initialValues: IKafilaCredentialForm = useMemo(() => {
        return {
            baseUrl: props.baseUrl || '',
            agentId: props.agentId || '',
            username: props.username || '',
            password: props.password || '',
        };
    }, [props]);

    const handleSubmit = async (values: IKafilaCredentialForm) => {
        try {
            const authKeys = {
                credentials: {
                    agentId: values.agentId,
                    username: values.username,
                    password: values.password,
                },
                baseUrl: values.baseUrl,
            };

            await mutation.mutateAsync(authKeys);

            toast.success('Credentials saved successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
            console.log(ex);
        }
    };

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/kafila`, payload);
        return data;
    });

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={kafilaCredentialsFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className='grid grid-cols-12 gap-4 mt-8'>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='baseUrl'>
                                Base Url
                            </label>
                            <Field
                                type='text'
                                id='baseUrl'
                                name='baseUrl'
                                className='form-control'
                                placeholder='Enter base url'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='baseUrl' />
                            </div>
                        </div>

                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='agentId'>
                                Agent ID
                            </label>
                            <Field
                                type='text'
                                id='agentId'
                                name='agentId'
                                className='form-control'
                                placeholder='Enter your Kafila agent ID'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='agentId' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='username'>
                                Username
                            </label>
                            <Field
                                type='text'
                                id='username'
                                name='username'
                                className='form-control'
                                placeholder='Enter your Kafila username'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='username' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='password'>
                                Password
                            </label>
                            <Field
                                type='text'
                                id='password'
                                name='password'
                                className='form-control'
                                placeholder='Enter your Kafila password'
                                onFocus={() => setFieldValue('password', '')}
                            />
                            <div className='form-error'>
                                <ErrorMessage name='password' />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type='submit'
                        className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                        isLoading={mutation.isLoading}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default KafilaCredentialsForm;
