import { FC } from 'react';
import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { ISubAgencyUser } from 'app/types';
import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';

const PasswordFormSchema = Yup.object().shape({
    password: Yup.string()
        .matches(/^\S*$/, 'Password cannot contain spaces')
        .required('This field is required')
        .min(5, 'The password should be atleast 5 characters long'),
    confirmPassword: Yup.string()
        .matches(/^\S*$/, 'Password cannot contain spaces')
        .required('This field is required')
        .when('password', {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string()
                .trim()
                .oneOf([Yup.ref('password')], 'Passwords do not match'),
        }),
});

const initialValues: any = {
    password: '',
    confirmPassword: '',
};

interface ChangePasswordFormProps {
    subAgent: ISubAgencyUser;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {
    const { subAgent } = props;

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgent.subAgency}/sub-agency-users/${subAgent._id}/password`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await mutation.mutateAsync({ password: values.password });

            toast.success('Password changed successfully.');

            formikHelpers.resetForm();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.data.message || 'Some error occured, please try again.');
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={PasswordFormSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className="grid grid-cols-12 gap-6 mt-8">
                    <div className="col-span-6">
                        <label className="text-xs mb-2" htmlFor="password">
                            NEW PASSWORD
                        </label>
                        <Field type="password" id="password" name="password" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="col-span-6">
                        <label className="text-xs mb-2" htmlFor="confirmPassword">
                            CONFIRM PASSWORD
                        </label>
                        <Field type="password" id="confirmPassword" name="confirmPassword" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="confirmPassword" />
                        </div>
                    </div>
                </div>

                <LoaderButton
                    type="submit"
                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-10"
                    isLoading={mutation.isLoading}
                >
                    Change
                </LoaderButton>
            </Form>
        </Formik>
    );
};

export default ChangePasswordForm;
