import { FC } from 'react';

interface ITicketInformationList {
    htmlContent: string;
}

const TicketInformationList: FC<ITicketInformationList> = (props) => {
    return (
        <div className='mt-12 mb-12 px-10'>
            <div className='text-base font-semibold text-primary'>Important Information</div>
            {/* <ul className='mt-5 list-disc list-outside text-xs pl-3'>
                <li>
                    Check-in Time : Passengers are advised to reach airport 2-3 hrs before departure. Check-in procedure
                    and baggage drop will close 1 hour before departure
                </li>
                <li className='mt-2'>
                    DGCA passenger charter : Please refer to passenger charter on the DGCA website.
                </li>
                <li className='mt-2'>
                    Please do not share your personal banking and security details like passwords, CVV, etc. with any
                    third person. For any query, please reach out to us on our official customer care number.
                </li>
                <li className='mt-2'>
                    Valid ID proof needed : Carry a valid photo identification proof (Driver Licence, Aadhar Card, Pan
                    Card or any other Government recognised photo identification)
                </li>
                <li className='mt-2'>For date-change requests please contact our customer care.</li>
            </ul> */}
            <div
                className='text-xs  custom-ticket-html-styles   '
                dangerouslySetInnerHTML={{ __html: props.htmlContent }}
            />
        </div>
    );
};

export default TicketInformationList;
