import { ISeriesTicket } from 'app/types/series-ticket';
import { FC, useLayoutEffect, useRef } from 'react';
import { formatDate } from 'app/utils/date-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { formatCurrency } from 'app/utils/currency-utils';
import { MenuDotIcon } from 'app/icons';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import update from 'immutability-helper';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from 'app/components/Spinner/Spinner';
import ReactDOM from 'react-dom';

interface SubAgencySeriesInventoryTableProps {
    seriesTickets: ISeriesTicket[];
    isLoading: boolean;
    QueryIdentifier: any[];
    handleIsLiveToggle: any;
    showIsLive: boolean;
}

const SubAgencySeriesInventoryTable: FC<SubAgencySeriesInventoryTableProps> = (props) => {
    const { seriesTickets, isLoading, QueryIdentifier, handleIsLiveToggle, showIsLive } = props;
    const nagivate = useNavigate();

    const tableTop = useRef();

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const queryClient = useQueryClient();

    const changeLiveNetworkMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/series-supplier-ticket/${values._id}/is-live-on-network`,
            { isLiveOnNetwork: values.isLiveOnNetwork }
        );
        return data;
    });

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'yellow';
            case 'COMPLETED':
            case 'SUBMITTED':
                return 'green';
            case 'CANCELLED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Naming Pending';
            case 'COMPLETED':
                return 'Completed';
            case 'SUBMITTED':
                return 'Submitted';
            case 'CANCELLED':
                return 'Cancelled';
        }
    };

    const handleLiveOnNetworkChange = async (row: ISeriesTicket, isChecked: boolean) => {
        queryClient.setQueryData(QueryIdentifier, (prevValues: any) => {
            const index = prevValues.findIndex((val: any) => val._id === row._id);

            return update(prevValues, {
                [index]: { isLiveOnNetwork: { $set: isChecked } },
            });
        });

        try {
            await changeLiveNetworkMutation.mutateAsync({ _id: row._id, isLiveOnNetwork: isChecked });

            toast.success(`Series inventory  ${isChecked ? 'enabled' : 'disabled'} from network`);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
            queryClient.setQueryData(QueryIdentifier, (prevValues: any) => {
                const index = prevValues.findIndex((val: any) => val._id === row._id);

                return update(prevValues, {
                    [index]: { isLiveOnNetwork: { $set: !isChecked } },
                });
            });
        }
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50" ref={tableTop}>
                        <th className="th-1">Inventory ID</th>
                        <th className="th-1">Travel & Date</th>
                        <th className="th-1">Fare & Seats</th>
                        <th className="th-1">Flight Details</th>
                        <th className="th-1">Airline PNR</th>
                        <th className="th-1">Listed By</th>
                        <th className="th-1">Created At</th>
                        <th className="th-1 group">
                            <div>Live On Network</div>
                            <div
                                onClick={() => handleIsLiveToggle(!showIsLive)}
                                className="cursor-pointer  hidden text-blue-500 text-xs  font-semibold  group-hover:block"
                            >
                                {showIsLive ? 'Show all' : 'Show live only'}
                            </div>
                        </th>
                        <th className="th-1" />
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && seriesTickets.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        seriesTickets.map((row) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div
                                        className="font-semibold text-blue-500 cursor-pointer"
                                        onClick={() => nagivate(`/series-inventory/${row._id}/bookings/SAG`)}
                                    >
                                        {row._id}
                                    </div>
                                    <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                        {getStatusText(row.status)}
                                    </StatusBadge>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">
                                        {row.trip.departure.airport.city} to {row.trip.arrival.airport.city}
                                    </div>
                                    <p className="mt-1">{formatDate(row.trip.departure.date, 'DD MMM, YYYY')}</p>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{`${formatCurrency(row.farePerSeat)} / Seat`}</div>
                                    <p className="mt-1">
                                        {row.bookings.length}/{row.totalSeats} Seats Sold
                                    </p>
                                </td>
                                <td className="td-1">
                                    <div className="flex items-center gap-3">
                                        <img className="w-9 h-9" crossOrigin="anonymous" src={row.flight.logo} alt="" />
                                        <div>
                                            <div className="font-semibold">{row.flight.name}</div>
                                            <p className="mt-1">
                                                {row.flight.code} {row.flight.number}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.pnr}</div>
                                </td>
                                <td className="td-1">
                                    <Link
                                        to={`/sub-agencies/${row?.listedBy?._id}/detials`}
                                        className="text text-primary cursor-pointer font-semibold"
                                    >
                                        {row?.listedBy?.name}
                                    </Link>
                                </td>
                                <td className="td-1">
                                    <div className="min-w-max">
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">
                                        <input
                                            type="checkbox"
                                            className="form-switch success"
                                            checked={row.isLiveOnNetwork}
                                            onChange={(e) => handleLiveOnNetworkChange(row, e.target.checked)}
                                        />
                                    </div>
                                </td>
                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className="dropdown-action">
                                                <MenuDotIcon />{' '}
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/series-inventory/${row._id}/bookings/SAG`}>
                                                    View Bookings
                                                </DropdownItem>
                                                {/* <DropdownItem to={`/series-inventory/${row._id}/edit`}>
                                                    Edit
                                                </DropdownItem>
                                                <DropdownItem to={`/series-inventory/${row._id}/revise-markup`}>
                                                    <span className="text-blue-500">Revise Markup</span>
                                                </DropdownItem>
                                                <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                    <span className="text-red-500">Delete</span>
                                                </DropdownItem> */}
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}
            {/* <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this series?'}
                onClose={deleteDialog.hide}
                onConfirm={handleUserDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} /> */}
        </div>
    );
};

export default SubAgencySeriesInventoryTable;
