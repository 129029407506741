import { FC } from 'react';
import { MenuDotIcon } from 'app/icons';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'app/utils/date-utils';
import { ISeriesTicket } from 'app/types/series-ticket';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { Dropdown, DropdownList, DropdownItem } from 'app/components/Dropdown/Dropdown';

interface UpcomingDepartureTableProps {
    bookings: ISeriesTicket[];
}

const UpcomingDepartureTable: FC<UpcomingDepartureTableProps> = (props) => {
    const navigate = useNavigate();

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
            case 'SUBMISSION_PENDING':
            case 'AWAITING_PNR':
                return 'yellow';
            case 'COMPLETED':
            case 'SUBMITTED':
                return 'green';
            case 'CANCELLED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Naming Pending';
            case 'AWAITING_PNR':
                return 'Awaiting PNR';

            case 'SUBMISSION_PENDING':
                return 'Submission Pending';
            case 'SUBMITTED':
                return 'Submitted';
            case 'COMPLETED':
                return 'Completed';
            case 'CANCELLED':
                return 'Cancelled';
        }
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <tbody>
                    {props.bookings.map((row) => (
                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                            <td className="px-3 py-5">
                                <div
                                    className="font-semibold text-blue-500 cursor-pointer "
                                    onClick={() => navigate(`/series-inventory/${row._id}/bookings/AG`)}
                                >
                                    {row._id}{' '}
                                </div>
                                <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                    {getStatusText(row.status)}
                                </StatusBadge>
                            </td>
                            <td className="px-3 py-5">
                                <div className="font-semibold">
                                    {row.trip.departure.airport.city} to {row.trip.arrival.airport.city}
                                </div>
                                <p className="mt-1">{formatDate(row.trip.departure.date, 'DD MMM, YYYY')}</p>
                            </td>
                            <td className="px-3 py-5">
                                <div className="font-semibold">{formatCurrency(row.totalfare)}</div>
                                <p className="mt-1">
                                    {row.bookings.length} / {row.totalSeats} Sold
                                </p>
                            </td>
                            <td className="px-3 py-5">
                                <div className="flex items-center gap-3">
                                    <img className="w-9 h-9" crossOrigin="anonymous" src={row.flight.logo} alt="" />
                                    <div>
                                        <div className="font-semibold">{row.flight.name}</div>
                                        <p className="mt-1">
                                            {row.flight.code} {row.flight.number}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td className="px-3 py-5">
                                <Dropdown
                                    target={
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a className="dropdown-action">
                                            <MenuDotIcon />
                                        </a>
                                    }
                                    menu={
                                        <DropdownList>
                                            <DropdownItem to={`/series-inventory/${row._id}/bookings/AG`}>
                                                View Ticket
                                            </DropdownItem>
                                            {/* <DropdownItem onClick={() => window.open(`/bookings/${row._id}/invoice`)}>
                                                Invoice
                                            </DropdownItem> */}
                                        </DropdownList>
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UpcomingDepartureTable;
