import * as Yup from 'yup';
import http from 'app/config/http';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IFareBoutiqueCredentialForm } from '../../types/fare-boutique-credential-form';

const fareBoutiqueCredentialsFormSchema = Yup.object({
    baseUrl: Yup.string().required('This field is required'),
    partnerUserId: Yup.string().required('This field is required'),
    token: Yup.string()
        .required('This field is required')
        .test('token', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
});

interface IFareBoutiqueCredentialsFormProps {
    baseUrl: string;
    partnerUserId: string;
    token: string;
}

const FareBoutiqueCredentialsForm: FC<IFareBoutiqueCredentialsFormProps> = (props) => {
    const initialValues: IFareBoutiqueCredentialForm = useMemo(() => {
        return {
            baseUrl: props.baseUrl || '',
            partnerUserId: props.partnerUserId || '',
            token: props.token || '',
        };
    }, [props]);

    const handleSubmit = async (values: IFareBoutiqueCredentialForm) => {
        try {
            const authKeys = {
                credentials: {
                    partnerUserId: values.partnerUserId,
                    token: values.token,
                },
                baseUrl: values.baseUrl,
            };

            await mutation.mutateAsync(authKeys);

            toast.success('Credentials saved successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
            console.log(ex);
        }
    };

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/fare-boutique`,
            payload
        );
        return data;
    });

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={fareBoutiqueCredentialsFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className="grid grid-cols-12 gap-4 mt-8">
                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="baseUrl">
                                Base Url
                            </label>
                            <Field
                                type="text"
                                id="baseUrl"
                                name="baseUrl"
                                className="form-control"
                                placeholder="Enter base url"
                            />
                            <div className="form-error">
                                <ErrorMessage name="baseUrl" />
                            </div>
                        </div>

                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="partnerUserId">
                                Partner User ID
                            </label>
                            <Field
                                type="text"
                                id="partnerUserId"
                                name="partnerUserId"
                                className="form-control"
                                placeholder="Enter your Fare Boutique Partner User Id"
                            />
                            <div className="form-error">
                                <ErrorMessage name="partnerUserId" />
                            </div>
                        </div>
                        {/* <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="token">
                                Token
                            </label>
                            <Field
                                type="text"
                                id="token"
                                name="token"
                                className="form-control"
                                placeholder="Enter your Fare Boutique token"
                            />
                            <div className="form-error">
                                <ErrorMessage name="token" />
                            </div>
                        </div> */}
                        <div className="col-span-8">
                            <label className="text-xs mb-2" htmlFor="token">
                                Token
                            </label>
                            <Field
                                type="text"
                                id="token"
                                name="token"
                                className="form-control"
                                placeholder="Enter your Fare Boutique token"
                                onFocus={() => setFieldValue('token', '')}
                            />
                            <div className="form-error">
                                <ErrorMessage name="token" />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type="submit"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                        isLoading={mutation.isLoading}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default FareBoutiqueCredentialsForm;
