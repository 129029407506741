import http from 'app/config/http';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { ICcAvenue } from './ccAvenue.enum';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IPlugin } from 'app/types';
import { IGatewayCharges } from 'app/enums/gateway-payment-mode.enum';

const useCcAvenue = () => {
    const pluginId = 'ccavenue-payment';
    const queryClient = useQueryClient();

    const ccAvenueDataQuery = useQuery<ICcAvenue>(['CcAvenue', 'plugin', 'keys'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/ccavenue-payment`
        );
        return data;
    });

    const ccAvenueCredentialsUpdateMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/ccavenue-payment/credentials`,
            values
        );
        return data;
    });

    const ccAvenueGateWayChargesUpdateMutation = useMutation(async (payload: { gatewayCharges: IGatewayCharges }) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/ccavenue-payment/gateway-charges`,
            payload
        );
        return data;
    });

    const updateMutation = useMutation(async (values: any) => {
        await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/${pluginId}/is-enabled`, values);
    });

    const handlePluginActivationChange = async (e: any) => {
        const isChecked = e.target.checked;
        try {
            await updateMutation.mutateAsync({ isEnabled: isChecked });
            queryClient.setQueryData(['CcAvenue', 'plugin', 'keys'], (prev: IPlugin) => {
                return update(prev, {
                    isEnabled: { $set: isChecked },
                });
            });

            toast.success(`CC Avenue ${isChecked ? 'enabled' : 'disabled'} successfully`);
        } catch (err) {
            toast.error(
                `Some error occurred while ${isChecked ? 'deactivating' : 'activating'} the plugin, please try again`
            );
        } finally {
            queryClient.fetchQuery(['CcAvenue', 'plugin', 'keys']);
        }
    };

    return {
        isLoading: ccAvenueDataQuery.isLoading,
        ccAvenueDetails: ccAvenueDataQuery.data,
        ccAvenueCredentialsUpdateMutation: ccAvenueCredentialsUpdateMutation,
        ccAvenueGateWayChargesUpdateMutation,
        handlePluginToggle: handlePluginActivationChange,
        isToggling: updateMutation.isLoading,
    };
};

export default useCcAvenue;
