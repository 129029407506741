import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import FareruleForm from 'app/components/FareruleForm/FareruleForm';
import MarkupControlForm from 'app/components/MarkupControlForm/MarkupControlForm';
import MarkupHistoryTable from 'app/components/MarkupHistoryTable/MarkupHistoryTable';
import TripJackCredentialForm from '../shared/components/TripJackCredentialForm/TripJackCredentialForm';
import { Status } from 'app/enums/status.enum';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { ITripJackDetails } from '../shared/types/trip-jack';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import { mapFareSupplierStatusToBoolean } from '../shared/utils';
import TripJackSupplierMarkup from './components/TripJackSupplierMarkup/TripJackSupplierMarkup';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';
import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';

interface FareSupplierTripJackProps {}

const FareSupplierTripJack: FC<FareSupplierTripJackProps> = (props) => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
    } = useFareSupplierStatus<ITripJackDetails>({
        fareIdentifier: FareSupplierEnum.TRIP_JACK,
    });

    if (isLoading) return <PageLoader />;

    const data: ITripJackDetails = isError
        ? {
              apiKey: '',
              markup: 0,
              markupHistory: [] as IMarkUpHistory[],
              baseUrl: '',
              status: Status.DISABLED,
              fareRules: [],
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">TripJack Details</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-switch success"
                            checked={mapFareSupplierStatusToBoolean(data.status)}
                            onChange={(e) => handleToggleStatus(e.target.checked)}
                        />
                    </div>
                </div>

                <TripJackCredentialForm apiKey={data.apiKey} baseUrl={data.baseUrl} />
            </div>

            <FareSupplierAirlineFilter fareSupplier={FareSupplierIdEnum.TRIP_JACK} />

            <TripJackSupplierMarkup />

            {!isError && (
                <div className="card pt-5 mt-8">
                    <div className="px-6 font-bold">Markup Control & Fare Rules</div>

                    <div className="px-6 mt-8">
                        <MarkupControlForm
                            currentMarkup={data.markup}
                            fareSupplier={'trip-jack'} // usage::  /api/v1/agent/fare-suppliers/${fareSupplier}/markup
                            refetchQueryIdentifier={queryIdentifier}
                        />
                    </div>

                    <div className="px-6 mt-8">
                        <FareruleForm
                            fareRules={data.fareRules}
                            fareSupplier={'trip-jack'}
                            refetchQueryIdentifier={queryIdentifier}
                        />
                    </div>

                    <div className="mt-10">
                        {data.markupHistory.length > 0 && <MarkupHistoryTable markupHistory={data.markupHistory} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default FareSupplierTripJack;
