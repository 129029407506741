import BackLink from 'app/components/BackLink/BackLink';
import React from 'react';
import FlightForm from './components/FlightForm';

const ManualBookingForm = () => {
   return (
       <>
           <div className="mb-5">
               <BackLink />
           </div>

           <div className="card pt-4 pb-10 px-6">
               <div className="flex items-center justify-between mb-12">
                   <div className="font-bold">Add Manual Booking</div>
               </div>

               <FlightForm />
           </div>
       </>
   );
};

export default ManualBookingForm;
