import http from 'app/config/http';

export const getSearchResults = async <T = unknown>(searchParam: string, q: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/search/${searchParam}`, {
        params: {
            q,
        },
    });

    return { data: data.data.searchResults, q } as { data: T[]; q: string };
};
