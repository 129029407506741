import moment from 'moment';
import { MenuDotIcon } from 'app/icons';
import { Link } from 'react-router-dom';
import useDataQuery from 'app/hooks/useDataQuery';
import Spinner from 'app/components/Spinner/Spinner';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { CommissionPlanTypeEnum } from 'app/enums/commission-plan-type.enum';
import { getCommissionPlans } from 'app/api/services/fare-commission.service';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { CommissionPlanStatusEnum } from 'app/enums/commission-plan-status.enum';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface ICommissionPlan {
    _id: string;
    agency: string;
    name?: string;
    subagencies: string[];
    commissionPlanType: CommissionPlanTypeEnum;
    commissionFareSupplier: FareSupplierEnum;
    commissionBreakdown: {
        [key: string]: {
            flat: number;
            percentage: number;
        };
    };
    status: CommissionPlanStatusEnum;
    createdAt: string;
    updatedAt: string;
}

const CommissionPlanListPage = () => {
    const commissionPlansQuery = useDataQuery<ICommissionPlan[]>(['COMMISSION', 'PLANS'], getCommissionPlans);


    const { isLoading, data: commissionPlans } = commissionPlansQuery;

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Commission Plans</h5>
                <Link
                    to="/fare-management/commission-plan/add"
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add Commission Plan
                </Link>
            </div>
            <div className="card  mt-8">
                <div className="flex items-center justify-between px-6 pt-5">
                    {/* <TableSearch placeholder="Search for a payment by id, method" onSearch={handleSearch} /> */}
                    {/* <div className='flex items-center gap-3'>
                        <label className='text-xs' htmlFor='filter'>
                            FILTER
                        </label>
                        <select
                            id='filter'
                            className='border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium'
                            onChange={(e: any) => handleStatusFilterChange(e.target.value)}
                        >
                            <option value=''>All</option>
                            <option value='PENDING'>Pending</option>
                            <option value='APPROVED'>Approved</option>
                            <option value='REJECTED'>Rejected</option>
                        </select>
                    </div> */}
                </div>

                <div className="overflow-auto mt-5">
                    <table className="card-table text-sm">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="th-1">ID</th>
                                <th className="th-1">Name</th>
                                <th className="th-1">Supplier</th>
                                <th className="th-1">Applied on</th>
                                <th className="th-1">Created At</th>
                                <th className="th-1">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && commissionPlans.length === 0 && (
                                <tr>
                                    <td
                                        align="center"
                                        colSpan={10}
                                        className="py-12 font-medium text-base text-[#98A2B3]"
                                    >
                                        - No data available -
                                    </td>
                                </tr>
                            )}
                            {!isLoading &&
                                commissionPlans.map((row, i) => (
                                    <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                        <td className="td-1">
                                            <div className="font-semibold ">
                                                {/* <div className='font-semibold'>{row._id}</div> */}
                                                <Link
                                                    className="text-blue-500"
                                                    to={`/fare-management/commission-plan/${row._id}`}
                                                >
                                                    {' '}
                                                    {row._id}
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">{row.name}</div>
                                        </td>

                                        <td className="td-1">
                                            <div className="font-semibold">
                                                {createReadableFareSupplierLabel(row.commissionFareSupplier)}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">{row.subagencies.length}</div>
                                        </td>
                                        <td className="td-1">
                                            <div className="min-w-max">
                                                <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                                <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <Dropdown
                                                target={
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        <DropdownItem
                                                            to={`/fare-management/commission-plan/${row._id}`}
                                                        >
                                                            View
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            to={`/fare-management/commission-plan/${row._id}/associate`}
                                                            // onClick={() => rejectPDialog.show(row._id)}
                                                        >
                                                            <span className="">Associate</span>
                                                        </DropdownItem>
                                                        {/* <DropdownItem
                                                            onClick={() => unMappedTransactionDialog.show(row._id)}
                                                        >
                                                            <span className='text-green-500'>Approve</span>
                                                        </DropdownItem>

                                                        <DropdownItem onClick={() => rejectPDialog.show(row._id)}>
                                                            <span className='text-red-500'>Reject</span>
                                                        </DropdownItem> */}
                                                    </DropdownList>
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {isLoading && (
                        <div className="flex justify-center items-center min-h-[500px]">
                            <Spinner />
                        </div>
                    )}
                </div>

                <div className="px-4 pb-3 ">
                    {/* <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} /> */}
                </div>
            </div>
        </>
    );
};

export default CommissionPlanListPage;
