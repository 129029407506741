import * as Yup from 'yup';
import { useRef } from 'react';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import { DeleteIcon, PaperclipIcon } from 'app/icons';
import BackLink from 'app/components/BackLink/BackLink';
import { useFeatureRequest } from '../hooks/useFeatureRequest';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { RichTextEditor } from 'app/components/RichTextEditor/RichTextEditor';
import { useNavigate } from 'react-router-dom';

const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
const featureRequestSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    files: Yup.array().of(
        Yup.mixed().test('fileSize', 'File size is too large. Maximum size is 5MB.', (file) => {
            if (!file) return true; // skip validation if no file is provided
            return file.size <= maxFileSize;
        })
    ),
});

type IFeatureRequestFrom = {
    title: string;
    description: string;
    files: Array<File>;
};

const FeatureRequestAdd = () => {
    const fileFieldRef = useRef<HTMLInputElement>();
    const navigate = useNavigate();

    const { createFeatureRequestMutation } = useFeatureRequest();

    const initialValues: IFeatureRequestFrom = {
        title: '',
        description: '',
        files: [],
    };

    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    const handleSubmit = async (values: any) => {
        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            values.files.forEach((file: File) => {
                formData.append('files', file);
            });
            console.log('Form Data:', formData);
            const x = await createFeatureRequestMutation.mutateAsync(formData);
            toast.success('Feature Request Added Successfully.');
            navigate('/feature-request');
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: any,
        previousFiles: Array<File>
    ) => {
        const files = Array.from(event.target.files || []);
        const validFiles: File[] = [];
        const invalidFiles: File[] = [];

        files.forEach((file) => {
            if (file.size <= maxFileSize) {
                validFiles.push(file);
            } else {
                invalidFiles.push(file);
            }
        });
        if (invalidFiles.length > 0) {
            alert(
                `Some files exceed the 5MB limit and were not added: ${invalidFiles
                    .map((file) => file.name)
                    .join(', ')}`
            );
        }
        setFieldValue('files', [...previousFiles, ...validFiles]);
        fileFieldRef.current.value = '';
    };

    const removeAttachment = (setFieldValue: any, previousFiles: Array<File>, index: number) => {
        const processedFiles = produce(previousFiles, (draft) => {
            draft.splice(index, 1);
            return draft;
        });
        setFieldValue('files', processedFiles);
    };

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Feature Request</div>
                <>
                    <div className="mt-8">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={featureRequestSchema}
                            enableReinitialize
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }: FormikProps<IFeatureRequestFrom>) => (
                                <Form>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-4">
                                            <label className="text-xs mb-2" htmlFor="title">
                                                Title
                                            </label>
                                            <Field type="text" id="title" name="title" className="form-control" />
                                            <div className="form-error">
                                                <ErrorMessage name="title" />
                                            </div>
                                        </div>

                                        <div className="col-span-12">
                                            <div className="mt-6">
                                                <label className="text-xs mb-2" htmlFor="description">
                                                    Description
                                                </label>
                                                <RichTextEditor
                                                    value={values.description}
                                                    onChange={(value) => setFieldValue('description', value)}
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name="description" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-12">
                                            <div className="mt-6">
                                                <label className="" htmlFor="files">
                                                    <div className="bg-primary flex items-center justify-center text-white text-sm font-semibold rounded-lg w-40 h-12 ">
                                                        Attach file <PaperclipIcon className="rotate-45 ml-2" />
                                                    </div>
                                                </label>
                                                {values.files.length > 0 && (
                                                    <div className="col-span-12 flex flex-col mt-8">
                                                        <div className="text-lg font-semibold "> Attachments </div>
                                                        <ul className="list-decimal list-inside text-sm  font-medium mt-2">
                                                            {values.files.map((uploadedFile, index) => {
                                                                return (
                                                                    <li
                                                                        className="flex w-96 mt-3 justify-between"
                                                                        key={uploadedFile.name}
                                                                    >
                                                                        <div className="max-h-80 truncate">
                                                                            {index + 1}. &nbsp; {uploadedFile.name}
                                                                        </div>
                                                                        <div
                                                                            className="text-danger cursor-pointer"
                                                                            onClick={() =>
                                                                                removeAttachment(
                                                                                    setFieldValue,
                                                                                    values.files,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                                <input
                                                    ref={fileFieldRef}
                                                    type="file"
                                                    id="files"
                                                    name="files"
                                                    multiple
                                                    placeholder="Select File(s)"
                                                    onChange={(event) =>
                                                        handleFileChange(event, setFieldValue, values.files)
                                                    }
                                                    className="form-control"
                                                    style={{ visibility: 'hidden' }}
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name="files" />
                                                </div>
                                            </div>
                                        </div>

                                        <LoaderButton
                                            type="submit"
                                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                            isLoading={false}
                                        >
                                            Add Feature Request
                                        </LoaderButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </>
            </div>
        </>
    );
};

export default FeatureRequestAdd;
