import { debounce } from 'lodash';
import http from 'app/config/http';
import { IBooking } from 'app/types';
import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import BookingTable from '../BookingTable/BookingTable';
import TableSearch from 'app/components/TableSearch/TableSearch';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import { BOOKING_STATUS_LIST } from 'app/utils/constants';

interface AgencyBookingProps {}

const AgencyBooking: FC<AgencyBookingProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);
    let fromDate = searchParams.get('fromDate') || '';
    let toDate = searchParams.get('toDate') || '';
    let query = searchParams.get('query') || '';
    let page = searchParams.get('page') || 1;
    let bookingStatus = searchParams.get('bookingStatus') || '';

    const agencyBookingQuery = useQuery<IBooking[]>(
        ['agencyBookingListPaginated', query, page, fromDate, toDate, bookingStatus],
        async () => {
            let params = '';
            page ? (params += `page=${page}`) : (params += '');
            fromDate ? (params += `&fromDate=${fromDate}`) : (params += '');
            toDate ? (params += `&toDate=${toDate}`) : (params += '');
            query ? (params += `&searchParam=${query}`) : (params += '');
            bookingStatus ? (params += `&statusFilter=${bookingStatus}`) : (params += '');

            const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings?${params}`);
            setPageCount(data.pageCount);
            return data.data;
        }
    );

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const debouncedSearch = debounce((q) => {
        if (q !== '') {
            searchParams.set('query', q);
        } else {
            searchParams.delete('query');
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    }, 300);

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleDateFilter = (date1: string, date2: string) => {
        date1 ? searchParams.set('fromDate', date1) : searchParams.delete('fromDate');
        date2 ? searchParams.set('toDate', date2) : searchParams.delete('toDate');
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    };

    const handleBookingStatusChangeFilter = (status: string) => {
        if (status === '') {
            searchParams.delete('bookingStatus');
        } else {
            searchParams.set('bookingStatus', status);
        }
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        // <div className="relative">
        <>
            <div className="flex justify-between items-center px-6 pt-5 gap-6 bg-white">
                <TableSearch placeholder="Search for a booking by name or id" onSearch={handleSearch} value={query} />

                <div className="flex items-center gap-8">
                    <select
                        className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                        onChange={(e) => handleBookingStatusChangeFilter(e.target.value)}
                        value={bookingStatus}
                    >
                        <option value="">Filter by Status</option>
                        {BOOKING_STATUS_LIST.map((status) => (
                            <option key={status.value} value={status.value}>
                                {status.label}
                            </option>
                        ))}
                    </select>

                    <TableDateFilter onChange={handleDateFilter} fromDate={fromDate} toDate={toDate} />
                </div>
            </div>
            <div className="mt-6">
                <BookingTable bookings={agencyBookingQuery.data} type={'AG'} isLoading={agencyBookingQuery.isLoading} />
                <div className="px-4 pb-3 ">
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default AgencyBooking;
