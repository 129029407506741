/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import Spinner from 'app/components/Spinner/Spinner';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import Tooltip from 'app/components/Tooltip/Tooltip';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { MenuDotIcon } from 'app/icons';
import { TRootState } from 'app/store';
import { formatCurrency } from 'app/utils/currency-utils';
import update from 'immutability-helper';
import moment from 'moment';
import { FC, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAgentRemark } from '../../types/agent-remark';
import RemarkAddModal from '../RemarkAddModal/RemarkAddModal';
import RemarkListModal from '../RemarkListModal/RemarkListModal';
import ReactDOM from 'react-dom';
import RejectRemarkDialog from '../RejectRemarkDialog/RejectRemarkDialog';
import TransactionSelectModal from '../TransactionSelectModal/TransactionSelectModal';
import InfoModal from 'app/components/InfoModal/InfoModal';
import { IPayment } from 'app/types';
interface PaymentTableProps {
    payments: IPayment[];
    isLoading: boolean;
    params: any;
}

// ???

const PaymentTable: FC<PaymentTableProps> = (props) => {
    const { payments, params, isLoading } = props;

    const tableTop = useRef();

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const queryClient = useQueryClient();

    const approvePaymentMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/payments/${values._id}/approve`,
            { transactionId: values.transactionId }
        );

        return data;
    });

    const rejectPaymentMutation = useMutation(async (values: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/payments/${values._id}/reject`, {
            remark: values.remark,
        });

        return data;
    });

    const remarkAddDialog = useDialog<string>();
    const remarkListDialog = useDialog<IAgentRemark[]>([]);
    const rejectPDialog = useDialog<string>();
    const unMappedTransactionDialog = useDialog<string>('');
    const infoDialog = useDialog<string>();

    const { user } = useSelector((state: TRootState) => state.auth);

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'yellow';
            case 'APPROVED':
                return 'green';
            case 'REJECTED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Pending';
            case 'APPROVED':
                return 'Approved';
            case 'REJECTED':
                return 'Rejected';
        }
    };

    const getMethodText = (method: string) => {
        switch (method) {
            case 'BANK_TRANSFER':
                return 'Bank Transfer';
            case 'CHEQUE_OR_CASH':
                return 'Cheque / Cash';
            case 'RAZORPAY_GATEWAY':
                return 'Online';
            case 'CCAVENUE_GATEWAY':
                return 'CCAvenue';
        }
    };

    const handleApprovePayment = async (_id: string, transactionId: string) => {
        if (approvePaymentMutation.isLoading || rejectPaymentMutation.isLoading) return;

        try {
            const payment = await approvePaymentMutation.mutateAsync({ _id, transactionId });

            queryClient.setQueryData(['paymentListPaginated', params], (prev: IPayment[]) => {
                const index = prev.findIndex((p) => p._id === _id);

                return update(prev, {
                    [index]: {
                        $set: payment,
                    },
                });
            });

            queryClient.fetchQuery(['pendingPayments']);

            toast.success('Payment approved successfully.');
        } catch (ex: any) {
            infoDialog.show(ex.data.message);
            // toast.error(ex.data.message);
        } finally {
            unMappedTransactionDialog.hide();
        }
    };

    const handleRejectPayment = async (_id: string, remark: string) => {
        if (approvePaymentMutation.isLoading || rejectPaymentMutation.isLoading) return;

        try {
            const payment = await rejectPaymentMutation.mutateAsync({ _id, remark });

            queryClient.setQueryData(['paymentListPaginated', params], (prev: IPayment[]) => {
                const index = prev.findIndex((p) => p._id === _id);

                return update(prev, {
                    [index]: {
                        $set: payment,
                    },
                });
            });

            queryClient.fetchQuery(['pendingPayments']);

            toast.success('Payment rejected successfully.');
        } catch (ex: any) {
            toast.error(ex.data.message);
        }
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50" ref={tableTop}>
                            <th className="th-1">Request ID</th>
                            <th className="th-1">Sub Agency</th>
                            <th className="th-1">Requested By</th>
                            <th className="th-1">Payment Method</th>
                            <th className="th-1">Amount</th>
                            <th className="th-1">Transaction Date</th>
                            <th className="th-1">Sub Agent Remarks</th>
                            <th className="th-1">{user.agency.name} Remarks</th>
                            <th className="th-1">Created At</th>
                            <th className="th-1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && payments.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}
                        {!isLoading &&
                            payments.map((row, i) => (
                                <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                    <td className="td-1">
                                        <div className="font-semibold">{row._id}</div>
                                        <Tooltip
                                            content={
                                                row.status !== 'PENDING' ? (
                                                    <div className="px-4 py-3">
                                                        <p className="text-xs">{getStatusText(row.status)} By</p>
                                                        <div className="text-sm font-semibold mt-1">
                                                            {row.method === 'RAZORPAY_GATEWAY'
                                                                ? 'Farepod Pay'
                                                                : row.actionBy?.name}
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            placement="bottom-start"
                                        >
                                            <StatusBadge className="mt-1" color={getStatusClass(row.status)}>
                                                {getStatusText(row.status)}
                                            </StatusBadge>
                                        </Tooltip>
                                    </td>

                                    <td className="td-1">
                                        <div className="font-semibold text-blue-500">
                                            <Link to={`/sub-agencies/${row.subAgency._id}/detials?tab=1`}>
                                                {row.subAgency.name}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{row.paidBy ? row.paidBy.name : '-'}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="flex flex-col items-center">
                                            <div className="font-semibold">{getMethodText(row.method)}</div>
                                            {row.method === 'BANK_TRANSFER' && (
                                                <div className="flex flex-col items-center text-center text-slate-500 text-[.7rem]">
                                                    <div className="">{row.details?.bank?.name}</div>
                                                    <div>{row.details?.bank?.account?.number}</div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold whitespace-nowrap">
                                            {formatCurrency(row.amount)}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {moment(row.details.transactionDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{row.subAgentRemark || '-'}</div>
                                    </td>
                                    <td className="td-1">
                                        <div
                                            className="font-semibold text-blue-500 cursor-pointer"
                                            onClick={() => remarkListDialog.show(row.agentRemarks)}
                                        >
                                            View
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="min-w-max">
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <Dropdown
                                            target={
                                                <a className="dropdown-action">
                                                    <MenuDotIcon />
                                                </a>
                                            }
                                            menu={
                                                <DropdownList>
                                                    <DropdownItem onClick={() => remarkAddDialog.show(row._id)}>
                                                        Add Remark
                                                    </DropdownItem>
                                                    {row.status === 'PENDING' && (
                                                        <>
                                                            {/* <DropdownItem onClick={() => handleApprovePayment(row._id)}> */}
                                                            <DropdownItem
                                                                onClick={() => unMappedTransactionDialog.show(row._id)}
                                                            >
                                                                <span className="text-green-500">Approve</span>
                                                            </DropdownItem>
                                                            {/* <DropdownItem onClick={() => handleRejectPayment(row._id)}>
                                                                <span className="text-red-500">Reject</span>
                                                            </DropdownItem> */}
                                                            <DropdownItem onClick={() => rejectPDialog.show(row._id)}>
                                                                <span className="text-red-500">Reject</span>
                                                            </DropdownItem>
                                                        </>
                                                    )}
                                                </DropdownList>
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}
            </div>

            <RemarkAddModal
                show={remarkAddDialog.isOpen}
                onClose={remarkAddDialog.hide}
                paymentId={remarkAddDialog.data}
                params={params}
            />
            <RemarkListModal
                show={remarkListDialog.isOpen}
                onClose={remarkListDialog.hide}
                remarks={remarkListDialog.data}
            />
            <RejectRemarkDialog
                show={rejectPDialog.isOpen}
                onClose={rejectPDialog.hide}
                paymentRequestId={rejectPDialog.data}
                handleSubmit={handleRejectPayment}
                isLoading={rejectPaymentMutation.isLoading}
            />
            {unMappedTransactionDialog.isOpen && (
                <TransactionSelectModal
                    show={unMappedTransactionDialog.isOpen}
                    onClose={unMappedTransactionDialog.hide}
                    onSubmit={handleApprovePayment}
                    isLoading={approvePaymentMutation.isLoading}
                    paymentId={unMappedTransactionDialog.data}
                />
            )}
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </>
    );
};

export default PaymentTable;
