import React from 'react';

const Icon = (props: any) => {
    return (
        <svg width='800px' height='800px' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M28.781 4.405h-10.13V2.018L2 4.588v22.527l16.651 2.868v-3.538h10.13A1.162 1.162 0 0030 25.349V5.5a1.162 1.162 0 00-1.219-1.095zm.16 21.126H18.617l-.017-1.889h2.487v-2.2h-2.506l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2h10.411z'
                fill='#20744a'
                fillRule='evenodd'
            />
            <path fill='#20744a' d='M22.487 7.439H26.81V9.639H22.487z' />
            <path fill='#20744a' d='M22.487 10.94H26.81V13.14H22.487z' />
            <path fill='#20744a' d='M22.487 14.441H26.81V16.641000000000002H22.487z' />
            <path fill='#20744a' d='M22.487 17.942H26.81V20.142H22.487z' />
            <path fill='#20744a' d='M22.487 21.443H26.81V23.643H22.487z' />
            <path
                fill='#fff'
                fillRule='evenodd'
                d='M6.347 10.673L8.493 10.55 9.842 14.259 11.436 10.397 13.582 10.274 10.976 15.54 13.582 20.819 11.313 20.666 9.781 16.642 8.248 20.513 6.163 20.329 8.585 15.666 6.347 10.673z'
            />
        </svg>
    );
};

export default Icon;
