import { useQuery } from '@tanstack/react-query';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { PaymentStatusEnum } from 'app/enums/payment-status.enum';
import PaymentTable from './components/PaymentTable/PaymentTable';
import { GatewayPaymentModeEnum } from 'app/enums/gateway-payment-mode.enum';
import { PaymentEnum } from 'app/enums/payment.enum';

export interface IGatewayPayment {
    _id: string;
    amount: number;
    method: PaymentEnum;
    details: {
        paymentMode: GatewayPaymentModeEnum;
        transactionId: string;
        transactionDate: string;
        baseAmount: number;
        gatewayCharges: number;
        totalOrderAmount: number;
    };
    agency: string;
    subAgency: {
        _id: string;
        name: string;
        logoUrl: string;
        id: string;
    };
    paidBy: {
        _id: string;
        name: string;
        id: string;
    };
    actionBy: null;
    status: PaymentStatusEnum;
    subAgentRemark: string;
    agentRemarks: [];
    createdAt: string;
    id: 'AUX0689305P';
}

const PaymentListGateway = () => {
    const [filter, setFilter] = useState({
        search: '',
        status: '',
    });

    const [pageCount, setPageCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [params, setParams] = useState<String>(`?page=${currentPage}`);

    const paymentsQuery = useQuery<IGatewayPayment[]>(['paymentListPaginated', 'GATEWAY', params], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/payments/gateway-payments${params}`
        );
        setPageCount(data.pageCount);
        return data.data;
    });

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;
            if (filter.status !== '') newUrl += `&filter=${filter.status}`;

            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const debouncedSearch = useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleStatusFilterChange = (status: string) => {
        setFilter((prev) => ({ ...prev, status }));
        setCurrentPage(() => 1);
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <>
            <h5 className='font-bold'>Payment Gateway Transactions</h5>

            <div className='card  mt-8'>
                <div className='flex items-center justify-between px-6 pt-5'>
                    <TableSearch placeholder='Search for a payment by id, method' onSearch={handleSearch} />
                    <div className='flex items-center gap-3'>
                        <label className='text-xs' htmlFor='filter'>
                            FILTER
                        </label>
                        <select
                            id='filter'
                            className='border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium'
                            onChange={(e: any) => handleStatusFilterChange(e.target.value)}
                        >
                            <option value=''>All</option>
                            <option value='PENDING'>Pending</option>
                            <option value='APPROVED'>Approved</option>
                            <option value='REJECTED'>Rejected</option>
                        </select>
                    </div>
                </div>

                <PaymentTable payments={paymentsQuery.data} isLoading={paymentsQuery.isLoading} params={params} />
                <div className='px-4 pb-3 '>
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default PaymentListGateway;
