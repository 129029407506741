import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import React, { FC } from 'react';

interface SettlePaymentDialogProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading: boolean;
}

const SettlePaymentDialog: FC<SettlePaymentDialogProps> = (props) => {
    return (
        <Modal className="px-10 py-10" show={props.show} onClose={props.onClose}>
            <div className="text-gray-900 mt-4 text-center">Are you sure you want to settle this payment</div>
            <div className="flex justify-center gap-4 mt-6">
                <LoaderButton
                    className="bg-red-500 text-white px-6 text-sm rounded-lg"
                    onClick={props.onConfirm}
                    isLoading={props.isLoading}
                >
                    Yes, Proceed
                </LoaderButton>
                <button className="bg-gray-200 text-gray-600 px-6 text-sm rounded-lg" onClick={props.onClose}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default SettlePaymentDialog;
