import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ChangePasswordForm from './components/ChangePasswordForm/ChangePasswordForm';
import SubAgencyUserForm from './components/SubAgencyUserForm/SubAgencyUserForm';
import { ISubAgencyUser } from 'app/types';
import BackLink from 'app/components/BackLink/BackLink';

interface SubAgencyUserEditProps {}

const SubAgencyUserEdit: FC<SubAgencyUserEditProps> = (props) => {
    const { subAgencyId, subAgentId } = useParams();

    const query = useQuery<ISubAgencyUser>(['subAgentDetail'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/sub-agency-users/${subAgentId}`
        );

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Edit Sub Agency User</div>

                <SubAgencyUserForm subAgent={query.data} />
            </div>

            <div className="card px-6 pt-5 pb-10 mt-6">
                <div className="font-bold">Change Password</div>

                <ChangePasswordForm subAgent={query.data} />
            </div>
        </>
    );
};

export default SubAgencyUserEdit;
