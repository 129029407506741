import { useContext } from 'react';
import { AddCommissionPlanContext } from 'app/modules/shared/context/AddCommissionPlanWrapper';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import TripJackCommissionPlan from '../../components/commission-add-components/Trip-jack-commission-plan/TripJackCommissionPlan';
import TravelBoutiqueCommissionPlan from '../../components/commission-add-components/Travel-boutique-commission-plan/TravelBoutiqueCommissionPlan';


const AddCommissionPlan = () => {
    const { selectedFareSupplier } = useContext(AddCommissionPlanContext);

    return (
        <>
            {selectedFareSupplier === CommissionPlanEnabledSuppliers.TRIP_JACK && <TripJackCommissionPlan />}
            {selectedFareSupplier === CommissionPlanEnabledSuppliers.TRAVEL_BOUTIQUE && (
                <TravelBoutiqueCommissionPlan />
            )}
        </>
    );
};

export default AddCommissionPlan;
