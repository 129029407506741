import AmendmentChat from 'app/components/AmendmentChat/AmendmentChat';
import Spinner from 'app/components/Spinner/Spinner';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import useDialog from 'app/hooks/use-dialog';
import { getAmendmentStatusColor, getAmendmentStatusText } from 'app/utils/common-utils';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import React, { FC, useRef } from 'react';
import { Link } from 'react-router-dom';

interface IOpenAmendment {
    bookingId: string;
    amendmentId: string;
}
interface AmendmentTableProps {
    amendmentList: any[];
    isLoading: boolean;
    refetchQueryIdentifier: any;
}

const AmendmentTable: FC<AmendmentTableProps> = (props) => {
    const { amendmentList, isLoading, refetchQueryIdentifier } = props;
    const tableTop = useRef();

    const amendmentDialog = useDialog<IOpenAmendment>({ amendmentId: '', bookingId: '' });

    const openChatDialog = (row: any) => {
        amendmentDialog.show({
            amendmentId: row._id,
            bookingId: row.booking._id,
        });
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50" ref={tableTop}>
                            <th className="th-1">Request ID</th>
                            <th className="th-1">Request Date Time</th>
                            <th className="th-1">Trip ID</th>
                            {/* <th className="th-1">Airline PNR</th> */}
                            <th className="th-1">Departure Date</th>
                            <th className="th-1">Change Type</th>
                            <th className="th-1">Requested By</th>
                            <th className="th-1">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && amendmentList.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}

                        {!isLoading &&
                            amendmentList.map((amendment) => {
                                return (
                                    <tr key={amendment._id}>
                                        <td className="td-1">
                                            <div>
                                                <div className="font-semibold text-blue-500 cursor-pointer">
                                                    <div onClick={() => openChatDialog(amendment)}>{amendment._id}</div>
                                                </div>
                                                {amendment.actionNeeded && (
                                                    <StatusBadge className="mt-1" color={'red'}>
                                                        Action needed
                                                    </StatusBadge>
                                                )}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                <div>
                                                    {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format(
                                                        'DD MMM, YYYY'
                                                    )}
                                                </div>
                                                <div className="text-xs font-medium text-gray-500">
                                                    {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold text-blue-500">
                                                <Link to={`/bookings/${amendment.booking._id}/SAG`}>
                                                    {amendment.booking._id}
                                                </Link>
                                            </div>
                                        </td>
                                        {/* <td className="td-1">
                                            <div className="font-semibold">
                                                {amendment.booking.pnr === '' ? '-' : amendment.booking.pnr}
                                            </div>
                                        </td> */}
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                {moment(amendment.booking.departure, 'YYYYMMDD').format(
                                                    'DD MMMM, YYYY'
                                                )}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                {capitalize(amendment.type.toLowerCase())}
                                            </div>
                                        </td>
                                        <td className="td-1">
                                            <div className="font-semibold">
                                                <div>{amendment.raisedBy}</div>
                                                <Link
                                                    className="text-xs font-medium text-blue-500"
                                                    to={`/sub-agencies/${amendment.subAgency._id}/detials`}
                                                >
                                                    {amendment.subAgency.name}
                                                </Link>
                                            </div>
                                        </td>
                                        <td>
                                            <StatusBadge
                                                className="mt-1"
                                                color={getAmendmentStatusColor(amendment.status)}
                                            >
                                                {getAmendmentStatusText(amendment.status, amendment.subAgency.name)}
                                            </StatusBadge>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}
            </div>
            {amendmentDialog.isOpen && (
                <AmendmentChat
                    show={amendmentDialog.isOpen}
                    onClose={amendmentDialog.hide}
                    amendmentId={amendmentDialog.data.amendmentId}
                    bookingId={amendmentDialog.data.bookingId}
                    refetchQueryIdentifier={refetchQueryIdentifier}
                />
            )}
        </>
    );
};

export default AmendmentTable;
