import { useMutation, useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StatsCard from 'app/components/StatsCard/StatsCard';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import { formatCurrency } from 'app/utils/currency-utils';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SubAgencyTable from './components/SubAgencyTable/SubAgencyTable';
import { IPayable, IPayableSubAgeny } from './types';

interface MySeriesPayablesReportProps {}

const MySeriesPayablesReport: FC<MySeriesPayablesReportProps> = (props) => {
    const [filteredSubAgencyList, setFilteredSubAgencyList] = useState<IPayableSubAgeny[]>([]);

    const downloadExcelMutation = useMutation(async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/reports/payables/excel`, {
            responseType: 'blob',
        });

        return data;
    });

    const handleDownloadExcel = async () => {
        try {
            const res = await downloadExcelMutation.mutateAsync();

            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute('download', `payable-excel.xlsx`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            window.URL.revokeObjectURL(url);
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const mySeriesPayableQuery = useQuery<IPayable>(['mySeriesPayableQuery'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/reports/payables`);

        return data;
    });

    useEffect(() => {
        if (mySeriesPayableQuery.data) {
            setFilteredSubAgencyList(mySeriesPayableQuery.data.reports);
        }
    }, [mySeriesPayableQuery.data]);

    const handleSearch = (q: string) => {
        const list = mySeriesPayableQuery.data.reports.filter((row: any) => {
            const val = q.toLowerCase();

            let flag = row.name.toLowerCase().includes(val);
            flag = flag || row._id.toLowerCase().includes(val);

            return flag;
        });

        setFilteredSubAgencyList(list);
    };

    if (mySeriesPayableQuery.isLoading) return <PageLoader />;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="flex justify-between items-center">
                <div>
                    <h5 className="font-bold">Payables</h5>
                    <p className="mt-1">MySeries Balances</p>
                </div>
            </div>

            <div className="grid grid-cols-3 gap-6 mt-8">
                <div className="col-span-3 lg:col-span-1">
                    <StatsCard
                        title="TOTAL AMOUNT TO BE PAID"
                        content={
                            <div className="text-green-500">{formatCurrency(mySeriesPayableQuery.data.total)}</div>
                        }
                    />
                </div>
            </div>

            <div className="card mt-8">
                <div className="flex items-center px-6 pt-5">
                    <TableSearch
                        placeholder="Search for a sub agency"
                        onSearch={handleSearch}
                        onSearchReset={() => setFilteredSubAgencyList(mySeriesPayableQuery.data.reports)}
                    />

                    <LoaderButton
                        onClick={handleDownloadExcel}
                        isLoading={downloadExcelMutation.isLoading}
                        className="ml-auto bg-[#1D6F42] text-white text-sm font-semibold px-6 rounded-lg text-center h-[38px]"
                    >
                        Download Excel
                    </LoaderButton>
                </div>

                <div className="mt-6">
                    <SubAgencyTable subAgencies={filteredSubAgencyList} />
                </div>
            </div>
        </>
    );
};

export default MySeriesPayablesReport;
