import useNavTab from 'app/hooks/use-nav-tab';
import { FC } from 'react';
import AmendmentList from './components/AmendmentList/AmendmentList';
import AmendmentNav from './components/AmendmentNav/AmendmentNav';
import UnsettledAmenmentList from './components/UnsettledAmenmentList/UnsettledAmenmentList';

interface AmendmentProps {}

const Amendment: FC<AmendmentProps> = (props) => {
    const [activeTab, setActiveTab] = useNavTab('1');
    return (
        <div>
            <h5 className="font-bold">Amendments</h5>

            <div className="card mt-8">
                <AmendmentNav activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === '1' && <AmendmentList />}
                {activeTab === '2' && <UnsettledAmenmentList />}
            </div>
        </div>
    );
};

export default Amendment;
