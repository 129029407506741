/* eslint-disable jsx-a11y/anchor-is-valid */
import Spinner from 'app/components/Spinner/Spinner';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { ExclamationIcon, TickIcon } from 'app/icons';
import { IReceviable } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';

interface SubAgencyTableProps {
    subAgencies: IReceviable[];
    handleToggle: any;
    handleToggleAll: any;
    batchMutation: any;
    allSelected: boolean;
}

const SubAgencyTable: FC<SubAgencyTableProps> = (props) => {
    const { subAgencies, handleToggle, handleToggleAll, allSelected, batchMutation } = props;

    return (
        <div className='overflow-auto'>
            <table className='card-table text-sm'>
                <thead>
                    <tr className='bg-gray-50'>
                        <th className='th-1'>
                            <div className='flex gap-2 '>
                                <input
                                    type='checkbox'
                                    className='form-checkbox'
                                    checked={allSelected}
                                    onChange={(e) => handleToggleAll(e.target.checked)}
                                />
                            </div>
                        </th>
                        <th className='th-1'>Agency ID</th>
                        <th className='th-1'>Agency Name & Address</th>
                        <th className='th-1'>Agency Contact & Email</th>
                        <th className='th-1'>Amount to be Recovered</th>
                        <th className='th-1'>Last Payment</th>
                        <th className='th-1'></th>
                    </tr>
                </thead>
                <tbody>
                    {subAgencies.map((row, i) => (
                        <tr key={row._id} className='border-b border-gray-200 last:border-none'>
                            <td className='td-1'>
                                <div>
                                    <input
                                        type='checkbox'
                                        className='form-checkbox'
                                        checked={row.isChecked}
                                        onChange={(e) => handleToggle(row._id, e.target.checked)}
                                    />
                                </div>
                            </td>

                            <td className='td-1'>
                                <Link className='font-semibold text-blue-500' to={`/sub-agencies/${row._id}/detials`}>
                                    {row._id}
                                </Link>
                            </td>
                            <td className='td-1'>
                                <Link className='font-semibold text-blue-500' to={`/sub-agencies/${row._id}/detials`}>
                                    {row.name}
                                </Link>
                                <p className='mt-1'>
                                    {row.address.city}, {row.address.state}
                                </p>
                            </td>
                            <td className='td-1'>
                                <div className='font-semibold'>{row.phoneNumber}</div>
                                <p className='mt-1'>{row.email}</p>
                            </td>
                            <td className='td-1'>
                                <div className='font-semibold text-red-500 text-base'>
                                    {formatCurrency(row.recoverAmount)}
                                </div>
                            </td>
                            <td className='td-1'>
                                {Object.keys(row.lastPayment).length > 0 && row.lastPayment.amount !== 0 ? (
                                    <div className='font-semibold text-green-500 text-base'>
                                        {formatCurrency(row.lastPayment.amount)}
                                        <p>
                                            {moment(row.lastPayment.on, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')} at{' '}
                                            {moment(row.lastPayment.on, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                            <Duration paymentDate={moment(row.lastPayment.on, 'YYYYMMDDHHmmss')} />
                                        </p>
                                    </div>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td className='td-1'>
                                {/* isLoading: boolean; : boolean; data: any; error: any */}
                                {batchMutation[row._id]?.isError && (
                                    <Tooltip
                                        content={
                                            <div className='px-6 py-4 w-48'>
                                                <div className='text-sm text-gray-500'>
                                                    {batchMutation[row._id]?.error.data.message}
                                                </div>
                                            </div>
                                        }
                                        placement='bottom-start'
                                        triggers={['hover']}
                                    >
                                        <ExclamationIcon className='text-red-500' />
                                    </Tooltip>
                                )}
                                {batchMutation[row._id]?.isLoading && <Spinner size={16} />}
                                {batchMutation[row._id]?.data && (
                                    <Tooltip
                                        content={
                                            <div className='px-6 py-4 w-48'>
                                                <div className='text-sm text-gray-500'>
                                                    Payment reminder sent successfully
                                                </div>
                                            </div>
                                        }
                                        placement='bottom-start'
                                        triggers={['hover']}
                                    >
                                        <TickIcon size={24} className='text-green-500' />
                                    </Tooltip>
                                )}

                                {!batchMutation[row._id]?.isError &&
                                    !batchMutation[row._id]?.isLoading &&
                                    !batchMutation[row._id]?.data && (
                                        <div className='min-w-max'>
                                            {row.latestReminder ? (
                                                <>
                                                    <p>Last reminder sent on</p>
                                                    <p>
                                                        {moment(row.latestReminder, 'YYYYMMDDHHmmss').format(
                                                            'DD MMM, YYYY'
                                                        )}{' '}
                                                        at{' '}
                                                        {moment(row.latestReminder, 'YYYYMMDDHHmmss').format('hh:mm A')}{' '}
                                                        <Duration
                                                            paymentDate={moment(row.latestReminder, 'YYYYMMDDHHmmss')}
                                                        />
                                                    </p>
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

interface IDurationProps {
    paymentDate: Moment;
}

const Duration: FC<IDurationProps> = (props) => {
    const { paymentDate } = props;
    const now = moment();
    const duration = moment.duration(now.diff(paymentDate)).humanize();
    return <div>{duration} ago</div>;
};

export default SubAgencyTable;
