export enum GatewayPaymentModeEnum {
    CREDIT_CARD = 'CREDIT_CARD',
    DEBIT_CARD = 'DEBIT_CARD',
    NET_BANKING = 'NET_BANKING',
    UPI = 'UPI',
}



export interface IGatewayCharges {
    [GatewayPaymentModeEnum.CREDIT_CARD]: {
        percentage: number;
        flat: number;
    };
    [GatewayPaymentModeEnum.DEBIT_CARD]: {
        percentage: number;
        flat: number;
    };
    [GatewayPaymentModeEnum.NET_BANKING]: {
        percentage: number;
        flat: number;
    };
    [GatewayPaymentModeEnum.UPI]: {
        percentage: number;
        flat: number;
    };
}
