import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice, { authListenerMiddleware } from './auth';

const rootReducer = combineReducers({
    auth: authSlice.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(authListenerMiddleware.middleware),
});

export type TRootState = ReturnType<typeof rootReducer>;

export default store;
