import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import SubAgencyForm from './components/SubAgencyForm/SubAgencyForm';

interface SubAgencyAddProps {}

const SubAgencyAdd: FC<SubAgencyAddProps> = (props) => {
    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Add Sub Agency</div>

                <SubAgencyForm />
            </div>
        </>
    );
};

export default SubAgencyAdd;
