import { FC } from 'react';
import http from 'app/config/http';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader/PageLoader';
import FareDetails from './components/FareDetails/FareDetails';
import TickerHeader from './components/TicketHeader/TicketHeader';
import PassengerList from './components/PassengerList/PassengerList';
import FlightItinerary from './components/FlightItinerary/FlightItinerary';
import PrintLayoutWrapper from 'app/components/PrintLayoutWrapper/PrintLayoutWrapper';
import TicketInformationList from './components/TicketInformationList/TicketInformationList';

interface BookingTicketProps {}

const BookingTicket: FC<BookingTicketProps> = (props) => {
    const { bookingId, type } = useParams();
    const navigate = useNavigate();

    let api = '';
    if (type === 'AG') {
        api = `/api/v1/agent/bookings/${bookingId}`;
    } else if (type === 'SAG') {
        api = `/api/v1/agent/agency/sub-agency-bookings/${bookingId}/print-ticket`;
    } else {
        navigate('/404');
    }

    const query = useQuery(['bookingTicket'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}${api}`);

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    const booking = query.data;

    return (
        <PrintLayoutWrapper>
            <div className='flex flex-col min-h-screen'>
                <TickerHeader agency={booking.agency} subAgency={booking.subAgency} />
                {/* {Itinerary} */}
                {/* <div className='mt-12'>
                        <h4 className='font-bold text-primary'>Your Itinerary</h4>

                        <div className='mt-10 text-xs space-y-6'>
                            {booking.segments.map((segment: any, i: number) => (
                                <div key={i} className='grid grid-cols-5 overflow-hidden gap-4'>
                                    <div className='col-span-1'>
                                        <div className='min-w-max'>
                                            <div className='font-semibold'>
                                                {segment.departure.airport.city}{' '}
                                                <span className='font-light'> to </span> {segment.arrival.airport.city}
                                            </div>
                                            <p className='mt-1 text-xs'>
                                                {formatDate(segment.departure.date, 'dddd, MMM D')}
                                            </p>
                                            <div className='flex flex-col mt-2 gap-1 items-start'>
                                                <div
                                                    className={` mt-2 rounded-full py-[0.4rem] px-3 leading-none text-white text-xs
                                                font-medium inline-block ${cabinColor} `}
                                                >
                                                    {capitalize(getCabinClass().toLowerCase())}
                                                </div>
                                                {getRefundableType() && (
                                                    <div
                                                        className={` rounded-full py-[0.4rem] px-3 leading-none text-white text-xs font-medium inline-block ${getRefundTypeBackgroundColor(
                                                            getRefundableType()
                                                        )} `}
                                                    >
                                                        {getFormattedRefundableType(getRefundableType())}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-1 pl-2'>
                                        <div className='flex items-center gap-2 min-w-max'>
                                            <img
                                                crossOrigin='anonymous'
                                                className='w-8 h-8'
                                                src={segment.flight.logo}
                                                alt=''
                                            />
                                            <div>
                                                <div className='font-semibold'>{segment.flight.name}</div>
                                                <div className='text-xs text-black/70 mt-[2px]'>
                                                    {segment.flight.code} {segment.flight.number}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-1'>
                                        <div>
                                            <div className='text-xs text-black/70'>
                                                {formatDate(segment.departure.date, 'dddd, D MMM')}
                                            </div>
                                            <div className='font-bold mt-[2px] text-normal'>
                                                {formatTime(segment.departure.time)}
                                            </div>
                                        </div>
                                        <div className='text-xs text-black/70 mt-[2px]'>
                                            {segment.departure.airport.name}
                                            {segment.departure.airport.terminal &&
                                                ', ' + segment.departure.airport.terminal}
                                        </div>
                                    </div>
                                    <div className='col-span-1 flex flex-col items-center mt-1'>
                                        <div className='text-xs text-black/70'>
                                            {formatMinutesToTime(segment.duration)}
                                        </div>

                                        <div className='bg-[#ECAE0E] h-1 w-20 rounded-lg mt-1' />
                                    </div>
                                    <div className='col-span-1'>
                                        <div>
                                            <div className='text-xs text-black/70'>
                                                {formatDate(segment.arrival.date, 'dddd, D MMM')}
                                            </div>
                                            <div className='font-bold mt-[2px] text-normal'>
                                                {formatTime(segment.arrival.time)}
                                            </div>
                                        </div>
                                        <div className='text-xs text-black/70 mt-[2px]'>
                                            {segment.arrival.airport.name}{' '}
                                            {segment.arrival.airport.terminal &&
                                                ', ' + segment.arrival.airport.terminal}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}

                <FlightItinerary
                    segments={booking.segments}
                    cabinClass={booking.priceDetail.fareDescription.adult.cabinClass}
                    refundableType={booking.priceDetail.fareDescription.adult.refundableType}
                />

                {/* {Passenger Details} */}
                <PassengerList booking={booking} />

                {/* {Fare details} */}
                <FareDetails booking={booking} />

                {/* {Important information} */}
                <TicketInformationList htmlContent={booking.tnc.content} />
                {/* {Helpline} */}
                <div className='mt-12 px-10 pb-8'>
                    <div className='text-sm font-semibold text-primary'>
                        {booking.agency.email} | {booking.agency.website}
                    </div>
                </div>
            </div>
        </PrintLayoutWrapper>
    );
};

export default BookingTicket;
