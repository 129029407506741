import { useFeatureRequestByIdQuery } from '../hooks/useFeatureRequestById';
import { Link, useParams } from 'react-router-dom';
import { DownloadIcon } from 'app/icons';
import BackLink from 'app/components/BackLink/BackLink';
import { SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import moment from 'moment';
import { FC } from 'react';

interface IFeatureRequestView {}

const FeatureRequestView: FC<IFeatureRequestView> = (props) => {
    const { id } = useParams();

    const {
        getFeatureRequestByIdQuery: { data, isLoading },
    } = useFeatureRequestByIdQuery(id);

    console.log({ data });

    if (isLoading) return <h1> Loading </h1>;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>
            <h5 className="font-bold">Feature Request</h5>

            <div className="card px-6 pt-5 pb-10 mt-6">
                <div className="col-span-4">
                    <label className="text-xs mb-2">Title</label>
                    <div className="font-semibold py-2 flex gap-2 ">{data.title}</div>
                </div>

                <div className="mt-12">
                    <div className="col-span-4">
                        <label className="text-xs mb-2">Description</label>
                        <div className="static-page  mt-2" dangerouslySetInnerHTML={{ __html: data.description }} />
                    </div>

                    {data.files.length > 0 && (
                        <>
                            <div className=" mt-10 mb-2 ">
                                <label className="text-xs mb-2">Attachments</label>

                                <ul>
                                    {data.files.map((file) => (
                                        <li>
                                            <a
                                                className="border border-white hover:bg-blue-700 hover:text-white   hover:border-primary rounded-lg   px-4 py-2 mt-2 cursor-pointer flex items-center justify-between text-sm font-semibold  w-96"
                                                href={file.pathUrl}
                                                download={true}
                                            >
                                                <div className="w-80 truncate">{file.path}</div>
                                                <DownloadIcon height={20} width={20} />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
                <div className="grid grid-cols-12 mt-8">
                    <div className="col-span-4">
                        <label className="text-xs mb-2">Requested By</label>
                        <div className="font-semibold py-2 flex gap-2 text-primary cursor-pointer">
                            <Link to={`/users/${data.createdBy._id}/edit`}>{data.createdBy.name}</Link>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <label className="text-xs mb-2">Requested At</label>
                        <div className="font-semibold py-2 flex gap-2">
                            {moment(data.createdAt, SYSTEM_DATE_TIME_FORMAT).format('DD MMM YYYY hh:mm A')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeatureRequestView;
