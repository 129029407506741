import React, { FC } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal from 'app/components/Modal/Modal';
import * as Yup from 'yup';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { IPayment } from 'app/types';

const AddRemarkFormSchema = Yup.object().shape({
    comment: Yup.string().trim().required('This field is required'),
});

const initialValues: any = {
    comment: '',
};

interface RemarkAddModalProps {
    paymentId: string;
    show: boolean;
    onClose: () => void;
    params: any;
}

const RemarkAddModal: FC<RemarkAddModalProps> = (props) => {
    const { paymentId, params } = props;

    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/payments/${paymentId}/agent-remarks`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: any) => {
        try {
            const remarks = await mutation.mutateAsync(values);

            queryClient.setQueryData(['paymentListPaginated', params], (prev: any) => {
                const index = prev.findIndex((p: IPayment) => p._id === paymentId);

                return update(prev, {
                    [index]: {
                        agentRemarks: {
                            $set: remarks,
                        },
                    },
                });
            });

            toast.success('Remark added successfully.');

            props.onClose();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.data.message);
        }
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10 w-[620px] max-w-full">
            <div className="font-bold">Add Remark</div>

            <div className="mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={AddRemarkFormSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="grid grid-cols-12 gap-6 mt-4">
                            <div className="col-span-12">
                                <label className="text-xs mb-2" htmlFor="comment">
                                    REMARK
                                </label>
                                <Field as="textarea" id="comment" name="comment" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="comment" />
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-10"
                            isLoading={mutation.isLoading}
                        >
                            Add
                        </LoaderButton>
                    </Form>
                </Formik>
            </div>
        </Modal>
    );
};

export default RemarkAddModal;
