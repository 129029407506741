import React, { FC } from 'react';
import Modal from 'app/components/Modal/Modal';
import { IAgentRemark } from '../../types/agent-remark';
import moment from 'moment';

interface RemarkListModalProps {
    remarks: IAgentRemark[];
    show: boolean;
    onClose: () => void;
}

const RemarkListModal: FC<RemarkListModalProps> = (props) => {
    const { remarks } = props;

    return (
        <Modal show={props.show} onClose={props.onClose} className="py-3 min-h-[25rem] w-[620px] max-w-full">
            <div className="px-10 mt-4">
                <div className="font-bold">Remarks</div>
            </div>
            <div className="overflow-auto mt-8">
                {remarks.length > 0 ? (
                    <table className="w-full text-sm">
                        <thead>
                            <tr className="bg-gray-50 text-left">
                                <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10 ali">Date</th>
                                <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">Remark</th>
                                <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">
                                    Remark By
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {remarks.map((row, i) => (
                                <tr key={i}>
                                    <td className="px-3 py-4 first:pl-10 last:pr-10">
                                        {moment(row.createdAt, 'YYYYMMDD').format('DD MMM, YYYY')}
                                    </td>
                                    <td className="px-3 py-4 first:pl-10 last:pr-10">{row.comment}</td>
                                    <td className="px-3 py-4 first:pl-10 last:pr-10">{row.by.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="px-10 text-center max-w-full">No remarks added yet</p>
                )}
            </div>
        </Modal>
    );
};

export default RemarkListModal;
