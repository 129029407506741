import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { IManualBooking } from 'app/types/manual-booking';
import { createManualBooking } from 'app/api/services/booking.service';
import { IManualBookingForm, ISegmentInitial } from '../types/IManualBooking';

interface IFlightForm {
    formKey: string;
}

const FlightForm: FC<IFlightForm> = (props) => {
    const { formKey } = props;
    const [formValues, setFormValues] = useState<IManualBookingForm>();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const manualBookingMutation = useMutation((payload: IManualBooking) => createManualBooking(payload));

    const handleManualBookingCreation = async (value: IManualBooking) => {
        try {
            await manualBookingMutation.mutateAsync(value);
            localStorage.removeItem('manualBookingFormState');
            toast.success('Manual booking create Successfully');
            navigate('/bookings?tab=4&page=1');
        } catch (e: any) {
            // toast.error('Something went wrong');
            toast.error(e?.data?.message || 'Something went wrong');
            console.log(e);
        }
    };

    const handleNextClick = (values: Partial<IManualBookingForm>) => {
        setFormValues((prev: any) => ({ ...prev, ...values }));
        setStep((prev) => prev + 1);
    };

    const handlePrevClick = () => {
        setStep((prev) => prev - 1);
    };

    const handleFinalSubmission = (values: Partial<IManualBookingForm>) => {
        const finalValue: IManualBooking = {
            booking: {
                ...values.booking,
                bookingId: formValues.ticket.bookingId,
                pnr: formValues.ticket.pnr,
                subAgency: formValues.ticket.subAgency._id,
            },
            ticket: {
                baggage: formValues.ticket.baggage,
                segments: formValues.ticket.segments,
                meta: formValues.ticket.meta,
            },
        };

        if (finalValue.ticket.meta.fareSupplier === 'OTHER') delete finalValue.ticket.meta.fareSupplier;
        if (finalValue.ticket.meta.fareSupplierName.trim() === '') delete finalValue.ticket.meta.fareSupplierName;

        handleManualBookingCreation(finalValue);
    };

    const checkIsInternationalFlight = (segment: ISegmentInitial[]) => {
        return !(segment[0].departure.airport.countryCode === segment[segment.length - 1].arrival.airport.countryCode);
    };

    return (
        <>
            <div className={step === 1 ? 'block' : 'hidden'}>
                {step === 1 && <Step1Form onSubmit={handleNextClick} formKey={formKey} />}
            </div>

            <div className={step === 2 ? 'block' : 'hidden'}>
                {step === 2 && (
                    <Step2Form
                        onBack={handlePrevClick}
                        onSubmit={handleFinalSubmission}
                        passengers={formValues.ticket.passengers}
                        subAgency={formValues.ticket.subAgency}
                        isMutating={manualBookingMutation.isLoading}
                        tripDate={formValues.ticket.segments[0].departure.date}
                        isInternational={checkIsInternationalFlight(formValues.ticket.segments)}
                        segments={formValues.ticket.segments}
                    />
                )}
            </div>
        </>
    );
};

export default FlightForm;
