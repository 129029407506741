import { ErrorMessage, useFormikContext } from 'formik';
import { FC } from 'react';
import ReactQuill from 'react-quill';
import { IStaticPageForm } from '../../types';

interface HtmlEditorProps {}

const HtmlEditor: FC<HtmlEditorProps> = (props) => {
    const { values, setFieldValue } = useFormikContext<IStaticPageForm>();

    return (
        <>
            <label className="text-xs mb-2" htmlFor="content">
                Page Content
            </label>
            <ReactQuill
                theme="snow"
                value={values.content}
                onChange={(htmlContent: string) => setFieldValue('content', htmlContent)}
                modules={{
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '+1' }, { align: [] }],
                        [],
                        [],
                    ],
                }}
            />
            <div className="form-error">
                <ErrorMessage name="content" />
            </div>
        </>
    );
};

export default HtmlEditor;
