import BackLink from 'app/components/BackLink/BackLink';
import React, { FC } from 'react';
import AgencyUserForm from './components/AgencyUserForm/AgencyUserForm';

interface AgencyUserAddProps {}

const AgencyUserAdd: FC<AgencyUserAddProps> = (props) => {
    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Add User</div>

                <AgencyUserForm />
            </div>
        </>
    );
};

export default AgencyUserAdd;
