// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard_linkDate__GGcKL {\n  border: none;\n  box-sizing: border-box;\n  outline: 0;\n  padding: 0.75rem;\n  position: relative;\n  width: 100%;\n}\n\n.Dashboard_linkDate__GGcKL::-webkit-calendar-picker-indicator {\n  background: transparent;\n  bottom: 0;\n  color: transparent;\n  cursor: pointer;\n  height: auto;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: auto;\n}", "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,uBAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,OAAA;EACA,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;AACJ","sourcesContent":[".linkDate {\n    border: none;\n    box-sizing: border-box;\n    outline: 0;\n    padding: 0.75rem;\n    position: relative;\n    width: 100%;\n}\n\n.linkDate::-webkit-calendar-picker-indicator {\n    background: transparent;\n    bottom: 0;\n    color: transparent;\n    cursor: pointer;\n    height: auto;\n    left: 0;\n    position: absolute;\n    right: 0;\n    top: 0;\n    width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkDate": "Dashboard_linkDate__GGcKL"
};
export default ___CSS_LOADER_EXPORT___;
