import Modal from 'app/components/Modal/Modal';
import React, { FC } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const CREDIT = 'CREDIT';
const BANK_TRANSFER = 'BANK_TRANSFER';
const CHEQUE_OR_CASH = 'CHEQUE_OR_CASH';

const PaymentFormSchema = Yup.object().shape({
    method: Yup.string().trim().required('This field is required'),
    // amount: Yup.string().trim().required('This field is required').matches(/^\d+$/, 'Enter a valid amount'),
    amount: Yup.number()
        .typeError('You must specify a number')
        .required('This field is required')
        .min(1, 'The amount must be greater than Zero'),
    remarks: Yup.string().trim(),
    bankAccount: Yup.string()
        .trim()
        .when('method', {
            is: (method: any) => method === BANK_TRANSFER,
            then: Yup.string().trim().required('Field is required'),
        }),
    bankTransactionId: Yup.string()
        .trim()
        .when('method', {
            is: (method: any) => method === BANK_TRANSFER,
            then: Yup.string().trim().required('Field is required'),
        }),

    transactionDate: Yup.string()
        .trim()
        .when('method', {
            is: (method: any) => method === BANK_TRANSFER || method === CHEQUE_OR_CASH,
            then: Yup.string().trim().required('Field is required'),
        }),
});

interface PaymentAddDialogProps {
    show: boolean;
    onClose: () => void;
    // onSubmit: () => void;
    bankList: [];
    subAgencyId: string;
}

const PaymentAddDialog: FC<PaymentAddDialogProps> = (props) => {
    const { show, onClose, bankList, subAgencyId } = props;
    const queryClient = useQueryClient();

    const paymentInitialValues = {
        amount: '',
        method: BANK_TRANSFER,
        bankAccount: '',
        transactionDate: moment().format('YYYY-MM-DD'),
        remarks: '',
        details: {},
        bankTransactionId: '',
    };

    // bank cheque and cash
    const makePaymentMutation = useMutation(async (payload: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/series-supplier-payment`,
            payload
        );

        return data;
    });

    // pre razor pay
    // const initiateOnlinePayment = useMutation(async (payload: any) => {
    //     const { data } = await http.post(
    //         `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/razorpay/initiate`,
    //         payload
    //     );

    //     return data;
    // });

    // const onlinePaymentMutation = useMutation(async (values: any) => {
    //     const { data } = await http.post(
    //         `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/bookings/${booking._id}/payment/razorpay`,
    //         values
    //     );

    //     return data;
    // });

    const onlinePaymentMutation = useMutation(async (payload: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/payments/${payload.id}/razorpay`,
            payload
        );

        return data;
    });

    const paymentOptions = [
        {
            label: 'Credit Cards / Debit Cards / UPI / Net Banking',
            value: CREDIT,
        },
        {
            label: 'Bank Transfer',
            value: BANK_TRANSFER,
        },
        {
            label: 'Cheque / Cash',
            value: CHEQUE_OR_CASH,
        },
    ];

    // const payWithRazorpay = (id: any, res: any) => {
    //     const razorPayOptions: any = {
    //         key: res.rzpKey,
    //         amount: res.rzpAmount, // amount should be in paise format to display Rs 1255 without decimal point
    //         currency: res.rzpCurrency,
    //         name: user.agency.name, // company name or product name
    //         image: user.agency.logoUrl, // company logo or product image
    //         order_id: res.rzpOrderId, // order_id created by you in backend
    //         modal: {
    //             escape: false, // We should prevent closing of the form when esc key is pressed.
    //         },
    //         prefill: {
    //             email: user.email,
    //             contact: user.phoneNumber,
    //         },
    //         theme: {
    //             // hex color here
    //             color: rgbToHex(document.documentElement.style.getPropertyValue('--primary')),
    //         },
    //         handler: async (response: any) => {
    //             const data = {
    //                 razorpayPaymentId: response.razorpay_payment_id,
    //                 razorpayOrderId: response.razorpay_order_id,
    //                 razorpaySignature: response.razorpay_signature,
    //             };
    //             try {
    //                 const res = await onlinePaymentMutation.mutateAsync({
    //                     rzpOrderId: data.razorpayOrderId,
    //                     rzpPaymentId: data.razorpayPaymentId,
    //                     rzpSignature: data.razorpaySignature,
    //                     id: id,
    //                 });
    //                 console.log({ paymentDone: res });
    //                 queryClient.fetchQuery(['accountData']);
    //                 queryClient.fetchQuery(['transactions']);

    //                 toast.success('Payment completed Success fully');

    //                 onClose();
    //             } catch (err: any) {
    //                 toast.error(err.data.message);
    //                 console.log(err);
    //             }
    //         },
    //     };

    //     const rzp = new (window as any).Razorpay(razorPayOptions);

    //     rzp.on('payment.failed', function (response: any) {
    //         console.log('Payment failed...');
    //     });

    //     rzp.open();
    // };

    const onSubmit = async (values: any) => {
        let payload: any = {
            amount: values.amount,
            method: values.method,
            // subAgentRemark: values.remarks.trim(),
            agentRemark: values.remarks.trim(),
            details: {
                transactionDate: moment(values.transactionDate).format('YYYYMMDD'),
            },
        };

        switch (values.method) {
            case CHEQUE_OR_CASH:
                break;
            case BANK_TRANSFER:
                payload.details.bank = bankList.find((bank: any) => values.bankAccount === bank._id);
                payload.details.transactionId = values.bankTransactionId;
                break;
        }

        // if (values.method === CREDIT) {
        //     try {
        //         const newData = {
        //             amount: values.amount,
        //             subAgentRemark: values.remarks.trim(),
        //         };

        //         const res = await initiateOnlinePayment.mutateAsync({ ...newData });

        //         payWithRazorpay(res.payment._id, res.razorpayOrder);
        //     } catch (ex) {
        //         console.log(ex);
        //     }
        // } else {
        //     try {
        //         await makePaymentMutation.mutateAsync(payload);
        //         queryClient.fetchQuery(['accountData']);
        //         toast.success('Payment request sent successfully');
        //         onClose();
        //     } catch (ex: any) {
        //         console.log(ex);
        //         toast.error(ex.data.message);
        //     }
        // }

        try {
            await makePaymentMutation.mutateAsync(payload);
            queryClient.fetchQuery(['subAgency', subAgencyId, 'SeriesSupplierLedger']);
            toast.success('Payment request sent successfully');
            onClose();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    return (
        <Modal show={show} onClose={onClose} className="px-10 py-10 w-[680px] max-w-full">
            <div className="text-2xl font-bold">Make Payment </div>

            <Formik
                initialValues={paymentInitialValues}
                validationSchema={PaymentFormSchema}
                enableReinitialize
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="mt-5">
                            <label className="text-xs mb-2 block" htmlFor="Amount">
                                Payment Amount
                            </label>

                            <div className="flex items-center relative">
                                <div className="absolute pl-4"> ₹ </div>
                                <Field name="amount" type="text" id="Amount" className="form-control pl-8 " />
                            </div>
                            <div className="form-error">
                                <ErrorMessage name="amount" />
                            </div>
                        </div>

                        <div className="mt-5">
                            <label className="text-xs mb-4 block" htmlFor="Amount">
                                Select Payment Method
                            </label>
                            {paymentOptions.map((row) => (
                                <div key={row.value} className="flex gap-3 mb-4">
                                    <Field
                                        type="radio"
                                        id={row.value}
                                        name="method"
                                        onChange={() => setFieldValue('method', row.value)}
                                        checked={row.value === values.method}
                                        className="accent-primary"
                                        disabled={row.value === CREDIT}
                                    />
                                    <label
                                        className={'text-sm block ' + (row.value === CREDIT ? 'text-gray-400' : '')}
                                        htmlFor={row.value}
                                    >
                                        {row.label}
                                    </label>
                                </div>
                            ))}
                        </div>

                        {values.method === BANK_TRANSFER && (
                            <>
                                <div className="mt-5">
                                    <label className="text-xs mb-2 block" htmlFor="bankAccount">
                                        Select Account
                                    </label>
                                    <Field
                                        as="select"
                                        name="bankAccount"
                                        type="text"
                                        id="bankAccount"
                                        className="form-control "
                                    >
                                        <option value="">Select Bank Account</option>
                                        {bankList.map((row: any) => (
                                            <option key={row._id} value={row._id}>
                                                {`${row.name} ${row.account.number}`}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className="form-error">
                                        <ErrorMessage name="bankAccount" />
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <label className="text-xs mb-2 block" htmlFor="BankTransactionId">
                                        Transaction ID
                                    </label>
                                    <Field
                                        name="bankTransactionId"
                                        type="text"
                                        id="BankTransactionId"
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="bankTransactionId" />
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <label className="text-xs mb-2 block" htmlFor="TransactionDate">
                                        Transaction Date
                                    </label>
                                    <Field
                                        name="transactionDate"
                                        type="date"
                                        id="TransactionDate"
                                        className="form-control "
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="transactionDate" />
                                    </div>
                                </div>
                            </>
                        )}

                        {values.method === CHEQUE_OR_CASH && (
                            <>
                                <div className="mt-5">
                                    <label className="text-xs mb-2 block" htmlFor="TransactionDate">
                                        Transaction Date
                                    </label>
                                    <Field
                                        name="transactionDate"
                                        type="date"
                                        id="TransactionDate"
                                        className="form-control "
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="transactionDate" />
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="mt-5">
                            <label className="text-xs mb-2 block" htmlFor="Remarks">
                                Remarks
                            </label>
                            <Field as="textarea" name="remarks" type="text" id="Remarks" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="remarks" />
                            </div>
                        </div>
                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                            isLoading={
                                makePaymentMutation.isLoading ||
                                // initiateOnlinePayment.isLoading ||
                                onlinePaymentMutation.isLoading
                            }
                            disabled={
                                makePaymentMutation.isLoading ||
                                // initiateOnlinePayment.isLoading ||
                                onlinePaymentMutation.isLoading
                            }
                        >
                            Proceed
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default PaymentAddDialog;
