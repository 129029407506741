import Modal from 'app/components/Modal/Modal';
import React, { FC } from 'react';

interface RemarkModalProps {
    show: boolean;
    onClose: () => void;
    remark: string;
    id: string;
}

const RemarkModal: FC<RemarkModalProps> = (props) => {
    return (
        <>
            <Modal show={props.show} onClose={props.onClose} className="py-3 min-h-[15rem]">
                <div className="px-6 mt-4">
                    <div className="font-bold">Transaction Remark - {props.id}</div>
                </div>
                <div className="overflow-auto mt-6">
                    <div className="px-6 w-[480px] max-w-full">{props.remark}</div>
                </div>
            </Modal>
        </>
    );
};

export default RemarkModal;
