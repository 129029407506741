import { FC } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal from 'app/components/Modal/Modal';
import * as Yup from 'yup';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { IBankForm } from '../../types';
import update from 'immutability-helper';

const BankFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('This field is required'),
    ifsc: Yup.string().trim().required('This field is required'),
    account: Yup.object().shape({
        type: Yup.string().trim().required('This field is required'),
        number: Yup.string().trim().required('This field is required'),
        holderName: Yup.string().trim().required('This field is required'),
    }),
});

const initialValues: IBankForm = {
    name: '',
    ifsc: '',
    account: {
        type: '',
        number: '',
        holderName: '',
    },
};

interface BankAddModalProps {
    show: boolean;
    onClose: () => void;
    subAgencyId: string;
}

const BankAddModal: FC<BankAddModalProps> = (props) => {
    const { subAgencyId } = props;
    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/banks`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: IBankForm) => {
        try {
            const banks = await mutation.mutateAsync(values);

            toast.success('Bank added successfully.');

            queryClient.setQueryData(['subAgency', subAgencyId], (prev: any) => {
                return update(prev, {
                    banks: {
                        $set: banks,
                    },
                });
            });

            props.onClose();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10">
            <div className="font-bold">Add Bank Account</div>

            <div className="mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={BankFormSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="grid grid-cols-12 gap-6 mt-4">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="name">
                                    BANK NAME
                                </label>
                                <Field type="text" id="name" name="name" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="ifsc">
                                    IFSC CODE
                                </label>
                                <Field type="text" id="ifsc" name="ifsc" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="ifsc" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="account.type">
                                    ACCOUNT TYPE
                                </label>
                                <Field type="text" id="account.type" name="account.type" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="account.type" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="account.holderName">
                                    ACCOUNT HOLDER NAME
                                </label>
                                <Field
                                    type="text"
                                    id="account.holderName"
                                    name="account.holderName"
                                    className="form-control"
                                />
                                <div className="form-error">
                                    <ErrorMessage name="account.holderName" />
                                </div>
                            </div>
                            <div className="col-span-8">
                                <label className="text-xs mb-2" htmlFor="account.number">
                                    ACCOUNT NUMBER
                                </label>
                                <Field type="text" id="account.number" name="account.number" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="account.number" />
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                            isLoading={mutation.isLoading}
                        >
                            Add
                        </LoaderButton>
                    </Form>
                </Formik>
            </div>
        </Modal>
    );
};

export default BankAddModal;
