import Spinner from 'app/components/Spinner/Spinner';
import { useHdfcPayment } from './shared/useHdfcPayment';
import PageLoader from 'app/components/PageLoader/PageLoader';
import PaymentGateWayChargesCard from '../shared/components/PaymentGatewayCharges';
import HdfcCredentialsCard from './shared/components/HdfcCredentialsCard';

const HdfcPaymentGateway = () => {
    const {
        hdfcPaymentDetails,
        handlePluginToggle,
        hdfcPaymentCredentialsUpdateMutation,
        hdfcPaymentGateWayChargesUpdateMutation,
        isLoading,
        isToggling,
    } = useHdfcPayment();

    if (isLoading) return <PageLoader />;

    return (
        <div>
            <div className='flex justify-between items-center'>
                <h5 className='font-bold'>Hdfc Payment Gateway</h5>
                <span>
                    {isToggling && <Spinner size={24} />}
                    <input
                        id='isEnabled'
                        type='checkbox'
                        className='form-switch success ml-2'
                        checked={hdfcPaymentDetails.isEnabled}
                        onChange={handlePluginToggle}
                    />
                    {/* <label htmlFor='isEnabled'>{ccAvenueDetails.isEnabled ? 'Deactivate' : 'Activate'}</label> */}
                </span>
            </div>
            <HdfcCredentialsCard
                credentialUpdateMutation={hdfcPaymentCredentialsUpdateMutation}
                hdfcGatewayInfo={hdfcPaymentDetails}
            />
            {/* <CcAvenueCredentialsCard
                CcAvenueInfo={ccAvenueDetails}
                credentialUpdateMutation={ccAvenueCredentialsUpdateMutation}
            /> */}
            <PaymentGateWayChargesCard
                pluginInfo={hdfcPaymentDetails}
                chargesUpdateMutation={hdfcPaymentGateWayChargesUpdateMutation}
                gatewayLabel='Hdfc'
            />
        </div>
    );
};

export default HdfcPaymentGateway;
