import moment from 'moment';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { IAgencySeriesSearch } from '../types/agency-series-search';
import { getStatusClass, getStatusText } from 'app/utils/flight-utils';
import { useNavigate } from 'react-router-dom';

interface IRenderAgencySeries {
    seriesInventory: IAgencySeriesSearch;
    handlePreNavigate: () => void;
}
const RenderAgencySeries = (props: IRenderAgencySeries) => {
    const { seriesInventory, handlePreNavigate } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        handlePreNavigate();
        navigate(`/series-inventory/${seriesInventory._id}/bookings/AG`);
    };

    return (
        <div className='grid grid-cols-12 px-2 py-2 gap-2 hover:bg-gray-50 cursor-pointer' onClick={handleClick}>
            <div className='col-span-3 flex flex-col items-center justify-between'>
                <div className='font-semibold text-primary'>{seriesInventory._id}</div>
                <StatusBadge className='' color={getStatusClass(seriesInventory.status)}>
                    {getStatusText(seriesInventory.status)}
                </StatusBadge>
            </div>
            <div className='col-span-5 flex items-center gap-3'>
                <img className='w-12 h-12' crossOrigin='anonymous' src={seriesInventory.departureFlight.logo} alt='' />
                <div className='flex flex-col justify-between'>
                    <div className='font-semibold text-black'>{seriesInventory.departureFlight.name}</div>
                    <p className=''>
                        {`${seriesInventory.departureFlight.code} ${seriesInventory.departureFlight.number}`}
                    </p>
                </div>
                <div className='flex flex-col justify-center items-center '>
                    <p>{moment(seriesInventory.date, 'YYYYMMDD').format('DD MMM YYYY')}</p>
                    <p>
                        {seriesInventory.departureAirport} - {seriesInventory.arrivalAirport}
                    </p>
                </div>
            </div>
            <div className='col-span-4 flex items-center'>
                {/* <p className='overflow-hidden whitespace-pre text-base text-black font-semibold text-ellipsis  '>
                    Star Link tour and travels
                </p> */}
            </div>
        </div>
    );
};

export default RenderAgencySeries;
