import React, { FC, useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { ISubAgentForm } from '../../types';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ISubAgencyUser, ISubAgencyUserStatus } from 'app/types';
import capitalize from 'lodash.capitalize';

const SubAgentFormSchema = Yup.object({
    name: Yup.string().trim().required('This field is required'),
    email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
    phoneNumber: Yup.string()
        .required('This field is required')
        .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
    username: Yup.string().required('This field is required').matches(/^\S*$/, 'Username cannot contain spaces'),
});

interface SubAgencyUserFormProps {
    subAgent: ISubAgencyUser;
}

const SubAgencyUserForm: FC<SubAgencyUserFormProps> = (props) => {
    const { subAgent } = props;

    const initialValues: ISubAgentForm = useMemo(() => {
        if (!subAgent)
            return {
                name: '',
                email: '',
                phoneNumber: '',
                username: '',
                designation: '',
                status: ISubAgencyUserStatus.DISABLED,
            };
        return {
            name: subAgent.name,
            email: subAgent.email,
            phoneNumber: subAgent.phoneNumber,
            username: subAgent.username,
            designation: subAgent.designation,
            status: subAgent.status,
        };
    }, [subAgent]);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgent.subAgency}/sub-agency-users/${subAgent._id}`,
            payload
        );

        return data;
    });

    const uniqueEmailValidator = useCallback(
        debounce(async (value) => {
            // if the value is equal to the initial value, don't do anything
            if (value === initialValues.email) return;

            try {
                await http.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgent.subAgency}/sub-agency-users/email`,
                    {
                        email: value,
                    }
                );
            } catch (err: any) {
                if (err.status === 400) {
                    return err.data.message;
                }
            }
        }, 500),
        []
    );

    const uniqueUsernameValidator = useCallback(
        debounce(async (value) => {
            // if the value is equal to the initial value, don't do anything
            if (value === initialValues.username) return;

            try {
                await http.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgent.subAgency}/sub-agency-users/username`,
                    {
                        username: value,
                    }
                );
            } catch (err: any) {
                if (err.status === 400) {
                    return err.data.message;
                }
            }
        }, 500),
        []
    );

    const handleSubmit = async (values: ISubAgentForm) => {
        try {
            await mutation.mutateAsync(values);

            toast.success('Sub Agent updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={SubAgentFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }: FormikProps<ISubAgentForm>) => (
                <Form>
                    <div className="grid grid-cols-12 gap-6 mt-8">
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="name">
                                NAME
                            </label>
                            <Field type="text" id="name" name="name" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="name" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="email">
                                EMAIL
                            </label>
                            <Field
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                validate={(val: string) => uniqueEmailValidator(val)}
                            />
                            <div className="form-error">
                                <ErrorMessage name="email" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="phoneNumber">
                                PHONE NUMBER
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">+91</div>
                                <Field type="text" id="phoneNumber" name="phoneNumber" className="form-control pl-12" />
                            </div>
                            <div className="form-error">
                                <ErrorMessage name="phoneNumber" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="username">
                                USERNAME
                            </label>
                            <Field
                                type="text"
                                id="username"
                                name="username"
                                className="form-control"
                                validate={(val: string) => uniqueUsernameValidator(val)}
                            />
                            <div className="form-error">
                                <ErrorMessage name="username" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="designation">
                                DESIGNATION
                            </label>
                            <Field type="text" id="designation" name="designation" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="designation" />
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="status">
                                Status
                            </label>
                            <Field as="select" name="status" type="text" id="status" className="form-control">
                                {Object.values(ISubAgencyUserStatus).map((status) => (
                                    <option key={status} value={status}>
                                        {capitalize(status)}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name="status" />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type="submit"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                        isLoading={mutation.isLoading}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default SubAgencyUserForm;
