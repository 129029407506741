import { FC } from 'react';
import http from 'app/config/http';
import { IAgency } from 'app/types';
import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import FareRulesEditor from './components/FareRulesEditor/FareRulesEditor';
import EditSettingsForm from './components/EditSettingsForm/EditSettingsForm';

interface AgencySettingsProps {}

const AgencySettings: FC<AgencySettingsProps> = (props) => {
    const query = useQuery<IAgency>(['agencyDetail'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency`);

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    return (
        <>
            <div className='card px-6 pt-5 pb-10'>
                <div className='font-bold'>Agency Settings</div>

                <EditSettingsForm agency={query.data} />
                <div className='text-white flex justify-end cursor-default'>#11NOV23-0857P</div>
            </div>

            <FareRulesEditor />
        </>
    );
};

export default AgencySettings;
