/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { IBooking, IBookingPassenger } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { Dropdown, DropdownList } from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon } from 'app/icons';
import { DropdownItem } from 'app/components/Dropdown/Dropdown';
import Tooltip from 'app/components/Tooltip/Tooltip';
import moment from 'moment';
import Spinner from 'app/components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { getFareSupplier } from 'app/utils/flight-utils';

interface BookingTableProps {
    bookings: IBooking[];
    showBookedBy?: boolean;
    isLoading?: boolean;
}

const BookingTable: FC<BookingTableProps> = (props) => {
    const { bookings, showBookedBy, isLoading } = props;

    const navigate = useNavigate();

    const getFirstPassengerName = (booking: IBooking) => {
        const firstPassenger = (booking.passengers as unknown as IBookingPassenger[])[0];

        return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
    };

    const getPassengerName = (passenger: IBookingPassenger) => {
        return `${passenger.title} ${passenger.firstName} ${passenger.lastName}`;
    };

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
            case 'SUBMISSION_PENDING':
                return 'yellow';
            case 'COMPLETED':
                return 'green';
            case 'CANCELLED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Pending';
            case 'SUBMISSION_PENDING':
                return 'Submission Pending';
            case 'COMPLETED':
                return 'Completed';
            case 'CANCELLED':
                return 'Cancelled';
        }
    };

    return (
        <div className='overflow-auto'>
            <table className='card-table text-sm'>
                <thead>
                    <tr className='bg-gray-50'>
                        <th className='th-1'>Trip ID</th>
                        <th className='th-1'>Travel & Date</th>
                        <th className='th-1'>Traveller(s)</th>
                        <th className='th-1'>Flight Details</th>
                        <th className='th-1'>Fare Details</th>
                        <th className='th-1'>Booking Type</th>
                        {showBookedBy && <th className='th-1'>Booked By</th>}
                        <th className='th-1'>Created At</th>

                        <th className='th-1'></th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && bookings.length === 0 && (
                        <tr>
                            <td align='center' colSpan={10} className='py-12 font-medium text-base text-[#98A2B3]'>
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        bookings.map((row) => (
                            <tr key={row._id} className='border-b border-gray-200 last:border-none'>
                                <td className='td-1'>
                                    <div
                                        className='font-semibold text-blue-500 cursor-pointer'
                                        onClick={() => navigate(`/bookings/${row._id}/SAG`)}
                                    >
                                        {row._id}{' '}
                                    </div>
                                    <StatusBadge className='mt-1' color={getStatusClass(row.status)}>
                                        {getStatusText(row.status)}
                                    </StatusBadge>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold'>
                                        {row.trip.departure.airport.city} to {row.trip.arrival.airport.city}
                                    </div>
                                    <p className='mt-1'>{formatDate(row.trip.departure.date, 'DD MMM, YYYY')}</p>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold'>{getFirstPassengerName(row)}</div>
                                    {(row.passengers as unknown as IBookingPassenger[]).length > 1 && (
                                        <Tooltip
                                            content={
                                                <div className='px-6 py-4 w-48'>
                                                    <ul className='space-y-4'>
                                                        {(row.passengers as unknown as IBookingPassenger[]).map(
                                                            (row, i) => (
                                                                <li className='text-sm text-gray-500' key={i}>
                                                                    {getPassengerName(row)}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                            placement='bottom-start'
                                            triggers={['click']}
                                        >
                                            <p className='mt-1'>
                                                + {(row.passengers as unknown as IBookingPassenger[]).length - 1}{' '}
                                                Passengers
                                            </p>
                                        </Tooltip>
                                    )}
                                </td>
                                <td className='td-1'>
                                    <div className='flex items-center gap-3'>
                                        <img className='w-9 h-9' crossOrigin='anonymous' src={row.flight.logo} alt='' />
                                        <div>
                                            <div className='font-semibold'>{row.flight.name}</div>
                                            <p className='mt-1'>
                                                {row.flight.code} {row.flight.number}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold'>{formatCurrency(row.payment.amount)}</div>
                                    <p className='mt-1'>Total</p>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold'>{getFareSupplier(row.fareSupplier)}</div>
                                </td>

                                {showBookedBy && (
                                    <td className='td-1'>
                                        <div className='font-semibold'>{row.bookedBy.name}</div>
                                    </td>
                                )}

                                <td className='td-1'>
                                    <div className='min-w-max'>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>
                                <td className='td-1'>
                                    <Dropdown
                                        target={
                                            <a className='dropdown-action'>
                                                <MenuDotIcon />{' '}
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/bookings/${row._id}/SAG`}>View Ticket</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => window.open(`/bookings/${row._id}/invoice/SAG`)}
                                                >
                                                    Invoice
                                                </DropdownItem>
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className='flex justify-center items-center min-h-[500px]'>
                    <Spinner />
                </div>
            )}
        </div>
    );
};

BookingTable.defaultProps = {
    showBookedBy: true,
};

export default BookingTable;
