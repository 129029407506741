import { FC } from 'react';
import ReactQuill from 'react-quill';

interface IRichTextEditorProps {
    value: string;
    onChange: (value: string) => void;
}

export const RichTextEditor: FC<IRichTextEditorProps> = (props) => {
    return (
        <ReactQuill
            theme="snow"
            value={props.value}
            onChange={props.onChange}
            modules={{
                toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '+1' }, { align: [] }],
                    [],
                    [],
                ],
            }}
        />
    );
};
