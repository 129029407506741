import { FC } from 'react';
import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import update from 'immutability-helper';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const OpeningBalanceSchema = Yup.object().shape({
    openingBalance: Yup.number().typeError('you must specify a number').required('This field is required'),
});

interface OpeningBalanceEditModalProps {
    show: boolean;
    onClose: () => void;
    subAgencyId: string;
    openingBalance: number;
}

const OpeningBalanceEditModal: FC<OpeningBalanceEditModalProps> = (props) => {
    const { subAgencyId, openingBalance } = props;

    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/opening-balance`,
            payload
        );

        return data;
    });

    const initialValues = {
        openingBalance: openingBalance,
    };

    const handleSubmit = async (values: any) => {
        try {
            const newOpeningBalance = await mutation.mutateAsync(values);

            toast.success('Opening Balance updated successfully.');

            queryClient.setQueryData(['subAgencyDetail', subAgencyId], (prev: any) => {
                return update(prev, {
                    openingBalance: { $set: newOpeningBalance },
                });
            });

            // queryClient.invalidateQueries({ queryKey: ['subAgencyDetail', subAgencyId] });

            props.onClose();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.data.message);
        }
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10">
            <div className="font-bold">Update Opening Balance</div>

            <div className="mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={OpeningBalanceSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="grid grid-cols-12 gap-6 mt-4">
                            <div className="col-span-12">
                                <label className="text-xs mb-2" htmlFor="openingBalance">
                                    OPENING BALANCE
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field
                                        id="openingBalance"
                                        name="openingBalance"
                                        className="form-control pl-8 w-96"
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="openingBalance" />
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-8"
                            isLoading={mutation.isLoading}
                        >
                            Update
                        </LoaderButton>
                    </Form>
                </Formik>
            </div>
        </Modal>
    );
};

export default OpeningBalanceEditModal;
