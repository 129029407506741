import moment from 'moment';

export const getGenderFromTitle = (title: string) => {
    if (['Ms', 'Mrs'].includes(title)) return 'Female';
    else return 'Male';
};

export const getInitials = (name: string) => {
    const arr = name.split(' ');

    let initials = arr[0].substring(0, 1);

    if (arr.length > 1) {
        initials += arr[arr.length - 1].substring(0, 1);
    }

    return initials.toUpperCase();
};

export const formatDOB = (dob: string, format: string) => {
    return moment(dob, format).format('DD MMM YYYY');
};

export const getAmendmentStatusColor = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'yellow';
        case 'APPROVED':
            return 'green';
        case 'REJECTED':
            return 'red';
        case 'COMPLETED':
            return 'green';
        case 'AWAITING_SUB_AGENCY_APPROVAL':
            return 'yellow';
        case 'APPROVED_BY_SUB_AGENCY':
            return 'yellow';
        case 'AWAITING_AGENCY_APPROVAL':
            return 'yellow';
    }
};

export const getAmendmentStatusText = (status: string, subAgency: string) => {
    switch (status) {
        case 'PENDING':
            return 'Pending';
        case 'APPROVED':
            return 'Approved';
        case 'REJECTED':
            return 'Rejected';
        case 'COMPLETED':
            return 'Completed';
        case 'AWAITING_SUB_AGENCY_APPROVAL':
            return `Awaitng ${subAgency} approval`;
        case 'APPROVED_BY_SUB_AGENCY':
            return `Approved by ${subAgency}`;
        case 'AWAITING_AGENCY_APPROVAL':
            return 'Awaiting agency approval';
    }
};

export const capFirst = (str: string) => {
    let tmp = str.toLowerCase();
    return tmp[0].toUpperCase() + tmp.slice(1);
};

export const snakeCaseToWord = (stringInSnakeCase: string): string => {
    let inWords = stringInSnakeCase
        .toLowerCase()
        .split('_')
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ');
    return inWords;
};

export const secureString = ({ text, showCharacters }: { text: string; showCharacters: number }) => {
    return '*'.repeat(text.length - showCharacters) + text.slice(-showCharacters);
};

export const valueArrayFromEnum = (enumObject: { [key: string]: string | number }): (string | number)[] => {
    return Object.values(enumObject);
};