import { useQuery } from '@tanstack/react-query';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import React, { FC, useEffect, useState } from 'react';
import PaymentTable from './components/PaymentTable/PaymentTable';
import { debounce } from 'lodash';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import { IPayment } from 'app/types';

interface PaymentListProps {}

const PaymentList: FC<PaymentListProps> = (props) => {
    const [filter, setFilter] = useState({
        search: '',
        status: '',
    });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [params, setParams] = useState<String>(`?page=${currentPage}`);

    const paymentsQuery = useQuery<IPayment[]>(['paymentListPaginated', params], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/payments${params}`);

        setPageCount(data.pageCount);
        return data.data;
    });

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;
            if (filter.status !== '') newUrl += `&filter=${filter.status}`;

            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const debouncedSearch = React.useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleStatusFilterChange = (status: string) => {
        setFilter((prev) => ({ ...prev, status }));
        setCurrentPage(() => 1);
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <>
            <h5 className="font-bold">Balance Update Requests</h5>

            <div className="card  mt-8">
                <div className="flex items-center justify-between px-6 pt-5">
                    <TableSearch placeholder="Search for a payment by id, method" onSearch={handleSearch} />
                    <div className="flex items-center gap-3">
                        <label className="text-xs" htmlFor="filter">
                            FILTER
                        </label>
                        <select
                            id="filter"
                            className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                            onChange={(e: any) => handleStatusFilterChange(e.target.value)}
                        >
                            <option value="">All</option>
                            <option value="PENDING">Pending</option>
                            <option value="APPROVED">Approved</option>
                            <option value="REJECTED">Rejected</option>
                        </select>
                    </div>
                </div>

                <PaymentTable payments={paymentsQuery.data} isLoading={paymentsQuery.isLoading} params={params} />
                <div className="px-4 pb-3 ">
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default PaymentList;
