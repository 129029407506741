import http from 'app/config/http';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
// import { ICcAvenue, IGatewayCharges } from './ccAvenue.enum';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IPlugin } from 'app/types';
import { IGatewayCharges } from 'app/enums/gateway-payment-mode.enum';

export const useHdfcPayment = () => {
    const pluginId = 'hdfc-payment';
    const queryClient = useQueryClient();

    const hdfcPaymentDataQuery = useQuery([pluginId, 'plugin', 'keys'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/${pluginId}`);
        return data;
    });

    const hdfcPaymentCredentialsUpdateMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${pluginId}/credentials`,
            values
        );
        return data;
    });

    const hdfcPaymentGateWayChargesUpdateMutation = useMutation(
        async (payload: { gatewayCharges: IGatewayCharges }) => {
            const { data } = await http.put(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${pluginId}/gateway-charges`,
                payload
            );
            return data;
        }
    );

    const updateMutation = useMutation(async (values: any) => {
        await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/${pluginId}/is-enabled`, values);
    });

    const handlePluginActivationChange = async (e: any) => {
        const isChecked = e.target.checked;
        try {
            await updateMutation.mutateAsync({ isEnabled: isChecked });
            queryClient.setQueryData([pluginId, 'plugin', 'keys'], (prev: IPlugin) => {
                return update(prev, {
                    isEnabled: { $set: isChecked },
                });
            });

            toast.success(`HDFC Payment Gateway ${isChecked ? 'enabled' : 'disabled'} successfully`);
        } catch (err) {
            toast.error(
                `Some error occurred while ${isChecked ? 'deactivating' : 'activating'} the plugin, please try again`
            );
        } finally {
            queryClient.fetchQuery([pluginId, 'plugin', 'keys']);
        }
    };

    return {
        isLoading: hdfcPaymentDataQuery.isLoading,
        hdfcPaymentDetails: hdfcPaymentDataQuery.data,
        hdfcPaymentCredentialsUpdateMutation: hdfcPaymentCredentialsUpdateMutation,
        hdfcPaymentGateWayChargesUpdateMutation,
        handlePluginToggle: handlePluginActivationChange,
        isToggling: updateMutation.isLoading,
    };
};
