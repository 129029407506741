import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import SubAgencyForm from './components/SubAgencyForm/SubAgencyForm';

interface SubAgencyEditProps {}

const SubAgencyEdit: FC<SubAgencyEditProps> = (props) => {
    const { subAgencyId } = useParams();

    const query = useQuery(['subAgencyDetail', subAgencyId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}`
        );

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Edit Sub Agency</div>

                <SubAgencyForm subAgency={query.data} openingBalance={query.data.openingBalance} />
            </div>
        </>
    );
};

export default SubAgencyEdit;
