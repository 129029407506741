import moment from 'moment';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { getStatusClass, getStatusText } from 'app/utils/flight-utils';
import { ISubAgencySeriesSearch } from '../types/sub-agency-series-search';
import { useNavigate } from 'react-router-dom';

interface IRenderSubAgencySeries {
    subAgencyInventory: ISubAgencySeriesSearch;
    handlePreNavigate: () => void;
}
const RenderSubAgencySeries = (props: IRenderSubAgencySeries) => {
    const { subAgencyInventory, handlePreNavigate } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        handlePreNavigate();
        navigate(`/series-inventory/${subAgencyInventory._id}/bookings/SAG`);
    };

    return (
        <div className='grid grid-cols-12 px-2 py-2 gap-2 hover:bg-gray-50 cursor-pointer ' onClick={handleClick}>
            <div className='col-span-3 flex flex-col items-center justify-between'>
                <div className='font-semibold text-primary'>{subAgencyInventory._id}</div>
                <StatusBadge className='' color={getStatusClass(subAgencyInventory.status)}>
                    {getStatusText(subAgencyInventory.status)}
                </StatusBadge>
            </div>
            <div className='col-span-5 flex items-center gap-3'>
                <img
                    className='w-12 h-12'
                    crossOrigin='anonymous'
                    src={subAgencyInventory.departureFlight.logo}
                    alt=''
                />
                <div className='flex flex-col justify-between'>
                    <div className='font-semibold text-black'>{subAgencyInventory.departureFlight.name}</div>
                    <p className=''>
                        {`${subAgencyInventory.departureFlight.code} ${subAgencyInventory.departureFlight.number}`}
                    </p>
                </div>
                <div className='flex flex-col justify-center items-center '>
                    <p>{moment(subAgencyInventory.date, 'YYYYMMDD').format('DD MMM YYYY')}</p>
                    <p>
                        {subAgencyInventory.departureAirport} - {subAgencyInventory.arrivalAirport}
                    </p>
                </div>
            </div>
            <div className='col-span-4 flex items-center'>
                <p className='overflow-hidden whitespace-pre text-base text-black font-semibold text-ellipsis '>
                    {subAgencyInventory.subAgency.name}
                </p>
            </div>
        </div>
    );
};

export default RenderSubAgencySeries;
