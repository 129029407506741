// import { useMutation, useQueryClient } from '@tanstack/react-query';
// import http from 'app/config/http';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import { CrossIcon } from 'app/icons';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

let isIntialial = true;

interface FareruleFormProps {
    fareRules: string[];
    fareSupplier: string;
    refetchQueryIdentifier: any[];
}

const FareruleForm: FC<FareruleFormProps> = (props) => {
    const { fareRules, fareSupplier } = props;
    // const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/${fareSupplier}/fare-rules`,
            payload
        );
        return data;
    });

    const [rules, setRules] = useState<string[]>([]);
    const [currentRule, setCurrentRule] = useState('');

    useEffect(() => {
        setRules(() => [...fareRules]);
    }, [fareRules]);

    const handleServerSync = async () => {
        const payload = {
            fareRules: [...rules],
        };

        try {
            await mutation.mutateAsync(payload);
            // queryClient.fetchQuery(refetchQueryIdentifier);
            // toast.success('Fare Rules updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    useEffect(() => {
        if (!isIntialial) {
            handleServerSync();
        } else {
            isIntialial = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rules]);

    useEffect(() => {
        return () => {
            isIntialial = true;
        };
    }, []);

    const handleChange = (newRule: string) => {
        const rule = newRule.trimStart();
        setCurrentRule(rule);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const rule = currentRule.trim();
        if (rule !== '') {
            setRules((val) => [...val, rule]);
            setCurrentRule('');
        }
    };

    const onDelete = (index: number) => {
        setRules((val) => {
            const temp = [...val];
            temp.splice(index, 1);
            return temp;
        });
    };

    return (
        <>
            <div className="font-bold py-4">Fare Rules</div>

            <div>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-4">
                            <input
                                className="form-control"
                                value={currentRule}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                            />
                        </div>

                        <div className="col-span-4">
                            <button
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full h-full"
                                type="submit"
                                disabled={currentRule.trim() === ''}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="mt-6">
                <RulePill rules={rules} onDelete={onDelete} />
            </div>
        </>
    );
};

export default FareruleForm;

interface RulePillProps {
    rules: string[];
    onDelete: (index: number) => void;
}

const RulePill: FC<RulePillProps> = (props) => {
    const { rules, onDelete } = props;

    return (
        <div className="flex flex-wrap gap-4 items-start  ">
            {rules.map((rule, index) => (
                <div
                    key={rule}
                    className="flex items-center gap-2 rounded-full px-[10px] py-[6px] bg-gray-50  text-gray-500 text-sm font-semibold"
                >
                    <span>{rule}</span>
                    <button type="button" onClick={() => onDelete(index)}>
                        <CrossIcon width="8" height="8" />
                    </button>
                </div>
            ))}
        </div>
    );
};

RulePill.defaultProps = {
    rules: [],
};
