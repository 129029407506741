import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { getSearchResults } from 'app/api/services/search.service';
import { SearchEntityEnum } from '../search-entity.enum';

export type ISubAgencySeriesSearch = {
    _id: string;
    pnr: string;
    status: 'PENDING';
    subAgency: {
        _id: string;
        name: string;
    };
    date: string;
    seats: {
        available: number;
        booked: number;
        total: number;
    };
    departureAirport: string;
    arrivalAirport: string;
    departureFlight: {
        name: string;
        code: string;
        number: number;
        logo: string;
    };
};

export type IAgencySeriesSearch = {
    _id: string;
    pnr: string;
    status: 'PENDING';
    date: string;
    seats: {
        available: number;
        booked: number;
        total: number;
    };
    departureAirport: string;
    arrivalAirport: string;
    departureFlight: {
        name: string;
        code: string;
        number: string;
        logo: string;
    };
};

export type ISubAgencySearch = {
    _id: string;
    name: string;
    email: string;
    address: {
        line: string;
        country: string;
        state: string;
        city: string;
        pincode: number;
    };
    creditLimit: number;
    availableCashBalance: number;
    availableCredit: number;
    logoUrl: string;
};

export type IBookingSearch = {
    _id: string;
    bookingId: string;
    status: 'COMPLETED';
    passengers: Array<string>;
    pnrs: Array<string>;
    date: string;
    departureAirport: string;
    arrivalAirport: string;
    departureFlight: {
        name: string;
        code: string;
        number: string;
        logo: string;
    };
};

const useSearchQuery = () => {
    const ref = useRef(null);

    const [agencySeriesTickets, setAgencySeriesTickets] = useState<IAgencySeriesSearch[]>([]);
    const [subAgencySeriesTickets, setSubAgencySeriesTickets] = useState<ISubAgencySeriesSearch[]>([]);
    const [bookings, setBookings] = useState<IBookingSearch[]>([]);
    const [subagencies, setSubAgencies] = useState<ISubAgencySearch[]>([]);

    const searchAgencySeriesTicketsMutation = useMutation((q: string) =>
        getSearchResults<IAgencySeriesSearch>(SearchEntityEnum.AgencySeriesTicket, q)
    );
    const searchSubAgencySeriesTicketsMutation = useMutation((q: string) =>
        getSearchResults<ISubAgencySeriesSearch>(SearchEntityEnum.SubAgencySeriesTicket, q)
    );
    const searchBookingMutation = useMutation((q: string) =>
        getSearchResults<IBookingSearch>(SearchEntityEnum.Bookings, q)
    );
    const searchSubagenciesMutation = useMutation((q: string) =>
        getSearchResults<ISubAgencySearch>(SearchEntityEnum.SubAgency, q)
    );

    const groups: { type: SearchEntityEnum; label: string; isLoading: boolean; data: any[] }[] = useMemo(() => {
        return [
            {
                type: SearchEntityEnum.Bookings,
                label: 'Bookings',
                isLoading: searchBookingMutation.isLoading,
                data: bookings,
            },
            {
                type: SearchEntityEnum.SubAgencySeriesTicket,
                label: 'Sub Agency Series Ticket',
                isLoading: searchSubAgencySeriesTicketsMutation.isLoading,
                data: subAgencySeriesTickets,
            },
            {
                type: SearchEntityEnum.AgencySeriesTicket,
                label: 'Agency Series Ticket',
                isLoading: searchAgencySeriesTicketsMutation.isLoading,
                data: agencySeriesTickets,
            },
            {
                type: SearchEntityEnum.SubAgency,
                label: 'Sub Agencies',
                isLoading: searchSubagenciesMutation.isLoading,
                data: subagencies,
            },
        ];
    }, [
        agencySeriesTickets,
        subAgencySeriesTickets,
        bookings,
        subagencies,
        searchAgencySeriesTicketsMutation.isLoading,
        searchSubAgencySeriesTicketsMutation.isLoading,
        searchBookingMutation.isLoading,
        searchSubagenciesMutation.isLoading,
    ]);

    const resetResults = useCallback(() => {
        setAgencySeriesTickets([]);
        setSubAgencySeriesTickets([]);
        setBookings([]);
        setSubAgencies([]);
    }, []);

    const fetch = useCallback(
        (q: string) => {
            ref.current = q;

            searchAgencySeriesTicketsMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current) {
                    setAgencySeriesTickets(result.data);
                }
            });
            searchSubAgencySeriesTicketsMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current) setSubAgencySeriesTickets(result.data);
            });
            searchBookingMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current) setBookings(result.data);
            });
            searchSubagenciesMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current) setSubAgencies(result.data);
            });
        },
        [
            searchAgencySeriesTicketsMutation,
            searchSubAgencySeriesTicketsMutation,
            searchBookingMutation,
            searchSubagenciesMutation,
        ]
    );

    return {
        fetch,
        resetResults,
        groups,
        isEmpty: groups.every((group) => group.data.length === 0),
        isLoading: groups.some((group) => group.isLoading),
    };
};

export default useSearchQuery;
