/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import capitalize from 'lodash.capitalize';
import { IAgencyUserForm } from '../../types';
import { useMutation } from '@tanstack/react-query';
import { IAgencyUser, IAgentStatus } from 'app/types';
import React, { FC, useCallback, useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const AgencyUserFormSchema = Yup.object({
    name: Yup.string().trim().required('This field is required'),
    email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
    phoneNumber: Yup.string()
        .trim()
        .required('This field is required')
        .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
    username: Yup.string().trim().required('This field is required').matches(/^\S*$/, 'Username cannot contain spaces'),
});

interface AgencyUserFormProps {
    agent: IAgencyUser;
}

const AgencyUserForm: FC<AgencyUserFormProps> = (props) => {
    const { agent } = props;

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/${agent._id}`,
            payload
        );

        return data;
    });

    const initialValues: IAgencyUserForm = useMemo(() => {
        if (!agent)
            return {
                name: '',
                email: '',
                phoneNumber: '',
                username: '',
                designation: '',
                status: IAgentStatus.DISABLED,
            };

        return {
            name: agent.name,
            email: agent.email,
            phoneNumber: agent.phoneNumber,
            username: agent.username,
            designation: agent.designation,
            status: agent.status,
        };
    }, [agent]);

    const handleSubmit = async (values: IAgencyUserForm) => {
        try {
            await mutation.mutateAsync(values);

            toast.success('User updated successfully.');
        } catch (ex: any) {
            toast.error(ex.message);
            console.log(ex);
        }
    };

    const uniqueEmailValidator = useCallback(
        debounce(async (value) => {
            // if the value is equal to the initial value, don't do anything
            if (value === initialValues.email) return;

            try {
                await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/email`, {
                    email: value,
                });
            } catch (err: any) {
                if (err.status === 400) {
                    // validateField(err.data.message);
                    return err.data.message;
                }
            }
        }, 500),
        []
    );

    const uniqueUsernameValidator = useCallback(
        debounce(async (value) => {
            // if the value is equal to the initial value, don't do anything
            if (value === initialValues.username) return;

            try {
                await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/username`, {
                    username: value,
                });
            } catch (err: any) {
                if (err.status === 400) {
                    return err.data.message;
                }
            }
        }, 500),
        []
    );

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={AgencyUserFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, validateField, errors }) => (
                <Form>
                    <div className="grid grid-cols-12 gap-6 mt-8">
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="name">
                                NAME
                            </label>
                            <Field type="text" id="name" name="name" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="name" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="email">
                                EMAIL
                            </label>
                            <Field
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                validate={uniqueEmailValidator}
                            />
                            <div className="form-error">
                                <ErrorMessage name="email" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="phoneNumber">
                                PHONE NUMBER
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">+91</div>
                                <Field type="text" id="phoneNumber" name="phoneNumber" className="form-control pl-12" />
                            </div>

                            <div className="form-error">
                                <ErrorMessage name="phoneNumber" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="username">
                                USERNAME
                            </label>
                            <Field
                                type="text"
                                id="username"
                                name="username"
                                className="form-control"
                                validate={uniqueUsernameValidator}
                            />
                            <div className="form-error">
                                <ErrorMessage name="username" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="designation">
                                DESIGNATION
                            </label>
                            <Field type="text" id="designation" name="designation" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="designation" />
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="status">
                                Status
                            </label>
                            <Field as="select" name="status" type="text" id="status" className="form-control">
                                {Object.values(IAgentStatus).map((status) => (
                                    <option key={status} value={status}>
                                        {capitalize(status)}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name="status" />
                            </div>
                        </div>
                    </div>

                    {/* <div className="mt-8">
                        <div className="flex items-center gap-2">
                            <Field id="isEnabled" name="isEnabled" type="checkbox" className="form-switch success" />

                            <label htmlFor="isEnabled">Enabled</label>
                        </div>
                    </div> */}

                    <LoaderButton
                        type="submit"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                        isLoading={mutation.isLoading}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default AgencyUserForm;
