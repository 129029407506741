import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import http from 'app/config/http';
import { IBankTransaction } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

interface BankTransactionViewProps {}

const BankTransactionView: FC<BankTransactionViewProps> = (props) => {
    const { id } = useParams();

    const transactionQuery = useQuery<IBankTransaction>(['bank transaction', id], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/accounts/${id}`);

        return data;
    });

    if (transactionQuery.isLoading) return <PageLoader />;

    const transaction = transactionQuery.data;

    console.log({ transaction });

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex gap-2 items-center ">
                    <div className="font-bold">Bank Transaction</div>
                    <div className="font-bold">{id}</div>
                    <StatusBadge className="mt-1" color={transaction.mapped ? 'green' : 'yellow'}>
                        {transaction.mapped ? 'Mapped' : 'Unmapped'}
                    </StatusBadge>
                </div>

                {/* name , gst */}
                <div className="grid grid-cols-12 gap-6 mt-6">
                    {/* transaction date */}
                    <div className="col-span-4">
                        <label className="text-xs mb-2">TRANSACTION DATE</label>
                        <div className="font-semibold py-2">
                            {moment(transaction.transactionDate, 'YYYYMMDD').format('DD MMM YYYY')}
                        </div>
                    </div>

                    {/* Amount */}
                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="balanceUploaded">
                            AMOUNT
                        </label>
                        <div className="font-semibold py-2">{formatCurrency(transaction.balanceUploaded)}</div>
                    </div>

                    {/* transaction type  */}
                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="type">
                            TRANSACTION TYPE
                        </label>
                        <div className="font-semibold py-2">{transaction.type}</div>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-6 mt-6">
                    <div className="col-span-4">
                        <label className="text-xs mb-2">BANK NAME</label>
                        <div className="font-semibold py-2">{transaction.bankAccount.name}</div>
                    </div>

                    <div className="col-span-4">
                        <label className="text-xs mb-2">IFSC</label>
                        <div className="font-semibold py-2">{transaction.bankAccount.ifsc}</div>
                    </div>
                    <div className="col-span-4">
                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                            ACCOUNT HOLDER
                        </label>
                        <div className="font-semibold py-2">{transaction.bankAccount.account.holderName}</div>
                    </div>
                    <div className="col-span-4">
                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                            ACCOUNT NUMBER
                        </label>
                        <div className="font-semibold py-2">{transaction.bankAccount.account.number}</div>
                    </div>
                    <div className="col-span-4">
                        <label className="text-xs mb-2 " htmlFor="transactionRemark">
                            ACCOUNT TYPE
                        </label>
                        <div className="font-semibold py-2">{transaction.bankAccount.account.type}</div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 mt-3">
                    <div className="col-span-8">
                        <div className="mt-4">
                            <label className="text-xs mb-2">REMARKS</label>
                            <div className="font-semibold py-2">{transaction.transactionRemark}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BankTransactionView;
