import { useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { snakeCaseToWord } from 'app/utils/common-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

interface ISupplierReport {
    fareSupplierSales: [
        {
            _id: string;
            segments: number;
            bookings: number;
            bookingAmount: number;
            totalPassengers: number;
            amount: number;
            fareSupplier: string;
        }
    ];
    totalAmount: number;
    totalSegments: number;
    startDate: string;
    endDate: string;
}

interface SalesReportProps {}

const SalesReport: FC<SalesReportProps> = (props) => {
    const [filter, setFilter] = useState({
        date1: moment().startOf('month').format('YYYYMMDD'),
        date2: moment().format('YYYYMMDD'),
    });

    const [params, setParams] = useState<String>(``);

    useEffect(() => {
        setParams(() => {
            let newUrl = ``;
            if (filter.date1 !== '') newUrl += `fromDate=${filter.date1}`;
            if (filter.date2 !== '') newUrl += `&toDate=${filter.date2}`;
            return newUrl;
        });
    }, [filter]);

    const salesDetaisQuery = useQuery<ISupplierReport>(['supplier', 'report', params], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/sales?${params}`);

        return data;
    });

    return (
        <div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Supplier Report</h5>
            </div>

            <div className="card py-4 mt-8">
                <div className="flex justify-end  px-8">
                    <div className="flex items-center gap-3">
                        <label className="text-xs" htmlFor="">
                            FILTER BY DATE
                        </label>
                        <input
                            type="date"
                            className="border border-gray-300 rounded-xl text-sm px-4 py-2 outline-none font-medium"
                            value={moment(filter.date1, 'YYYYMMDD').format('YYYY-MM-DD')}
                            onChange={({ target: { value } }) =>
                                setFilter((prev) => ({
                                    ...prev,
                                    date1: moment(value, 'YYYY-MM-DD').format('YYYYMMDD'),
                                }))
                            }
                            max={moment().format('YYYY-MM-DD')}
                        />

                        <label className="text-xs" htmlFor="">
                            TO
                        </label>
                        <input
                            type="date"
                            className="border border-gray-300 rounded-xl text-sm px-4 py-2 outline-none font-medium"
                            value={moment(filter.date2, 'YYYYMMDD').format('YYYY-MM-DD')}
                            onChange={({ target: { value } }) =>
                                setFilter((prev) => ({
                                    ...prev,
                                    date2: moment(value, 'YYYY-MM-DD').format('YYYYMMDD'),
                                }))
                            }
                            max={moment().format('YYYY-MM-DD')}
                        />
                    </div>
                </div>

                <div className=" mt-8">
                    <table className="w-full card-table text-sm">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="th-1">Fare Supplier</th>
                                <th className="th-1">Booking</th>
                                <th className="th-1">Total Passengers</th>
                                <th className="th-1">Booking Amount</th>
                                <th className="th-1 ">
                                    <div className="text-right">Segments</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!salesDetaisQuery.isLoading && (
                                <>
                                    {salesDetaisQuery.data.fareSupplierSales.map((row, i) => (
                                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                            <td className="td-1">
                                                <div className="font-semibold">{snakeCaseToWord(row.fareSupplier)}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.bookings}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.totalPassengers}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{formatCurrency(row.bookingAmount)}</div>
                                            </td>
                                            <td className="td-1 text-right">
                                                <div className="font-semibold">{row.segments}</div>
                                            </td>
                                        </tr>
                                    ))}

                                    {salesDetaisQuery.data.fareSupplierSales.length ? (
                                        <tr className="border-b border-gray-200 last:border-none">
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td className="td-1">
                                                <div className="font-semibold flex justify-between">
                                                    <div>Total Segments</div>
                                                    <div>{salesDetaisQuery.data.totalSegments}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td
                                                align="center"
                                                colSpan={10}
                                                className="py-12 font-medium text-base text-[#98A2B3]"
                                            >
                                                - No data available -
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                    {salesDetaisQuery.isLoading && (
                        <div className="flex justify-center items-center min-h-[500px]">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SalesReport;
