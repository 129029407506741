import { debounce } from 'lodash';
import http from 'app/config/http';
import React, { FC, useState, useEffect } from 'react';
import BookingTable from '../BookingTable/BookingTable';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableSearch from 'app/components/TableSearch/TableSearch';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import { ExcelIcon } from 'app/icons';

interface BookingListProps {
    // bookings: IBooking[];
    subAgencyId: string;
}

// filterBy: 'departure' | 'createdAt';
const filterBy = [
    { label: 'Departure Date', value: 'departure' },
    { label: 'Booking Date', value: 'createdAt' },
];

const BookingList: FC<BookingListProps> = (props) => {
    const { subAgencyId } = props;
    const [filter, setFilter] = useState({
        search: '',
        date1: '',
        date2: '',
        filterBy: 'departure',
    });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [params, setParams] = useState<String>(`?page=${currentPage}`);

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.date1 !== '') newUrl += `&fromDate=${filter.date1}`;
            if (filter.date2 !== '') newUrl += `&toDate=${filter.date2}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;
            if (filter.filterBy !== '') newUrl += `&filterBy=${filter.filterBy}`;
            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const subAgencyBookingQuery = useQuery(['subAgency', subAgencyId, 'bookings', params], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/bookings${params}`
        );
        setPageCount(data.pageCount);

        return data.data;
    });

    const subAgencyBookingListDownloadMutation = useMutation(async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/bookings-excel${params}`,
            {
                responseType: 'blob',
            }
        );
        return data;
    });

    const debouncedSearch = React.useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleDateFilter = (date1: string, date2: string) => {
        setFilter((prev) => ({ ...prev, date1, date2 }));
        setCurrentPage(() => 1);
    };

    const handleDownnloadExcel = async () => {
        const res = await subAgencyBookingListDownloadMutation.mutateAsync();
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'bookings.xlsx');

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
    };

    const handleFilterByChange = (val: string) => {
        setFilter((prev) => ({ ...prev, filterBy: val }));
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div>
            <div className=''>
                <div className='flex justify-between items-center px-6 pt-5'>
                    <TableSearch
                        placeholder='Search for a booking by name, id or flight details'
                        onSearch={handleSearch}
                    />

                    <div className='flex gap-2 bg items-center'>
                        <TableDateFilter onChange={handleDateFilter} />
                        <label className='text-xs' htmlFor=''>
                            BY
                        </label>
                        <select
                            className='border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium'
                            onChange={(e) => handleFilterByChange(e.target.value)}
                            value={filter.filterBy}
                        >
                            {filterBy.map((status) => (
                                <option key={status.value} value={status.value}>
                                    {status.label}
                                </option>
                            ))}
                        </select>
                        <button
                            className='cursor-pointer flex items-center justify-center rounded-full'
                            onClick={handleDownnloadExcel}
                            disabled={subAgencyBookingListDownloadMutation.isLoading}
                        >
                            <ExcelIcon width='24' height='24' />
                        </button>
                        {/* <button
                            onClick={handleDownnloadExcel}
                            className='text-center bg-primary text-white text-sm font-semibold px-4 py-2 rounded-lg '
                        >
                            Download
                        </button> */}
                    </div>
                </div>
                <div className='mt-6'>
                    <BookingTable
                        bookings={subAgencyBookingQuery.data}
                        showBookedBy={false}
                        isLoading={subAgencyBookingQuery.isLoading}
                    />
                </div>
                <div className='px-4 pb-3 '>
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </div>
    );
};

export default BookingList;
