import { useQuery } from '@tanstack/react-query';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import { IAgencyUser } from 'app/types';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AgencyUserTable from './components/AgencyUserTable/AgencyUserTable';
import { debounce } from 'lodash';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';

interface AgencyUserListProps {}

const AgencyUserList: FC<AgencyUserListProps> = (props) => {
    const [filter, setFilter] = useState({
        search: '',
    });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [params, setParams] = useState<String>(`?page=${currentPage}`);

    const agencyUserQuery = useQuery<IAgencyUser[]>(['agencyUserList', params], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users${params}`);

        setPageCount(data.pageCount);
        return data.data;
    });

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;

            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const debouncedSearch = useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Users</h5>
                <Link
                    to="add"
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add User
                </Link>
            </div>

            <div className="card mt-8">
                <div className="flex justify-between items-center px-6 pt-5">
                    <TableSearch placeholder="Search for a user by name or email" onSearch={handleSearch} />
                </div>

                <div className="mt-6">
                    <AgencyUserTable
                        users={agencyUserQuery.data}
                        isLoading={agencyUserQuery.isLoading}
                        params={params}
                    />
                    <div className="px-4 pb-3 ">
                        <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgencyUserList;
