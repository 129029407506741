// commission-plan-view

import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { ITripJackCommission } from '../commission-plan-edit/Page';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { getCommissionPlanById } from 'app/api/services/fare-commission.service';
import { tripJackCommissionComponentEnum } from 'app/enums/commission-component-trip-jack';
import { getTripJacKFareComponentLabel } from 'app/modules/shared/utils/trip-jack-supplier.utils';
import { getFareSupplierFareComponentKeys } from 'app/modules/shared/utils/plan-commission.utils';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';

type tripJackComponentKeys = `${tripJackCommissionComponentEnum}`;

const CommissionPlanView = () => {
    const { id } = useParams();
    const tripJackFareComponentKeys = getFareSupplierFareComponentKeys(CommissionPlanEnabledSuppliers.TRIP_JACK);
    const { isLoading, data } = useQuery(['COMMISSiON_PLAN', id], () => getCommissionPlanById(id));

    if (isLoading) return <PageLoader />;

    return (
        <div>
            <div className='mb-5'>
                <BackLink />
            </div>
            <h4 className='font-bold'>Commission Plan Details</h4>
            <div className='card mt-8 px-6 py-6'>
                <div className='grid grid-cols-12 gap-6 mt-6'>
                    <div className='col-span-8'>
                        <label className='text-xs mb-2' htmlFor='name'>
                            Plan Name
                        </label>
                        <div className='font-semibold py-2 flex gap-2'>{data.name}</div>
                    </div>
                    <div className='col-span-8'>
                        <label className='text-xs mb-2' htmlFor='commissionFareSupplier'>
                            Fare Supplier
                        </label>
                        <div className='font-semibold py-2 flex gap-2'>
                            {createReadableFareSupplierLabel(data.commissionFareSupplier as FareSupplierEnum)}
                        </div>
                    </div>

                    <div className='col-span-8'>
                        <label className='text-xs mb-2' htmlFor='managementChargesPerPassenger'>
                            Management Charges Per Passenger
                        </label>
                        <div className='font-semibold py-2 flex gap-2'>
                            ₹ &nbsp; {data.managementChargesPerPassenger}
                        </div>
                    </div>
                    <div className='col-span-4' />
                    <div className='col-span-4'>
                        <label className='text-xs mb-2' htmlFor='taxRates.gstRate'>
                            GST Rate on Management Fee
                        </label>
                        <div className='font-semibold py-2 flex gap-2'>{data.taxRates.gstRate}%</div>
                    </div>
                    <div className='col-span-4'>
                        <label className='text-xs mb-2' htmlFor='taxRates.commissionTdsRate'>
                            Commission TDS Rate
                        </label>
                        <div className='font-semibold py-2 flex gap-2'>{data.taxRates.commissionTdsRate}%</div>
                    </div>

                    {data.commissionFareSupplier === CommissionPlanEnabledSuppliers.TRAVEL_BOUTIQUE && (
                        <div className='col-span-8'>
                            <div className='grid grid-cols-5 gap-4'>
                                <div className='mt-auto'>
                                    <span className='mb-2'>Net Commission</span>
                                    <div className='form-error' />
                                </div>
                                <div className='col-span-2'>
                                    <label className='text-xs mb-2' htmlFor=''>
                                        Flat
                                    </label>
                                    <div className=''>
                                        <div className='font-semibold py-2 flex gap-2'>
                                            ₹ &nbsp;
                                            {data.commissionBreakdown.netCommission.flat}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-2'>
                                    <label className='text-xs mb-2' htmlFor=''>
                                        Percentage
                                    </label>
                                    <div className=''>
                                        <div className='font-semibold py-2 flex gap-2'>
                                            {data.commissionBreakdown.netCommission.percentage} %
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {data.commissionFareSupplier === CommissionPlanEnabledSuppliers.TRIP_JACK &&
                        tripJackFareComponentKeys.map((fareComponentKey) => (
                            <div className='col-span-8' key={fareComponentKey}>
                                <div className='grid grid-cols-5 gap-4'>
                                    <div className='mt-auto'>
                                        <span className='mb-2'>{getTripJacKFareComponentLabel(fareComponentKey)}</span>
                                        <div className='form-error' />
                                    </div>
                                    <div className='col-span-2'>
                                        <label className='text-xs mb-2' htmlFor=''>
                                            Flat
                                        </label>
                                        <div className=''>
                                            <div className='font-semibold py-2 flex gap-2'>
                                                ₹ &nbsp;
                                                {
                                                    data.commissionBreakdown[fareComponentKey as tripJackComponentKeys]
                                                        .flat
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <label className='text-xs mb-2' htmlFor=''>
                                            Percentage
                                        </label>
                                        <div className=''>
                                            <div className='font-semibold py-2 flex gap-2'>
                                                {
                                                    data.commissionBreakdown[fareComponentKey as tripJackComponentKeys]
                                                        .percentage
                                                }{' '}
                                                %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className='flex justify-end gap-2'>
                    <Link
                        className='py-3 bg-primary flex items-center justify-center text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4'
                        to={`/fare-management/commission-plan/${id}/edit`}
                    >
                        Edit
                    </Link>

                    <Link
                        className='bg-primary flex items-center justify-center text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4'
                        to={`/fare-management/commission-plan/${id}/associate`}
                    >
                        Associate
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CommissionPlanView;
