import moment from 'moment';



export const SYSTEM_DATE_FORMAT = 'YYYYMMDD';
export const SYSTEM_TIME_FORMAT = 'HHmmss';
export const SYSTEM_DATE_TIME_FORMAT = SYSTEM_DATE_FORMAT + SYSTEM_TIME_FORMAT;





export const formatTime = (time: string, format: string = 'HH:mm') => {
    return moment(time, 'HHmmss').format(format);
};

export const formatDate = (date: string, format: string = 'ddd, DD MMM') => {
    return moment(date, 'YYYYMMDD').format(format);
};

export const formatMinutesToTime = (minutes: number) => {
    return `${Math.floor(minutes / 60).toString()}hr ${Math.floor(minutes % 60)
        .toString()
        .padStart(2, '0')}mn`;
};
