import TableSearch from 'app/components/TableSearch/TableSearch';
import React, { FC, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import PaymentNoteTable from './components/PaymentNoteTable/PaymentNoteTable';
import { IPaymentNote } from 'app/types';

interface PaymentNoteListProps {}

const PaymentNoteList: FC<PaymentNoteListProps> = (props) => {
    const [filter, setFilter] = useState({
        search: '',
        status: '',
        type: '',
    });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [params, setParams] = useState<string>(`?page=${currentPage}`);

    const paymentsQuery = useQuery<IPaymentNote[]>(['payment', 'notes', params], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/payment-notes${params}`
        );

        setPageCount(data.pageCount);
        return data.data;
    });

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;
            if (filter.status !== '') newUrl += `&status=${filter.status}`;
            if (filter.type !== '') newUrl += `&type=${filter.type}`;

            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const debouncedSearch = React.useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleStatusFilterChange = (status: string) => {
        setFilter((prev) => ({ ...prev, status }));
        setCurrentPage(() => 1);
    };

    const handleTypeFilterChange = (type: string) => {
        setFilter((prev) => ({ ...prev, type }));
        setCurrentPage(() => 1);
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <>
            <h5 className="font-bold">Payment Notes</h5>

            <div className="card  mt-8">
                <div className="flex items-center justify-between px-6 pt-5">
                    <TableSearch placeholder="Search for a payment note by id, sub agency" onSearch={handleSearch} />

                    <div className="flex justify-end gap-4">
                        <div className="flex items-center gap-3">
                            <label className="text-xs" htmlFor="filter">
                                Status
                            </label>
                            <select
                                id="filter"
                                className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                                onChange={(e: any) => handleStatusFilterChange(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="APPROVED">Approved</option>
                                <option value="REJECTED">Rejected</option>
                                <option value="PENDING">Pending</option>
                            </select>
                        </div>

                        <div className="flex items-center gap-3">
                            <label className="text-xs" htmlFor="filter">
                                Type
                            </label>
                            <select
                                id="filter"
                                className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                                onChange={(e: any) => handleTypeFilterChange(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="DEBIT">Debit</option>
                                <option value="CREDIT">Credit</option>
                            </select>
                        </div>
                    </div>
                </div>
                <PaymentNoteTable payments={paymentsQuery.data} isLoading={paymentsQuery.isLoading} params={params} />
                <div className="px-4 pb-3 ">
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default PaymentNoteList;
