import { WarningIcon } from 'app/icons';
import React, { FC, ReactNode } from 'react';
import Modal from '../Modal/Modal';

interface InfoModalProps {
    show: boolean;
    message: ReactNode;
    onClose: () => void;
}

const InfoModal: FC<InfoModalProps> = (props) => {
    return (
        <Modal className="px-10 py-10" show={props.show} onClose={props.onClose}>
            <div className="flex items-center justify-center gap-3 mt-4">
                <WarningIcon height={28} className="text-yellow-500" />
            </div>
            <div className="text-gray-900 text-center mt-4 max-w-md">{props.message}</div>
            <div className="flex justify-center gap-4 mt-8">
                <button className="bg-gray-200 text-gray-600 px-6 py-2 text-sm rounded-lg" onClick={props.onClose}>
                    Close
                </button>
            </div>
        </Modal>
    );
};

export default InfoModal;
