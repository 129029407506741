import { FC } from 'react';
import moment from 'moment';
import { produce } from 'immer';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import update from 'immutability-helper';
import { ISubAgencyUser } from 'app/types';
import useDialog from 'app/hooks/use-dialog';
import { getInitials } from 'app/utils/common-utils';
import InfoModal from 'app/components/InfoModal/InfoModal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { mapSubAgencyUserStatusToBoolean, mapBooleanToSubAgencyUserStatus } from 'app/pages/SubAgencyUser/utils/common';

interface SubAgencyUserTableProps {
    subAgents: ISubAgencyUser[];
    subAgencyId: string;
}

const SubAgencyUserTable: FC<SubAgencyUserTableProps> = (props) => {
    const { subAgents, subAgencyId } = props;

    const queryClient = useQueryClient();
    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();

    const deleteMutation = useMutation(async (values: any) => {
        await http.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/sub-agency-users/${values._id}`
        );
    });

    const changeEnabledMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/sub-agency-users/${values._id}/is-enabled`,
            { status: values.status }
        );

        return data;
    });

    const handleUserDelete = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Sub Agent deleted successfully.');

            const index = subAgents.findIndex((r) => r._id === _id);

            queryClient.setQueryData(['subAgencyUserList'], (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                });
            });
        } catch (ex: any) {
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    const handleEnabledChange = async (row: ISubAgencyUser) => {
        const previousStatus = row.status;

        queryClient.setQueryData<ISubAgencyUser[]>(['subAgencyUserList'], (prevValues) => {
            const index = prevValues.findIndex((val: any) => val._id === row._id);
            return produce(prevValues, (draft) => {
                draft[index].status = mapBooleanToSubAgencyUserStatus(
                    !mapSubAgencyUserStatusToBoolean(draft[index].status)
                );
            });
        });

        try {
            await changeEnabledMutation.mutateAsync({
                _id: row._id,
                status: mapBooleanToSubAgencyUserStatus(!mapSubAgencyUserStatusToBoolean(previousStatus)),
            });
            toast.success(`User status updated successfully`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            queryClient.setQueryData<ISubAgencyUser[]>(['subAgencyUserList'], (prevValues) => {
                const index = prevValues.findIndex((val: any) => val._id === row._id);

                return produce(prevValues, (draft) => {
                    draft[index].status = mapBooleanToSubAgencyUserStatus(
                        mapSubAgencyUserStatusToBoolean(previousStatus)
                    );
                    return draft;
                });
            });
        }
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">Name</th>
                        <th className="th-1">Username</th>
                        <th className="th-1">Email</th>
                        <th className="th-1">Created At</th>
                        <th className="th-1">Status</th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {subAgents.map((row, i) => (
                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                            <td className="td-1">
                                <div className="flex items-center gap-3">
                                    <div className="w-10 h-10 bg-primary/10 rounded-full flex items-center justify-center">
                                        <div className="text-primary font-semibold">{getInitials(row.name)}</div>
                                    </div>

                                    <div>
                                        <div className="font-semibold">{row.name}</div>
                                        <p>{row.designation}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="td-1">
                                <p>{row.username}</p>
                            </td>
                            <td className="td-1">
                                <p>{row.email}</p>
                            </td>
                            <td className="td-1">
                                <div className="min-w-max">
                                    <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                    <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                </div>
                            </td>
                            <td className="td-1">
                                <input
                                    type="checkbox"
                                    className="form-switch success"
                                    checked={mapSubAgencyUserStatusToBoolean(row.status)}
                                    onChange={(e) => handleEnabledChange(row)}
                                />
                            </td>

                            <td className="td-1">
                                <Dropdown
                                    target={
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a className="dropdown-action">
                                            <MenuDotIcon />
                                        </a>
                                    }
                                    menu={
                                        <DropdownList>
                                            <DropdownItem to={`/sub-agencies/${row.subAgency}/users/${row._id}/edit`}>
                                                Edit
                                            </DropdownItem>
                                            <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                <span className="text-red-500">Delete</span>
                                            </DropdownItem>
                                        </DropdownList>
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this user?'}
                onClose={deleteDialog.hide}
                onConfirm={handleUserDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </div>
    );
};

export default SubAgencyUserTable;
