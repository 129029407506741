import { ChevronRightIcon } from 'app/icons';
import { formatCurrency } from 'app/utils/currency-utils';
import { FC, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { IAgency, IOurPayment } from 'app/types';
import PageLoader from 'app/components/PageLoader/PageLoader';
import moment from 'moment';
import Spinner from 'app/components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import { TRootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'app/store/auth';

const collapseAnimation = {
    variants: {
        collapsed: { opacity: 0, height: 0 },
        expanded: { opacity: 1, height: 'auto' },
    },
    transition: { duration: 1, type: 'tweeen' },
    initial: 'collapsed',
    animate: 'expanded',
    exit: 'collapsed',
};

interface IInternalBillingAmount {
    pendingAmount: number;
}
interface IInternalBillHistory {
    _id: string;
    agency: string;
    amount: number;
    createdAt: string;
    category: 'INVOICE' | 'PAYMENT';
    remarks: string;
    balance: number;
}

interface InternalBillingProps {}

const InternalBilling: FC<InternalBillingProps> = (props) => {
    const dispatch = useDispatch();

    const { user, token } = useSelector((state: TRootState) => state.auth);

    const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);

    const ourPaymentQuery = useQuery<IOurPayment>(['internal-bill', 'list'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/internal-billings`);

        return data;
    });

    const AgencyDetailQuery = useQuery<IAgency>(
        ['agencyDetail'],
        async () => {
            const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency`);

            return data;
        },
        {
            enabled: user.agency.address === undefined,
        }
    );

    const pendingAmountQuery = useQuery<IInternalBillingAmount>(['internal-bill', 'amount'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/internal-billings/pending-amount`
        );

        return data;
    });

    const invoiceHistory = useQuery<IInternalBillHistory[]>(['internal-bill', 'invoice', 'history'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/internal-billings/yearly-summary`
        );
        return data;
    });

    // remove this code in future and its query and role in page loader
    useEffect(() => {
        if (AgencyDetailQuery.data) {
            dispatch(
                authActions.login({
                    token: token,
                    rememberMe: true,
                    user: {
                        ...user,
                        agency: {
                            ...user.agency,
                            address: AgencyDetailQuery.data.address,
                        },
                    },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AgencyDetailQuery.data]);

    if (ourPaymentQuery.isLoading || user.agency.address === undefined) return <PageLoader />;

    const paymentData = ourPaymentQuery.data;

    const totalSegments = paymentData.bills.reduce((acc, bill) => {
        return acc + bill.segments;
    }, 0);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Billing</h5>
            </div>

            <div className="card mt-8 p-6">
                <div className="flex justify-between">
                    <div>
                        <div className="text-lg font-semibold ">Estimated costs for this billing period</div>
                        <div className="text-xs text-gray-600 font-light">
                            This is the current costs for your usage this billing period. A breakdown of your costs is
                            available below
                        </div>

                        <div className="text-lg font-bold mt-2">
                            {pendingAmountQuery.isLoading ? (
                                <Spinner size={32} />
                            ) : (
                                formatCurrency(pendingAmountQuery.data.pendingAmount)
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-6 p-6">
                <div className="text-lg font-semibold ">
                    Month-to-date Summary {moment(paymentData.startDate, 'YYYYMMDD').format('MMM D')} -{' '}
                    {moment(paymentData.endDate, 'YYYYMMDD').format('D,YYYY')}{' '}
                </div>
                <div className="text-xs text-gray-600 font-light">
                    These charges are factored into your account balance.
                </div>
                <div className=" my-5 h-[1px] border-t-2 border-gray-200" />

                <div className="">
                    <div>
                        <AnimatePresence initial={true}>
                            <table className="w-full ">
                                <thead>
                                    <tr className="">
                                        <th className="text-lg font-semibold !pl-0  ">
                                            <div
                                                className="flex items-center gap-2 cursor-pointer "
                                                onClick={() => setDropDownVisible((state) => !state)}
                                            >
                                                <motion.span
                                                    animate={{
                                                        rotate: dropDownVisible ? 90 : 0,
                                                    }}
                                                    transition={{
                                                        duration: 0.35,
                                                    }}
                                                >
                                                    <ChevronRightIcon
                                                        className="text-gray-600"
                                                        width={12}
                                                        height={12}
                                                    />
                                                </motion.span>
                                                Segments
                                            </div>
                                        </th>
                                        <th className="text-lg font-semibold">
                                            {/* {dropDownVisible && (
                                                <motion.div {...collapseAnimation}> Segments</motion.div>
                                            )} */}
                                        </th>
                                        <th className=" !text-right text-lg font-semibold !pr-0 ">
                                            {formatCurrency(paymentData.totalAmount)}
                                        </th>
                                    </tr>
                                </thead>
                                {dropDownVisible && (
                                    <motion.tbody {...collapseAnimation} className="overflow-hidden text-gray-500">
                                        {paymentData.bills.map((row, i) => (
                                            <tr key={i} className="text-sm font-medium   ">
                                                <td className="py-2">
                                                    <div className="pl-5">
                                                        {moment(row.createdAt, 'YYYYMMDD').format('DD MMM YYYY')}
                                                    </div>
                                                </td>
                                                <td className="py-2 text-center">
                                                    <div className="">{`${row.segments} segments`}</div>
                                                </td>
                                                <td className="py-2 text-right">
                                                    {formatCurrency(row.billingDetails.amount)}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="text-base font-normal mt-2">
                                            <td />
                                            <td className="text-lg font-semibold pt-2 text-center">
                                                <div className="text-lg font-semibold text-black">{`${totalSegments} segments`}</div>
                                            </td>
                                            <td className="text-right text-lg font-semibold border-t border-gray-200 pt-2">
                                                <div className="flex justify-between">
                                                    <div>Total</div>
                                                    <div className="text-lg font-semibold text-black">
                                                        {formatCurrency(paymentData.totalAmount)}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </motion.tbody>
                                )}
                            </table>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            <div className="card mt-6 p-6">
                <div className="text-lg font-semibold ">Billing Settings </div>

                <div className="text-base font-bold text-gray-600 mt-3">ADDRESS</div>

                <div className="text-xs text-gray-600 font-light ">
                    This address appears on your monthly invoice and should be the legal address of your home or
                    business
                </div>
                <div className="text-sm font-semibold mt-2">
                    {user.agency.address.line} <br />
                    {user.agency.address.city}, {user.agency.address.state} <br />
                    {user.agency.address.country} {user.agency.address.pincode}
                    <br />
                </div>
            </div>

            <div className="card mt-6 p-6">
                <div className="text-lg font-semibold ">Billing History</div>
                <div className="text-base font-semibold text-gray-600 mt-3">
                    <table className="w-full border-spacing-y-10">
                        <thead>
                            <tr className="">
                                <th className="text-base font-medium text-left ">DATE</th>
                                <th className="text-base font-medium text-left">DESCRIPTION</th>
                                <th className=" !text-right text-base font-medium !pr-0 ">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-hidden text-gray-500">
                            {invoiceHistory.isLoading ? (
                                <tr className="text-base font-normal mt-2">
                                    <td className="text-right text-lg font-semibold border-t border-gray-200 pt-2">
                                        <Spinner />
                                    </td>
                                </tr>
                            ) : (
                                invoiceHistory.data.map((row, i) => (
                                    <tr key={i} className="text-base font-medium  ">
                                        <td className="py-2 border-spacing-y-56">
                                            <div className="font-medium text-sm">
                                                {moment(row.createdAt, 'YYYYMMDD').format('MMM DD, YYYY')}
                                            </div>
                                        </td>
                                        <td className="py-2">
                                            <div className="font-medium text-sm">
                                                {row.category === 'PAYMENT' && row.remarks}
                                                {row.category === 'INVOICE' && (
                                                    <Link
                                                        to={`/my-bills/invoice/${row.createdAt}`}
                                                        className="text-blue-500"
                                                    >
                                                        Invoice for{' '}
                                                        {moment(row.createdAt, 'YYYYMMDD').format('MMM YYYY')}
                                                    </Link>
                                                )}
                                            </div>
                                        </td>
                                        <td className="py-2 text-right">
                                            <div className="font-medium text-sm">{formatCurrency(row.amount)}</div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default InternalBilling;
