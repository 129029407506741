import { FC, useEffect, useLayoutEffect } from 'react';
import useNavTab from 'app/hooks/use-nav-tab';
import { useSearchParams } from 'react-router-dom';
import AgencyBooking from './components/AgencyBooking/AgencyBooking';
import BookingDetailNav from './components/BookingDetailNav/BookingDetailNav';
import SubAgencyBooking from './components/SubAgencyBooking/SubAgencyBooking';
import AllBooking from './components/AllBooking/AllBooking';
import ManualBooking from './components/ManualBooking/ManualBooking';

interface BookingListProps {}

const BookingList: FC<BookingListProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useNavTab('1');
    const filterKeys = ['fromDate', 'toDate', 'query', 'page', 'bookingStatus'];

    useLayoutEffect(() => {
        let currentVersion = localStorage.getItem('current-version');
        const xAppVersion = localStorage.getItem('x-app-version');
        if (currentVersion === null || currentVersion === 'undefined' || +xAppVersion > +currentVersion) {
            localStorage.setItem('current-version', xAppVersion);
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        if (activeTab !== searchParams.get('tab')) {
            filterKeys.forEach((filterKey) => {
                searchParams.delete(filterKey);
            });
            searchParams.set('tab', activeTab.toString());
            setSearchParams(searchParams, {
                replace: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    return (
        <div>
            <h5 className="font-bold">Bookings</h5>

            <div className="card mt-8">
                <BookingDetailNav activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === '1' && <AllBooking />}
                {activeTab === '2' && <AgencyBooking />}
                {activeTab === '3' && <SubAgencyBooking />}
                {activeTab === '4' && <ManualBooking />}
            </div>
        </div>
    );
};

export default BookingList;
