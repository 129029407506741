import { useId, useState } from 'react';
import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { IManualBooking } from 'app/types/manual-booking';
import { IManualBookingForm } from '../types/IManualBooking';
import { createManualBooking } from 'app/api/services/booking.service';
import { useNavigate } from 'react-router-dom';

const FlightForm = () => {
    const [formValues, setFormValues] = useState<IManualBookingForm>();
    const formKey = useId();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    // console.log({ formKey });

    const manualBookingMutation = useMutation((payload: IManualBooking) => createManualBooking(payload));

    const handleManualBookingCreation = async (value: IManualBooking) => {
        try {
            await manualBookingMutation.mutateAsync(value);
            localStorage.removeItem(formKey);
            toast.success('Manual booking create Successfully');
            navigate('/bookings?tab=4&page=1');
        } catch (e: any) {
            // toast.error('Something went wrong');
            toast.error(e?.data?.message || 'Something went wrong');
            console.log(e);
        }
    };

    const handleNextClick = (values: Partial<IManualBookingForm>) => {
        setFormValues((prev: any) => ({ ...prev, ...values }));
        setStep((prev) => prev + 1);
    };

    const handlePrevClick = () => {
        setStep((prev) => prev - 1);
    };

    const handleFinalSubmission = (values: Partial<IManualBookingForm>) => {
        const finalValue: IManualBooking = {
            booking: {
                ...values.booking,
                bookingId: formValues.ticket.bookingId,
                pnr: formValues.ticket.pnr,
                subAgency: formValues.ticket.subAgency._id,
                ticketNumber: formValues.ticket.ticketNumber,
            },
            ticket: {
                baggage: formValues.ticket.baggage,
                segments: formValues.ticket.segments,
            },
        };

        // console.log({ finalValue });
        handleManualBookingCreation(finalValue);
    };

    return (
        <>
            <div className={step === 1 ? 'block' : 'hidden'}>
                {step === 1 && <Step1Form onSubmit={handleNextClick} formKey={formKey} />}
            </div>

            <div className={step === 2 ? 'block' : 'hidden'}>
                {step === 2 && (
                    <Step2Form
                        onBack={handlePrevClick}
                        onSubmit={handleFinalSubmission}
                        passengers={formValues.ticket.passengers}
                        isMutating={manualBookingMutation.isLoading}

                        // passengers={{ adult: 1, child: 1, infant: 0 }}
                    />
                )}
            </div>
        </>
    );
};

export default FlightForm;
