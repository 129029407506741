import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useState } from 'react';

interface TableSearchProps {
    placeholder: string;
    onSearch?: (q: string) => void;
    onSearchReset?: () => void;
    value?: string;
}

const TableSearch: FC<TableSearchProps> = (props) => {
    const { placeholder, onSearch, onSearchReset, value = '' } = props;

    const [localValue, setLocalValue] = useState('');

    useEffect(() => {
        setLocalValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeHandler = async (e: any) => {
        const val = e.target.value.trim();

        if (val === '') {
            onSearchReset?.();
        }

        onSearch?.(val);
    };

    const handleKeyUp = useCallback(debounce(changeHandler, 10), [changeHandler]);

    return (
        <input
            type="text"
            className="border border-gray-300 rounded-2xl text-sm px-4 py-2 outline-none w-96 max-w-full"
            placeholder={placeholder}
            onKeyUp={handleKeyUp}
            value={localValue}
            onChange={(event) => setLocalValue(event.target.value)}
        />
    );
};

export default TableSearch;
