import { FC } from 'react';
import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface AddPnrModalProps {
    show: boolean;
    onClose: () => void;
    booking: any;
}

const PassengerListSchema = Yup.array().of(
    Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        sector: Yup.string().trim().required('This field is required'),
        pnr: Yup.string().trim().required('This field is required'),
    })
);

const PassengerFormSchema = Yup.object().shape({
    adults: PassengerListSchema,
    children: PassengerListSchema,
    infants: PassengerListSchema,
});

const AddPnrModal: FC<AddPnrModalProps> = (props) => {
    const { booking, onClose } = props;
    const queryClient = useQueryClient();

    const addPnrMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${booking.id}/pnr`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: any) => {
        const modifiedValues = {
            adults: values.adults.map((adult: any) => {
                return [{ sector: adult.sector, number: adult.pnr }];
            }),
            children: values.children.map((adult: any) => {
                return [{ sector: adult.sector, number: adult.pnr }];
            }),
            infants: values.infants.map((adult: any) => {
                return [{ sector: adult.sector, number: adult.pnr }];
            }),
        };

        try {
            await addPnrMutation.mutateAsync(modifiedValues);
            toast.success('PNR added successfully.');

            queryClient.invalidateQueries({ queryKey: ['bookingDetail'] });

            onClose();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    const createInitialValuesList = (passengerList: any) => {
        return passengerList.map((row: any) => {
            return {
                name: `${row.title} ${row.firstName} ${row.lastName}`,
                sector: `${booking.departure.airport.code}-${booking.arrival.airport.code}`,
                pnr: '',
            };
        });
    };

    const initialValues = {
        adults: createInitialValuesList(booking.passengers.adults),
        children: createInitialValuesList(booking.passengers.children),
        infants: createInitialValuesList(booking.passengers.infants),
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10">
            <div className="font-bold">Add PNR</div>

            <div className="mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={PassengerFormSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, getFieldProps, errors }) => (
                        <Form>
                            <div>
                                {values.adults.map((row: any, index: number) => (
                                    <div key={index} className="grid grid-cols-4 gap-8 mb-3">
                                        <div className="col-span-1">
                                            <div className="font-medium">Adult {index + 1}</div>
                                        </div>
                                        <div className="col-span-1">
                                            <div className="font-medium">{row.name}</div>
                                        </div>
                                        <div className="col-span-2">
                                            <Field
                                                type="text"
                                                id={`adults[${index}].pnr`}
                                                name={`adults[${index}].pnr`}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={`adults[${index}].pnr`} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {values.children.map((row: any, index: number) => (
                                    <div key={index} className="grid grid-cols-4 gap-8 mb-3">
                                        <div className="col-span-1">
                                            <div className="font-medium">Child {index + 1}</div>
                                        </div>
                                        <div className="col-span-1">
                                            <div className="font-medium">{row.name}</div>
                                            {/* <div className="text-xs">Male, Adult</div> */}
                                        </div>
                                        <div className="col-span-2">
                                            {/* <label className="text-xs mb-2" htmlFor="name">
                                    PNR
                                </label> */}
                                            <Field
                                                type="text"
                                                id={`children[${index}].pnr`}
                                                name={`children[${index}].pnr`}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={`children[${index}].pnr`} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {values.infants.map((row: any, index: number) => (
                                    <div key={index} className="grid grid-cols-4 gap-8 mb-3">
                                        <div className="col-span-1">
                                            <div className="font-medium">Infant {index + 1}</div>
                                        </div>
                                        <div className="col-span-1">
                                            <div className="font-medium">{row.name}</div>
                                            <div className="text-xs">Male, Adult</div>
                                        </div>
                                        <div className="col-span-2">
                                            {/* <label className="text-xs mb-2" htmlFor="name">
                                    PNR
                                </label> */}
                                            <Field
                                                type="text"
                                                id={`infants[${index}].pnr`}
                                                name={`infants[${index}].pnr`}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={`infants[${index}].pnr`} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                isLoading={addPnrMutation.isLoading}
                            >
                                Add
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

export default AddPnrModal;
