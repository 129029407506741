import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { IOurPayment } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface InternalBillingProps {}
const InternalBilling: FC<InternalBillingProps> = (props) => {
    const { month } = useParams();
    const navigate = useNavigate();

    const ourPaymentQuery = useQuery<IOurPayment, any>(
        ['our-payments', 'list'],
        async () => {
            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/internal-billings?date=${month}`
            );

            return data;
        },
        {
            retry: 0,
        }
    );

    if (ourPaymentQuery.isLoading) return <PageLoader />;

    if (ourPaymentQuery.isError && ourPaymentQuery.error.status === 400) {
        navigate('/404');
    }

    const paymentData = ourPaymentQuery.data;

    const totalSegments = paymentData.bills.reduce((acc, bill) => {
        return acc + bill.segments;
    }, 0);

    return (
        <>
            <div>
                <div className="mb-5">
                    <BackLink />
                </div>
                <div className="flex justify-between items-center">
                    <h5 className="font-bold">Invoice</h5>
                </div>
                <div className="card mt-6 p-6">
                    <div className="text-lg font-semibold ">
                        Month-to-date Summary {moment(paymentData.startDate, 'YYYYMMDD').format('MMM D')} -{' '}
                        {moment(paymentData.endDate, 'YYYYMMDD').format('D,YYYY')}{' '}
                    </div>
                    <div className="text-xs text-gray-600 font-light">
                        These charges are factored into your account balance.
                    </div>
                    <div className=" my-5 h-[1px] border-t-2 border-gray-200" />
                    <div className="">
                        <table className="w-full">
                            <thead>
                                <tr className="">
                                    <th className="text-lg font-semibold !pl-0 ">
                                        <div className="flex items-center gap-2 cursor-pointer ">Segments</div>
                                    </th>
                                    <th className="text-lg font-semibold" />
                                    <th className=" !text-right text-lg font-semibold !pr-0 ">
                                        {formatCurrency(paymentData.totalAmount)}
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="overflow-hidden text-gray-500">
                                {paymentData.bills.map((row, i) => (
                                    <tr key={i} className="text-base font-medium py-2 ">
                                        <td className="py-2">
                                            <div className="">
                                                {moment(row.createdAt, 'YYYYMMDD').format('DD MMM YYYY')}
                                            </div>
                                        </td>
                                        <td className="py-2 text-center">
                                            <div className="">{`${row.segments} segments`}</div>
                                        </td>
                                        <td className="py-2 text-right">{formatCurrency(row.billingDetails.amount)}</td>
                                    </tr>
                                ))}
                                <tr className="text-base font-normal mt-2">
                                    <td />
                                    <td className="text-lg font-semibold pt-2 text-center">
                                        <div className="text-lg font-semibold ">{`${totalSegments} segments`}</div>
                                    </td>
                                    <td className="text-right text-lg font-semibold border-t border-gray-200 pt-2">
                                        <div className="flex justify-between text-lg font-semibold">
                                            <div>Total</div>
                                            <div>{formatCurrency(paymentData.totalAmount)}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InternalBilling;
