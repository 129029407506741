import { FC } from 'react';

interface FileUploadProps {
    id?: string;
    placeholder?: string;
    accept?: string;
    onChange?: (files: any[]) => void;
}

const FileUpload: FC<FileUploadProps> = (props) => {
    const { id, placeholder, accept, onChange } = props;

    const handleChange = (files: any[]) => {
        onChange?.(files);
    };

    return (
        <label
            htmlFor={id}
            className="flex flex-col justify-center items-center w-full relative h-48 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100"
        >
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
                <svg
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mb-3 w-10 h-10 text-gray-400"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6h.1a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                </svg>
                <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500">{placeholder}</p>
            </div>
            <input
                id={id}
                type="file"
                accept={accept}
                className="absolute top-0 left-0 w-full h-full opacity-0"
                onChange={(e: any) => handleChange(e.target.files)}
                onDrop={(e: any) => handleChange(e.dataTransfer.files)}
                onDragOver={(e: any) => e.preventDefault()}
            />
        </label>
    );
};

FileUpload.defaultProps = {
    id: 'file',
    placeholder: 'SVG, PNG or JPG (Images upto 1MB)',
};

export default FileUpload;
