import { produce } from 'immer';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { mapBooleanToFareSupplierStatus } from '../utils';
import { FareSupplierEnum, FareSupplierUrlEnum } from 'app/enums/fare-supplier.enum';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface IUseFareSupplierStatus {
    fareIdentifier: FareSupplierEnum;
}

const useFareSupplierStatus = <queryResp>(props: IUseFareSupplierStatus) => {
    const { fareIdentifier } = props;

    const queryClient = useQueryClient();

    const queryIdentifier = [fareIdentifier];

    const query = useQuery(
        queryIdentifier,
        async () => {
            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/${FareSupplierUrlEnum[fareIdentifier]}`
            );
            return data as queryResp;
        },
        {
            retry: 0,
        }
    );

    const fareSupplierStatusChangeMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/status`, payload);
        return data;
    });

    const handleToggleStatus = async (status: boolean) => {
        queryClient.setQueryData(queryIdentifier, (prevValues: any) => {
            return produce(prevValues, (draftState: any) => {
                draftState.status = mapBooleanToFareSupplierStatus(status);
            });
        });

        try {
            await fareSupplierStatusChangeMutation.mutateAsync({
                status: mapBooleanToFareSupplierStatus(status),
                fareSupplierIdentifier: fareIdentifier,
            });
        } catch (e: any) {
            toast.error(e.data.message);
        } finally {
            // neeeds to be checked what data we will recive
            queryClient.fetchQuery(queryIdentifier);
        }
    };

    return {
        data: query.data,
        isError: query.isError,
        isLoading: query.isLoading,
        queryIdentifier,
        handleToggleStatus,
    };
};

export default useFareSupplierStatus;
