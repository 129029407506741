import * as Yup from 'yup';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { UseMutationResult } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { getGatewayPaymentModeEnumLabel } from 'app/utils/payment-gateway.utils';
import { GatewayPaymentModeEnum, IGatewayCharges } from 'app/enums/gateway-payment-mode.enum';

const paymentGatewayChargesSchema = Yup.object().shape({
    CREDIT_CARD: Yup.object().shape({
        flat: Yup.number().required('This field is required'),
        percentage: Yup.number().required('This field is required'),
    }),
    DEBIT_CARD: Yup.object().shape({
        flat: Yup.number().required('This field is required'),
        percentage: Yup.number().required('This field is required'),
    }),
    NET_BANKING: Yup.object().shape({
        flat: Yup.number().required('This field is required'),
        percentage: Yup.number().required('This field is required'),
    }),
    UPI: Yup.object().shape({
        flat: Yup.number().required('This field is required'),
        percentage: Yup.number().required('This field is required'),
    }),
});

interface IPaymentGateWayChargesCard {
    pluginInfo: any;
    chargesUpdateMutation: UseMutationResult<any, unknown, { gatewayCharges: IGatewayCharges }, unknown>;
    gatewayLabel: string;
}

const PaymentGateWayChargesCard: FC<IPaymentGateWayChargesCard> = (props) => {
    const { pluginInfo, chargesUpdateMutation, gatewayLabel } = props;

    const paymentMethods = Object.values(GatewayPaymentModeEnum);

    const initialValues: IGatewayCharges = useMemo(() => {
        if (pluginInfo) {
            return pluginInfo.details.gatewayCharges;
        } else
            return {
                CREDIT_CARD: {
                    flat: 0,
                    percentage: 0,
                },
                DEBIT_CARD: {
                    flat: 0,
                    percentage: 0,
                },
                NET_BANKING: {
                    flat: 0,
                    percentage: 0,
                },
                UPI: {
                    flat: 0,
                    percentage: 0,
                },
            };
    }, [pluginInfo]);

    const handleSubmit = async (values: IGatewayCharges) => {
        try {
            await chargesUpdateMutation.mutateAsync({
                gatewayCharges: { ...values },
            });
            toast.success(`${gatewayLabel} gateway payment charges updated successfully`);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    return (
        <div className='card mt-8 p-6  '>
            <div className='font-semibold'>Charges</div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={paymentGatewayChargesSchema}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='grid grid-cols-12 gap-6 mt-8'>
                            {paymentMethods.map((paymentMethod) => (
                                <>
                                    <div className='col-span-3'>
                                        <label className='text-xs mb-2 ' htmlFor={`${paymentMethod}.flat`}>
                                            {getGatewayPaymentModeEnumLabel(paymentMethod)} Charge Flat
                                        </label>
                                        <div className='input-group'>
                                            <div className='input-group-prepend'>₹</div>
                                            <Field
                                                type='number'
                                                id={`${paymentMethod}.flat`}
                                                name={`${paymentMethod}.flat`}
                                                className='form-control pl-8'
                                            />
                                        </div>
                                        <div className='form-error'>
                                            <ErrorMessage name={`${paymentMethod}.flat`} />
                                        </div>
                                    </div>
                                    <div className='col-span-3'>
                                        <label className='text-xs mb-2 ' htmlFor={`${paymentMethod}.percentage`}>
                                            {getGatewayPaymentModeEnumLabel(paymentMethod)} Charge Percentage
                                        </label>
                                        <div className='input-group'>
                                            <div className='input-group-postpend'> % </div>
                                            <Field
                                                type='number'
                                                id={`${paymentMethod}.percentage`}
                                                name={`${paymentMethod}.percentage`}
                                                className='form-control '
                                            />
                                        </div>
                                        <div className='form-error'>
                                            <ErrorMessage name={`${paymentMethod}.percentage`} />
                                        </div>
                                    </div>
                                    <div className='col-span-6' />
                                </>
                            ))}
                        </div>

                        <LoaderButton
                            type='submit'
                            className='bg-primary text-white text-base font-semibold rounded-lg w-32 mt-8'
                            isLoading={chargesUpdateMutation.isLoading}
                        >
                            Save
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PaymentGateWayChargesCard;
