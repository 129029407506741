import moment from 'moment';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useDialog from 'app/hooks/use-dialog';
import Spinner from 'app/components/Spinner/Spinner';
import { DownloadIcon, MenuDotIcon } from 'app/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useFeatureRequest } from '../hooks/useFeatureRequest';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useFeatureRequestQuery } from '../hooks/useFeatureRequestQuery';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

const collapseAnimation = {
    initial: 'collapsed',
    animate: 'expanded',
    exit: 'collapsed',
    variants: {
        collapsed: {
            opacity: 0,
            height: 0,
            overflow: 'hidden',
            transition: {
                height: { duration: 0.5, ease: 'easeInOut' },
                opacity: { duration: 0.3, ease: 'easeInOut' }, // Slightly shorter duration for opacity to sync with height
            },
        },
        expanded: {
            opacity: 1,
            height: 'auto',
            overflow: 'hidden',
            transition: {
                height: { duration: 0.5, ease: 'easeInOut' },
                opacity: { duration: 0.3, ease: 'easeInOut' }, // Same duration and easing for smooth transition
            },
        },
    },
};

const FeatureRequestList = () => {
    const { deleteFeatureRequestMutation, removeFeatureRequestFromCache } = useFeatureRequest();
    const { getAllFeatureRequestQuery } = useFeatureRequestQuery();
    const { data, isLoading } = getAllFeatureRequestQuery;
    const navigate = useNavigate();

    const featureRequestDeleteDialog = useDialog<string>();
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);

    const handleRowClick = (index: number) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteFeatureRequestMutation.mutateAsync(id);
            removeFeatureRequestFromCache(id);
            featureRequestDeleteDialog.hide();
            toast.success('Feature Request removed successfully');
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    const navigateToView = (id: string) => {
        navigate(`/feature-request/${id}`);
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Feature Requests</h5>

                <Link
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                    to={'/feature-request/add'}
                >
                    Request New Feature
                </Link>
            </div>

            <div className="card mt-6">
                <>
                    <div className="overflow-auto pt-6">
                        <table className="card-table text-sm">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="th-1">Title</th>
                                    <th className="th-1">Created By</th>
                                    <th className="th-1">Attachments</th>
                                    <th className="th-1">Created At</th>
                                    <th className="th-1" />
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && data.length === 0 && (
                                    <tr>
                                        <td
                                            align="center"
                                            colSpan={10}
                                            className="py-12 font-medium text-base text-[#98A2B3]"
                                        >
                                            - No data available -
                                        </td>
                                    </tr>
                                )}

                                {!isLoading &&
                                    data.map((row, index) => (
                                        <>
                                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                                <td className="td-1 flex">
                                                    <div
                                                        className="font-semibold text-primary cursor-pointer"
                                                        onClick={() => navigateToView(row._id)}
                                                    >
                                                        {row.title}
                                                    </div>
                                                </td>
                                                <td className="td-1">
                                                    <div className="font-semibold">{row.createdBy.name}</div>
                                                </td>
                                                <td className="td-1">
                                                    <div className="font-semibold">{row.files.length}</div>
                                                </td>
                                                <td className="td-1">
                                                    <div className="font-semibold">
                                                        <p>
                                                            {moment(row.createdAt, 'YYYYMMDDHHmmss').format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </p>
                                                        <p>
                                                            {moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="td-1">
                                                    <Dropdown
                                                        target={
                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                            <a className="dropdown-action">
                                                                <MenuDotIcon />
                                                            </a>
                                                        }
                                                        menu={
                                                            <DropdownList>
                                                                <DropdownItem onClick={() => navigateToView(row._id)}>
                                                                    View
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        featureRequestDeleteDialog.show(row._id)
                                                                    }
                                                                >
                                                                    <span className="text-danger">Delete</span>
                                                                </DropdownItem>
                                                            </DropdownList>
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                            </tbody>
                        </table>
                        {isLoading && (
                            <div className="flex justify-center items-center min-h-[500px]">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </>
            </div>
            <DeleteModal
                onClose={featureRequestDeleteDialog.hide}
                message="Are you sure you want to delete"
                onConfirm={() => handleDelete(featureRequestDeleteDialog.data)}
                show={featureRequestDeleteDialog.isOpen}
                isLoading={deleteFeatureRequestMutation.isLoading}
            />
        </>
    );
};

export default FeatureRequestList;


