import React, { FC, useMemo } from 'react';
import styles from './Spinner.module.scss';

interface SpinnerProps {
    color?: string;
    size?: number;
}

const Spinner: FC<SpinnerProps> = (props) => {
    const { color, size } = props;

    const borderWidth = useMemo(() => {
        if (size < 36) return 3;
        else return 5;
    }, [size]);

    return (
        <div className={styles['lds-ring']} style={{ width: size, height: size }}>
            {Array(4)
                .fill(1)
                .map((_, i: number) => (
                    <div
                        key={i}
                        style={{
                            borderColor: `${color} transparent transparent transparent`,
                            borderWidth: borderWidth,
                        }}
                    ></div>
                ))}
        </div>
    );
};

Spinner.defaultProps = {
    color: 'rgb(var(--primary))',
    size: 56,
};

export default Spinner;
