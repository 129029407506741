import { useMutation, useQuery } from '@tanstack/react-query';
import { updateCommissionPlan } from 'app/api/services/fare-commission.service';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';

const FareSupplierTravelBoutiqueMarkup = () => {
    const travelBoutiqueCommissionDefaultQuery = useQuery<any>(
        [FareSupplierEnum.TRAVEL_BOUTIQUE, 'DEFAULT COMMISSION PLAN'],
        async () => {
            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/tbo/supplier-plan`
            );
            return data.commissionPlan;
        }
    );

    const updateCommissionPlanMutation = useMutation((payload: { id: string; values: any }) =>
        updateCommissionPlan(payload)
    );

    const handleSubmit = async (values: any) => {
        try {
            await updateCommissionPlanMutation.mutateAsync({
                id: travelBoutiqueCommissionDefaultQuery.data._id,
                values: {
                    commissionBreakdown: { netCommission: { ...values.commissionBreakdown } },
                    managementChargesPerPassenger: values.managementChargesPerPassenger,
                    taxRates: values.taxRates,
                },
            });
            toast.success('Commission Updated');
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    if (travelBoutiqueCommissionDefaultQuery.isLoading)
        return (
            <div className='card mt-8 px-6 py-5 flex justify-center items-center h-48'>
                <Spinner />
            </div>
        );

    if (travelBoutiqueCommissionDefaultQuery.isLoading)
        return (
            <div className='card mt-8 px-6 py-5 flex justify-center items-center h-48'>
                <div className='font-semibold text-lg'>Something is missing. Please contact dev team</div>
            </div>
        );

    const validationSchema = Yup.object({
        managementChargesPerPassenger: Yup.number()
            .min(0, 'The minimum value you can enter is 0.')
            .required('This field is required'),
        taxRates: Yup.object({
            gstRate: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
            commissionTdsRate: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
        commissionBreakdown: Yup.object({
            flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
            percentage: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
    });

    const initialValues = travelBoutiqueCommissionDefaultQuery.data
        ? {
              managementChargesPerPassenger: travelBoutiqueCommissionDefaultQuery.data.managementChargesPerPassenger,
              taxRates: {
                  gstRate: travelBoutiqueCommissionDefaultQuery.data.taxRates.gstRate,
                  commissionTdsRate: travelBoutiqueCommissionDefaultQuery.data.taxRates.commissionTdsRate,
              },
              commissionBreakdown: {
                  flat: travelBoutiqueCommissionDefaultQuery.data.commissionBreakdown.netCommission.flat,
                  percentage: travelBoutiqueCommissionDefaultQuery.data.commissionBreakdown.netCommission.percentage,
              },
          }
        : {
              managementChargesPerPassenger: 0,
              taxRates: {
                  gstRate: 0,
                  commissionTdsRate: 0,
              },
              commissionBreakdown: {
                  flat: 0,
                  percentage: 0,
              },
          };

    return (
        <div>
            <div className='card mt-8 px-6 py-5'>
                <div className='font-bold'>Default Plan</div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ errors }: FormikProps<typeof initialValues>) => (
                        <Form>
                            <div className='grid grid-cols-12 gap-6 mt-6'>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='managementChargesPerPassenger'>
                                        Management Charges Per Passenger
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-prepend'>₹</div>
                                        <Field
                                            type='number'
                                            id={`managementChargesPerPassenger`}
                                            name={`managementChargesPerPassenger`}
                                            className='form-control pl-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='managementChargesPerPassenger' />
                                    </div>
                                </div>
                                <div className='col-span-4' />
                                <div className='col-span-4'>
                                    <label className='text-xs mb-2' htmlFor='taxRates.gstRate'>
                                        GST Rate on Management Fee
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-postpend'>%</div>
                                        <Field
                                            type='number'
                                            id={`taxRates.gstRate`}
                                            name={`taxRates.gstRate`}
                                            className='form-control pr-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='taxRates.gstRate' />
                                    </div>
                                </div>
                                <div className='col-span-4'>
                                    <label className='text-xs mb-2' htmlFor='taxRates.commissionTdsRate'>
                                        Commission TDS Rate
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-postpend'>%</div>
                                        <Field
                                            type='number'
                                            id={`taxRates.commissionTdsRate`}
                                            name={`taxRates.commissionTdsRate`}
                                            className='form-control pr-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='taxRates.commissionTdsRate' />
                                    </div>
                                </div>
                                <div className='col-span-8'>
                                    <div className='grid grid-cols-5 gap-4'>
                                        <div className='mt-auto'>
                                            <span className='mb-2'>Net Commission</span>
                                            <div className='form-error' />
                                        </div>
                                        <div className='col-span-2'>
                                            <label className='text-xs' htmlFor=''>
                                                Flat
                                            </label>
                                            <div className='input-group'>
                                                <div className='input-group-prepend'>₹</div>
                                                <Field
                                                    type='number'
                                                    id={`commissionBreakdown.flat`}
                                                    name={`commissionBreakdown.flat`}
                                                    className='form-control pl-8'
                                                />
                                            </div>
                                            <div className='form-error'>
                                                <ErrorMessage name={`commissionBreakdown.flat`} />
                                            </div>
                                        </div>
                                        <div className='col-span-2'>
                                            <label className='text-xs' htmlFor=''>
                                                Percentage
                                            </label>
                                            <div className='input-group'>
                                                <div className='input-group-postpend'>%</div>
                                                <Field
                                                    type='number'
                                                    id={`commissionBreakdown.percentage`}
                                                    name={`commissionBreakdown.percentage`}
                                                    className='form-control'
                                                />
                                            </div>
                                            <div className='form-error'>
                                                <ErrorMessage name={`commissionBreakdown.percentage`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-12'>
                                <LoaderButton
                                    type='submit'
                                    className='bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4'
                                    isLoading={updateCommissionPlanMutation.isLoading}
                                >
                                    Update
                                </LoaderButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default FareSupplierTravelBoutiqueMarkup;
