import React, { FC } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';
import { getInitials } from 'app/utils/common-utils';
import { useNavigate } from 'react-router-dom';

interface TopSubAgencyListProps {
    subAgencies: any[];
}

const TopSubAgencyList: FC<TopSubAgencyListProps> = (props) => {
    const navigate = useNavigate();
    const { subAgencies } = props;

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm ">
                <tbody>
                    {subAgencies.map((row, i) => (
                        <tr
                            key={i}
                            className="hover:bg-slate-100 cursor-pointer"
                            onClick={() => navigate(`/sub-agencies/${row._id}/detials?tab=1`)}
                        >
                            <td className="px-3 py-4 align-top">
                                {/* <Link to={`/sub-agencies/${row._id}/detials?tab=1`} className="flex justify-between"> */}
                                <div className="flex items-center gap-3">
                                    <div className="w-11 h-11 bg-primary/10 rounded-full flex items-center justify-center">
                                        <div className="text-primary font-semibold">{getInitials(row.name)}</div>
                                    </div>

                                    <div>
                                        <div className="font-semibold">{row.name}</div>
                                        <p className="mt-1">{row.email}</p>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </td>
                            <td className="px-3 py-4 align-top">
                                <div className="text-right">{formatCurrency(row.amount)}</div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TopSubAgencyList;
