/* eslint-disable jsx-a11y/anchor-is-valid */
import countryList from 'country-list';
import React, { FC, useCallback, useMemo } from 'react';
import { createPassengerTemplate } from './manualFlight.utils';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { PASSENGER_TITLES } from 'app/shared/constants/passenger-titles';
import { IManualBookingDetailForm, IManualBookingForm } from '../types/IManualBooking';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';

import * as Yup from 'yup';
import { ISubAgency } from 'app/types';
import moment from 'moment';
import { IPassengerWithTicketNumber, ISegment } from 'app/types/manual-booking';
import { BackIcon } from 'app/icons';

const createAgeLimit = (age: number, tripDate: string) => {
    return moment(tripDate, 'YYYYMMDD').subtract(age, 'years');
};

interface IStep2Form {
    subAgency: ISubAgency;
    onSubmit: (values: Partial<IManualBookingForm>) => void;
    onBack: () => void;
    passengers: { adult: number; infant: number; child: number };
    isMutating: boolean;
    tripDate: string; // YYYYMMDD
    isInternational: boolean;
    segments: Array<ISegment>;
}

const Step2Form: FC<IStep2Form> = (props) => {
    const { onBack, onSubmit, passengers, isMutating, subAgency, tripDate, segments, isInternational } = props;

    const PassportSchema = Yup.object().when('isInternational', {
        is: true,
        then: Yup.object({
            nationality: Yup.string().trim().required('This field is required'),
            number: Yup.string().trim().required('This field is required'),
            issueDate: Yup.string()
                .trim()
                .required('This field is required')
                .test('is-passport-issue-date-in-future', 'Enter valid Passport issue date.', (value) => {
                    const issueDate = moment(value, 'YYYYMMDD');
                    return issueDate <= moment();
                }),
            expiry: Yup.string()
                .trim()
                .required('This field is required')
                .test('is-passport-expired', 'Expired Passport.', (value) => {
                    const exp = moment(value, 'YYYYMMDD');
                    return exp > moment().subtract(1, 'day');
                })
                .test(
                    'is-passport-expiring-within-6-months',
                    'Passport should not expire within 6 months from travel date.',
                    (value) => {
                        const trip = moment(tripDate, 'YYYYMMDD');
                        const exp = moment(value, 'YYYYMMDD');
                        return trip.add(6, 'months') < exp;
                    }
                ),
        }),
    });

    // Infant: 0-2 years, Child: 2-12 years, Adult: 12+ years
    const passengerSchema = (passengerType: PassengerTypeEnum, tripDate: string) => {
        const getValidationMessage = (passengerType: PassengerTypeEnum): string => {
            if (passengerType === PassengerTypeEnum.ADULT) return 'Adult should be at least 12';
            if (passengerType === PassengerTypeEnum.CHILD) return 'Child must be between 2 - 12 years';
            if (passengerType === PassengerTypeEnum.INFANT) return 'Infant must be between 0 - 2 years';
        };

        return Yup.object().shape({
            title: Yup.string().required('Title is required'),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            ticketNumbers: Yup.array().of(
                Yup.object().shape({
                    number: Yup.string().trim(),
                })
            ),
            dob: Yup.date()
                .required('Date of birth is required')
                .nullable()
                .test('dob-range', getValidationMessage(passengerType), function (dob) {
                    if (!dob) return true; // Skip if no date is selected
                    if (passengerType === 'INFANT') {
                        const minDob = createAgeLimit(2, tripDate);
                        const maxDob = createAgeLimit(0, tripDate);
                        return moment(dob).isBetween(minDob, maxDob, 'days', '[]');
                    } else if (passengerType === 'CHILD') {
                        const minDob = createAgeLimit(12, tripDate);
                        const maxDob = createAgeLimit(2, tripDate);
                        return moment(dob).isBetween(minDob, maxDob, 'days', '[]');
                    } else if (passengerType === 'ADULT') {
                        const minDob = createAgeLimit(120, tripDate); // assuming 120 as the max valid age for an adult
                        const maxDob = createAgeLimit(12, tripDate);
                        return moment(dob).isBetween(minDob, maxDob, 'days', '[]');
                    }

                    return true;
                }),
            passport: PassportSchema,
        });
    };

    const validationSchema = Yup.object().shape({
        //  // adults: Yup.array().of(passengerSchema).min(1, 'At least one adult is required'),
        // Adults validation (Array of adults)
        adults: Yup.array()
            .of(passengerSchema(PassengerTypeEnum.ADULT, tripDate))
            .min(1, 'At least one adult is required'),

        // // children: Yup.array().of(passengerSchema).min(0, 'Invalid number of children'),
        // Children validation (Array of children)
        children: Yup.array()
            .of(passengerSchema(PassengerTypeEnum.CHILD, tripDate))
            .min(0, 'Invalid number of children'),

        // // infants: Yup.array().of(passengerSchema).min(0, 'Invalid number of infants'),
        // Infants validation (Array of infants)
        infants: Yup.array()
            .of(passengerSchema(PassengerTypeEnum.INFANT, tripDate))
            .min(0, 'Invalid number of infants'),

        // Contact Details validation
        contactDetails: Yup.object().shape({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            mobileNumber: Yup.string()
                .matches(/^[0-9]+$/, 'Mobile number must be numeric')
                .required('Mobile number is required'),
        }),

        // Price Details validation
        priceDetails: Yup.object().shape({
            managementFeesPerSeat: Yup.number()
                .required('Management fee per seat is required')
                .typeError('Must be a valid number')
                .min(0, 'Management fee must be a positive number'),

            // Adult pricing
            adult: Yup.object().shape({
                base: Yup.number()
                    .required('Base fare for adults is required')
                    .typeError('Must be a valid number')
                    .min(0, 'Base fare must be a positive number'),
                taxAndFees: Yup.number()
                    .required('Tax and fees for adults is required')
                    .typeError('Must be a valid number')
                    .min(0, 'Tax and fees must be a positive number'),
            }),

            // context: { childCount: passengers.child, infantCount: passengers.infant },

            // Child pricing (conditionally required)
            child: Yup.object()
                .nullable()
                .when({
                    is: () => {
                        return passengers.child > 0;
                    },
                    then: Yup.object().shape({
                        base: Yup.number()
                            .required('Base fare for children is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Base fare must be a positive number'),
                        taxAndFees: Yup.number()
                            .required('Tax and fees for children is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Base fare must be a positive number'),
                    }),
                }),

            // Infant pricing (conditionally required)
            infant: Yup.object()
                .nullable()
                .when({
                    is: () => {
                        return passengers.infant > 0;
                    },
                    then: Yup.object().shape({
                        base: Yup.number()
                            .required('Base fare for infants is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Base fare must be a positive number'),
                        taxAndFees: Yup.number()
                            .required('Tax and fees for infants is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Tax and fees must be a positive number'),
                    }),
                }),
        }),
    });

    const initialValues = useCallback((): IManualBookingDetailForm => {
        const createArrayOfPassengers = (
            count: number,
            passengerType: PassengerTypeEnum
        ): Array<IPassengerWithTicketNumber> => {
            const passengerList: Array<IPassengerWithTicketNumber> = [];
            for (let i = 0; i < count; i++) {
                passengerList.push(createPassengerTemplate(passengerType, segments, isInternational));
            }
            return passengerList;
        };

        return {
            adults: createArrayOfPassengers(passengers.adult, PassengerTypeEnum.ADULT),
            children: createArrayOfPassengers(passengers.child, PassengerTypeEnum.CHILD),
            infants: createArrayOfPassengers(passengers.infant, PassengerTypeEnum.INFANT),
            isInternational,
            priceDetails: {
                adult: {
                    base: 0,
                    taxAndFees: 0,
                },
                child:
                    passengers.child === 0
                        ? null
                        : {
                              base: 0,
                              taxAndFees: 0,
                          },
                infant:
                    passengers.infant === 0
                        ? null
                        : {
                              base: 0,
                              taxAndFees: 0,
                          },
                managementFeesPerSeat: 0,
            },

            contactDetails: {
                email: subAgency.email,
                mobileNumber: subAgency.phoneNumber,
            },
        };
    }, [passengers.adult, passengers.child, passengers.infant, subAgency.email, subAgency.phoneNumber]);

    const handleSubmit = async (
        values: IManualBookingDetailForm,
        formikHelpers: FormikHelpers<IManualBookingDetailForm>
    ) => {
        onSubmit({ booking: values });
    };

    return (
        <>
            <LoaderButton
                type="button"
                onClick={onBack}
                className="flex gap-4 pl-0"
                // className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12  disabled:cursor-not-allowed"
                disabled={isMutating}
            >
                <BackIcon size={5} /> <span className="text-sm font-semibold">Back</span>
            </LoaderButton>

            <Formik
                initialValues={initialValues()}
                enableReinitialize={false}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, errors }: FormikProps<IManualBookingDetailForm>) => (
                    <Form>
                        <div className="pb-4">
                            <FieldArray name="adults">
                                {({ push, remove }) => (
                                    <>
                                        {values.adults.map((passenger, passengerIndex) => (
                                            <div className="mt-6" key={`adult-${passengerIndex}`}>
                                                <PassengerDetailsForm
                                                    passengerIndex={passengerIndex}
                                                    passengerType={PassengerTypeEnum.ADULT}
                                                    key={`adults-${passengerIndex}`}
                                                    segments={segments}
                                                    isInternational={isInternational}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </FieldArray>
                            <FieldArray name="children">
                                {({ push, remove }) => (
                                    <>
                                        {values.children.map((passenger, passengerIndex) => (
                                            <div className="mt-6" key={`child-${passengerIndex}`}>
                                                {' '}
                                                <PassengerDetailsForm
                                                    passengerIndex={passengerIndex}
                                                    passengerType={PassengerTypeEnum.CHILD}
                                                    key={`children-${passengerIndex}`}
                                                    segments={segments}
                                                    isInternational={isInternational}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </FieldArray>
                            <FieldArray name="infants">
                                {({ push, remove }) => (
                                    <>
                                        {values.infants.map((passenger, passengerIndex) => (
                                            <div className="mt-6" key={`infant-${passengerIndex}`}>
                                                {' '}
                                                <PassengerDetailsForm
                                                    passengerIndex={passengerIndex}
                                                    passengerType={PassengerTypeEnum.INFANT}
                                                    key={`infants-${passengerIndex}`}
                                                    segments={segments}
                                                    isInternational={isInternational}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </FieldArray>

                            {/* contact details */}
                            <div className="mt-12">
                                <div className="font-semibold col-span-full">Contact Details</div>
                                <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={`contactDetails.mobileNumber`}>
                                            MOBILE NUMBER
                                        </label>
                                        <Field
                                            type="text"
                                            id={`contactDetails.mobileNumber`}
                                            name={`contactDetails.mobileNumber`}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={`contactDetails.mobileNumber`} />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={`contactDetails.email`}>
                                            E MAIL
                                        </label>
                                        <Field
                                            type="text"
                                            id={`contactDetails.email`}
                                            name={`contactDetails.email`}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={`contactDetails.email`} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Price Details */}
                            <div className="mt-12">
                                <div className="font-semibold col-span-full">Pricing Details</div>

                                <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={`priceDetails.adult.base`}>
                                            ADULT BASE FARE
                                        </label>
                                        <Field
                                            type="text"
                                            id={`priceDetails.adult.base`}
                                            name={`priceDetails.adult.base`}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={`priceDetails.adult.base`} />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={'priceDetails.adult.taxAndFees'}>
                                            ADULT TAX & FEE
                                        </label>
                                        <Field
                                            type="text"
                                            id={'priceDetails.adult.taxAndFees'}
                                            name={'priceDetails.adult.taxAndFees'}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={'priceDetails.adult.taxAndFees'} />
                                        </div>
                                    </div>
                                </div>

                                {values.children.length > 0 && (
                                    <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                        <div className="sm:col-span-6">
                                            <label className="text-xs mb-2" htmlFor={`priceDetails.child.base`}>
                                                CHILD BASE FARE
                                            </label>
                                            <Field
                                                type="text"
                                                id={`priceDetails.child.base`}
                                                name={`priceDetails.child.base`}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={`priceDetails.child.base`} />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <label className="text-xs mb-2" htmlFor={'priceDetails.child.taxAndFees'}>
                                                CHILD TAX & FEE
                                            </label>
                                            <Field
                                                type="text"
                                                id={'priceDetails.child.taxAndFees'}
                                                name={'priceDetails.child.taxAndFees'}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={'priceDetails.child.taxAndFees'} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {values.infants.length > 0 && (
                                    <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                        <div className="sm:col-span-6">
                                            <label className="text-xs mb-2" htmlFor={`priceDetails.infant.base`}>
                                                INFANT BASE FARE
                                            </label>
                                            <Field
                                                type="text"
                                                id={`priceDetails.infant.base`}
                                                name={`priceDetails.infant.base`}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={`priceDetails.infant.base`} />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <label className="text-xs mb-2" htmlFor={'priceDetails.infant.taxAndFees'}>
                                                INFANT TAX & FEE
                                            </label>
                                            <Field
                                                type="text"
                                                id={'priceDetails.infant.taxAndFees'}
                                                name={'priceDetails.infant.taxAndFees'}
                                                className="form-control"
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name={'priceDetails.infant.taxAndFees'} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={`priceDetails.managementFeesPerSeat`}>
                                            MANAGEMENT FEE PER SEAT
                                        </label>
                                        <Field
                                            type="text"
                                            id={`priceDetails.managementFeesPerSeat`}
                                            name={`priceDetails.managementFeesPerSeat`}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={`priceDetails.managementFeesPerSeat`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-between ">
                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12 "
                                isLoading={isMutating}
                            >
                                Save
                            </LoaderButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Step2Form;

interface IPassengerDetailsForm {
    passengerIndex: number;
    passengerType: PassengerTypeEnum;
    segments: Array<ISegment>;
    isInternational: boolean;
}

const PassengerDetailsForm: FC<IPassengerDetailsForm> = (props) => {
    const { passengerIndex, passengerType, segments, isInternational } = props;
    const nations = useMemo(() => countryList.getNameList(), []);

    const paxType = useMemo(() => {
        if (passengerType === PassengerTypeEnum.ADULT) return 'adults';
        if (passengerType === PassengerTypeEnum.CHILD) return 'children';
        if (passengerType === PassengerTypeEnum.INFANT) return 'infants';
    }, [passengerType]);

    const paxLabel = useMemo(() => {
        if (passengerType === PassengerTypeEnum.ADULT) return 'Adult';
        if (passengerType === PassengerTypeEnum.CHILD) return 'Child';
        if (passengerType === PassengerTypeEnum.INFANT) return 'Infant';
    }, [passengerType]);

    return (
        <div>
            <div className="col-span-12 mt-6 text-base leading-5 font-bold">{`${paxLabel} ${passengerIndex + 1}`}</div>
            <div className="mt-2  grid grid-cols-1 gap-2 sm:grid-cols-12 sm:gap-4">
                <div className="sm:col-span-2">
                    <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].title`}>
                        TITLE
                    </label>
                    <Field
                        as="select"
                        name={`${paxType}[${passengerIndex}].title`}
                        type="text"
                        id={`${paxType}[${passengerIndex}].title`}
                        className="form-control"
                    >
                        {PASSENGER_TITLES[paxType].map((title) => (
                            <option key={title} value={title}>
                                {title}
                            </option>
                        ))}
                    </Field>
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].title`} />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].firstName`}>
                        FIRST NAME
                    </label>
                    <Field
                        type="text"
                        id={`${paxType}[${passengerIndex}].firstName`}
                        name={`${paxType}[${passengerIndex}].firstName`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].firstName`} />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].lastName`}>
                        LAST NAME
                    </label>
                    <Field
                        type="text"
                        id={`${paxType}[${passengerIndex}].lastName`}
                        name={`${paxType}[${passengerIndex}].lastName`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].lastName`} />
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <label className="text-xs mb-2  " htmlFor={`${paxType}[${passengerIndex}].dob`}>
                        D.O.B
                    </label>
                    <Field
                        type="date"
                        id={`${paxType}[${passengerIndex}].dob`}
                        name={`${paxType}[${passengerIndex}].dob`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].dob`} />
                    </div>
                </div>

                {/* /////////////////////////////////////////////// */}

                {isInternational && (
                    <>
                        {/* <div className='mt-4 text-xs'>Passport Details</div> */}
                        {/* nationality */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`${paxType}[${passengerIndex}].passport.nationality`}
                                className="text-xs text-slate-500"
                            >
                                Nationality
                            </label>

                            <Field
                                as="select"
                                name={`${paxType}[${passengerIndex}].passport.nationality`}
                                placeholder="Mr"
                                className="form-control appearance-none"
                            >
                                {Object.keys(nations).map((val) => (
                                    <option key={val} value={nations[val]}>
                                        {val.charAt(0).toUpperCase() + val.slice(1)}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name={`${paxType}[${passengerIndex}].passport.nationality`} />
                            </div>
                        </div>
                        {/* number */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`${paxType}[${passengerIndex}].passport.number`}
                                className="text-xs text-slate-500"
                            >
                                Passport Number
                            </label>
                            <Field
                                type="text"
                                name={`${paxType}[${passengerIndex}].passport.number`}
                                placeholder="Passport Number"
                                className="form-control"
                            />
                            <div className="form-error">
                                <ErrorMessage name={`${paxType}[${passengerIndex}].passport.number`} />
                            </div>
                        </div>
                        {/* issueDate */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`${paxType}[${passengerIndex}].passport.issueDate`}
                                className="text-xs text-slate-500"
                            >
                                Passport Issue Date
                            </label>

                            <Field
                                type="date"
                                id={`${paxType}[${passengerIndex}].passport.issueDate`}
                                name={`${paxType}[${passengerIndex}].passport.issueDate`}
                                className="form-control"
                            />

                            <div className="form-error">
                                <ErrorMessage name={`${paxType}[${passengerIndex}].passport.issueDate`} />
                            </div>
                        </div>
                        {/* expiryData */}
                        <div className=" sm:col-span-3">
                            <label
                                htmlFor={`${paxType}[${passengerIndex}].passport.expiry`}
                                className="text-xs text-slate-500"
                            >
                                Passport Expiry Date
                            </label>
                            <Field
                                type="date"
                                id={`${paxType}[${passengerIndex}].passport.expiry`}
                                name={`${paxType}[${passengerIndex}].passport.expiry`}
                                className="form-control"
                            />

                            <div className="form-error">
                                <ErrorMessage name={`${paxType}[${passengerIndex}].passport.expiry`} />
                            </div>
                        </div>
                    </>
                )}

                {/* /////////////////////////////////////////////// */}

                <div className="sm:col-span-6">
                    {/* <label className="text-xs mb-2 " htmlFor={`ticketNumber`}>
                        TICKET NUMBER
                    </label> */}
                    <FieldArray name={`${paxType}[${passengerIndex}].ticketNumbers`}>
                        {({ push, remove }) => (
                            <>
                                <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].firstName`}>
                                    TICKET NUMBERS
                                </label>
                                <div className="flex flex-col gap-3">
                                    {segments.map((segment, segmentIndex) => (
                                        <div key={segmentIndex}>
                                            {/* <div className="input-group group">
                                                <Field
                                                    type="text"
                                                    name={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                    id={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                    className="form-control pl-32"
                                                />
                                                <div className="absolute top-0 left-0 w-28 h-full  border-gray-200">
                                                    <Field
                                                        type="text"
                                                        name={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].sector`}
                                                        id={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].sector`}
                                                        className="form-control rounded-r-none bg-primary/70 text-white px-5 "
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="input-group group relative">
                                                <Field
                                                    type="text"
                                                    name={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                    id={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                    className="form-control pl-32 focus:outline-none" // Ensure this has no outline so that you see the border effect
                                                />
                                                <div className="absolute top-0 left-0 w-28 h-full">
                                                    <Field
                                                        type="text"
                                                        name={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].sector`}
                                                        id={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].sector`}
                                                        className="form-control rounded-r-none bg-primary/70 text-white px-5 border-gray-200 group-focus-within:border-[#219653]"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-error ">
                                                <ErrorMessage
                                                    name={`${paxType}[${passengerIndex}].ticketNumbers[${segmentIndex}].number`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
            </div>
        </div>
    );
};
