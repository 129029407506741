import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import BackLink from 'app/components/BackLink/BackLink';
import { ICommissionPlan } from 'app/enums/commission-plan';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { getCommissionPlanById, updateCommissionPlan } from 'app/api/services/fare-commission.service';
import useCommissionPlanLayoutContext from '../../hooks/use-commission-plan-layout-context';

const planEditSchema = Yup.object({
    name: Yup.string().trim().required('This field is required'),
    commissionBreakdown: Yup.object({
        netCommission: Yup.object({
            flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
            percentage: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
    }),
    managementChargesPerPassenger: Yup.number().min(0).required('This field is required'),
    zeroCommissionConditionalManagementChargesPerPassenger: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.'),
});

export const CommissionPlanEdit = () => {
    const { id } = useParams();
    const { commissionPlanEnabledFareSupplier } = useCommissionPlanLayoutContext();

    const { isLoading, data } = useQuery(['COMMISSiON_PLAN', id], () => getCommissionPlanById(id));
    const updateCommissionPlanMutation = useMutation((payload: { id: string; values: any }) =>
        updateCommissionPlan(payload)
    );

    if (isLoading) return <PageLoader />;

    const initialValuesOrg: ICommissionPlan = {
        name: data.name,
        commissionPlanType: data.commissionPlanType,
        commissionFareSupplier: data.commissionFareSupplier,
        commissionBreakdown: data.commissionBreakdown,
        managementChargesPerPassenger: data.managementChargesPerPassenger,
        zeroCommissionConditionalManagementChargesPerPassenger:
            data.zeroCommissionConditionalManagementChargesPerPassenger,
    };

    const handleSubmit = async (values: ICommissionPlan) => {
        try {
            await updateCommissionPlanMutation.mutateAsync({
                id,
                values: {
                    commissionBreakdown: values.commissionBreakdown,
                    managementChargesPerPassenger: values.managementChargesPerPassenger,
                    zeroCommissionConditionalManagementChargesPerPassenger:
                        values.zeroCommissionConditionalManagementChargesPerPassenger,
                },
            });
            toast.success('Commission plan saved');
        } catch (e) {
            toast.error('Something went wrong');
        }
        console.log({ values });
    };

    return (
        <div>
            <div className="mb-5">
                <BackLink />
            </div>
            <h4 className="font-bold">Edit Commission Plan</h4>
            <div className="card mt-8 px-6 py-5">
                <Formik initialValues={initialValuesOrg} onSubmit={handleSubmit} validationSchema={planEditSchema}>
                    {({ isSubmitting }: FormikProps<ICommissionPlan>) => (
                        <Form>
                            <div className="grid grid-cols-12 gap-6 mt-6">
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="name">
                                        Plan Name
                                    </label>
                                    <Field type="text" id="name" name="name" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="name" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="commissionFareSupplier">
                                        Fare Supplier
                                    </label>
                                    <Field
                                        as="select"
                                        name="commissionFareSupplier"
                                        type="text"
                                        id="commissionFareSupplier"
                                        className="form-control"
                                        disabled
                                    >
                                        {commissionPlanEnabledFareSupplier.map((comFareSup) => (
                                            <option key={comFareSup} value={comFareSup}>
                                                {createReadableFareSupplierLabel(comFareSup)}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className="form-error">
                                        <ErrorMessage name="commissionFareSupplier" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="managementChargesPerPassenger">
                                        Management Charges (Per Seat)
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`managementChargesPerPassenger`}
                                            name={`managementChargesPerPassenger`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="managementChargesPerPassenger" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label
                                        className="text-xs mb-2"
                                        htmlFor="zeroCommissionConditionalManagementChargesPerPassenger"
                                    >
                                        Management Charges (Per Seat) When Net Commission Is Zero
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="number"
                                            id={`zeroCommissionConditionalManagementChargesPerPassenger`}
                                            name={`zeroCommissionConditionalManagementChargesPerPassenger`}
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="zeroCommissionConditionalManagementChargesPerPassenger" />
                                    </div>
                                </div>
                                <div className="col-span-4" />

                                <div className="col-span-8">
                                    <div className="grid grid-cols-5 gap-4">
                                        <div className="mt-auto">
                                            <div className="mb-5 text-base font-medium">Commission</div>
                                            <div className="form-error" />
                                        </div>
                                        <div className="col-span-2">
                                            <label className="text-xs" htmlFor="">
                                                Flat (Per Seat)
                                            </label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">₹</div>
                                                <Field
                                                    type="number"
                                                    id={`commissionBreakdown.netCommission.flat`}
                                                    name={`commissionBreakdown.netCommission.flat`}
                                                    className="form-control pl-8"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name={`commissionBreakdown.netCommission.flat`} />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <label className="text-xs" htmlFor="">
                                                Share Net Commission With Sub Agent (%)
                                            </label>
                                            <div className="input-group">
                                                <div className="input-group-postpend">%</div>
                                                <Field
                                                    type="number"
                                                    id={`commissionBreakdown.netCommission.percentage`}
                                                    name={`commissionBreakdown.netCommission.percentage`}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-error">
                                                <ErrorMessage name={`commissionBreakdown.netCommission.percentage`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12">
                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                                    isLoading={updateCommissionPlanMutation.isLoading}
                                >
                                    Submit
                                </LoaderButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CommissionPlanEdit;


