import BackLink from 'app/components/BackLink/BackLink';
import { FC } from 'react';
import BankTransactionForm from './components/BankTransactionForm/BankTransactionForm';

interface BankTransactionAddProps {}

const BankTransactionAdd: FC<BankTransactionAddProps> = (props) => {
    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Add Bank Transaction</div>

                <BankTransactionForm />
            </div>
        </>
    );
};

export default BankTransactionAdd;
