import { FC } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FileUpload from 'app/components/FileUpload/FileUpload';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const FormSchema = Yup.object().shape({
    file: Yup.mixed().required('This field is required'),
    type: Yup.string().trim().required('This field is required'),
});

const initialValues: any = {
    file: null,
    type: '',
};

interface DocumentUploadModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit: (val: any) => void;
}

const documentTypes = [
    { name: 'personalKyc', label: 'Personal KYC' },
    { name: 'agencyRegistration', label: 'Agency Registration' },
    { name: 'panCard', label: 'PAN Card' },
    { name: 'gstRegistration', label: 'GST Registration' },
];

const DocumentUploadModal: FC<DocumentUploadModalProps> = (props) => {
    const { onClose, show, onSubmit } = props;

    const handleSubmit = async (values: any) => {
        try {
            const formData = new FormData();

            formData.append('name', values.type);
            formData.append('file', values.file);

            onSubmit(formData);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Modal className="px-10 py-10 w-[680px] max-w-full " show={show} onClose={onClose}>
            <div className="font-bold">Add Documents</div>

            <div className="mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="mt-6">
                                <div>
                                    <label className="text-xs mb-2" htmlFor="file">
                                        DOCUMENT
                                    </label>

                                    <FileUpload
                                        id="file"
                                        accept="*"
                                        onChange={(files) => setFieldValue('file', files[0])}
                                        placeholder="JPEG PNG or PDF (Files upto 1MB)"
                                    />
                                    {values.file && <div className="mt-2 text-sm">{values.file.name}</div>}
                                    <div className="form-error">
                                        <ErrorMessage name="file" />
                                    </div>
                                </div>

                                {/* <div className="mt-6">
                                    <label className="text-xs mb-2" htmlFor="type">
                                        TYPE
                                    </label>
                                    <Field id="type" name="type" type="text" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="type" />
                                    </div>
                                </div> */}

                                <div className="mt-5">
                                    <label className="text-xs mb-2 block" htmlFor="type">
                                        DOCUMENT TYPE
                                    </label>
                                    <Field as="select" name="type" type="text" id="type" className="form-control ">
                                        <option value="">Select Document Type</option>
                                        {documentTypes.map((row: any) => (
                                            <option key={row.label} value={row.label}>
                                                {`${row.label}`}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className="form-error">
                                        <ErrorMessage name="type" />
                                    </div>
                                </div>
                            </div>

                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                // isLoading={mutation.isLoading}
                                isLoading={false}
                            >
                                Add
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

export default DocumentUploadModal;
