/* eslint-disable jsx-a11y/anchor-is-valid */
import { IReceviable } from 'app/types';
import { formatCurrency } from 'app/utils/currency-utils';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface SubAgencyTableProps {
    subAgencies: IReceviable[];
}

const SubAgencyTable: FC<SubAgencyTableProps> = (props) => {
    const { subAgencies } = props;

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">Agency ID</th>
                        <th className="th-1">Agency Name & Address</th>
                        <th className="th-1">Agency Contact & Email</th>
                        <th className="th-1">Amount to be Recovered</th>
                        <th className="th-1">Amount to be Recovered this week</th>
                        <th className="th-1">Last Payment</th>
                    </tr>
                </thead>
                <tbody>
                    {subAgencies.map((row, i) => (
                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                            <td className="td-1">
                                <Link className="font-semibold text-blue-500" to={`/sub-agencies/${row._id}/details`}>
                                    {row._id}
                                </Link>
                            </td>
                            <td className="td-1">
                                <Link className="font-semibold text-blue-500" to={`/sub-agencies/${row._id}/details`}>
                                    {row.name}
                                </Link>
                                <p className="mt-1">
                                    {row.address.city}, {row.address.state}
                                </p>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold">{row.phoneNumber}</div>
                                <p className="mt-1">{row.email}</p>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold text-red-500 text-base">
                                    {formatCurrency(row.recoverAmount)}
                                </div>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold text-red-500 text-base">
                                    {formatCurrency(row.weeklyRecoverAmount)}
                                </div>
                            </td>
                            <td className="td-1">
                                {Object.keys(row.lastPayment).length > 0 && row.lastPayment.amount !== 0 ? (
                                    <div className="font-semibold text-green-500 text-base">
                                        {formatCurrency(row.lastPayment.amount)}
                                        <p>
                                            {moment(row.lastPayment.on, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')} at{' '}
                                            {moment(row.lastPayment.on, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                            <Duration paymentDate={moment(row.lastPayment.on, 'YYYYMMDDHHmmss')} />
                                        </p>
                                    </div>
                                ) : (
                                    '-'
                                )}

                                <div></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SubAgencyTable;

interface IDurationProps {
    paymentDate: Moment;
}

const Duration: FC<IDurationProps> = (props) => {
    const { paymentDate } = props;
    const now = moment();
    const duration = moment.duration(now.diff(paymentDate)).humanize();
    return <div>{duration} ago</div>;
};