// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar_sidebar__Whc4p {\n  height: calc(100vh - var(--navbar-height));\n  overflow: auto;\n}\n\n.Sidebar_sidebar-link__0IoB8 {\n  margin-bottom: 0.25rem;\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  gap: 0.75rem;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  --tw-text-opacity: 1;\n  color: rgb(55 65 81 / var(--tw-text-opacity));\n}\n.Sidebar_sidebar-link__0IoB8[aria-current=page] {\n  font-weight: 700;\n}\n\n.Sidebar_sidebar-sub-link__IGfk6 {\n  margin-bottom: 0.25rem;\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  gap: 0.75rem;\n  padding-top: 0.25rem;\n  padding-bottom: 0.25rem;\n  font-size: 0.85rem;\n  --tw-text-opacity: 1;\n  color: rgb(55 65 81 / var(--tw-text-opacity));\n}\n.Sidebar_sidebar-sub-link__IGfk6[aria-current=page] {\n  font-weight: 700;\n}", "",{"version":3,"sources":["webpack://./src/app/components/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,cAAA;AACJ;;AAGI;EAAA,sBAAA;EAAA,aAAA;EAAA,eAAA;EAAA,mBAAA;EAAA,YAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA;AAAA;AAGI;EAAA;AAAA;;AAKJ;EAAA,sBAAA;EAAA,aAAA;EAAA,eAAA;EAAA,mBAAA;EAAA,YAAA;EAAA,oBAAA;EAAA,uBAAA;EAAA,kBAAA;EAAA,oBAAA;EAAA;AAAA;AAGI;EAAA;AAAA","sourcesContent":[".sidebar {\n    height: calc(100vh - var(--navbar-height));\n    overflow: auto;\n}\n\n.sidebar-link {\n    @apply flex items-center gap-3 py-2 text-sm text-gray-700 mb-1 cursor-pointer;\n\n    &[aria-current='page'] {\n        @apply font-bold;\n    }\n}\n\n.sidebar-sub-link {\n    @apply flex items-center gap-3 py-1 text-[0.85rem] text-gray-700 mb-1 cursor-pointer;\n\n    &[aria-current='page'] {\n        @apply font-bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "Sidebar_sidebar__Whc4p",
	"sidebar-link": "Sidebar_sidebar-link__0IoB8",
	"sidebar-sub-link": "Sidebar_sidebar-sub-link__IGfk6"
};
export default ___CSS_LOADER_EXPORT___;
