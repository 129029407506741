import moment from 'moment';

interface IBarcodeGeneratorInput {
    pnr: string;
    fromAirport: string;
    toAirport: string;
    airlineCode: string;
    flightNum: string;
    DepartureDate: string;
    firstName: string;
    lastName: string;
}

const convertDateToJulian = (flightDate: string) => {
    const day = moment(flightDate, 'YYYYMMDD').dayOfYear();
    if (day < 10) {
        return '00'.concat(day.toString());
    } else if (day < 100) {
        return '0'.concat(day.toString());
    } else {
        return day.toString();
    }
};


const fixFlighNumber = (flightNum: string) => {
    const flightNumLength = flightNum.length;
    if (flightNumLength >= 4) return flightNum;
    const leftPadding = 4 - flightNumLength;
    return Array(leftPadding).fill('0').join('') + flightNum;
};




export const barCodeInputStringGenerator = (data: IBarcodeGeneratorInput): string => {
    const { pnr, fromAirport, toAirport, airlineCode, DepartureDate, flightNum, firstName, lastName } = data;

    const formatCode = 'M'; //  default value 'M'
    const numberofLegsEncoded = '1'; //  default value  '1'
    const passengerName = generatePassengerName(firstName, lastName);
    const electronicTicketIndicator = ' '; // default value  blank space (' ')
    const operatingCarrierPnrCode = addPaddingatRight(pnr, 7); // PNR and length of 7
    const fromCityAirportCode = fromAirport;
    const toCityAirportCode = toAirport;
    const operatingCarrierDesignator = addPaddingatRight(airlineCode, 3);
    const flightNumber = addPaddingatRight(fixFlighNumber(flightNum), 5);
    const dateOfFlight = addPaddingatRight(convertDateToJulian(DepartureDate), 3); //
    const compartmentCode = 'Y'; // default value 'Y' lenngth 1
    const SeatNumber = '0000'; // length 4 default '0000'
    const CheckInSequenceNumber = '00000'; // lenght 5 default '00000'
    const passengerStatus = '0'; // length 1 defaul '0'
    const fieldSizeOfVariable = '00'; // length 2 default '00'

    return [
        formatCode,
        numberofLegsEncoded,
        passengerName,
        electronicTicketIndicator,
        operatingCarrierPnrCode,
        fromCityAirportCode,
        toCityAirportCode,
        operatingCarrierDesignator,
        flightNumber,
        dateOfFlight,
        compartmentCode,
        SeatNumber,
        CheckInSequenceNumber,
        passengerStatus,
        fieldSizeOfVariable,
    ]
        .join('')
        .toUpperCase();
};

const addPaddingatRight = (input: string, totalLengthWithPadding: number) => {
    let output = input.concat(' '.repeat(totalLengthWithPadding));
    return output.substring(0, totalLengthWithPadding);
};

/**
 * Returns string of length 20.
 * format <LastName>/<FirstName><MiddleName> max length 20 characters,
 * in case less than 20 character adding paddinng on right with blank space
 */
const generatePassengerName = (firstName: string, lastName: string, middleName: string = '') => {
    let passengerName = `${lastName}/${firstName}${middleName}`;

    return addPaddingatRight(passengerName, 20);
};
