import TableSearch from 'app/components/TableSearch/TableSearch';
import React, { FC, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import SubAgencyUserTable from './components/SubAgencyUserTable/SubAgencyUserTable';
import { ISubAgencyUser } from 'app/types';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import PageLoader from 'app/components/PageLoader/PageLoader';
import BackLink from 'app/components/BackLink/BackLink';

interface SubAgencyUserListProps {}

const SubAgencyUserList: FC<SubAgencyUserListProps> = (props) => {
    const [filteredSubAgencyUserList, setFilteredSubAgencyUserList] = useState<ISubAgencyUser[]>([]);

    const { subAgencyId } = useParams();

    const subAgencyUserQuery = useQuery<ISubAgencyUser[]>(['subAgencyUserList'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/sub-agency-users`
        );
        return data;
    });

    useEffect(() => {
        if (subAgencyUserQuery.data) {
            setFilteredSubAgencyUserList(subAgencyUserQuery.data);
        }
    }, [subAgencyUserQuery.data]);

    const handleSearch = (q: string) => {
        const list = subAgencyUserQuery.data.filter((row) => {
            const val = q.toLowerCase();

            let flag = row.name.includes(val);
            flag = flag || row.phoneNumber.includes(val);
            flag = flag || row.email.includes(val);

            return flag;
        });

        setFilteredSubAgencyUserList(list);
    };

    if (subAgencyUserQuery.isLoading) return <PageLoader />;

    return (
        <div>
            <div className="mb-3">
                <BackLink />
            </div>

            <div className="flex justify-between items-center">
                <h5 className="font-bold">Sub Agency Users</h5>
                <Link
                    to={`/sub-agencies/${subAgencyId}/users/add`}
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add User
                </Link>
            </div>

            <div className="card mt-8">
                <div className="flex items-center px-6 pt-5">
                    <TableSearch
                        placeholder="Search for a sub agent"
                        onSearch={handleSearch}
                        onSearchReset={() => setFilteredSubAgencyUserList(subAgencyUserQuery.data)}
                    />
                </div>

                <div className="mt-6">
                    <SubAgencyUserTable subAgents={filteredSubAgencyUserList} subAgencyId={subAgencyId} />
                </div>
            </div>
        </div>
    );
};

export default SubAgencyUserList;
