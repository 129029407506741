export enum ISubAgencyUserStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export interface ISubAgencyUser {
    _id: string;
    name: string;
    email: string;
    username: string;
    phoneNumber: string;
    designation: string;
    subAgency: string;
    agency: string;
    createdAt: string;
    status: ISubAgencyUserStatus;
}
