export const getFormattedStops = (stops: number) => {
    if (stops <= 1) return 'Non Stop';
    else if (stops === 2) return '1 Stop';
    else return stops + ' Stops';
};

export const calculateStops = (segments: any[]) => {
    let stops = segments.length - 1;
    segments.forEach((s) => {
        stops += s.stops;
    });
    return stops;
};

export const getFormattedRefundableType = (key: string) => {
    const RefundableType: any = {
        NON_REFUNDABLE: 'Non-refundable',
        REFUNDABLE: 'Refundable',
        PARTIAL_REFUNDABLE: 'Partial Refundable',
    };

    return RefundableType[key];
};

export const getStatusClass = (status: string) => {
    switch (status) {
        case 'PENDING':
        case 'SUBMISSION_PENDING':
        case 'AWAITING_PNR':
        case 'IN_PROCESS':
            return 'yellow';

        case 'COMPLETED':
        case 'SUBMITTED':
            return 'green';

        case 'CANCELLED':
        case 'SUB_AGENT_ABORT':
        case 'AGENT_ABORT':
        case 'FAILED':
            return 'red';
    }
};

export const getStatusText = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'Pending';
        case 'AWAITING_PNR':
            return 'Awaiting PNR';
        case 'SUBMISSION_PENDING':
            return 'Submission Pending';
        case 'IN_PROCESS':
            return 'Processing';
        case 'COMPLETED':
            return 'Completed';
        case 'SUBMITTED':
            return 'Submitted';
        case 'CANCELLED':
            return 'Cancelled';
        case 'SUB_AGENT_ABORT':
            return 'Sub Agent Aborted';
        case 'AGENT_ABORT':
            return 'You Aborted';
        case 'FAILED':
            return 'Failed';
    }
};

export const getRefundTypeBackgroundColor = (status: string) => {
    switch (status) {
        case 'REFUNDABLE':
            return 'bg-success';
        case 'NON_REFUNDABLE':
            return 'bg-danger';
        case 'PARTIAL_REFUNDABLE':
            return 'bg-yellow-400';
        default:
            return 'bg-slate-500';
    }
};




export const getFareSupplier = (fareSupplier: string) => {
    switch (fareSupplier) {
        case '0':
            return 'Series Fare';
        case '1':
            return 'Tripjack API';
        case '2':
            return 'Series Fare';
        case '3':
            return 'Air IQ';
        case '4':
            return 'Travel Boutique';
        default:
            return '-';
    }
};