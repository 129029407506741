import React, { FC } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import http from 'app/config/http';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { toast } from 'react-toastify';

const PasswordFormSchema = Yup.object().shape({
    currentPassword: Yup.string().trim().required('This field is required'),
    newPassword: Yup.string()
        .trim()
        .required('This field is required')
        .min(5, 'The password should be atleast 5 characters long'),
    confirmPassword: Yup.string()
        .trim()
        .required('This field is required')
        .when('newPassword', {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string()
                .trim()
                .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
        }),
});

const initialValues: any = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
};

interface ChangePasswordFormProps {}

const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {
    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/profile/password`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await mutation.mutateAsync({ currentPassword: values.currentPassword, newPassword: values.newPassword });

            toast.success('Password changed successfully.');

            formikHelpers.resetForm();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={PasswordFormSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className="grid grid-cols-12 mt-8">
                    <div className="col-span-6">
                        <label className="text-xs mb-2" htmlFor="currentPassword">
                            CURRENT PASSWORD
                        </label>
                        <Field type="password" id="currentPassword" name="currentPassword" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="currentPassword" />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 mt-6">
                    <div className="col-span-6">
                        <label className="text-xs mb-2" htmlFor="newPassword">
                            NEW PASSWORD
                        </label>
                        <Field type="password" id="newPassword" name="newPassword" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="newPassword" />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 mt-6">
                    <div className="col-span-6">
                        <label className="text-xs mb-2" htmlFor="confirmPassword">
                            CONFIRM PASSWORD
                        </label>
                        <Field type="password" id="confirmPassword" name="confirmPassword" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="confirmPassword" />
                        </div>
                    </div>
                </div>

                <LoaderButton
                    type="submit"
                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-10"
                    isLoading={mutation.isLoading}
                >
                    Change
                </LoaderButton>
            </Form>
        </Formik>
    );
};

export default ChangePasswordForm;
