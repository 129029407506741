import { FC, useEffect } from 'react';
import { EditPencilIcon } from 'app/icons';
import MySeriesPluginModal from '../MySeriesPluginModal/MySeriesPluginModal';
import useDialog from 'app/hooks/use-dialog';
import OutwardApiPluginModal from '../OutwardApiPluginModal/OutwardApiPluginModal';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import Spinner from 'app/components/Spinner/Spinner';

const MYSERIES = 'my-series';
const OUTWARD_API = 'outward-api';

interface SubAgencyPluginListProps {
    // plugins: any;
    subAgencyId: string;
}

const SubAgencyPluginList: FC<SubAgencyPluginListProps> = (props) => {
    const { subAgencyId } = props;

    const mySeriesPlugingModal = useDialog<string>('');
    const outwardApiModal = useDialog<string>('');

    const subAgencypluginsQuery = useQuery(['subAgency', subAgencyId, 'plugins'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/plugins`
        );
        return data;
    });

    useEffect(() => {
        if (subAgencypluginsQuery.data && outwardApiModal.isOpen) {
            const index = subAgencypluginsQuery.data.findIndex((plugin: any) => plugin.id === OUTWARD_API);
            outwardApiModal.show(subAgencypluginsQuery.data[index]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subAgencypluginsQuery.data]);

    if (subAgencypluginsQuery.isLoading)
        return (
            <div className="flex items-center justify-center py-24">
                <Spinner />
            </div>
        );

    const plugins = subAgencypluginsQuery.data;

    return (
        <div className="grid grid-cols-4 gap-6 mt-8 p-6">
            {plugins.map((row: any) => {
                let Component = null;

                switch (row.id) {
                    case MYSERIES:
                        Component = (
                            <div
                                key={row._id}
                                className="card col-span-4 sm:col-span-2 xl:col-span-1 px-5 pt-4 pb-6 hover:cursor-pointer"
                            >
                                <div className="flex gap-3 justify-between  items-center">
                                    <div className="flex justify-end">
                                        <div
                                            className={`inline-flex text-xs px-3 py-1 rounded-full font-semibold  ${
                                                row.isEnabled ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-900'
                                            }`}
                                        >
                                            {row.isActive ? (row.isEnabled ? 'ENABLED' : 'DISABLED') : 'COMING SOON'}
                                        </div>
                                    </div>

                                    <div className="cursor-pointer p-2" onClick={() => mySeriesPlugingModal.show(row)}>
                                        {/* <MenuDotIcon /> */}
                                        <EditPencilIcon />
                                    </div>
                                </div>
                                <img className="max-h-10 mt-6" src={row.logoUrl} alt="" />
                                <div className="font-bold mt-4">{row.name}</div>
                                <p className="mt-2">{row.description}</p>
                            </div>
                        );
                        break;
                    case OUTWARD_API:
                        Component = (
                            <div
                                key={row._id}
                                className="card col-span-4 sm:col-span-2 xl:col-span-1 px-5 pt-4 pb-6 hover:cursor-pointer"
                            >
                                <div className="flex gap-3 justify-between items-center">
                                    <div className="flex justify-end">
                                        <div
                                            className={`inline-flex text-xs px-3 py-1 rounded-full font-semibold  ${
                                                row.isEnabled ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-900'
                                            }`}
                                        >
                                            {row.isActive ? (row.isEnabled ? 'ENABLED' : 'DISABLED') : 'COMING SOON'}
                                        </div>
                                    </div>
                                    <div className="cursor-pointer p-2" onClick={() => outwardApiModal.show(row)}>
                                        {/* <MenuDotIcon /> */}
                                        <EditPencilIcon />
                                    </div>
                                </div>
                                <img className="max-h-10 mt-6" src={row.logoUrl} alt="" />
                                <div className="font-bold mt-4">{row.name}</div>
                                <p className="mt-2">{row.description}</p>
                            </div>
                        );
                }
                return Component;
            })}

            {mySeriesPlugingModal.isOpen && (
                <MySeriesPluginModal
                    onClose={mySeriesPlugingModal.hide}
                    show={mySeriesPlugingModal.isOpen}
                    mySeries={mySeriesPlugingModal.data}
                    subAgencyId={subAgencyId}
                />
            )}
            {outwardApiModal.isOpen && (
                <OutwardApiPluginModal
                    onClose={outwardApiModal.hide}
                    show={outwardApiModal.isOpen}
                    outwardApi={outwardApiModal.data as any}
                    subAgencyId={subAgencyId}
                />
            )}
        </div>
    );
};

export default SubAgencyPluginList;
