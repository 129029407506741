import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
    remark: Yup.string().trim().required('This field is required'),
});

const initialValues: any = {
    remark: '',
};

interface RejectRemarkDialogProps {
    show: boolean;
    onClose: () => void;
    paymentRequestId: string;
    handleSubmit: (_id: string, remark: string) => Promise<void>;
    isLoading: boolean;
}

const RejectRemarkDialog: FC<RejectRemarkDialogProps> = (props) => {
    const handleSubmit = (values: any) => {
        props.handleSubmit(props.paymentRequestId, values.remark);
        props.onClose();
    };

    return (
        <Modal className="px-8 py-8 w-full max-w-[36rem]" show={props.show} onClose={props.onClose}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={FormSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="mt-6">
                            <div className="mt-6">
                                <label className="text-xs mb-2" htmlFor="remark">
                                    REMARK
                                </label>
                                <Field id="remark" name="remark" className="form-control" as="textarea" rows="3" />
                                <div className="form-error">
                                    <ErrorMessage name="remark" />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center mt-10 gap-4 justify-center">
                            <LoaderButton
                                type="submit"
                                className="bg-red-500 text-white text-base font-semibold rounded-lg w-32"
                                isLoading={props.isLoading}
                            >
                                Reject
                            </LoaderButton>
                            <LoaderButton
                                type="button"
                                className="bg-gray-200  text-gray-600 text-base font-semibold rounded-lg w-32"
                                disabled={props.isLoading}
                                onClick={props.onClose}
                            >
                                Cancel
                            </LoaderButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default RejectRemarkDialog;
