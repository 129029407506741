import { toggleCommissionPlanBonding } from './../../../api/services/fare-commission.service';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ICommissionPlanSubAgency } from 'app/enums/commission-plan-sub-agency.enum';
import {
    getCommissionPlanById,
    getSubAgenciesWithAttachedCommissionPlans,
    // toggleCommissionPlanBonding,
} from 'app/api/services/fare-commission.service';
// import { CommissionPlanTypeEnum } from 'app/enums/commission-plan-type.enum';
import { commissionPlanBondingEnum } from 'app/enums/commission-plan-bonding-enum';

export interface ICommissionPlanSubAgencyWithPlanCheck extends ICommissionPlanSubAgency {
    isAttached: boolean;
    inProgress: boolean;
    disable: boolean;
    toggle: (agencyId: string) => void;
    currentPlan: string;
}

const useCommissionAssociation = (commissionPlanId: string) => {
    const subAgencyListQueryIdentifier = ['COMMISSION', 'PLANS', 'USERS'];
    const commissionPlanQueryIdentifier = ['COMMISSION', 'PLANS', commissionPlanId];

    const [subAgencies, setSubAgencies] = useState<ICommissionPlanSubAgencyWithPlanCheck[]>([]);

    const {
        isLoading: isLoading_SL,
        data: data_SL,
        isError: isError_SL,
    } = useQuery(subAgencyListQueryIdentifier, getSubAgenciesWithAttachedCommissionPlans);

    const {
        isLoading: IsLoading_CP,
        data: data_CP,
        isError: isError_CP,
    } = useQuery(commissionPlanQueryIdentifier, () => getCommissionPlanById(commissionPlanId));

    const { isLoading, context, mutateAsync } = useMutation(
        async (payload: { operation: commissionPlanBondingEnum; SubAgencyId: string }) =>
            toggleCommissionPlanBonding({
                commissionPlanId,
                operation: payload.operation,
                SubAgencyId: payload.SubAgencyId,
            })
    );

    console.log({ isLoading, context });

    useEffect(() => {
        if (data_SL && data_CP) {
            const hydratedSubAgencyList = data_SL.map((subAgency) => {
                let isDisabled = false;
                let isChecked = false;
                let prevCommissionPlanName = '';
                subAgency.commissionPlans.map((commissionPlan) => {
                    // if (commissionPlan.commissionPlanType !== CommissionPlanTypeEnum.SUPPLIER) {
                    if (commissionPlan.commissionFareSupplier === data_CP.commissionFareSupplier) {
                        if (commissionPlan._id === data_CP._id) {
                            isChecked = true;
                        } else {
                            isDisabled = true;
                            prevCommissionPlanName = commissionPlan.name;
                        }
                    }
                    // }
                });

                return {
                    isAttached: isChecked,
                    inProgress: false,
                    toggle: function (agencyId: string) {
                        const that = this;
                        that.inProgress = true;
                        console.log('this in progress is started');
                        mutateAsync(
                            {
                                operation: commissionPlanBondingEnum.ATTACH,
                                SubAgencyId: agencyId,
                            },
                            {
                                onSettled: () => {
                                    console.log('this progess is toggled finished');
                                    that.inProgress = false;
                                },
                            }
                        );
                        // toggleCommissionPlanBonding = async (payload: {
                        //     commissionPlanId: string;
                        //     SubAgencyId: string;
                        //     operation: commissionPlanBondingEnum;
                        // })
                        // const xyz = useMutation(() =>
                        //     toggleCommissionPlanBonding({ SubAgencyId, commissionPlanId, operation: '' })
                        // );
                    },
                    _id: subAgency._id,
                    name: subAgency.name,
                    commissionPlans: subAgency.commissionPlans,
                    disable: isDisabled,
                    currentPlan: prevCommissionPlanName,
                };
            });

            setSubAgencies(hydratedSubAgencyList);
        }
    }, [isLoading_SL, IsLoading_CP, data_SL, data_CP]);

    return {
        loading: isLoading_SL || IsLoading_CP,
        error: isError_CP || isError_SL,
        subAgencies,
        commissionPlan: data_CP,
    };
};

export default useCommissionAssociation;
