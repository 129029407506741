import moment from 'moment';
import { IPassenger } from 'app/types/IPassenger';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import { PASSENGER_TITLES } from 'app/shared/constants/passenger-titles';
import { IManualBookingDetailForm, ISegmentInitial } from '../types/IManualBooking';

export const createBlankSegment = (): ISegmentInitial => {
    return {
        flight: null,
        flightNumber: '',
        arrival: {
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            airport: null,
        },
        departure: {
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            airport: null,
        },
        stopsOverAirport: [],
    };
};

const generateTitleFromPassengerType = (passengerType: PassengerTypeEnum): string => {
    if (passengerType === PassengerTypeEnum.ADULT) return PASSENGER_TITLES.adults[0];
    if (passengerType === PassengerTypeEnum.CHILD) return PASSENGER_TITLES.children[0];
    if (passengerType === PassengerTypeEnum.INFANT) return PASSENGER_TITLES.infants[0];

    return '';
};

export const createPassengerTemplate = (passengerType: PassengerTypeEnum): IPassenger => {
    return {
        title: generateTitleFromPassengerType(passengerType),
        firstName: '',
        lastName: '',
        dob: '',
        passport: {
            expiry: '',
            issueDate: '',
            number: '',
            nationality: 'IN',
        },
        savePassengerDetails: false,
    };
};

export const createBookingDetailsTemplate = (): IManualBookingDetailForm => {
    return {
        adults: [createPassengerTemplate(PassengerTypeEnum.ADULT)],
        children: [],
        infants: [],
        priceDetails: {
            adult: {
                base: 0,
                taxAndFees: 0,
            },
            infant: {
                base: 0,
                taxAndFees: 0,
            },
            child: {
                base: 0,
                taxAndFees: 0,
            },
            managementFeesGstRate: 0,
            managementFeesPerSeat: 0,
        },
        contactDetails: {
            email: '',
            mobileNumber: '',
        },
    };
};
