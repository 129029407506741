import * as Yup from 'yup';
import http from 'app/config/http';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

import { ITripJackCredentialForm } from '../../types';

const TripJackCredentialFormSchema = Yup.object().shape({
    apiKey: Yup.string().trim().required('This field is required'),
    baseUrl: Yup.string().required('This field is required'),
});

interface ITripJackCredentialFormProps {
    apiKey: string;
    baseUrl: string;
}

const TripJackCredentialForm: FC<ITripJackCredentialFormProps> = (props) => {
    const initialValues: ITripJackCredentialForm = useMemo(() => {
        return {
            apiKey: props.apiKey || '',
            baseUrl: props.baseUrl || '',
        };
    }, [props]);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/trip-jack`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: ITripJackCredentialForm) => {
        try {
            const authKeys = {
                credentials: {
                    apiKey: values.apiKey,
                },
                baseUrl: values.baseUrl,
            };
            await mutation.mutateAsync({ credentials: authKeys });
            toast.success('API Key updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={TripJackCredentialFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className='grid grid-cols-12 gap-6 mt-8'>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='baseUrl'>
                                Base Url
                            </label>
                            <Field
                                type='text'
                                id='baseUrl'
                                name='baseUrl'
                                className='form-control'
                                placeholder='Enter your TripJack client id'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='baseUrl' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='apiKey'>
                                API KEY
                            </label>
                            <Field
                                type='text'
                                id='apiKey'
                                name='apiKey'
                                className='form-control'
                                placeholder='Enter your TripJack API key here'
                                onFocus={() => setFieldValue('apiKey', '')}
                            />
                            <div className='form-error'>
                                <ErrorMessage name='apiKey' />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type='submit'
                        className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                        isLoading={mutation.isLoading}
                        disabled={values.apiKey === initialValues.apiKey}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default TripJackCredentialForm;
