import moment from 'moment';
import { debounce } from 'lodash';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { ISubAgency } from 'app/types';
import { FC, useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import TableSearch from 'app/components/TableSearch/TableSearch';
import StatsCard from 'app/components/StatsCard/StatsCard';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import SubAgencyTable from './components/SubAgencyTable/SubAgencyTable';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';

const periodList: any = {
    'The Beginning': 'BEGINING',
    'This Week': 'THIS_WEEK',
    'This Month': 'THIS_MONTH',
    'This Quater': 'THIS_QUATER',
    'This Year': 'THIS_YEAR',
};

const activityList: any = {
    All: 'ALL',
    Active: 'ACTIVE',
    'In Active': 'INACTIVE',
};

interface SubAgencyListProps {}

const SubAgencyList: FC<SubAgencyListProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);

    const [period, setPeriod] = useState('BEGINING');
    const [activity, SetActivity] = useState('ALL');

    let search = searchParams.get('search') ? searchParams.get('search') : '';
    let page = searchParams.get('page') ? searchParams.get('page') : '';

    const subAgenciesQuery = useQuery<ISubAgency[]>(['subAgenciesList', search, page, period, activity], async () => {
        let params = '';
        search ? (params += `searchParam=${search}`) : (params += '');
        page ? (params += `&page=${page}`) : (params += '');
        period ? (params += `&active=${period}`) : (params += '');
        activity ? (params += `&activityStatus=${activity}`) : (params += '');

        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies?${params}`);
        setPageCount(data.pageCount);

        return data.data;
    });

    const subAgenciesStatsQuery = useQuery(['subAgenciesStats'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/stats`);

        return data;
    });

    // useEffect(() => {
    //     setParams(() => {
    //         let newUrl = `?page=${currentPage}`;
    //         if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;

    //         return newUrl;
    //     });
    // }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const debouncedSearch = debounce((q) => {
        if (q !== '') {
            searchParams.set('search', q);
        } else {
            searchParams.delete('search');
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    }, 300);

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const downloadActiveSubAgentsMutation = useMutation(async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/filter-by-activity/excel?active=${period}&activityStatus=${activity}`,
            { responseType: 'blob' }
        );
        return data;
    });

    const handleDownloadExcel = async () => {
        try {
            const res = await downloadActiveSubAgentsMutation.mutateAsync();

            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute(
                'download',
                `${activity.toLowerCase()}-sub-agencies-${moment().format('YYYY-MM-DD')}.xlsx`
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            window.URL.revokeObjectURL(url);
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    if (subAgenciesStatsQuery.isLoading) return <PageLoader />;

    return (
        <div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Sub Agencies</h5>
                <Link
                    to="/sub-agencies/add"
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add Sub Agency
                </Link>
            </div>

            <div className="grid grid-cols-4 gap-6 mt-8">
                <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                    <StatsCard title="TOTAL SUB AGENCIES" content={subAgenciesStatsQuery.data.totalSubAgencyCount} />
                </div>
                <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                    <StatsCard
                        title="SUB AGENCIES THIS MONTH"
                        content={subAgenciesStatsQuery.data.currentMonthSubAgencyCount}
                    />
                </div>
                <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                    <StatsCard title="TOTAL USERS" content={subAgenciesStatsQuery.data.totalSubAgentCount} />
                </div>
                <div className="col-span-4 sm:col-span-2 xl:col-span-1">
                    <StatsCard
                        title="TOTAL USERS THIS MONTH"
                        content={subAgenciesStatsQuery.data.currentMonthSubAgentCount}
                    />
                </div>
            </div>

            <div className="card mt-8">
                <div className="flex items-center justify-between px-6 pt-5">
                    <TableSearch
                        placeholder="Search for sub agency by name or id"
                        onSearch={handleSearch}
                        value={search}
                    />
                    <div className="flex items-center gap-2 ">
                        <select
                            disabled={downloadActiveSubAgentsMutation.isLoading}
                            className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                            onChange={(e) => SetActivity(e.target.value)}
                            value={activity}
                        >
                            {/* <option value="">Filter by Status</option> */}
                            {Object.keys(activityList).map((key) => (
                                <option key={activityList[key]} value={activityList[key]}>
                                    {key}
                                </option>
                            ))}
                        </select>
                        <label className="text-xs self-center " htmlFor="">
                            Agencies since
                        </label>
                        <select
                            disabled={downloadActiveSubAgentsMutation.isLoading}
                            className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                            onChange={(e) => setPeriod(e.target.value)}
                            value={period}
                        >
                            {/* <option value="">Filter by Status</option> */}
                            {Object.keys(periodList).map((key) => (
                                <option key={periodList[key]} value={periodList[key]}>
                                    {key}
                                </option>
                            ))}
                        </select>
                        <LoaderButton
                            className="bg-primary text-white text-sm font-semibold rounded-lg "
                            isLoading={downloadActiveSubAgentsMutation.isLoading}
                            onClick={handleDownloadExcel}
                        >
                            {' '}
                            Download{' '}
                        </LoaderButton>
                    </div>
                </div>

                <div className="mt-6">
                    <SubAgencyTable
                        subAgencies={subAgenciesQuery.data}
                        isLoading={subAgenciesQuery.isLoading}
                        queryIdentifier={['subAgenciesList', search, page]}
                    />
                    <div className="px-4 pb-3 ">
                        <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubAgencyList;
