import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { FC, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const AirIqFareIdentifierFormSchema = Yup.object({
    fareIdentifier: Yup.string().trim().required('This field is required'),
});

interface IAirIqFareIdentifierForm {
    fareIdentifier: string;
}

interface AirIqFareIdentifierFormProps {
    fareIdentifier: string;
}

const AirIqFareIdentifierForm: FC<AirIqFareIdentifierFormProps> = (props) => {
    const { fareIdentifier } = props;

    const initialValues: IAirIqFareIdentifierForm = useMemo(() => {
        if (!fareIdentifier)
            return {
                fareIdentifier: '',
            };

        return {
            fareIdentifier: fareIdentifier,
        };
    }, [fareIdentifier]);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/air-iq/fare-identifier`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: IAirIqFareIdentifierForm) => {
        try {
            await mutation.mutateAsync({ fareIdentifier: values.fareIdentifier.trim() });
            toast.success('Fare Identifier updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={AirIqFareIdentifierFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className='grid grid-cols-12 gap-6 mt-8'>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='fareIdentifier'>
                                FARE IDENTIFIER
                            </label>
                            <Field
                                type='text'
                                id='fareIdentifier'
                                name='fareIdentifier'
                                className='form-control'
                                placeholder='Enter Air IQ Identifier here'
                                onChange={(e: any) => {
                                    setFieldValue('fareIdentifier', e.target.value.trim());
                                }}
                            />
                            <div className='form-error'>
                                <ErrorMessage name='fareIdentifier' />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type='submit'
                        className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                        isLoading={mutation.isLoading}
                        disabled={values.fareIdentifier === initialValues.fareIdentifier}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default AirIqFareIdentifierForm;
