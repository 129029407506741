import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const InventoryFormSchema = Yup.object({
    totalSeats: Yup.number().required('This field is required').typeError('You must specify a number'),
});

interface ChangeSeatsFormProps {
    ticketId: string;
    totalSeats: number;
}

const ChangeSeatsForm: FC<ChangeSeatsFormProps> = (props) => {
    const { ticketId, totalSeats } = props;

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${ticketId}/total-seats`,
            payload
        );

        return data;
    });

    const initialValues = useMemo(() => {
        if (!totalSeats)
            return {
                totalSeats: 0,
            };

        return {
            totalSeats: totalSeats,
        };
    }, [totalSeats]);

    const handleSubmit = async (values: { totalSeats: number }) => {
        const payload = {
            totalSeats: values.totalSeats,
        };

        try {
            await mutation.mutateAsync(payload);

            toast.success('Availability updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={InventoryFormSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className="grid grid-cols-4 gap-6 mt-8">
                    <div className="col-span-1">
                        <label className="text-xs mb-2 " htmlFor="totalSeats">
                            TOTAL SEATS
                        </label>
                        <Field id="totalSeats" name="totalSeats" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="totalSeats" />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-7"
                            isLoading={mutation.isLoading}
                        >
                            Change
                        </LoaderButton>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default ChangeSeatsForm;
