import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { IStaticPage } from 'app/types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import StaticPageTable from './components/StaticPageTable/StaticPageTable';

interface StaticPageListProps {}

// '20230524142015';

const StaticPageList: FC<StaticPageListProps> = (props) => {
    const staticPagesQuery = useQuery<IStaticPage[]>(['StaticPage', 'List'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/static-page`);

        return data;
    });

    return (
        <div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Pages</h5>
                <Link
                    to="add"
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add Page
                </Link>
            </div>

            <div className="card mt-8">
                <div className="mt-6">
                    <StaticPageTable isLoading={staticPagesQuery.isLoading} staticPages={staticPagesQuery.data} />
                </div>
            </div>
        </div>
    );
};

export default StaticPageList;
