import { FC } from 'react';
import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FileUpload from 'app/components/FileUpload/FileUpload';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const FormSchema = Yup.object().shape({
    image: Yup.mixed().required('This field is required'),
    heading: Yup.string().trim().required('This field is required'),
    description: Yup.string().trim().required('This field is required'),
});

const initialValues: any = {
    image: null,
    heading: '',
    description: '',
};

interface AddAnnouncementModalProps {
    show: boolean;
    onClose: () => void;
}

const AddAnnouncementModal: FC<AddAnnouncementModalProps> = (props) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/announcements`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: any) => {
        try {
            const formData = new FormData();

            formData.append('heading', values.heading);
            formData.append('description', values.description);
            formData.append('file', values.image);

            const announcements = await mutation.mutateAsync(formData);

            toast.success('Announcement added successfully.');

            queryClient.setQueryData(['announcementList'], announcements);

            props.onClose();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10 w-[680px] max-w-full">
            <div className="font-bold">Make Announcement</div>

            <div className="mt-8">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={FormSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="mt-6">
                                <div>
                                    <label className="text-xs mb-2" htmlFor="image">
                                        IMAGE
                                    </label>

                                    <FileUpload
                                        id="image"
                                        accept="image/*"
                                        onChange={(files) => setFieldValue('image', files[0])}
                                    />
                                    {values.image && <div className="mt-2 text-sm">{values.image.name}</div>}
                                    <div className="form-error">
                                        <ErrorMessage name="image" />
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <label className="text-xs mb-2" htmlFor="heading">
                                        HEADING
                                    </label>
                                    <Field id="heading" name="heading" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="heading" />
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <label className="text-xs mb-2" htmlFor="description">
                                        DESCRIPTION
                                    </label>
                                    <Field as="textarea" id="description" name="description" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="description" />
                                    </div>
                                </div>
                            </div>

                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                isLoading={mutation.isLoading}
                            >
                                Add
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

export default AddAnnouncementModal;
