/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import SeriesInventoryForm from './components/SeriesInventoryForm/SeriesInventoryForm';

interface SeriesInventoryAddProps {}

const SeriesInventoryAdd: FC<SeriesInventoryAddProps> = (props) => {
    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex items-center justify-between">
                    <div className="font-bold">Add Series Inventory</div>

                    <a className="text-blue-500 text-sm cursor-pointer">Upload Excel</a>
                </div>

                <SeriesInventoryForm />
            </div>
        </>
    );
};

export default SeriesInventoryAdd;
