import { ButtonHTMLAttributes, FC } from 'react';
import Spinner from '../Spinner/Spinner';

interface LoaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    spinnerColor?: string;
    isDisabled?: boolean;
}

const LoaderButton: FC<LoaderButtonProps> = ({ children, className, isLoading, spinnerColor, isDisabled, ...rest }) => {
    return (
        <button
            {...rest}
            className={`flex justify-center items-center h-12 px-4 ${className}`}
            disabled={isLoading || isDisabled}
        >
            {isLoading ? <Spinner color={spinnerColor} size={20} /> : children}
        </button>
    );
};

LoaderButton.defaultProps = {
    isLoading: false,
    spinnerColor: '#fff',
};

export default LoaderButton;
