import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { IBookingSearch } from '../types/booking-search';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { getStatusClass, getStatusText } from 'app/utils/flight-utils';

interface IRenderBookingSearch {
    booking: IBookingSearch;
    handlePreNavigate: () => void;
}

const RenderBookingSearch = (props: IRenderBookingSearch) => {
    const { booking, handlePreNavigate } = props;
    const navigate = useNavigate();
    const handleClick = () => {
        handlePreNavigate();
        navigate(`/bookings/${booking._id}/${booking.fareSupplier === '2' ? 'SAG' : 'AG'}`);
    };

    return (
        <div className='grid grid-cols-12 px-2 py-2 gap-2 hover:bg-gray-50 cursor-pointer' onClick={handleClick}>
            <div className='col-span-3 flex flex-col items-center justify-between'>
                <div className='font-semibold text-primary'>{booking._id}</div>
                <StatusBadge className='' color={getStatusClass(booking.status)}>
                    {getStatusText(booking.status)}
                </StatusBadge>
            </div>
            <div className='col-span-5 flex items-center gap-3'>
                <img className='w-12 h-12' crossOrigin='anonymous' src={booking.departureFlight.logo} alt='' />
                <div className='flex flex-col justify-between'>
                    <div className='font-semibold text-black'>{booking.departureFlight.name}</div>
                    <p className=''>{`${booking.departureFlight.code} ${booking.departureFlight.number}`}</p>
                </div>
                <div className='flex flex-col justify-center items-center '>
                    <p>{moment(booking.date, 'YYYYMMDD').format('DD MMM YYYY')}</p>
                    <p>
                        {booking.departureAirport} - {booking.arrivalAirport}
                    </p>
                </div>
            </div>
            <div className='col-span-4 flex items-center'>
                <div className=''>
                    <div className='font-semibold'>{booking.passengers[0]}</div>
                    {booking.passengers.length > 1 && (
                        <p className='mt-1'>+ {booking.passengers.length - 1} Passengers</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RenderBookingSearch;
