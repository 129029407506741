import React, { FC } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';
import moment from 'moment';

interface MarkupHistoryTableProps {
    markupHistory: any[];
}

const MarkupHistoryTable: FC<MarkupHistoryTableProps> = (props) => {
    const { markupHistory } = props;

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">Time & Date</th>
                        <th className="th-1">Value</th>
                        <th className="th-1">Agent Email</th>
                        <th className="th-1">Seats Left</th>
                    </tr>
                </thead>
                <tbody>
                    {markupHistory.map((row, i) => (
                        <tr key={i} className="border-b border-gray-200 last:border-none">
                            <td className="td-1">
                                <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A - DD MMM, YYYY')}</p>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold"> {formatCurrency(row.markup)}</div>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold">{row.agentUser.name}</div>
                                <p className="mt-1">{row.agentUser.email}</p>
                            </td>
                            <td className="td-1">
                                <div className="font-semibold">{row.seatsLeft}</div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MarkupHistoryTable;
