//------------------------------------------------------------------ Manual Booking ------------------------------------------------------------------
import http from 'app/config/http';
import CommissionPlanAssociation from 'app/modules/fare-management/pages/commission-plan-association/Page';
import { IManualBooking } from 'app/types/manual-booking';

export const createManualBooking = async (payload: IManualBooking) => {
    const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/manual-booking`, payload);

    console.log({ resMB: data });

    return data.commissionPlans;
};
