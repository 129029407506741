import useDialog from 'app/hooks/use-dialog';
import { FC, useCallback, useEffect, useState } from 'react';
import PageLoader from 'app/components/PageLoader/PageLoader';
import TravellerDetails from '../TravellerDetails/TravellerDetails';
import FlightDetailsCard from '../FlightDetailsCard/FlightDetailsCard';
import EditBookingFlightDialog from '../EditBookingFlightDialog/EditBookingFlightDialog';

interface EditBookingDialogProps {
    booking: any;
    show: boolean;
    onClose: () => void;
    fromTop: number;
}

const EditBookingDialog: FC<EditBookingDialogProps> = (props) => {
    const { booking: orginalBooking, fromTop, onClose } = props;
    const [booking, setBooking] = useState(null);

    const editPassengerDialog = useDialog();
    const edidFlightDialog = useDialog();

    useEffect(() => {
        setBooking(() => {
            return JSON.parse(JSON.stringify(orginalBooking));
        });
    }, [orginalBooking]);

    // useEffect(() => {
    //     console.log({ booking });
    // });

    const handleUpdateSegments = useCallback((segments: any) => {
        setBooking((prevBookingState: any) => {
            return {
                ...prevBookingState,
                segments,
            };
        });
    }, []);

    if (booking === null) return <PageLoader />;
    return (
        <>
            <div className="fixed top-0 left-0 h-full w-screen  bg-slate-800/90 z-20 overflow-auto pb-8">
                <div
                    className={'grid grid-cols-3  gap-6 ml-[var(--sidebar-width)] px-8'}
                    style={{ marginTop: `${fromTop}px` }}
                >
                    <div className="col-span-3 lg:col-span-2">
                        <FlightDetailsCard
                            booking={booking}
                            isEditable={true}
                            openModal={() => edidFlightDialog.show()}
                        />
                        <TravellerDetails
                            booking={booking}
                            isEditable={true}
                            openModal={() => editPassengerDialog.show()}
                        />
                    </div>
                    <div className="col-span-3 lg:col-span-1 mt-16">{/* <div className="card py-4"></div> */}</div>
                </div>

                <div className="flex gap-4 fixed bottom-0 right-0 mx-10 mb-4">
                    <button className="bg-primary py-2 px-4 text-white">Save</button>
                    <button className="bg-red-500 py-2 px-4 text-white" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
            {/* <EditBookingPassengerDialog
                adults={booking.passengers.adults}
                children={booking.passengers.children}
                infants={booking.passengers.infants}
                onClose={editPassengerDialog.hide}
                show={editPassengerDialog.isOpen}
                tripDate={booking.departure.date}
                isInternationalFlight={booking.isInternational}
            /> */}
            {edidFlightDialog.isOpen && (
                <EditBookingFlightDialog
                    booking={{ ...booking }}
                    onClose={edidFlightDialog.hide}
                    show={edidFlightDialog.isOpen}
                    handleUpdate={handleUpdateSegments}
                />
            )}
        </>
    );
};

export default EditBookingDialog;
