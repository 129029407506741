import { debounce } from 'lodash';
import http from 'app/config/http';
import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { ISeriesTicket } from 'app/types/series-ticket';
import TableSearch from 'app/components/TableSearch/TableSearch';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import SubAgencySeriesInventoryTable from '../SubAgencySeriesInventoryTable/SubAgencySeriesInventoryTable';

interface SubAgencySeriesListProps {}

const SubAgencySeriesList: FC<SubAgencySeriesListProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);

    let search = searchParams.get('search') ? searchParams.get('search') : '';
    let fromDate = searchParams.get('fromDate') ? searchParams.get('fromDate') : '';
    let toDate = searchParams.get('toDate') ? searchParams.get('toDate') : '';
    let sector = searchParams.get('sector') ? searchParams.get('sector') : '';
    let isLive = searchParams.get('isLive') ? searchParams.get('isLive') : 'false';
    let page = searchParams.get('page') ? searchParams.get('page') : '';

    const subSeriesTicketListQuery = useQuery<ISeriesTicket[]>(
        ['subSeriesTicketList', search, fromDate, toDate, sector, isLive, page],
        async () => {
            let params = '';
            search ? (params += `searchParam=${search}`) : (params += '');
            fromDate ? (params += `&fromDate=${fromDate}`) : (params += '');
            toDate ? (params += `&toDate=${toDate}`) : (params += '');
            sector ? (params += `&sector=${sector}`) : (params += '');
            page ? (params += `&page=${page}`) : (params += '');
            params += `&isLive=${isLive}`;

            const { data } = await http.get(
                `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/series-supplier-ticket?${params}`
            );

            setPageCount(data.pageCount);
            return data.data;
        }
    );

    const sectorsQuery = useQuery(['sectors', 'series-supplier-ticket'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/series-supplier-ticket/sectors`
        );

        return data;
    });

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    const debouncedSearch = debounce((q) => {
        if (q !== '') {
            searchParams.set('search', q);
        } else {
            searchParams.delete('search');
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    }, 300);

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const handleDateFilter = (date1: string, date2: string) => {
        date1 ? searchParams.set('fromDate', date1) : searchParams.delete('fromDate');
        date2 ? searchParams.set('toDate', date2) : searchParams.delete('toDate');
        searchParams.set('page', '1');
        setSearchParams(searchParams, { replace: true });
    };

    const handleSectorChangeFilter = (sector: string) => {
        searchParams.set('sector', sector);
        setSearchParams(searchParams, { replace: true });
    };

    const handleIsLive = (isLive: boolean) => {
        searchParams.set('isLive', isLive.toString());
        setSearchParams(searchParams, { replace: true });
    };

    return (
        <>
            {' '}
            <div className="flex justify-between items-center px-6 pt-5">
                <TableSearch
                    placeholder="Search by flight name, listed by or PNR"
                    onSearch={handleSearch}
                    value={search}
                />

                <div className="flex items-center gap-8">
                    <select
                        className="border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium"
                        onChange={(e) => handleSectorChangeFilter(e.target.value)}
                        disabled={sectorsQuery.isLoading}
                        value={sector}
                    >
                        <option value="">Filter by Sector</option>
                        {sectorsQuery.data &&
                            sectorsQuery.data.map((s: string) => (
                                <option key={s} value={s}>
                                    {s}
                                </option>
                            ))}
                    </select>
                    <TableDateFilter onChange={handleDateFilter} fromDate={fromDate} toDate={toDate} />
                </div>
            </div>
            <div className="mt-6">
                <SubAgencySeriesInventoryTable
                    seriesTickets={subSeriesTicketListQuery.data}
                    isLoading={subSeriesTicketListQuery.isLoading}
                    QueryIdentifier={['subSeriesTicketList', search, fromDate, toDate, sector, isLive, page]}
                    handleIsLiveToggle={handleIsLive}
                    showIsLive={isLive == 'true' ? true : false}
                />
                <div className="px-4 pb-3 ">
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default SubAgencySeriesList;
