import { FC, ReactNode } from 'react';

interface StatusBadgeProps {
    children: ReactNode;
    color: string;
    className?: string;
}

const StatusBadge: FC<StatusBadgeProps> = (props) => {
    const { children, color, className } = props;

    const getDotClass = () => {
        switch (color) {
            case 'green':
                return 'bg-green-500';
            case 'red':
                return 'bg-red-500';
            case 'yellow':
                return 'bg-yellow-500';
        }
    };

    const getBgClass = () => {
        switch (color) {
            case 'green':
                return 'bg-green-100';
            case 'red':
                return 'bg-red-100';
            case 'yellow':
                return 'bg-yellow-100';
        }
    };

    const getTextClass = () => {
        switch (color) {
            case 'green':
                return 'text-green-900';
            case 'red':
                return 'text-red-900';
            case 'yellow':
                return 'text-yellow-900';
        }
    };

    return (
        <div
            className={`badge inline-flex items-center gap-2 whitespace-nowrap ${getBgClass()} ${getTextClass()} ${className}`}
        >
            <span className={`inline-block w-2 h-2 rounded-full ${getDotClass()}`}></span>
            {children}
        </div>
    );
};

export default StatusBadge;
