import { FC } from 'react';
import { IBankTransactionForm } from 'app/pages/Accounting/BankTransaction/BankTransactionAdd/types';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import http from 'app/config/http';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import moment from 'moment';
import { TRANSACTION_TYPES } from 'app/utils/constants';
import { IBank } from 'app/types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import * as Yup from 'yup';

const TransactionFormSchema = Yup.object().shape({
    transactionRemark: Yup.string().trim().required('This field is required'),
    bankAccount: Yup.string().trim().required('This field is required'),
    type: Yup.string().trim().required('This field is required'),
    balanceUploaded: Yup.number()
        .typeError('Enter valid amount')
        .required('This field is required')
        .moreThan(0, 'Enter valid amount'),
    transactionDate: Yup.string()
        .required('This field is required')
        .test('isValid', 'Enter valid date', (transDate) => {
            return !moment(transDate, 'YYYY-MM-DD').isAfter(moment());
        }),
});

interface BankTransactionFormProps {}

const BankTransactionForm: FC<BankTransactionFormProps> = (props) => {
    const navigate = useNavigate();

    const agencyBankAccountQuery = useQuery<IBank[]>(['Agency Bank Account List'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/banks`);
        return data;
    });

    const transactionMutation = useMutation(async (values: any) => {
        const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/accounts`, values);
        return data;
    });

    if (agencyBankAccountQuery.isLoading) return <PageLoader />;
    const bankAccounts = agencyBankAccountQuery.data;

    if (bankAccounts.length === 0) return <h1> Add Bank Account First</h1>;

    const initialValues: IBankTransactionForm = {
        transactionDate: moment().format('YYYY-MM-DD'),
        transactionRemark: '',
        balanceUploaded: 0,
        bankAccount: '',
        type: 'CREDIT',
    };

    const handleSubmit = async (values: IBankTransactionForm) => {
        const bankAccount = bankAccounts.find((account) => account._id === values.bankAccount);

        const valuesHydrated = {
            ...values,
            bankAccount,
            transactionDate: moment(values.transactionDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        };

        try {
            await transactionMutation.mutateAsync(valuesHydrated);
            toast.success('Transaction added successfully.');
            navigate('/account/transactions');
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.message || 'Some error occured, please try again.');
        }
    };

    const getFieldValue = (bankId: string, field: string) => {
        const bankAccount = bankAccounts.find((account: any) => account._id === bankId);

        // if no bank selected return empty stirng
        if (!bankAccount) return '';

        let value = '';
        switch (field) {
            case 'name':
                value = bankAccount.name;
                break;
            case 'ifsc':
                value = bankAccount.ifsc;
                break;
            case 'account.holderName':
                value = bankAccount.account.holderName;
                break;
            case 'account.number':
                value = bankAccount.account.number;
                break;
            case 'account.type':
                value = bankAccount.account.type;
                break;
            default:
                value = '';
                break;
        }

        return value;
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={TransactionFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }: FormikProps<IBankTransactionForm>) => (
                <Form>
                    {/* name , gst */}
                    <div className="grid grid-cols-12 gap-6 mt-6">
                        {/* transaction date */}
                        <div className="col-span-4">
                            <label className="text-xs mb-2 " htmlFor="transactionDate">
                                TRANSACTION DATE
                            </label>
                            <Field type="date" id="transactionDate" name="transactionDate" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="transactionDate" />
                            </div>
                        </div>

                        {/* Amount */}
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="balanceUploaded">
                                AMOUNT
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">₹</div>
                                <Field
                                    type="number"
                                    id="balanceUploaded"
                                    name="balanceUploaded"
                                    className="form-control pl-8"
                                />
                            </div>
                            <div className="form-error">
                                <ErrorMessage name="balanceUploaded" />
                            </div>
                        </div>

                        {/* transaction type  */}
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="type">
                                TRANSACTION TYPE
                            </label>
                            <Field as="select" name="type" type="text" id="type" className="form-control">
                                {/* TRANSACTION_TYPES */}
                                <option value="">Select Transaction Type</option>
                                {Object.keys(TRANSACTION_TYPES).map((trnType: string) => (
                                    // <h1>hello</h1>
                                    <option
                                        key={TRANSACTION_TYPES[`${trnType}`].value}
                                        value={TRANSACTION_TYPES[`${trnType}`].value}
                                    >
                                        {TRANSACTION_TYPES[`${trnType}`].label}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name="type" />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 mt-6">
                        <div className="col-span-6">
                            <label className="text-xs mb-2" htmlFor="bankAccount">
                                SELECT ACCOUNT
                            </label>
                            <Field as="select" name="bankAccount" type="text" id="bankAccount" className="form-control">
                                {/* TRANSACTION_TYPES */}

                                <option value="">Select Bank Account</option>
                                {bankAccounts.map((bankAccount) => (
                                    <option key={bankAccount._id} value={bankAccount._id}>
                                        {`${bankAccount.name} - ${bankAccount.account.number} `}
                                    </option>
                                ))}
                            </Field>
                            <div className="form-error">
                                <ErrorMessage name="bankAccount" />
                            </div>
                        </div>
                        <div className="col-span-6" />

                        {/* ///////////////////////////////////// view only ////////////////////// */}

                        <div className="col-span-4">
                            <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                Name
                            </label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                value={getFieldValue(values.bankAccount, 'name')}
                            />
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                IFSC
                            </label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                value={getFieldValue(values.bankAccount, 'ifsc')}
                            />
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                ACCOUNT HOLDER
                            </label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                value={getFieldValue(values.bankAccount, 'account.holderName')}
                            />
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                ACCOUNT NUMBER
                            </label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                value={getFieldValue(values.bankAccount, 'account.number')}
                            />
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                ACCOUNT TYPE
                            </label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                value={getFieldValue(values.bankAccount, 'account.type')}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 mt-3">
                        <div className="col-span-8">
                            <div className="mt-4">
                                <label className="text-xs mb-2 " htmlFor="transactionRemark">
                                    REMARKS
                                </label>
                                <Field
                                    as="textarea"
                                    id="name"
                                    name="transactionRemark"
                                    className="form-control"
                                    rows="5"
                                />
                                <div className="form-error">
                                    <ErrorMessage name="transactionRemark" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoaderButton
                        type="submit"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4"
                        isLoading={transactionMutation.isLoading}
                    >
                        Submit
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default BankTransactionForm;
