import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { IAgencyUser } from 'app/types';
import React, { FC } from 'react';
import ChangePasswordForm from './components/ChangePasswordForm/ChangePasswordForm';
import ProfileEditForm from './components/ProfileEditForm/ProfileEditForm';

interface ProfileProps {}

const Profile: FC<ProfileProps> = (props) => {
    const query = useQuery<IAgencyUser>(['agentDetail'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/profile`);

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    return (
        <>
            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Profile</div>

                <ProfileEditForm profile={query.data} />
            </div>

            <div className="card px-6 pt-5 pb-10 mt-6">
                <div className="font-bold">Change Password</div>

                <ChangePasswordForm />
            </div>
        </>
    );
};

export default Profile;
