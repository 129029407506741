/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction } from 'react';

interface BookingDetailNavProps {
    activeTab: string;
    setActiveTab: Dispatch<SetStateAction<string>>;
}

const BookingDetailNav: FC<BookingDetailNavProps> = (props) => {
    const { activeTab, setActiveTab } = props;

    return (
        <ul className="flex text-base font-normal px-6 pt-6 pb-2">
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '1' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('1')}
                >
                    All Bookings
                </a>
            </li>
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '2' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('2')}
                >
                    Agency Bookings
                </a>
            </li>
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '3' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('3')}
                >
                    Sub Agency Series Bookings
                </a>
            </li>
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '4' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('4')}
                >
                    Manual Bookings
                </a>
            </li>
        </ul>
    );
};

export default BookingDetailNav;
