import { GatewayPaymentModeEnum } from 'app/enums/gateway-payment-mode.enum';
import { PaymentEnum } from 'app/enums/payment.enum';

export const getGatewayPaymentModeEnumLabel = (onlinePaymentMode: GatewayPaymentModeEnum): string => {
    let label: string;

    switch (onlinePaymentMode) {
        case GatewayPaymentModeEnum.CREDIT_CARD:
            label = 'Credit Card';
            break;
        case GatewayPaymentModeEnum.DEBIT_CARD:
            label = 'Debit Card';
            break;
        case GatewayPaymentModeEnum.NET_BANKING:
            label = 'Net Banking';
            break;
        case GatewayPaymentModeEnum.UPI:
            label = 'UPI';
            break;
    }
    return label;
};

export const getPaymentEnumLabel = (paymentMode: PaymentEnum) => {
    let label: string;

    switch (paymentMode) {
        case PaymentEnum.SUB_AGENCY_WALLET:
            label = 'Sub Agency wallet';
            break;
        case PaymentEnum.RAZORPAY_GATEWAY:
            label = 'Razor Pay';
            break;
        case PaymentEnum.BANK_TRANSFER:
            label = 'Net Banking';
            break;
        case PaymentEnum.CHEQUE_OR_CASH:
            label = 'Cheque/Cash';
            break;
        case PaymentEnum.CCAVENUE_GATEWAY:
            label = 'CC Avenue';
            break;
        case PaymentEnum.HDFC_GATEWAY:
            label = 'HDFC';
            break;
    }
    return label;
};
