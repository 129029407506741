import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { EditPencilIcon } from 'app/icons';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import ChangeSeatsForm from './components/ChangeSeatsForm/ChangeSeatsForm';
import SeriesInventoryForm from './components/SeriesInventoryForm/SeriesInventoryForm';

interface SeriesInventoryEditProps {}

const SeriesInventoryEdit: FC<SeriesInventoryEditProps> = (props) => {
    const { inventoryId } = useParams();

    const query = useQuery(['seriesInventoryTicket'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${inventoryId}`);

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    const ticket = query.data;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Edit Series Inventory</div>
                    <div className="">
                        <label className="text-xs">Markup</label>
                        <div className="font-semibold flex gap-4">
                            ₹ {ticket.markup}
                            <Link to={`/series-inventory/${ticket._id}/revise-markup`}>
                                <EditPencilIcon />
                            </Link>
                        </div>
                    </div>
                </div>

                <SeriesInventoryForm ticket={ticket} />
            </div>

            <div className="card px-6 pt-5 pb-8 mt-6">
                <div className="font-bold">Edit Availability</div>

                <ChangeSeatsForm ticketId={ticket._id} totalSeats={ticket.totalSeats} />
            </div>
        </>
    );
};

export default SeriesInventoryEdit;
