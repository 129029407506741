import { FC } from 'react';
import http from 'app/config/http';
import { IAgency } from 'app/types';
import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import FareRulesEditor from './components/FareRulesEditor/FareRulesEditor';
import EditSettingsForm from './components/EditSettingsForm/EditSettingsForm';
import AgencySettingForm from './components/AgencySettingForm/AgencySettingForm';
import PageTitle from 'app/components/PageTitle/PageTitle';
import useNavTab from 'app/hooks/use-nav-tab';
import { getAgencyDetails } from 'app/api/services/agency.service';
import AgencySettingsNav from './components/AgencySettingsNav/AgencySettingsNav';
import AgencyBankList from './components/AgencyBankList/AgencyBankList';

interface AgencySettingsProps {}

const AgencySettings: FC<AgencySettingsProps> = (props) => {
    const [activeTab, setActiveTab] = useNavTab('profile', {
        queryName: 'view',
    });
    const { data: agencyDetails, isLoading } = useQuery<IAgency>(['agencyDetail'], getAgencyDetails);

    if (isLoading) return <PageLoader />;

    return (
        <>
            <PageTitle title="Agency Settings" />

            <div className="card px-6 pb-10">
                <AgencySettingsNav activeTab={activeTab} setActiveTab={setActiveTab} />

                {activeTab === 'profile' && <EditSettingsForm agency={agencyDetails} />}
                {activeTab === 'bank' && <AgencyBankList banks={agencyDetails.banks} />}
                {activeTab === 'config' && (
                    <div>
                        <AgencySettingForm />
                        <FareRulesEditor />
                    </div>
                )}
            </div>
        </>
    );
};

export default AgencySettings;
