// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrintLayoutWrapper_page-wrapper__PnOX9 {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n  width: 643px;\n  border-width: 1px;\n}\n\n@page {\n  size: A4;\n  margin: 0;\n}\n@media print {\n  .PrintLayoutWrapper_page-wrapper__PnOX9 {\n    margin: 0px;\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    border-style: none;\n  }\n  .PrintLayoutWrapper_print-btn-wrapper__YWVpG {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/app/components/PrintLayoutWrapper/PrintLayoutWrapper.module.scss"],"names":[],"mappings":"AACI;EAAA,iBAAA;EAAA,kBAAA;EAAA,kBAAA;EAAA,qBAAA;EAAA,YAAA;EAAA;AAAA;;AAGJ;EACI,QAAA;EACA,SAAA;AACJ;AAEA;EAEQ;IAAA,WAAA;IAAA,iBAAA;IAAA,kBAAA;IAAA,WAAA;IAAA;EAAA;EAGJ;IACI,aAAA;EADN;AACF","sourcesContent":[".page-wrapper {\n    @apply w-[643px] border mx-auto my-6;\n}\n\n@page {\n    size: A4;\n    margin: 0;\n}\n\n@media print {\n    .page-wrapper {\n        @apply w-full border-none mx-auto m-0;\n    }\n\n    .print-btn-wrapper {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-wrapper": "PrintLayoutWrapper_page-wrapper__PnOX9",
	"print-btn-wrapper": "PrintLayoutWrapper_print-btn-wrapper__YWVpG"
};
export default ___CSS_LOADER_EXPORT___;
