import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'app/components/Spinner/Spinner';
import { CommissionPlan, getCommissionPlansOfSubAgency } from 'app/api/services/sub-agency.service';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { CrossIcon } from 'app/icons';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { produce } from 'immer';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import useDialog from 'app/hooks/use-dialog';
import { FC } from 'react';

interface ISubAgencyCommissionPlanListProps {
    subAgency: string;
}
const SubAgencyCommissionPlanList: FC<ISubAgencyCommissionPlanListProps> = (props) => {
    const { subAgency } = props;
    const { subAgencyId } = useParams();
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const confirmationDialog = useDialog({ commissionPlansId: '', commissionPlanName: '' });

    const { data: commissionPlans, isLoading } = useQuery(['SUB-AGENCY', subAgencyId, 'COMMISSION-PLANS'], () =>
        getCommissionPlansOfSubAgency(subAgencyId)
    );

    const handleRemoveFromCommissionPlan = async (commissionPlanId: string) => {
        queryClient.setQueryData<CommissionPlan[]>(['SUB-AGENCY', subAgencyId, 'COMMISSION-PLANS'], (prevData) => {
            const index = prevData.findIndex((p) => p._id === commissionPlanId);
            return produce(prevData, (draft) => {
                draft.splice(index, 1);
                return draft;
            });
        });

        try {
            await removeFromCommissionPlanMutation.mutateAsync({
                commissionPlanId,
                body: {
                    operation: 'DETACH',
                },
            });
            toast.success('Commission plan removed successfully');
        } catch (e) {
            toast.error('Something went wrong');
        } finally {
            queryClient.fetchQuery(['SUB-AGENCY', subAgencyId, 'COMMISSION-PLANS']);
            confirmationDialog.hide();
        }
    };

    const removeFromCommissionPlanMutation = useMutation(async (payload: any) => {
        const { data } = await http.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans/${payload.commissionPlanId}/sub-agencies/${subAgencyId}`,
            { ...payload.body }
        );
        return data;
    });

    if (isLoading)
        return (
            <div className='min-h-[320px] flex items-center justify-center  '>
                <Spinner />
            </div>
        );

    return (
        <div className='overflow-auto  min-h-[320px] px-4'>
            {/* <div className='text-sm font-semibold'>Commission Plans</div> */}

            <div className='flex justify-end px-6 pt-6'>
                <button
                    onClick={() => navigate('/fare-management/commission-plan')}
                    className='text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56'
                >
                    All Commission Plans
                </button>
            </div>

            <table className='card-table text-sm mt-6'>
                <thead>
                    <tr className='bg-gray-50'>
                        <th className='th-1'>Commission Plan</th>
                        <th className='th-1'>Fare Supplier</th>
                        <th className='th-1'></th>
                    </tr>
                </thead>
                <tbody>
                    {commissionPlans.map((row) => (
                        <tr key={row._id} className='border-b border-gray-200 last:border-none'>
                            <td className='td-1'>
                                <div className='font-semibold'>
                                    <Link className='text-blue-500' to={`/fare-management/commission-plan/${row._id}`}>
                                        {row.name}
                                    </Link>
                                </div>
                            </td>
                            <td className='td-1'>
                                <div className='font-semibold'>
                                    {createReadableFareSupplierLabel(row.commissionFareSupplier)}
                                </div>
                            </td>
                            <td>
                                <button
                                    onClick={() =>
                                        confirmationDialog.show({
                                            commissionPlansId: row._id,
                                            commissionPlanName: row.name,
                                        })
                                    }
                                    className='cursor-pointer  rounded-full w-10 h-10 flex items-center justify-center hover:bg-red-100 '
                                >
                                    <CrossIcon className='text-red-500' />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <DeleteModal
                message={
                    <span>
                        Are you sure to want to detach <b> {subAgency}</b> from{' '}
                        <b> {confirmationDialog.data.commissionPlanName} </b>
                    </span>
                }
                onClose={confirmationDialog.hide}
                onConfirm={() => handleRemoveFromCommissionPlan(confirmationDialog.data.commissionPlansId)}
                show={confirmationDialog.isOpen}
                actionLabel='Proceed'
                isLoading={removeFromCommissionPlanMutation.isLoading}
            />
        </div>
    );
};

export default SubAgencyCommissionPlanList;
