import { toast } from 'react-toastify';
import { FC, ReactNode, useEffect, useState } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import { Link, Navigate, useParams } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { commissionPlanBondingEnum } from 'app/enums/commission-plan-bonding-enum';
import { toggleCommissionPlanBonding } from 'app/api/services/fare-commission.service';

import useCommissionAssociation, {
    ICommissionPlanSubAgencyWithPlanCheck,
} from 'app/modules/shared/hooks/use-commission-association';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import { WarningIcon } from 'app/icons';

const CommissionPlanAssociation = () => {
    const { id: commissionPlanId } = useParams();

    const { subAgencies, commissionPlan, loading, error } = useCommissionAssociation(commissionPlanId);

    if (loading) return <PageLoader />;

    if (error) return <Navigate to='/404' replace />;

    return (
        <div className=''>
            <div className='mb-5'>
                <BackLink />
            </div>
            <h5 className='font-bold'>Attach Commission Plan</h5>

            <div className='card mt-8 p-6'>
                <div className='font-bold'>Commission Plan Details </div>
                <div className='grid grid-cols-12 gap-6 mt-8'>
                    <div className='col-span-4'>
                        <label className='text-xs mb-2'>Name</label>
                        <div className='font-semibold py-2'>{commissionPlan.name}</div>
                    </div>
                    <div className='col-span-4'>
                        <label className='text-xs mb-2'>Fare Supplier</label>
                        <div className='font-semibold py-2 flex gap-2'>
                            {' '}
                            {createReadableFareSupplierLabel(commissionPlan.commissionFareSupplier)}
                        </div>
                    </div>

                    {/* <div className='col-span-4'>
                            <label className='text-xs mb-2'>GST Number</label>
                            <div className='font-semibold py-2 flex gap-2'>{reqAgency.subAgencyDetails.gst.number}</div>
                        </div>
                        <div className='col-span-4'>
                            <label className='text-xs mb-2'>PAN Number</label>
                            <div className='font-semibold py-2 flex gap-2'>{reqAgency.subAgencyDetails.pan.number}</div>
                        </div>
                        <div className='col-span-4'>
                            <label className='text-xs mb-2'>Agency Email</label>
                            <div className='font-semibold py-2 flex gap-2'>{reqAgency.subAgencyDetails.email}</div>
                        </div> */}
                </div>

                <div className='mt-8'>
                    <div className='grid grid-cols-12 gap-4 mb-4 text-sm font-light '>
                        <div className='col-span-1'>#</div>
                        <div className='col-span-2'>ID</div>
                        <div className='col-span-7'>Name</div>
                        <div className='col-span-2'>Action</div>
                    </div>
                    {subAgencies.map((subAgency, index) => (
                        <div className='grid grid-cols-12 gap-4 text-lg font-semibold mb-4' key={subAgency._id}>
                            <div className='col-span-1'> {index + 1} </div>
                            <div className='col-span-2'>
                                <Link className='text-blue-500' to={`/sub-agencies/${subAgency._id}/detials?tab=1`}>
                                    {subAgency._id}
                                </Link>
                            </div>
                            <div className='col-span-7'> {subAgency.name}</div>
                            <div className='col-span-2'>
                                <ToggleInput subAgency={subAgency} commissionPlan={commissionPlan} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

interface IToggleInput {
    subAgency: ICommissionPlanSubAgencyWithPlanCheck;
    commissionPlan: any;
}

const ToggleInput: FC<IToggleInput> = (props) => {
    const queryClient = useQueryClient();
    const confirmationDialog = useDialog();
    const { subAgency, commissionPlan } = props;
    const [isChecked, setIsChecked] = useState(false);

    if (subAgency._id === 'AUX0434' && 'AUX6762C-SA' === commissionPlan) {
        console.log({ disabled: subAgency });
    }

    const booleanToAttachedStatus = (isAttached: boolean): commissionPlanBondingEnum =>
        isAttached ? commissionPlanBondingEnum.ATTACH : commissionPlanBondingEnum.DETACH;

    const attachedToBoolean = (isAttached: commissionPlanBondingEnum): boolean =>
        isAttached === commissionPlanBondingEnum.ATTACH ? true : false;

    useEffect(() => {
        setIsChecked(subAgency.isAttached);
    }, []);

    const toggleMutation = useMutation(async (operation: commissionPlanBondingEnum) => {
        const data = await toggleCommissionPlanBonding({
            operation,
            commissionPlanId: commissionPlan?._id as string,
            SubAgencyId: subAgency._id,
        });

        return data;
    });

    const actionSelector = () => {
        if (subAgency.disable) {
            confirmationDialog.show();
        } else {
            handleToggle();
        }
    };

    const handleToggle = async () => {
        setIsChecked((prev) => !prev);
        try {
            const { subAgency: recSubAgency } = await toggleMutation.mutateAsync(
                booleanToAttachedStatus(!subAgency.isAttached)
            );
            queryClient.fetchQuery(['COMMISSION', 'PLANS', 'USERS']);
            queryClient.fetchQuery(['COMMISSION', 'PLANS', commissionPlan?._id]);
        } catch (e) {
            setIsChecked((prev) => !prev);
            toast.error('Something went wrong');
        }
    };

    return (
        <>
            <input
                type='checkbox'
                className={`form-switch success disabled:bg-gray-100 ${subAgency.disable && 'bg-gray-100'}`}
                checked={isChecked}
                // disabled={subAgency.disable}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggle()}
                onChange={actionSelector}
            />
            {confirmationDialog.isOpen && (
                <ToggleConfirmationModal
                    show={confirmationDialog.isOpen}
                    message={
                        <div>
                            <span className='font-bold text-base '>{subAgency.name}</span> is attached to{' '}
                            <span className='font-bold text-base '>{subAgency.currentPlan}</span> commission plan. If
                            you proceed, <span className='font-bold text-base '>{subAgency.name}</span> will be migrated
                            to <span className='font-bold text-base '>{commissionPlan.name}</span> commission plan
                        </div>
                    }
                    onClose={confirmationDialog.hide}
                    onConfirm={() => {
                        handleToggle();
                        confirmationDialog.hide();
                    }}
                />
            )}
        </>
    );
};

interface InfoModalProps {
    show: boolean;
    message: ReactNode;
    onClose: () => void;
    onConfirm: () => void;
}

const ToggleConfirmationModal: FC<InfoModalProps> = (props) => {
    return (
        <Modal className='px-10 py-10' show={props.show} onClose={props.onClose}>
            <div className='flex items-center justify-center gap-3 mt-4'>
                <WarningIcon height={28} className='text-yellow-500' />
            </div>
            <div className='text-gray-900 text-center mt-4 max-w-md'>{props.message}</div>
            <div className='flex justify-center gap-4 mt-8'>
                <button className='bg-danger text-white px-6 py-2 text-sm rounded-lg' onClick={props.onConfirm}>
                    Proceed
                </button>
                <button className='bg-gray-200 text-gray-600 px-6 py-2 text-sm rounded-lg' onClick={props.onClose}>
                    Close
                </button>
            </div>
        </Modal>
    );
};



export default CommissionPlanAssociation;
