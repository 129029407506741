import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import { getFareSupplierFareComponentKeys } from 'app/modules/shared/utils/plan-commission.utils';
import { getTripJacKFareComponentLabel } from 'app/modules/shared/utils/trip-jack-supplier.utils';
import { getCommissionPlanById, updateCommissionPlan } from 'app/api/services/fare-commission.service';
import { tripJackCommissionComponentEnum } from 'app/enums/commission-component-trip-jack';
import TripjackEditForm from './components/TripjackEditForm';
import TravelBoutiqueEditForm from './components/TravelBoutiqueEditForm';

const planEditSchema = Yup.object({
    name: Yup.string().trim().required('This field is required'),
    commissionBreakdown: Yup.object({
        NCM: Yup.object({
            flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
            percentage: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
    }),
    managementChargesPerPassenger: Yup.number().min(0).required('This field is required'),
    taxRates: Yup.object({
        gstRate: Yup.number()
            .required('This field is required')
            .min(0, 'The minimum value you can enter is 0.')
            .max(100, 'The maximum value you can enter is 100.'),
        commissionTdsRate: Yup.number()
            .required('This field is required')
            .min(0, 'The minimum value you can enter is 0.')
            .max(100, 'The maximum value you can enter is 100.'),
    }),
});

export type ITripJackCommission = {
    name: string;
    commissionPlanType: string;
    commissionFareSupplier: string;
    managementChargesPerPassenger: number;
    taxRates: {
        gstRate: number;
        commissionTdsRate: number;
    };
    commissionBreakdown: {
        [K in tripJackCommissionComponentEnum]: {
            flat: number;
            percentage: number;
        };
    };
};

export const CommissionPlanEdit = () => {
    const { id } = useParams();
    const { isLoading, data } = useQuery(['COMMISSiON_PLAN', id], () => getCommissionPlanById(id));

    if (isLoading) return <PageLoader />;

    if (data.commissionFareSupplier === CommissionPlanEnabledSuppliers.TRIP_JACK)
        return <TripjackEditForm data={data} />;

    if (data.commissionFareSupplier === CommissionPlanEnabledSuppliers.TRAVEL_BOUTIQUE)
        return <TravelBoutiqueEditForm data={data} />;
};

export default CommissionPlanEdit;


