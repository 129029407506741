import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import { TRIP_JACK_FARE_COMPONENTS } from '../constants/trip-jack-fare-component';
// import { CommissionPlanEnabledSuppliers } from '../enum/commission-plan-enabled-suppliers.enum';

export const getFareSupplierFareComponents = (fareSupplier: CommissionPlanEnabledSuppliers) => {
    switch (fareSupplier) {
        case CommissionPlanEnabledSuppliers.TRIP_JACK:
            return TRIP_JACK_FARE_COMPONENTS;
    }
};

export const getFareComponentLabel = (fareSupplier: CommissionPlanEnabledSuppliers, fareComponentKey: string) => {
    const fareComponents = getFareSupplierFareComponents(fareSupplier);
    const tmpFareComponent = fareComponents.find((fareComponent) => fareComponent.code === fareComponentKey);
    if (!tmpFareComponent) return '';
    return tmpFareComponent.description;
};

export const getFareSupplierFareComponentKeys = (fareSupplier: CommissionPlanEnabledSuppliers) => {
    const fareComponents = getFareSupplierFareComponents(fareSupplier);
    return fareComponents.map((fareComponent) => fareComponent.code);
};
