/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
} from '@floating-ui/react-dom-interactions';
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IDropdownContextData {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}
const DropdownContext = createContext<IDropdownContextData>({
    open: false,
    setOpen: () => {},
});

interface DropdownProps {
    target: ReactNode;
    menu: ReactNode;
}
export const Dropdown: FC<DropdownProps> = (props) => {
    const { target, menu } = props;

    const [open, setOpen] = useState<boolean>(false);

    const { context, x, y, reference, floating, strategy } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: 'bottom-end',
        middleware: [offset(4), flip(), shift()],
        open: open,
        onOpenChange: setOpen,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useDismiss(context)]);

    return (
        <DropdownContext.Provider value={{ open, setOpen }}>
            <div className="cursor-pointer" ref={reference} {...getReferenceProps()}>
                {target}
            </div>

            <FloatingPortal>
                {open && (
                    <div
                        ref={floating}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            width: 'max-content',
                            zIndex: 999,
                        }}
                        {...getFloatingProps()}
                    >
                        {menu}
                    </div>
                )}
            </FloatingPortal>
        </DropdownContext.Provider>
    );
};

interface DropdownListProps {
    children: ReactNode;
}
export const DropdownList: FC<DropdownListProps> = (props) => {
    return <ul className="bg-white rounded-lg drop-shadow py-3 min-w-[120px]">{props.children}</ul>;
};

interface DropdownItemProps {
    children: ReactNode;
    to?: string;
    onClick?: (e: any) => void;
}
export const DropdownItem: FC<DropdownItemProps> = (props) => {
    const { onClick, to } = props;

    const navigate = useNavigate();
    const { setOpen } = useContext<IDropdownContextData>(DropdownContext);

    const handleClick = (e: any) => {
        if (to) {
            navigate(to);
        }

        if (onClick) {
            onClick(e);
        }

        setOpen(false);
    };

    return (
        <li>
            <a className="block text-sm text-gray-900 px-5 py-2 cursor-pointer hover:bg-gray-100" onClick={handleClick}>
                {props.children}
            </a>
        </li>
    );
};
