import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { IStaticPageForm } from '../../../types';
import HtmlEditor from '../../../components/HtmlEditor/HtmlEditor';
import { IStaticPage } from 'app/types';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const staticPageFormSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required('This field is required')
        .matches(/^[-_ a-zA-Z0-9]+$/, 'Please enter a valid name'),
    content: Yup.string().trim().required('This field is required'),
});
interface StaticPageFormProps {
    staticPage: IStaticPage;
}

const StaticPageForm: FC<StaticPageFormProps> = (props) => {
    const { staticPage } = props;
    const navigate = useNavigate();

    const staticPageUpdateMutation = useMutation(async (values: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/static-page/${staticPage._id}`, {
            ...values,
        });

        return data;
    });

    const generateRouteName = (pageName: string) => {
        let routeName = pageName.trim().toLowerCase();
        routeName = routeName.replace(/ /g, '-');
        return `/static/${routeName}`;
    };

    const initialValues: IStaticPageForm = {
        name: staticPage.name,
        content: staticPage.content,
    };

    const handleSubmit = async (values: IStaticPageForm, formikHelpers: FormikHelpers<IStaticPageForm>) => {
        const newValues = {
            ...staticPage,
            ...values,
            route: generateRouteName(values.name),
        };

        try {
            await staticPageUpdateMutation.mutateAsync(newValues);
            // queryClient.fetchQuery(refetchQueryIdentifier);
            toast.success('Changes saved successfully.');
            navigate('/static-pages');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <div className="mt-8">
            <Formik
                initialValues={initialValues}
                validationSchema={staticPageFormSchema}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({ values, errors }: FormikProps<IStaticPageForm>) => (
                    <Form>
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="name">
                                    Page
                                </label>
                                <Field type="text" id="name" name="name" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="mt-6">
                                    <HtmlEditor />
                                </div>
                            </div>
                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                isLoading={staticPageUpdateMutation.isLoading}
                            >
                                Save Page
                            </LoaderButton>
                            {/* <button onClick={() => console.log({ values, errors })}> data check</button> */}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default StaticPageForm;
