/* eslint-disable jsx-a11y/anchor-is-valid */
import { ISubAgency } from 'app/types';
import { FC, useLayoutEffect, useRef } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';
import { MenuDotIcon } from 'app/icons';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import moment from 'moment';
import useDialog from 'app/hooks/use-dialog';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import update from 'immutability-helper';
import http from 'app/config/http';
import InfoModal from 'app/components/InfoModal/InfoModal';
import Spinner from 'app/components/Spinner/Spinner';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

interface SubAgencyTableProps {
    subAgencies: ISubAgency[];
    isLoading: boolean;
    queryIdentifier: any[];
}

const SubAgencyTable: FC<SubAgencyTableProps> = (props) => {
    const { subAgencies, isLoading, queryIdentifier } = props;

    const navigate = useNavigate();
    const tableTop = useRef();

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const queryClient = useQueryClient();
    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();

    const deleteMutation = useMutation(async (values: any) => {
        // delete api here
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${values._id}`);
    });

    const getAvailableBalance = (availableCredit: number, availableCashBalance: number) => {
        let availableBalance = availableCredit + availableCashBalance;

        if (availableBalance < 0) {
            availableBalance = 0;
        }

        return availableBalance;
    };

    const getAmountDue = (creditLimit: number, availableCredit: number, availableCashBalance: number) => {
        let amountDue = creditLimit - availableCredit - availableCashBalance;

        if (amountDue < 0) {
            amountDue = 0;
        }

        return amountDue;
    };

    const handleUserDelete = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Sub Agency deleted successfully.');

            const index = subAgencies.findIndex((r) => r._id === _id);

            queryClient.setQueryData(queryIdentifier, (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                });
            });
        } catch (ex: any) {
            console.log(ex);
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50" ref={tableTop}>
                        <th className="th-1">Agency ID</th>
                        <th className="th-1">Agency Name & Address</th>
                        <th className="th-1">Agency Users</th>
                        <th className="th-1">Bookings this month</th>
                        <th className="th-1">Total Bookings</th>
                        <th className="th-1">Last Active</th>
                        <th className="th-1">Balances</th>
                        <th className="th-1">Created At</th>
                        <th className="th-1">Status</th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && subAgencies.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        subAgencies.map((row, i) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div
                                        className="font-semibold text-blue-500 cursor-pointer"
                                        onClick={() => navigate(`/sub-agencies/${row._id}/detials`)}
                                    >
                                        {row._id}
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.name}</div>
                                    <p className="mt-1">
                                        {row.address.city}, {row.address.state}
                                    </p>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.subAgencyUserCount}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.currentMonthBookingCount}</div>
                                </td>
                                <td className="td-1">
                                    <div className="font-semibold">{row.totalBookingCount}</div>
                                </td>
                                <td className="td-1">
                                    {row.lastActive ? (
                                        <div className="min-w-max">
                                            <p>{moment(row.lastActive, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.lastActive, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className="td-1">
                                    <div className="flex items-center gap-3 min-w-max">
                                        <p className="w-16">Cr Limit:</p>
                                        <div className="font-semibold">{formatCurrency(row.creditLimit)}</div>
                                    </div>

                                    <div className="flex items-center gap-3 min-w-max">
                                        <p className="w-16">Avl Bal:</p>
                                        <div className="font-semibold text-green-500">
                                            {formatCurrency(
                                                getAvailableBalance(row.availableCredit, row.availableCashBalance)
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-3 min-w-max">
                                        <p className="w-16">Amt Due:</p>
                                        <div className="font-semibold text-red-500">
                                            {formatCurrency(
                                                getAmountDue(
                                                    row.creditLimit,
                                                    row.availableCredit,
                                                    row.availableCashBalance
                                                )
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <div className="min-w-max">
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <StatusBadge color={row.isEnabled ? 'green' : 'red'}>
                                        {row.isEnabled ? 'Active' : 'Inactive'}
                                    </StatusBadge>
                                </td>

                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            <a className="dropdown-action">
                                                <MenuDotIcon />
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/sub-agencies/${row._id}/detials`}>
                                                    View
                                                </DropdownItem>
                                                <DropdownItem to={`/sub-agencies/${row._id}/users`}>Users</DropdownItem>
                                                <DropdownItem to={`/sub-agencies/${row._id}/edit`}>Edit</DropdownItem>
                                                <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                    <span className="text-red-500">Delete</span>
                                                </DropdownItem>
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}
            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this subagency?'}
                onClose={deleteDialog.hide}
                onConfirm={handleUserDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </div>
    );
};

export default SubAgencyTable;
