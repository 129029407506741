import React, { useState, useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import Autosuggest, { InputProps } from 'react-autosuggest';
import { useQuery } from '@tanstack/react-query';
import theme from 'styles/auto-suggestion-overrides.module.scss';

interface SearchComponentProps<T> {
    fetchSuggestions: (query: string) => Promise<T[]>;
    setField: (item: T) => void;
    selectedItem: T | null;
    placeholder: string;
    initialSuggestions: T[];
    getSuggestionValue: (suggestion: T) => string;
    renderSuggestion: (suggestion: T) => React.ReactNode;
    clearInputOnSelection?: boolean;
}

const SearchComponent = <T,>({
    fetchSuggestions,
    setField,
    selectedItem,
    placeholder,
    initialSuggestions,
    getSuggestionValue,
    renderSuggestion,
    clearInputOnSelection,
}: SearchComponentProps<T>) => {
    const [value, setValue] = useState(selectedItem ? getSuggestionValue(selectedItem) : '');
    const [suggestions, setSuggestions] = useState<T[]>([]);
    const [firstFocus, setFirstFocus] = useState(true);
    const searchRef = useRef();

    useEffect(() => {
        if (selectedItem) {
            setValue(getSuggestionValue(selectedItem));
        }
    }, [selectedItem, getSuggestionValue]);

    const debouncedFetchSuggestions = useCallback(
        debounce((query) => {
            refetch();
        }, 300),
        []
    );

    const { refetch } = useQuery(['suggestions', value], () => fetchSuggestions(value), {
        enabled: false,
        onSuccess: (data) => {
            setSuggestions(data);
        },
    });

    const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
        setValue(value);
        if (firstFocus) {
            setSuggestions(initialSuggestions);
            setFirstFocus(false);
        } else {
            debouncedFetchSuggestions(value);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onChange = (event: any, { newValue }: { newValue: string }) => {
        setValue(newValue);
    };

    const onSuggestionSelected = (event: any, { suggestion }: { suggestion: T }) => {
        setField(suggestion);
        if (clearInputOnSelection) {
            setValue('');
        }
    };

    const inputProps: InputProps<T> = {
        placeholder,
        value,
        onChange,
        onFocus: () => {
            if (firstFocus) {
                setSuggestions(initialSuggestions);
            }
        },
        onBlur: () => {
            setValue(selectedItem ? getSuggestionValue(selectedItem) : '');
        },
    };

    return (
        <Autosuggest
            ref={searchRef}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected}
            theme={theme}
        />
    );
};

export default SearchComponent;
