import { FareSupplierAirlineFilterStatusEnum } from 'app/enums/fare-supplier-airline-filter-status.enum';
import { IAirline } from 'app/types';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import AirlineSearchField from '../AirlineSearchField/AirlineSearchField';
import { CrossIcon } from 'app/icons';
import LoaderButton from '../LoaderButton/LoaderButton';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getFareSupplierAirlineFilter, updateFareSupplierAirlineFilter } from 'app/api/services/fare-supplier.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import Spinner from '../Spinner/Spinner';
import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';
import { toast } from 'react-toastify';
import SearchComponent from '../SearchComponent/SearchComponent';
import { getAirlines } from 'app/api/services/airline.service';

type IFareSupplierAirlineFilterRequest = {
    status: FareSupplierAirlineFilterStatusEnum;
    includeAirlines: Array<IAirline>;
    excludeAirlines: Array<IAirline>;
};

const filterType = [
    {
        label: 'None',
        value: FareSupplierAirlineFilterStatusEnum.NONE,
    },
    {
        label: 'Enable Only',
        value: FareSupplierAirlineFilterStatusEnum.ENABLE_ONLY,
    },
    {
        label: 'Disable Only',
        value: FareSupplierAirlineFilterStatusEnum.DISABLE_ONLY,
    },
];
interface IFareSupplierAirlineFilter {
    fareSupplier: FareSupplierIdEnum;
}

const FareSupplierAirlineFilter: FC<IFareSupplierAirlineFilter> = (props) => {
    const { fareSupplier } = props;
    const queryClient = useQueryClient();

    const getAirlineMutation = useMutation((query: string) => getAirlines(query));

    const getAirlineSuggestion = async (query: string) => {
        try {
            const airlines = await getAirlineMutation.mutateAsync(query);
            return airlines;
        } catch (e) {}
    };

    const { data: apiRes, isLoading } = useQuery(['FARE_SUPPLIER', 'AIRLINE_FILTER', fareSupplier], () =>
        getFareSupplierAirlineFilter(fareSupplier)
    );

    const updateAirlineFilterMutation = useMutation(
        (payload: { payload: IFareSupplierAirlineFilterRequest; fareSupplier: FareSupplierIdEnum }) =>
            updateFareSupplierAirlineFilter(payload)
    );

    const handleUpdate = (
        values: IFareSupplierAirlineFilterRequest,
        helpers: FormikHelpers<IFareSupplierAirlineFilterRequest>
    ) => {
        try {
            updateAirlineFilterMutation.mutateAsync({ fareSupplier, payload: values });
            toast.success('Airline Filter Updated Successfully');
            queryClient.fetchQuery(['FARE_SUPPLIER', 'AIRLINE_FILTER', fareSupplier]);
        } catch (e) {
            toast.error('Something went wrong');
        }
    };

    if (isLoading)
        return (
            <div className="card mt-8 px-6 py-5">
                <div className="font-bold">Airline Filter</div>
                <div className="flex items-center justify-center h-28">
                    <Spinner />
                </div>
            </div>
        );

    const initialValues: IFareSupplierAirlineFilterRequest = {
        status: apiRes.status,
        includeAirlines: apiRes.includeAirlines,
        excludeAirlines: apiRes.excludeAirlines,
    };

    return (
        <div className="card mt-8 px-6 py-5">
            <div className="font-bold">Airline Filter</div>

            <div className="mt-8">
                <Formik initialValues={initialValues} onSubmit={handleUpdate}>
                    {({ values }: FormikProps<IFareSupplierAirlineFilterRequest>) => (
                        <Form>
                            <div className="grid grid-cols-12">
                                <div className="col-span-6">
                                    <label className="text-xs mb-2" htmlFor="status">
                                        Filter Type
                                    </label>
                                    <Field as="select" name="status" type="text" id="status" className="form-control">
                                        {filterType.map((airlineFilter) => (
                                            <option key={airlineFilter.value} value={airlineFilter.value}>
                                                {airlineFilter.label}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className="form-error">
                                        <ErrorMessage name="status" />
                                    </div>
                                </div>
                            </div>

                            {/* excluded Airlines */}
                            {(values.status === FareSupplierAirlineFilterStatusEnum.DISABLE_ONLY ||
                                values.status === FareSupplierAirlineFilterStatusEnum.ENABLE_ONLY) && (
                                <FieldArray
                                    name={
                                        values.status === FareSupplierAirlineFilterStatusEnum.ENABLE_ONLY
                                            ? 'includeAirlines'
                                            : 'excludeAirlines'
                                    }
                                >
                                    {({ push, remove, handlePush }) => (
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-6">
                                                <label className="text-xs mb-2 label-required " htmlFor="flight">
                                                    Select Airline
                                                </label>
                                                <SearchComponent
                                                    fetchSuggestions={getAirlineSuggestion}
                                                    setField={(airline: IAirline) => push(airline)}
                                                    selectedItem={null}
                                                    placeholder="Search airline"
                                                    initialSuggestions={[]}
                                                    getSuggestionValue={(airline: IAirline) =>
                                                        `${airline.code} - ${airline.name}`
                                                    }
                                                    renderSuggestion={(airline: IAirline) => (
                                                        <div> {airline.name} </div>
                                                    )}
                                                    clearInputOnSelection={true}
                                                />
                                                {/* <AirlineSearchField
                                                    id="flight"
                                                    className="form-control"
                                                    placeholder={'Select Airline'}
                                                    selectedAirline={null}
                                                    onSelectedAirlineChange={(airline: IAirline) => push(airline)}
                                                    // onSelectedAirlineChange={(airline: IAirline) => handlePush()}
                                                /> */}
                                                <div className="form-error">
                                                    <ErrorMessage name="flight" />
                                                </div>
                                            </div>

                                            <div className="mt-6 col-span-12">
                                                <label className="text-xs mb-2 label-required " htmlFor="flight">
                                                    Selected Airlines
                                                </label>
                                                <div className="flex gap-2 border px-4 py-2 h-40">
                                                    {values[
                                                        values.status ===
                                                        FareSupplierAirlineFilterStatusEnum.ENABLE_ONLY
                                                            ? 'includeAirlines'
                                                            : 'excludeAirlines'
                                                    ].map((airline, index) => (
                                                        <div className="badge w-24 h-8 flex items-center justify-between bg-primary/90 text-white">
                                                            <div className="font-bold ">{airline.code}</div>
                                                            <div
                                                                className="cursor-pointer p-2"
                                                                onClick={() => remove(index)}
                                                            >
                                                                <CrossIcon width={10} height={10} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                            <LoaderButton
                                type="submit"
                                className="flex justify-center items-center h-12 px-4 bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                // isLoading={credentialUpdateMutation.isLoading}
                            >
                                Update
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>

                <div className="mt-12">
                    <div className="font-medium text-sm ">
                        {`Last Updated : ${moment(apiRes.updatedAt, 'YYYYMMDDHHmmss').format(
                            'DD MMM, YYYY'
                        )} at ${moment(apiRes.updatedAt, 'YYYYMMDDHHmmss').format('hh:mm A')} by  `}
                        <Link className="text-primary" to={`/users/${apiRes.updatedBy._id}/edit`}>
                            {apiRes.updatedBy.name}{' '}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FareSupplierAirlineFilter;
