import { formatCurrency } from 'app/utils/currency-utils';
import React, { FC } from 'react';

interface CurrencyProps {
    children: number;
}

const Currency: FC<CurrencyProps> = (props) => {
    const { children } = props;

    if (children > 0) return <span className="text-green-500">+ {formatCurrency(children)}</span>;
    else if (children < 0) return <span className="text-red-500">{formatCurrency(children)}</span>;
    else return <span>{formatCurrency(children)}</span>;
};

export default Currency;
