import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import {
    getFareComponentLabel,
    getFareSupplierFareComponentKeys,
    getFareSupplierFareComponents,
} from './plan-commission.utils';

export const getTripJackFareComponents = () => {
    return getFareSupplierFareComponents(CommissionPlanEnabledSuppliers.TRIP_JACK);
};

export const getTripJacKFareComponentLabel = (fareKey: string) => {
    return getFareComponentLabel(CommissionPlanEnabledSuppliers.TRIP_JACK, fareKey);
};

export const getTripJackFareComponentKeys = () => {
    getFareSupplierFareComponentKeys(CommissionPlanEnabledSuppliers.TRIP_JACK);
};
