import { useState } from 'react';

interface DialogData<T> {
    isOpen: boolean;
    data?: T;
}

const useDialog = <T = any>(initialValue: T = null) => {
    const [dialogData, setDialogData] = useState<DialogData<T>>({ isOpen: false, data: initialValue });

    const show = (data: T = null) => {
        setDialogData({ isOpen: true, data });
    };

    const hide = () => {
        setDialogData({ isOpen: false, data: initialValue });
    };

    return {
        show,
        hide,
        isOpen: dialogData.isOpen,
        data: dialogData.data,
    };
};

export default useDialog;
