import { bookingStatusEnum } from 'app/enums/booking-status.enum';

export const AMENDMENT_STATUS = Object.freeze({
    PENDING: 'PENDING',
    APPROVED_BY_SUB_AGENCY: 'APPROVED_BY_SUB_AGENCY',
    COMPLETED: 'COMPLETED', //means approved by agency
    AWAITING_SUB_AGENCY_APPROVAL: 'AWAITING_SUB_AGENCY_APPROVAL',
    AWAITING_AGENCY_APPROVAL: 'AWAITING_AGENCY_APPROVAL',
    REJECTED: 'REJECTED',
});

export const TRANSACTION_TYPES: { [x: string]: { label: string; value: string } } = Object.freeze({
    CREDIT: {
        label: 'Credit',
        value: 'CREDIT',
    },
    DEBIT: {
        label: 'Debit',
        value: 'DEBIT',
    },
});

export const BOOKING_STATUS_LIST = [
    { label: 'Pending', value: bookingStatusEnum.PENDING },
    { label: 'Processing', value: bookingStatusEnum.IN_PROCESS },
    { label: 'Completed', value: bookingStatusEnum.COMPLETED },
    { label: 'Cancelled', value: bookingStatusEnum.CANCELLED },
    { label: 'Submission Pending', value: bookingStatusEnum.SUBMISSION_PENDING },
    { label: 'Submitted', value: bookingStatusEnum.SUBMITTED },
    { label: 'Awaiting PNR', value: bookingStatusEnum.AWAITING_PNR },
    { label: 'Agent Aborted', value: bookingStatusEnum.SUB_AGENT_ABORT },
    { label: 'Aborted By you', value: bookingStatusEnum.AGENT_ABORT },
    { label: 'Failed', value: bookingStatusEnum.FAILED },
];

export const SUB_AGENCY_REQUEST_STATUS_LIST = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Completed', value: 'COMPLETED' },
];

export const MANUAL_BOOKING_STATUS_LIST = [
    { label: 'Pending', value: bookingStatusEnum.PENDING },
    { label: 'Awaiting PNR', value: bookingStatusEnum.AWAITING_PNR },
    { label: 'Completed', value: bookingStatusEnum.COMPLETED },
    { label: 'Cancelled', value: bookingStatusEnum.CANCELLED },
];
