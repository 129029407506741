import { FC } from 'react';
import BackLink from 'app/components/BackLink/BackLink';
import SubAgencyUserForm from './components/SubAgencyUserForm/SubAgencyUserForm';

interface SubAgencyUserAddProps {}

const SubAgencyUserAdd: FC<SubAgencyUserAddProps> = (props) => {
    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Add Sub Agency User</div>

                <SubAgencyUserForm />
            </div>
        </>
    );
};

export default SubAgencyUserAdd;
