import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import { EditPencilIcon } from 'app/icons';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import AddPnrModal from '../AddPnrModal/AddPnrModal';
import PassengerDetail from '../PassengerDetail/PassengerDetail';

interface TravellerDetailsProps {
    booking: any;
    isEditable?: boolean;
    openModal?: () => void;
}

const TravellerDetails: FC<TravellerDetailsProps> = (props) => {
    const { booking } = props;

    const queryClient = useQueryClient();
    const AddPnrDialog = useDialog();
    const abortDialog = useDialog();

    const abortTickerMutation = useMutation(async () => {
        const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/${booking.id}/abort`);

        return data;
    });

    const handleAbortTicker = async () => {
        try {
            await abortTickerMutation.mutateAsync();
            queryClient.invalidateQueries({ queryKey: ['bookingDetail'] });
        } catch (err: any) {
            toast.error(err?.data?.message || ' Some error occured while aborting the ticker, please try again');
        } finally {
            abortDialog.hide();
        }
    };

    const isInternational = () => booking.departure.airport.countryCode !== booking.arrival.airport.countryCode;

    return (
        <>
            <div className="card px-6 pt-5 pb-6 mt-6">
                <div className="flex justify-between items-center">
                    <div className="flex gap-4">
                        <div className="font-bold ">Traveller Details</div>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                        {booking.status === 'AWAITING_PNR' && (
                            <div className="flex gap-2">
                                <button
                                    onClick={abortDialog.show}
                                    className="text-center bg-red-500 text-white text-xs font-semibold px-6 py-2 rounded-md cursor-pointer "
                                >
                                    Abort
                                </button>

                                <button
                                    onClick={AddPnrDialog.show}
                                    className="text-center bg-primary text-white text-xs font-semibold px-4 py-2 rounded-md cursor-pointer"
                                >
                                    Add PNR
                                </button>
                            </div>
                        )}
                        {props.isEditable && (
                            <div className="hover:cursor-pointer" onClick={props.openModal}>
                                <EditPencilIcon />
                            </div>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-5 gap-8 mt-8 mb-4">
                    <div className="col-start-2 col-span-1">
                        <div className="text-xs text-gray-500">Name</div>
                    </div>
                    <div className="col-span-1">
                        <div className="text-xs text-gray-500">Airline PNR</div>
                    </div>
                    <div className="col-span-1">
                        <div className="text-xs text-gray-500">eTicket Number</div>
                    </div>
                    <div className="col-span-1">
                        <div className="text-xs text-gray-500">Seat Number</div>
                    </div>
                </div>

                {booking.passengers.adults.map((row: any, i: number) => (
                    <PassengerDetail index={i} row={row} type={'Adult'} key={i} isInternational={isInternational()} />
                ))}

                {booking.passengers.children.map((row: any, i: number) => (
                    <PassengerDetail index={i} row={row} type={'Child'} key={i} isInternational={isInternational()} />
                ))}

                {booking.passengers.infants.map((row: any, i: number) => (
                    <PassengerDetail index={i} row={row} type={'Infant'} key={i} isInternational={isInternational()} />
                ))}

                <div className="grid grid-cols-5 gap-8 mt-8 mb-4">
                    <div className="col-start-2 col-span-1">
                        <div className="text-xs text-gray-500">Mobile Number</div>
                    </div>
                    <div className="col-span-1">
                        <div className="text-xs text-gray-500">Email Address</div>
                    </div>
                </div>

                <div className="grid grid-cols-5 gap-8">
                    <div className="col-span-1">
                        <div className="font-medium">Contact</div>
                    </div>
                    <div className="col-span-1">
                        <div className="font-medium">{booking.contactDetails.mobileNumber}</div>
                    </div>
                    <div className="col-span-1">
                        <div className="font-medium">{booking.contactDetails.email}</div>
                    </div>
                </div>
            </div>
            {AddPnrDialog.isOpen && (
                <AddPnrModal onClose={AddPnrDialog.hide} show={AddPnrDialog.isOpen} booking={booking} />
            )}
            <DeleteModal
                show={abortDialog.isOpen}
                message={'Are you sure you want to abort this ticket?'}
                onClose={abortDialog.hide}
                onConfirm={handleAbortTicker}
                actionLabel={'Abort'}
                // isLoading={deleteMutation.isLoading}
                isLoading={false}
            />
        </>
    );
};

TravellerDetails.defaultProps = {
    isEditable: false,
    openModal: () => {},
};

export default TravellerDetails;
