import StatsCard from 'app/components/StatsCard/StatsCard';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface AgencySeriesTopSectionProps {
    stats: any;
}

const AgencySeriesTopSection: FC<AgencySeriesTopSectionProps> = (props) => {
    const { stats } = props;

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Series Inventory</h5>
                <Link
                    to="add"
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add Inventory
                </Link>
            </div>

            <div className="grid grid-cols-10 gap-6 mt-8">
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Total seats listed'} content={stats.totalSeats} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Total seats sold'} content={stats.totalSoldSeats} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Pending submission'} content={stats.pendingTickets} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Submitted tickets'} content={stats.submittedTickets} />
                </div>
                <div className="col-span-10 sm:col-span-5 xl:col-span-2">
                    <StatsCard title={'Total tickets'} content={stats.totalTickets} />
                </div>
            </div>
        </>
    );
};

export default AgencySeriesTopSection;
