import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ITripJackCommission } from '../Page';
import BackLink from 'app/components/BackLink/BackLink';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { createReadableFareSupplierLabel } from 'app/utils/fare-supplier.utils';
import { getCommissionPlanById, updateCommissionPlan } from 'app/api/services/fare-commission.service';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';
import { getFareSupplierFareComponentKeys } from 'app/modules/shared/utils/plan-commission.utils';
import { getTripJacKFareComponentLabel } from 'app/modules/shared/utils/trip-jack-supplier.utils';
import { FC } from 'react';

const planEditSchema = Yup.object({
    name: Yup.string().trim().required('This field is required'),
    commissionBreakdown: Yup.object({
        NCM: Yup.object({
            flat: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),
            percentage: Yup.number()
                .required('This field is required')
                .min(0, 'The minimum value you can enter is 0.')
                .max(100, 'The maximum value you can enter is 100.'),
        }),
    }),
    managementChargesPerPassenger: Yup.number().min(0).required('This field is required'),
    taxRates: Yup.object({
        gstRate: Yup.number()
            .required('This field is required')
            .min(0, 'The minimum value you can enter is 0.')
            .max(100, 'The maximum value you can enter is 100.'),
        commissionTdsRate: Yup.number()
            .required('This field is required')
            .min(0, 'The minimum value you can enter is 0.')
            .max(100, 'The maximum value you can enter is 100.'),
    }),
});

interface ITripjackEditForm {
    data: any;
}

const TripjackEditForm: FC<ITripjackEditForm> = (props) => {
    const { data } = props;

    const { id } = useParams();
    const tripJackFareComponentKeys = getFareSupplierFareComponentKeys(CommissionPlanEnabledSuppliers.TRIP_JACK);

    const updateCommissionPlanMutation = useMutation((payload: { id: string; values: any }) =>
        updateCommissionPlan(payload)
    );

    const handleSubmit = async (values: ITripJackCommission) => {
        try {
            await updateCommissionPlanMutation.mutateAsync({
                id,
                values: {
                    commissionBreakdown: values.commissionBreakdown,
                    managementChargesPerPassenger: values.managementChargesPerPassenger,
                    taxRates: values.taxRates,
                },
            });
            toast.success('Commission plan saved');
        } catch (e) {
            toast.error('Something went wrong');
        }
        console.log({ values });
    };

    const initialValuesOrg: ITripJackCommission = {
        name: data.name,
        commissionPlanType: data.commissionPlanType,
        commissionFareSupplier: data.commissionFareSupplier,
        commissionBreakdown: data.commissionBreakdown,
        managementChargesPerPassenger: data.managementChargesPerPassenger,
        taxRates: data.taxRates,
    };

    return (
        <div>
            <div className='mb-5'>
                <BackLink />
            </div>
            <h4 className='font-bold'>Edit Commission Plan</h4>
            <div className='card mt-8 px-6 py-5'>
                <Formik initialValues={initialValuesOrg} onSubmit={handleSubmit} validationSchema={planEditSchema}>
                    {({ isSubmitting }: FormikProps<ITripJackCommission>) => (
                        <Form>
                            <div className='grid grid-cols-12 gap-6 mt-6'>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='name'>
                                        Plan Name
                                    </label>
                                    <Field type='text' id='name' name='name' className='form-control' />
                                    <div className='form-error'>
                                        <ErrorMessage name='name' />
                                    </div>
                                </div>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='commissionFareSupplier'>
                                        Fare Supplier
                                    </label>
                                    <Field
                                        as='select'
                                        name='commissionFareSupplier'
                                        type='text'
                                        id='commissionFareSupplier'
                                        className='form-control'
                                        disabled
                                    >
                                        {Object.keys(CommissionPlanEnabledSuppliers).map((comFareSup: string) => (
                                            <option key={comFareSup} value={comFareSup}>
                                                {createReadableFareSupplierLabel(comFareSup as FareSupplierEnum)}
                                            </option>
                                        ))}
                                    </Field>
                                    <div className='form-error'>
                                        <ErrorMessage name='commissionFareSupplier' />
                                    </div>
                                </div>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='managementChargesPerPassenger'>
                                        Management Charges Per Passenger
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-prepend'>₹</div>
                                        <Field
                                            type='number'
                                            id={`managementChargesPerPassenger`}
                                            name={`managementChargesPerPassenger`}
                                            className='form-control pl-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='managementChargesPerPassenger' />
                                    </div>
                                </div>
                                <div className='col-span-4' />

                                <div className='col-span-4'>
                                    <label className='text-xs mb-2' htmlFor='taxRates.gstRate'>
                                        GST Rate on Management Fee
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-postpend'>%</div>
                                        <Field
                                            type='number'
                                            id={`taxRates.gstRate`}
                                            name={`taxRates.gstRate`}
                                            className='form-control pr-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='taxRates.gstRate' />
                                    </div>
                                </div>
                                <div className='col-span-4'>
                                    <label className='text-xs mb-2' htmlFor='taxRates.commissionTdsRate'>
                                        Commission TDS Rate
                                    </label>
                                    <div className='input-group'>
                                        <div className='input-group-postpend'>%</div>
                                        <Field
                                            type='number'
                                            id={`taxRates.commissionTdsRate`}
                                            name={`taxRates.commissionTdsRate`}
                                            className='form-control pr-8'
                                        />
                                    </div>
                                    <div className='form-error'>
                                        <ErrorMessage name='taxRates.commissionTdsRate' />
                                    </div>
                                </div>

                                {tripJackFareComponentKeys.map((fareComponentKey) => (
                                    <div className='col-span-8' key={fareComponentKey.toString()}>
                                        <div className='grid grid-cols-5 gap-4'>
                                            <div className='mt-auto'>
                                                <span className='mb-2'>
                                                    {getTripJacKFareComponentLabel(fareComponentKey)}
                                                </span>
                                                <div className='form-error' />
                                            </div>
                                            <div className='col-span-2'>
                                                <label className='text-xs' htmlFor=''>
                                                    Flat
                                                </label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>₹</div>
                                                    <Field
                                                        type='number'
                                                        id={`commissionBreakdown.${fareComponentKey}.flat`}
                                                        name={`commissionBreakdown.${fareComponentKey}.flat`}
                                                        className='form-control pl-8'
                                                    />
                                                </div>
                                                <div className='form-error'>
                                                    <ErrorMessage
                                                        name={`commissionBreakdown.${fareComponentKey}.flat`}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-span-2'>
                                                <label className='text-xs' htmlFor=''>
                                                    Percentage
                                                </label>
                                                <div className='input-group'>
                                                    <div className='input-group-postpend'>%</div>
                                                    <Field
                                                        type='number'
                                                        id={`commissionBreakdown.${fareComponentKey}.percentage`}
                                                        name={`commissionBreakdown.${fareComponentKey}.percentage`}
                                                        className='form-control'
                                                    />
                                                </div>
                                                <div className='form-error'>
                                                    <ErrorMessage
                                                        name={`commissionBreakdown.${fareComponentKey}.percentage`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='mt-12'>
                                <LoaderButton
                                    type='submit'
                                    className='bg-primary text-white text-sm font-semibold rounded-lg w-56 max-w-full mt-4'
                                    isLoading={updateCommissionPlanMutation.isLoading}
                                >
                                    Submit
                                </LoaderButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default TripjackEditForm;
