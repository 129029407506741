/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction } from 'react';

interface SubAgencyDetailNavProps {
    activeTab: string;
    setActiveTab: Dispatch<SetStateAction<string>>;
}

const SubAgencyDetailNav: FC<SubAgencyDetailNavProps> = (props) => {
    const { activeTab, setActiveTab } = props;

    return (
        <ul className='flex text-base font-normal px-6 pt-6 pb-2 overflow-x-auto'>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '1' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('1')}
                >
                    Bookings
                </a>
            </li>

            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '2' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('2')}
                >
                    Transactions
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '3' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('3')}
                >
                    Banks
                </a>
            </li>

            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '4' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('4')}
                >
                    Documents
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '5' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('5')}
                >
                    MySeries Ledger
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '6' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('6')}
                >
                    Plugins
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '7' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('7')}
                >
                    Tour Queries
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '8' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('8')}
                >
                    Payment Notes
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '9' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('9')}
                >
                    Series Inventory
                </a>
            </li>
            <li className='tab-item'>
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '10' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('10')}
                >
                    Commission Plan
                </a>
            </li>
        </ul>
    );
};

export default SubAgencyDetailNav;
