/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from '@tanstack/react-query';
import { TRootState } from 'app/store';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authActions } from 'app/store/auth';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { IAgencyUser } from 'app/types';

interface LoginProps {}

const initialValues: any = {
    username: '',
    password: '',
};

const Login: FC<LoginProps> = (props) => {
    const navigate = useNavigate();

    const { token } = useSelector((state: TRootState) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/auth/authenticate`, payload);

        return data;
    });

    const handleSubmit = async (values: any) => {
        if (!values.username || !values.password) {
            toast.error('Invalid Credentials.');
            return;
        }

        try {
            const res = await mutation.mutateAsync(values);

            const token = res.token;

            const payload: Partial<IAgencyUser> = jwtDecode(token);
            const currentUser = { _id: payload._id, name: payload.name, agency: payload.agency };

            dispatch(authActions.login({ token: token, user: currentUser, rememberMe: true }));

            navigate('/', { replace: true });
        } catch (err: any) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <div className="w-full min-h-screen bg-primary flex">
            <div className="card rounded-2xl px-9 py-8 w-full max-w-[580px] m-auto">
                <div className="flex items-center justify-between">
                    <h4>Sign In</h4>

                    <img className="w-32" src="/icons/logo.svg" alt="" />
                </div>

                <div className="mt-10">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form>
                            <div>
                                <label className="text-sm text-gray-800 mb-3" htmlFor="username">
                                    Email or Username
                                </label>
                                <Field
                                    type="text"
                                    id="username"
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter your email or username"
                                />
                            </div>

                            <div className="mt-6">
                                <label className="text-sm text-gray-800 mb-3" htmlFor="password">
                                    Password
                                </label>

                                <Field
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Enter your password"
                                />
                            </div>

                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg mt-10 w-full"
                                isLoading={mutation.isLoading}
                            >
                                Login
                            </LoaderButton>

                            {/* <div className="mt-12 text-sm">
                                <span className="text-gray-500 mr-2">Not on Farepod yet?</span>
                                <a className="text-blue-600 font-semibold underline cursor-pointer">Sign Up</a>
                            </div> */}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Login;
