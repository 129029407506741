import { FC } from 'react';
import * as Yup from 'yup';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import Modal from 'app/components/Modal/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const PaymentNoteFormSchema = Yup.object().shape({
    remark: Yup.string().trim().required('This field is required'),
    type: Yup.string().trim().required('This field is required'),
    amount: Yup.number()
        .typeError('You must specify a number')
        .required('This field is required')
        .min(1, 'The amount must be greater than Zero'),
});

const paymentNoteType = [
    {
        label: 'Credit Note',
        value: 'CREDIT',
    },
    {
        label: 'Debit Note',
        value: 'DEBIT',
    },
];

interface AddPaymentNoteModalProps {
    show: boolean;
    onClose: () => void;
    subAgencyId: string;
}

const AddPaymentNoteModal: FC<AddPaymentNoteModalProps> = (props) => {
    const { onClose, show, subAgencyId } = props;

    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/payment-note`,
            payload
        );

        return data;
    });

    const initialValues = {
        amount: '',
        remark: '',
        type: 'CREDIT',
    };

    const onSubmit = async (values: any) => {
        try {
            await mutation.mutateAsync(values);
            queryClient.invalidateQueries({ queryKey: ['subAgency', subAgencyId, 'ledger'] });
            queryClient.invalidateQueries({ queryKey: ['subAgency', subAgencyId] });

            props.onClose();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    return (
        <Modal show={show} onClose={onClose} className="px-10 py-10 w-[680px] max-w-full">
            <div className="text-2xl font-bold">Make Payment</div>

            <Formik
                initialValues={initialValues}
                validationSchema={PaymentNoteFormSchema}
                enableReinitialize
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="mt-5">
                            <label className="text-xs mb-2 block" htmlFor="Amount">
                                Amount
                            </label>

                            <div className="flex items-center relative">
                                <div className="absolute pl-4"> ₹ </div>
                                <Field name="amount" type="text" id="Amount" className="form-control pl-8 " />
                            </div>
                            <div className="form-error">
                                <ErrorMessage name="amount" />
                            </div>
                        </div>

                        <div className="mt-5">
                            <label className="text-xs mb-4 block" htmlFor="Amount">
                                Payment Note Type
                            </label>
                            {paymentNoteType.map((row) => (
                                <div key={row.value} className="flex gap-3 mb-4">
                                    <Field
                                        type="radio"
                                        id={row.value}
                                        name="type"
                                        onChange={() => setFieldValue('type', row.value)}
                                        checked={row.value === values.type}
                                        className="accent-primary"
                                    />
                                    <label className={'text-sm block'} htmlFor={row.value}>
                                        {row.label}
                                    </label>
                                </div>
                            ))}
                            <div className="form-error">
                                <ErrorMessage name="type" />
                            </div>
                        </div>

                        <div className="mt-5">
                            <label className="text-xs mb-2 block" htmlFor="Remark">
                                Remarks
                            </label>
                            <Field as="textarea" name="remark" type="text" id="Remark" className="form-control" />
                            <div className="form-error">
                                <ErrorMessage name="remark" />
                            </div>
                        </div>
                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                            isLoading={false}
                        >
                            Proceed
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddPaymentNoteModal;
