import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import { IAgencyUser } from 'app/types';

type IAuthData = {
    token: string;
    user: Partial<IAgencyUser>;
};

const initialState: IAuthData = {
    token: null,
    user: null,
};

const TOKEN_KEY = 'fa_token';
const USER_KEY = 'fa_user';

const getStorage = () => {
    if (localStorage.getItem(TOKEN_KEY) != null) {
        return localStorage;
    }

    if (sessionStorage.getItem(TOKEN_KEY) != null) {
        return sessionStorage;
    }

    return null;
};

const getInitialState = (): IAuthData => {
    const storage = getStorage();

    if (!storage) return initialState;

    return {
        token: storage.getItem(TOKEN_KEY),
        // user: JSON.parse(storage.getItem(USER_KEY)),
        user: null,
    };
};

const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        login(state, action) {
            const { token, user } = action.payload;

            state.token = token;
            state.user = user;
        },
        setToken(state, action) {
            state.token = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        removeToken(state, action) {
            state.token = null;
        },
        removeUser(state, action) {
            state.user = null;
        },
        logout() {
            return initialState;
        },
    },
});

export const authActions = authSlice.actions;

export const authListenerMiddleware = createListenerMiddleware();
// Listen for login
authListenerMiddleware.startListening({
    actionCreator: authActions.login,
    effect: (action, listenerApi) => {
        const payload = action.payload;

        if (payload.rememberMe) {
            localStorage.setItem(TOKEN_KEY, payload.token);
            localStorage.setItem(USER_KEY, JSON.stringify(payload.user));
        } else {
            sessionStorage.setItem(TOKEN_KEY, payload.token);
            sessionStorage.setItem(USER_KEY, JSON.stringify(payload.user));
        }
    },
});
// Listen for setUser
authListenerMiddleware.startListening({
    actionCreator: authActions.setUser,
    effect: (action, listenerApi) => {
        const user = action.payload;

        const storage = getStorage();
        storage.setItem(USER_KEY, JSON.stringify(user));
    },
});
// Listen for logout
authListenerMiddleware.startListening({
    actionCreator: authActions.logout,
    effect: (action, listenerApi) => {
        const storage = getStorage();

        storage.removeItem(TOKEN_KEY);
        storage.removeItem(USER_KEY);
    },
});

export default authSlice;
