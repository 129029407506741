import { FC } from 'react';
import * as Yup from 'yup';
import { produce } from 'immer';
import http from 'app/config/http';
import { CopyIcon } from 'app/icons';
import { toast } from 'react-toastify';
import { TRootState } from 'app/store';
import { useSelector } from 'react-redux';
import Modal from 'app/components/Modal/Modal';
import Spinner from 'app/components/Spinner/Spinner';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { secureString, snakeCaseToWord } from 'app/utils/common-utils';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface ISelected {
    fareSupplier: string;
    markup: number;
    isEnabled: boolean;
}

interface IFareSupplier {
    label: string;
    value: string;
}

interface IOutwardApi {
    isEnabled: boolean;
    fareSuppliers: {
        [key: string]: {
            markup: number;
            isEnabled: boolean;
        };
    };
}

const outwardApiSchema = Yup.object().shape({
    fareSuppliers: Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
            markup: Yup.number().required('This field is required').typeError('Enter a valid number'),
            isEnabled: Yup.boolean(),
        })
    ),
});

interface OutwardApiPluginModalProps {
    show: boolean;
    onClose: () => void;
    subAgencyId: any;
    outwardApi: {
        _id: string;
        name: string;
        description: string;
        identifier: string;
        logo: string;
        isActive: boolean;
        logoUrl: string;
        id: string;
        details: {
            key?: string;
            secret?: string;
            fareSuppliers?: {
                [Key: string]: {
                    isEnabled: boolean;
                    markup: number;
                };
            };
        };
        isEnabled: boolean;
    };
}

interface IToggleOutwardApi {
    isEnabled: boolean;
    credentials?: {
        key: string;
        secret: string;
    };
}

const OutwardApiPluginModal: FC<OutwardApiPluginModalProps> = (props) => {
    const { outwardApi, subAgencyId, onClose } = props;
    const {
        user: { agency },
    } = useSelector((state: TRootState) => state.auth);

    const queryClient = useQueryClient();

    const availableFareSuppliersQuery = useQuery<IFareSupplier[]>(['availableFareSuppliersQuery'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/fare-suppliers`
        );
        return data;
    });

    const outwardApiQuery = useQuery<IOutwardApi>(['List', 'out-warard', 'plugin'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${outwardApi._id}/${subAgencyId}?z`
        );

        return data;
    });

    const regenrateKeyMutation = useMutation(async () => {
        const { data } = await http.post(
            // /api/v1/agent/plugins/outward-api/AUX10001
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${outwardApi._id}/${subAgencyId}`
        );
        return data;
    });

    const handleRegenerateKeys = async () => {
        try {
            await regenrateKeyMutation.mutateAsync();
            toast.success('Api Keys regenerated');
            queryClient.invalidateQueries({ queryKey: ['subAgency', subAgencyId, 'plugins'] });
        } catch (ex: any) {
            toast.error(ex.data.message || 'Some error occured, please try again.');
        }
    };

    const outwardBodyMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${outwardApi._id}/${subAgencyId}/details`,
            { ...values }
        );

        return data;
    });

    const handleSubmit = async (values: any) => {
        // convert fare supplier array to object for mutation
        const fareSuppliers: any = {};
        values.fareSuppliers.forEach((row: ISelected) => {
            fareSuppliers[row.fareSupplier] = {
                isEnabled: row.isEnabled,
                markup: row.markup,
            };
        });

        try {
            await outwardBodyMutation.mutateAsync({ fareSuppliers });
            toast.success('Plugin Update Successfully');
            queryClient.invalidateQueries({ queryKey: ['subAgency', subAgencyId, 'plugins'] });
            onClose();
        } catch (ex: any) {
            toast.error(ex.data.message || 'Some error occured, please try again.');
        }
    };

    const outwardToggleMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${outwardApi._id}/${subAgencyId}`,
            { ...values }
        );

        return data;
    });

    const handleToggle = async (chx: boolean) => {
        queryClient.setQueryData(['List', 'out-warard', 'plugin'], (prevValues) => {
            return produce(prevValues, (draftState: any) => {
                draftState.isEnabled = chx;
            });
        });

        try {
            const result: IToggleOutwardApi = await outwardToggleMutation.mutateAsync({ isEnabled: chx });
            toast.success(`Plugin ${result.isEnabled ? 'enabled' : 'disabled'} successfully`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            queryClient.setQueryData(['List', 'out-warard', 'plugin'], (prevValues) => {
                return produce(prevValues, (draftState: any) => {
                    draftState.isEnabled = !chx;
                });
            });
        } finally {
        }
        queryClient.fetchQuery(['subAgency', subAgencyId, 'plugins']);
    };

    if (availableFareSuppliersQuery.isLoading || outwardApiQuery.isLoading)
        return (
            <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10 w-[520px]">
                <div className="font-bold">Outward Api</div>
                <div className="flex justify-center py-4">
                    <div>
                        <Spinner />
                    </div>
                </div>
            </Modal>
        );

    const transformExistingFareSuppliers = (myObj: any): ISelected[] => {
        const tempFareSupplier: any = [];
        Object.keys(myObj).forEach((fareSupplier: string, index) => {
            tempFareSupplier.push({ fareSupplier, ...myObj[fareSupplier] });
        });
        return tempFareSupplier;
    };

    const availableFareSuppliers = availableFareSuppliersQuery.data;

    const generateFormData = () => {
        const existingFareSuppliers = outwardApiQuery.data.fareSuppliers
            ? transformExistingFareSuppliers(outwardApiQuery.data.fareSuppliers)
            : [];

        const fareSuppliers = [...existingFareSuppliers];

        availableFareSuppliers.forEach(({ value }) => {
            const inludedFareSuppliers = Object.values(fareSuppliers);
            let result = inludedFareSuppliers.find((data) => value === data.fareSupplier);
            if (!result) {
                fareSuppliers.push({
                    fareSupplier: value,
                    isEnabled: false,
                    markup: 0,
                });
            }
        });

        return {
            fareSuppliers,
        };
    };

    const initialValues = generateFormData();

    const copyToClipboard = ({ data, message }: { data: string; message: string }) => {
        navigator.clipboard.writeText(data);
        toast.success(message);
    };

    const minimizeStringlength = (text: string) => {
        return text.substring(text.length - 40, text.length);
    };

    const getSupplierLabel = (supplierIdentifier: string) => {
        let label = snakeCaseToWord(supplierIdentifier);

        switch (supplierIdentifier) {
            case 'AIR_IQ':
                label = 'AirIQ';
                break;

            case 'AGENCY_SERIES':
                label = agency.name + ' Series';
                break;

            case 'MY_SERIES':
                label = 'Sub Agency Series';
                break;

            case 'TRIP_JACK':
                label = 'TripJack';
                break;
        }

        return label;
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10 w-[580px]   ">
            <div className="flex items-center gap-8">
                <div className="font-bold">Outward Api</div>
                <div>
                    <input
                        type="checkbox"
                        className="form-switch success"
                        onChange={(e) => handleToggle(e.target.checked)}
                        checked={outwardApiQuery.data.isEnabled}
                    />
                </div>
            </div>

            {props.outwardApi.details?.key && (
                <div className="mt-6 grid grid-cols-5 gap-3 w-full">
                    <div className="col-span-1 text-sm font-bold">API Key</div>
                    <div className="col-span-4">
                        <div className="font-semibold flex  gap-2 items-center break-all">
                            {minimizeStringlength(
                                secureString({ showCharacters: 8, text: props.outwardApi.details.key })
                            )}
                            <span
                                title="Copy to clipboard"
                                onClick={() =>
                                    copyToClipboard({
                                        data: props.outwardApi.details.key,
                                        message: 'Api Key copied to clip board',
                                    })
                                }
                                className="cursor-pointer text-primary"
                            >
                                <CopyIcon />
                            </span>
                        </div>
                    </div>

                    <div className="col-span-1 text-sm font-bold ">API Secret</div>
                    <div className="col-span-4">
                        <div className="font-semibold flex items-center gap-2  ">
                            {/* {props.outwardApi.details.secret} */}
                            {secureString({ showCharacters: 6, text: props.outwardApi.details.secret })}
                            <span
                                title="Copy to clipboard"
                                onClick={() =>
                                    copyToClipboard({
                                        data: props.outwardApi.details.secret,
                                        message: 'Api Secret copied to clip board',
                                    })
                                }
                                className="cursor-pointer text-primary"
                            >
                                <CopyIcon />
                            </span>
                        </div>
                    </div>

                    <div className="col-span-5">
                        <LoaderButton
                            type="button"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-full mt-6"
                            isLoading={regenrateKeyMutation.isLoading}
                            // isDisabled={!outwardApiQuery.data.isEnabled}
                            onClick={handleRegenerateKeys}
                        >
                            Regenerate
                        </LoaderButton>
                    </div>
                </div>
            )}

            {/* {credentials ? (
                <>
                    <div className="mt-6 grid grid-cols-5 gap-3 ">
                        <div className="col-span-1">Key</div>
                        <div className="col-span-4">
                            <div className="font-semibold flex  gap-2 items-center">
                                {credentials.key}
                                <span
                                    title="Copy to clipboard"
                                    onClick={() => copyToClipboard(credentials.key)}
                                    className="cursor-pointer"
                                >
                                    <CopyIcon />
                                </span>
                            </div>
                        </div>

                        <div className="col-span-1">Secret</div>
                        <div className="col-span-4">
                            <div className="font-semibold flex items-center gap-2  ">
                                {credentials.secret}
                                <span
                                    title="Copy to clipboard"
                                    onClick={() => copyToClipboard(credentials.secret)}
                                    className="cursor-pointer"
                                >
                                    <CopyIcon />
                                </span>
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        onClick={props.onClose}
                        type="button"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                    >
                        close
                    </LoaderButton>
                </>
            ) : ()} */}
            <>
                {availableFareSuppliersQuery.isLoading || outwardApiQuery.isLoading ? (
                    <div className="flex justify-center py-4">
                        <div>
                            <Spinner />
                        </div>
                    </div>
                ) : (
                    <div className="mt-8">
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={outwardApiSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values }) => (
                                <Form>
                                    <>
                                        <div className="grid"></div>
                                        <FieldArray
                                            name="fareSuppliers"
                                            render={() => {
                                                return (
                                                    <div className="flex flex-col  gap-2">
                                                        <div className="flex justify-between items-center">
                                                            <div>Select fare suppliers</div>
                                                            <div className="pr-[136px]">Add markup</div>
                                                        </div>
                                                        {values.fareSuppliers.map((row, index) => (
                                                            <div
                                                                className="flex justify-between items-center"
                                                                key={index}
                                                            >
                                                                <div className="col-span-2">
                                                                    <div key={row.fareSupplier} className="flex gap-3">
                                                                        <Field
                                                                            type="checkbox"
                                                                            id={index.toString()}
                                                                            name={`fareSuppliers[${index}].isEnabled`}
                                                                            className="form-switch success"
                                                                            disabled={!outwardApiQuery.data.isEnabled}
                                                                        />
                                                                        <label
                                                                            className={'text-sm block'}
                                                                            htmlFor={index.toString()}
                                                                        >
                                                                            {/* {snakeCaseToWord(row.fareSupplier)} */}
                                                                            {getSupplierLabel(row.fareSupplier)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <Field
                                                                        type="text"
                                                                        name={`fareSuppliers[${index}].markup`}
                                                                        className="form-control rounded-l-none"
                                                                        placeholder=""
                                                                        disabled={!outwardApiQuery.data.isEnabled}
                                                                    />
                                                                    <div className="form-error">
                                                                        <ErrorMessage
                                                                            name={`fareSuppliers[${index}].markup`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </>

                                    <LoaderButton
                                        type="submit"
                                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                        isLoading={outwardBodyMutation.isLoading}
                                        isDisabled={!outwardApiQuery.data.isEnabled}
                                    >
                                        Save
                                    </LoaderButton>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
            </>
        </Modal>
    );
};

export default OutwardApiPluginModal;
