import { FC } from 'react';
import http from 'app/config/http';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formatDate } from 'app/utils/date-utils';
import BackLink from 'app/components/BackLink/BackLink';
import { formatCurrency } from 'app/utils/currency-utils';
import PageLoader from 'app/components/PageLoader/PageLoader';
import MarkupControlForm from './components/MarkupControlForm/MarkupControlForm';
import MarkupHistoryTable from './components/MarkupHistoryTable/MarkupHistoryTable';

interface SeriesInventoryReviseMarkupProps {}

const SeriesInventoryReviseMarkup: FC<SeriesInventoryReviseMarkupProps> = (props) => {
    const { inventoryId } = useParams();

    const ticketQuery = useQuery(['seriesInventoryTicket'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${inventoryId}`);

        return data;
    });

    const markupHistoryQuery = useQuery(['markupHistory'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${inventoryId}/fare-history`
        );

        return data;
    });

    if (ticketQuery.isLoading || markupHistoryQuery.isLoading) return <PageLoader />;

    const ticket = ticketQuery.data;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10 mt-8">
                <div className="font-bold">Markup Control - {ticket._id}</div>

                <div className="grid grid-cols-4 gap-6 mt-6">
                    <div className="col-span-1">
                        <div className="font-semibold">
                            {ticket.trip.departure.airport.city} to {ticket.trip.arrival.airport.city}
                        </div>
                        <p className="mt-1">{formatDate(ticket.trip.departure.date, 'DD MMM, YYYY')}</p>
                    </div>
                    <div className="col-span-1">
                        <div className="font-semibold">{formatCurrency(ticket.totalfare)}</div>
                        <p className="mt-1">
                            {ticket.bookings.length}/{ticket.totalSeats} Seats Sold
                        </p>
                    </div>
                    <div className="col-span-1">
                        <div className="flex items-center gap-3">
                            <img className="w-9 h-9" crossOrigin="anonymous" src={ticket.flight.logo} alt="" />
                            <div>
                                <div className="font-semibold">{ticket.flight.name}</div>
                                <p className="mt-1">
                                    {ticket.flight.code} {ticket.flight.number}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="font-semibold">{ticket.pnr}</div>
                    </div>
                </div>

                <div className="mt-8">
                    <MarkupControlForm ticket={ticket} />
                </div>
            </div>

            <div className="card py-5 mt-6">
                <div className="px-6">
                    <div className="font-bold">Markup History</div>
                </div>

                <div className="mt-5">
                    <MarkupHistoryTable markupHistory={markupHistoryQuery.data} />
                </div>
            </div>
        </>
    );
};

export default SeriesInventoryReviseMarkup;
