import * as Yup from 'yup';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import useCcAvenue from './shared/useCcAvenue';
import Spinner from 'app/components/Spinner/Spinner';
import { UseMutationResult } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PageLoader from 'app/components/PageLoader/PageLoader';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ICcAvenue, ICcAvenueCredentials } from './shared/ccAvenue.enum';
import PaymentGateWayChargesCard from '../shared/components/PaymentGatewayCharges';

const CcAvenueInfo = () => {
    const {
        isLoading,
        ccAvenueDetails,
        ccAvenueCredentialsUpdateMutation,
        ccAvenueGateWayChargesUpdateMutation,
        handlePluginToggle,
        isToggling,
    } = useCcAvenue();

    if (isLoading) return <PageLoader />;

    return (
        <div>
            <div className='flex justify-between items-center'>
                <h5 className='font-bold'>CC Avenue</h5>
                <span>
                    {isToggling && <Spinner size={24} />}
                    <input
                        id='isEnabled'
                        type='checkbox'
                        className='form-switch success ml-2'
                        checked={ccAvenueDetails.isEnabled}
                        onChange={handlePluginToggle}
                    />
                    {/* <label htmlFor='isEnabled'>{ccAvenueDetails.isEnabled ? 'Deactivate' : 'Activate'}</label> */}
                </span>
            </div>

            <CcAvenueCredentialsCard
                CcAvenueInfo={ccAvenueDetails}
                credentialUpdateMutation={ccAvenueCredentialsUpdateMutation}
            />

            <PaymentGateWayChargesCard
                // CcAvenueInfo={ccAvenueDetails}
                pluginInfo={ccAvenueDetails}
                chargesUpdateMutation={ccAvenueGateWayChargesUpdateMutation}
                gatewayLabel='Cc Avenue'
            />
        </div>
    );
};

interface ICcAvenueCredentialsCard {
    CcAvenueInfo: ICcAvenue;
    credentialUpdateMutation: UseMutationResult;
    // <any, unknown, any, unknown>;
}

const ccAvenueCredentialsValidationSchema = Yup.object().shape({
    baseUrl: Yup.string().url('must be a valid URL').required('This field is required'),
    accessCode: Yup.string()
        .trim()
        .required('This field is required')
        .test('access-code', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    workingKey: Yup.string()
        .trim()
        .required('This field is required')
        .test('Working-valid-key', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    merchantId: Yup.string().trim().required('This field is required'),
});

const CcAvenueCredentialsCard: FC<ICcAvenueCredentialsCard> = (props) => {
    const { CcAvenueInfo, credentialUpdateMutation } = props;

    const initialValues: ICcAvenueCredentials = useMemo(() => {
        if (CcAvenueInfo) {
            return {
                baseUrl: CcAvenueInfo.details.credentials.baseUrl,
                accessCode: CcAvenueInfo.details.credentials.accessCode,
                workingKey: CcAvenueInfo.details.credentials.workingKey,
                merchantId: CcAvenueInfo.details.credentials.merchantId,
            };
        } else
            return {
                accessCode: '',
                baseUrl: '',
                merchantId: null,
                workingKey: '',
            };
    }, [CcAvenueInfo]);

    const handleSubmit = async (values: ICcAvenueCredentials) => {
        try {
            await credentialUpdateMutation.mutateAsync({
                credentials: { ...values },
            });
            toast.success('Cc Avenue keys updated successfully');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    return (
        <div className='card mt-8 p-6'>
            <div className='font-semibold'>Credentials</div>
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={ccAvenueCredentialsValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <div className='grid grid-cols-12 gap-6 mt-8'>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='baseUrl'>
                                        Payment Initiation Url
                                    </label>
                                    <Field type='text' id='baseUrl' name='baseUrl' className='form-control' />
                                    <div className='form-error'>
                                        <ErrorMessage name='baseUrl' />
                                    </div>
                                </div>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='accessCode'>
                                        Access Key
                                    </label>
                                    <Field
                                        type='text'
                                        id='accessCode'
                                        name='accessCode'
                                        className='form-control'
                                        onFocus={() => setFieldValue('accessCode', '')}
                                    />
                                    <div className='form-error'>
                                        <ErrorMessage name='accessCode' />
                                    </div>
                                </div>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='workingKey'>
                                        Working Key
                                    </label>
                                    <Field
                                        type='text'
                                        id='workingKey'
                                        name='workingKey'
                                        className='form-control'
                                        onFocus={() => setFieldValue('workingKey', '')}
                                    />
                                    <div className='form-error'>
                                        <ErrorMessage name='workingKey' />
                                    </div>
                                </div>
                                <div className='col-span-8'>
                                    <label className='text-xs mb-2' htmlFor='merchantId'>
                                        Merchant ID
                                    </label>
                                    <Field type='text' id='merchantId' name='merchantId' className='form-control' />
                                    <div className='form-error'>
                                        <ErrorMessage name='merchantId' />
                                    </div>
                                </div>
                            </div>

                            <LoaderButton
                                type='submit'
                                className='bg-primary text-white text-base font-semibold rounded-lg w-32 mt-8'
                                isLoading={credentialUpdateMutation.isLoading}
                            >
                                Save
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};



export default CcAvenueInfo;
