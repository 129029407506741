import * as Yup from 'yup';
import http from 'app/config/http';
import { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

import { ITravelBoutiqueCredentialForm } from '../../types/travel-boutique-credential-form';
import { ITravelBoutiqueDetail } from '../../types/travel-boutique';

const travelBoutiqueCredentialsFormSchema = Yup.object({
    credentials: Yup.object({
        clientId: Yup.string().required('This field is required'),
        username: Yup.string().required('This field is required'),
        password: Yup.string()
            .required('This field is required')
            .test('password', 'Invalid key', (value) => {
                const substr = '**';
                return !value.includes(substr);
            }),
    }),
    authUrl: Yup.string().required('This field is required'),
    searchUrl: Yup.string().required('This field is required'),
    bookingUrl: Yup.string().required('This field is required'),
});

type ITravelBoutiqueCredentialsFormProps = Pick<
    ITravelBoutiqueDetail,
    'clientId' | 'password' | 'username' | 'authUrl' | 'searchUrl' | 'bookingUrl'
>;

const TravelBoutiqueCredentialsForm: FC<ITravelBoutiqueCredentialsFormProps> = (props) => {
    const initialValues: ITravelBoutiqueCredentialForm = useMemo(() => {
        return {
            authUrl: props.authUrl,
            bookingUrl: props.bookingUrl,
            searchUrl: props.searchUrl,
            credentials: {
                clientId: props.clientId,
                password: props.password,
                username: props.username,
            },
        };
    }, [props]);

    const handleSubmit = async (values: ITravelBoutiqueCredentialForm) => {
        try {
            const authKeys = {
                credentials: {
                    username: values.credentials.username,
                    password: values.credentials.password,
                    clientId: values.credentials.clientId,
                },
                authUrl: values.authUrl,
                searchUrl: values.searchUrl,
                bookingUrl: values.bookingUrl,
            };

            await mutation.mutateAsync(authKeys);

            toast.success('Credentials saved successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/tbo`, payload);
        return data;
    });

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={travelBoutiqueCredentialsFormSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <div className='grid grid-cols-12 gap-4 mt-8'>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='authUrl'>
                                Auth Url
                            </label>
                            <Field
                                type='text'
                                id='authUrl'
                                name='authUrl'
                                className='form-control'
                                placeholder='Enter your Travel Boutique Auth Url'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='authUrl' />
                            </div>
                        </div>

                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='searchUrl'>
                                Search Url
                            </label>
                            <Field
                                type='text'
                                id='searchUrl'
                                name='searchUrl'
                                className='form-control'
                                placeholder='Enter your Travel Boutique Search Url'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='searchUrl' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='bookingUrl'>
                                Booking Url
                            </label>
                            <Field
                                type='text'
                                id='bookingUrl'
                                name='bookingUrl'
                                className='form-control'
                                placeholder='Enter your Travel Boutique Base Url'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='bookingUrl' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='credentials.clientId'>
                                Client Id
                            </label>
                            <Field
                                type='text'
                                id='credentials.clientId'
                                name='credentials.clientId'
                                className='form-control'
                                placeholder='Enter your Travel Boutique Client ID'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='credentials.clientId' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='credentials.username'>
                                Username
                            </label>
                            <Field
                                type='text'
                                id='credentials.username'
                                name='credentials.username'
                                className='form-control'
                                placeholder='Enter your Travel Boutique Username'
                            />
                            <div className='form-error'>
                                <ErrorMessage name='credentials.username' />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <label className='text-xs mb-2' htmlFor='credentials.password'>
                                Password
                            </label>
                            <Field
                                type='text'
                                id='credentials.password'
                                name='credentials.password'
                                className='form-control'
                                placeholder='Enter your Travel Boutique Password'
                                onFocus={() => setFieldValue('credentials.password', '')}
                            />
                            <div className='form-error'>
                                <ErrorMessage name='credentials.password' />
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type='submit'
                        className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                        isLoading={mutation.isLoading}
                    >
                        Save Changes
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default TravelBoutiqueCredentialsForm;
