import moment from 'moment';
import { FC } from 'react';
import http from 'app/config/http';
import { ITourQuery } from 'app/types';
import { MenuDotIcon } from 'app/icons';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formatDate } from 'app/utils/date-utils';
import Spinner from 'app/components/Spinner/Spinner';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface TourQueryListProps {}

const TourQueryList: FC<TourQueryListProps> = (props) => {
    const tourQuery = useQuery<ITourQuery[]>(['tour-query'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/tour-queries`);
        return data;
    });

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Tour Queries</h5>
            </div>

            <div className="card mt-6">
                <>
                    <div className="overflow-auto pt-6">
                        <table className="card-table text-sm">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="th-1">Tour Query Id</th>
                                    <th className="th-1">Arrival Date</th>
                                    <th className="th-1">Departure Date</th>
                                    <th className="th-1">Adults</th>
                                    <th className="th-1">Children</th>
                                    <th className="th-1">Sub Agency</th>
                                    <th className="th-1">Phone Number</th>
                                    <th className="th-1">Requested on</th>
                                    <th className="th-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!tourQuery.isLoading && tourQuery.data.length === 0 && (
                                    <tr>
                                        <td
                                            align="center"
                                            colSpan={10}
                                            className="py-12 font-medium text-base text-[#98A2B3]"
                                        >
                                            - No data available -
                                        </td>
                                    </tr>
                                )}

                                {!tourQuery.isLoading &&
                                    tourQuery.data.map((row, i) => (
                                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                            <td className="td-1">
                                                <Link to={`/tour-query/${row.subAgency._id}/${row._id}`}>
                                                    <div className="font-semibold text-blue-500">{row._id}</div>
                                                </Link>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{formatDate(row.arrivalDate)}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{formatDate(row.departureDate)}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.adults}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.children}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">
                                                    <div className="font-semibold text-blue-500">
                                                        <Link to={`/sub-agencies/${row.subAgency._id}/detials?tab=1`}>
                                                            {row.subAgency.name}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.subAgency.phoneNumber}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">
                                                    <p>
                                                        {moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                                    </p>
                                                    <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                                </div>
                                            </td>

                                            {/* <td className="td-1">
                                                <div className="font-semibold">{row.subAgencyDetails.name}</div>
                                                <p className="mt-1">{row.subAgencyDetails.address}</p>
                                            </td>

                                            <td className="td-1">
                                                <div className="font-semibold">{row.subAgencyDetails.phoneNumber}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.subAgencyDetails.email}</div>
                                            </td>
                                            <td className="td-1">
                                                <div className="font-semibold">{row.personalDetails.name}</div>
                                                <p className="text-xs">{row.personalDetails.designation}</p>
                                                <p className="text-xs mt-1">{row.personalDetails.phoneNumber}</p>
                                            </td>
                                            <td className="td-1">
                                                <div className="min-w-max">
                                                    <p>
                                                        {moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                                    </p>
                                                    <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                                </div>
                                            </td> */}

                                            <td className="td-1">
                                                <Dropdown
                                                    target={
                                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                        <a className="dropdown-action">
                                                            <MenuDotIcon />
                                                        </a>
                                                    }
                                                    menu={
                                                        <DropdownList>
                                                            <DropdownItem
                                                                to={`/tour-query/${row.subAgency._id}/${row._id}`}
                                                            >
                                                                View
                                                            </DropdownItem>
                                                        </DropdownList>
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {tourQuery.isLoading && (
                            <div className="flex justify-center items-center min-h-[500px]">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </>
            </div>
        </>
    );
};

export default TourQueryList;
