import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';

export const createReadableFareSupplierLabel = (fareSupplier: FareSupplierEnum) => {
    switch (fareSupplier) {
        case FareSupplierEnum.AGENCY_SERIES:
            return 'Agency Series';
        case FareSupplierEnum.AIR_IQ:
            return 'Air Iq';
        case FareSupplierEnum.MY_SERIES:
            return 'My Series';
        case FareSupplierEnum.SPICE_JET:
            return 'Spice Jet';
        case FareSupplierEnum.TRAVEL_BOUTIQUE:
            return 'Travel Boutique';
        case FareSupplierEnum.TRIP_JACK:
            return 'Trip Jack';
        case FareSupplierEnum.UNIVERSAL_API:
            return 'Universal Api';
        default:
            return '';
    }
};
