import React, { createContext, FC, useState } from 'react';
import { CommissionPlanEnabledSuppliers } from 'app/enums/commission-plan-enabled-suppliers.enum';

type IAddCommissionPlanContext = {
    selectedFareSupplier: CommissionPlanEnabledSuppliers;
    setSelectedFareSupplier: (fareSupplier: CommissionPlanEnabledSuppliers) => void;
};

const addCommissionPlanContextDefaultValues: IAddCommissionPlanContext = {
    selectedFareSupplier: CommissionPlanEnabledSuppliers.TRIP_JACK,
    setSelectedFareSupplier: (fareSupplier: CommissionPlanEnabledSuppliers) => {},
};

export const AddCommissionPlanContext = createContext(addCommissionPlanContextDefaultValues);

interface IAddCommissionPlanWrapper {
    children: React.ReactNode;
}

const AddCommissionPlanWrapper: FC<IAddCommissionPlanWrapper> = (props) => {
    const { children } = props;
    const [selectedFareSupplier, setSelectedFareSupplier] = useState(CommissionPlanEnabledSuppliers.TRIP_JACK);

    return (
        <AddCommissionPlanContext.Provider value={{ selectedFareSupplier, setSelectedFareSupplier }}>
            {children}
        </AddCommissionPlanContext.Provider>
    );
};

export default AddCommissionPlanWrapper;
