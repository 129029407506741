import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TRootState } from 'app/store';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { authActions } from '../../store/auth';

interface AuthGuardProps {
    page: any;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const location = useLocation();

    const { token } = useSelector((state: TRootState) => state.auth);
    const dispatch = useDispatch();

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    const payload: any = jwtDecode(token);

    const isExpired = moment().isAfter(moment.unix(payload.exp));

    if (isExpired) {
        dispatch(authActions.logout());

        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return props.page;
};

export default AuthGuard;
