/* eslint-disable jsx-a11y/anchor-is-valid */
import { BackIcon } from 'app/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface BackLinkProps {}

const BackLink: FC<BackLinkProps> = (props) => {
    const navigate = useNavigate();

    return (
        <a
            className="inline-flex items-center font-semibold gap-2 text-lg cursor-pointer text-gray-900"
            onClick={() => navigate(-1)}
        >
            <BackIcon height={12} />
            <span className="ms-2">Back</span>
        </a>
    );
};

export default BackLink;
