/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ISubAgencyForm } from '../../types';

const SubAgencyFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('This field is required'),
    gst: Yup.object().shape({
        number: Yup.string().trim(),
    }),
    pan: Yup.object().shape({
        number: Yup.string().trim().required('This field is required'),
    }),
    phoneNumber: Yup.string()
        .trim()
        .required('This field is required')
        .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
    email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
    creditLimit: Yup.string().trim().required('This field is required').matches(/^\d+$/, 'Please enter a valid amount'),
    openingBalance: Yup.number().typeError('you must specify a number').required('This field is required'),
    address: Yup.object().shape({
        line: Yup.string().trim().required('This field is required'),
        state: Yup.string().trim().required('This field is required'),
        city: Yup.string().trim().required('This field is required'),
        country: Yup.string().trim().required('This field is required'),
    }),
    banks: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().trim().required('This field is required'),
            ifsc: Yup.string().trim().required('This field is required'),
            account: Yup.object().shape({
                type: Yup.string().trim().required('This field is required'),
                number: Yup.string().trim().required('This field is required'),
                holderName: Yup.string().trim().required('This field is required'),
            }),
        })
    ),
    user: Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
        phoneNumber: Yup.string()
            .trim()
            .required('This field is required')
            .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
        designation: Yup.string().trim().required('This field is required'),
    }),
});

interface AddSubAgencyRequestModalProps {
    show: boolean;
    onClose: () => void;
    reqAgency: any;
}

const AddSubAgencyRequestModal: FC<AddSubAgencyRequestModalProps> = (props) => {
    const { onClose, show, reqAgency } = props;
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const initialValues: ISubAgencyForm = {
        name: reqAgency.subAgencyDetails.name,
        phoneNumber: reqAgency.subAgencyDetails.phoneNumber,
        email: reqAgency.subAgencyDetails.email,
        gst: { number: reqAgency.subAgencyDetails.gst.number },
        pan: { number: reqAgency.subAgencyDetails.pan.number },
        creditLimit: 0,
        openingBalance: 0,
        address: {
            line: reqAgency.subAgencyDetails.address,
            state: '',
            city: '',
            pincode: '',
            country: '',
        },
        banks: [],
        user: {
            name: reqAgency.personalDetails.name,
            email: reqAgency.personalDetails.email,
            phoneNumber: reqAgency.personalDetails.phoneNumber,
            designation: reqAgency.personalDetails.designation,
        },
    };

    const mutation = useMutation(async (payload: any) => {
        // const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies`, payload);
        const { data } = await http.post(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agency-registrations/${reqAgency.id}`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: ISubAgencyForm) => {
        try {
            await mutation.mutateAsync(values);
            toast.success('Sub Agency added successfully.');
            queryClient.invalidateQueries({ queryKey: ['requestAgencyId'] });
            queryClient.invalidateQueries({
                queryKey: ['pendingSubAgencyRequestQuery'],
            });

            onClose();
            navigate('/sub-agencies');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <Modal show={show} onClose={onClose} className="px-10 pt-8 pb-10 w-[1080px]">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={SubAgencyFormSchema}
                onSubmit={handleSubmit}
            >
                {({ values }: FormikProps<ISubAgencyForm>) => (
                    <Form>
                        <div className="font-bold text-xl mt-8">Add Sub Agency</div>
                        {/* name , gst */}
                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="name">
                                    NAME
                                </label>
                                <Field type="text" id="name" name="name" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="gst.number">
                                    GST NUMBER
                                </label>
                                <Field type="text" id="gst.number" name="gst.number" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="gst.number" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="pan.number">
                                    PAN
                                </label>
                                <Field type="text" id="pan.number" name="pan.number" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="pan.number" />
                                </div>
                            </div>
                        </div>

                        {/* address detials */}
                        <div className="grid grid-cols-10 gap-6 mt-6">
                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="line">
                                    ADDRESS LINE
                                </label>
                                <Field type="text" id="line" name="address.line" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.line" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="city">
                                    CITY
                                </label>
                                <Field type="text" id="city" name="address.city" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.city" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="state">
                                    STATE
                                </label>
                                <Field type="text" id="state" name="address.state" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.state" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="country">
                                    COUNTRY
                                </label>
                                <Field type="text" id="country" name="address.country" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.country" />
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="text-xs mb-2" htmlFor="pincode">
                                    PINCODE
                                </label>
                                <Field type="text" id="pincode" name="address.pincode" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="address.pincode" />
                                </div>
                            </div>
                        </div>

                        {/* email and number */}
                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="email">
                                    EMAIL
                                </label>
                                <Field type="email" id="email" name="email" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>

                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="phoneNumber">
                                    PHONE NUMBER
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">+91</div>
                                    <Field
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        className="form-control pl-12"
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="phoneNumber" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-8">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="creditLimit">
                                    CREDIT LIMIT
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field
                                        type="text"
                                        id="creditLimit"
                                        name="creditLimit"
                                        className="form-control pl-8"
                                    />
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="creditLimit" />
                                </div>
                            </div>
                        </div>

                        <div className="font-semibold mt-8">Bank Details</div>

                        <FieldArray
                            name="banks"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.banks.map((_, index) => (
                                        <div key={index} className="flex gap-6 mt-4">
                                            <div className="">
                                                <label className="text-xs mb-2" htmlFor={`banks[${index}].name`}>
                                                    BANK NAME
                                                </label>
                                                <Field
                                                    type="text"
                                                    id={`banks[${index}].name`}
                                                    name={`banks[${index}].name`}
                                                    className="form-control"
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name={`banks[${index}].name`} />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label className="text-xs mb-2" htmlFor={`banks[${index}].ifsc`}>
                                                    IFSC CODE
                                                </label>
                                                <Field
                                                    type="text"
                                                    id={`banks[${index}].ifsc`}
                                                    name={`banks[${index}].ifsc`}
                                                    className="form-control"
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name={`banks[${index}].ifsc`} />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label
                                                    className="text-xs mb-2"
                                                    htmlFor={`banks[${index}].account.type`}
                                                >
                                                    ACCOUNT TYPE
                                                </label>
                                                <Field
                                                    type="text"
                                                    id={`banks[${index}].account.type`}
                                                    name={`banks[${index}].account.type`}
                                                    className="form-control"
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name={`banks[${index}].account.type`} />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label
                                                    className="text-xs mb-2"
                                                    htmlFor={`banks[${index}].account.holderName`}
                                                >
                                                    ACCOUNT HOLDER NAME
                                                </label>
                                                <Field
                                                    type="text"
                                                    id={`banks[${index}].account.holderName`}
                                                    name={`banks[${index}].account.holderName`}
                                                    className="form-control"
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name={`banks[${index}].account.holderName`} />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label
                                                    className="text-xs mb-2"
                                                    htmlFor={`banks[${index}].account.number`}
                                                >
                                                    ACCOUNT NUMBER
                                                </label>
                                                <Field
                                                    type="text"
                                                    id={`banks[${index}].account.number`}
                                                    name={`banks[${index}].account.number`}
                                                    className="form-control"
                                                />
                                                <div className="form-error">
                                                    <ErrorMessage name={`banks[${index}].account.number`} />
                                                </div>
                                            </div>
                                            <div className="self-center">
                                                <button
                                                    type="button"
                                                    className="text-3xl text-red-500 mt-2"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="text-sm text-blue-500 mt-4"
                                        onClick={() =>
                                            arrayHelpers.push({
                                                name: '',
                                                ifsc: '',
                                                account: {
                                                    type: '',
                                                    number: '',
                                                    holderName: '',
                                                },
                                            })
                                        }
                                    >
                                        + Add Bank Details
                                    </button>
                                </div>
                            )}
                        />

                        {/* <div className="font-semibold mt-8">Balance</div> */}
                        <div className="font-semibold mt-8">Opening Balance</div>
                        <div className="grid grid-cols-12 gap-6 pt-4">
                            <div className="col-span-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field
                                        type="text"
                                        id="openingBalance"
                                        name="openingBalance"
                                        className="form-control pl-8"
                                    />
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="openingBalance" />
                                </div>
                            </div>
                        </div>

                        {/* user details */}

                        <div className="font-bold text-xl mt-8">Sub Agency User</div>
                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="user.name">
                                    NAME
                                </label>
                                <Field type="text" id="user.name" name="user.name" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="user.name" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="user.email">
                                    EMAIL
                                </label>
                                <Field type="text" id="user.email" name="user.email" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="user.email" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="user.phoneNumber">
                                    PHONE NUMBER
                                </label>
                                <Field
                                    type="text"
                                    id="user.phoneNumber"
                                    name="user.phoneNumber"
                                    className="form-control"
                                />
                                <div className="form-error">
                                    <ErrorMessage name="user.phoneNumber" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2" htmlFor="user.designation">
                                    DESIGNATION
                                </label>
                                <Field
                                    type="text"
                                    id="user.designation"
                                    name="user.designation"
                                    className="form-control"
                                />
                                <div className="form-error">
                                    <ErrorMessage name="user.designation" />
                                </div>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                            isLoading={mutation.isLoading}
                        >
                            Add Sub Agency
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddSubAgencyRequestModal;
