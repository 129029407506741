/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import http from 'app/config/http';
import { TRootState } from 'app/store';
import { authActions } from 'app/store/auth';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import debounce from 'lodash.debounce';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IProfileForm } from '../../types';

const ProfileFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('This field is required'),
    email: Yup.string().trim().required('This field is required').email('Please enter a valid email address'),
    phoneNumber: Yup.string()
        .required('This field is required')
        .matches(/^\d{10}$/, 'Please enter a valid mobile number'),
    username: Yup.string().required('This field is required').matches(/^\S*$/, 'Username cannot contain spaces'),
});

interface ProfileEditFormProps {
    profile: any;
}

const ProfileEditForm: FC<ProfileEditFormProps> = (props) => {
    const { profile } = props;

    const { user } = useSelector((state: TRootState) => state.auth);
    const dispatch = useDispatch();

    const initialValues: IProfileForm = useMemo(() => {
        if (!profile)
            return {
                name: '',
                email: '',
                phoneNumber: '',
                username: '',
                designation: '',
            };

        return {
            name: profile.name,
            email: profile.email,
            phoneNumber: profile.phoneNumber,
            username: profile.username,
            designation: profile.designation,
        };
    }, [profile]);

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/profile`,
            payload
        );

        return data;
    });

    const handleSubmit = async (values: IProfileForm) => {
        try {
            await mutation.mutateAsync(values);

            dispatch(
                authActions.setUser({
                    ...user,
                    name: values.name,
                })
            );

            toast.success('Profile updated successfully.');
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    const uniqueEmailValidator = useCallback(
        debounce(async (value) => {
            // if the value is equal to the initial value, don't do anything
            if (value === initialValues.email) return;

            try {
                await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/email`, {
                    email: value,
                });
            } catch (err: any) {
                if (err.status === 400) {
                    return err.data.message;
                }
            }
        }, 500),
        []
    );

    const uniqueUsernameValidator = useCallback(
        debounce(async (value) => {
            // if the value is equal to the initial value, don't do anything
            if (value === initialValues.username) return;

            try {
                await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/username`, {
                    username: value,
                });
            } catch (err: any) {
                if (err.status === 400) {
                    return err.data.message;
                }
            }
        }, 500),
        []
    );

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={ProfileFormSchema}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className="grid grid-cols-12 gap-6 mt-8">
                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="name">
                            NAME
                        </label>
                        <Field type="text" id="name" name="name" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="name" />
                        </div>
                    </div>

                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="email">
                            EMAIL
                        </label>
                        <Field
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            validate={(val: string) => uniqueEmailValidator(val)}
                        />
                        <div className="form-error">
                            <ErrorMessage name="email" />
                        </div>
                    </div>

                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="phoneNumber">
                            PHONE NUMBER
                        </label>
                        <div className="input-group">
                            <div className="input-group-prepend">+91</div>
                            <Field type="text" id="phoneNumber" name="phoneNumber" className="form-control pl-12" />
                        </div>
                        <div className="form-error">
                            <ErrorMessage name="phoneNumber" />
                        </div>
                    </div>

                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="username">
                            USERNAME
                        </label>
                        <Field
                            type="text"
                            id="username"
                            name="username"
                            className="form-control"
                            validate={(val: string) => uniqueUsernameValidator(val)}
                        />
                        <div className="form-error">
                            <ErrorMessage name="username" />
                        </div>
                    </div>

                    <div className="col-span-4">
                        <label className="text-xs mb-2" htmlFor="designation">
                            DESIGNATION
                        </label>
                        <Field type="text" id="designation" name="designation" className="form-control" />
                        <div className="form-error">
                            <ErrorMessage name="designation" />
                        </div>
                    </div>
                </div>

                <LoaderButton
                    type="submit"
                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                    isLoading={mutation.isLoading}
                >
                    Save Changes
                </LoaderButton>
            </Form>
        </Formik>
    );
};

export default ProfileEditForm;
