import {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 *  An updater for data of type TData, allowing either direct
 * replacement with a new value or transformation via a function.
 */
type DataUpdater<TData> = TData | ((input: TData) => TData);

/**
 * The result of a query operation along with an additional
 * setData function, which can be used to update the queried data.
 */
type UseDataQueryResult<TData, TError> = UseQueryResult<TData, TError> & {
    setData: (updater: DataUpdater<TData>) => void;
};

/**
 * Custom hook for handling data queries with React Query,
 * providing additional functionality to update data.
 *
 * @template TQueryFnData The data returned by the query function.
 * @template TError The error type for the query.
 * @template TData The data type to be returned.
 * @template TQueryKey The type for the query key.
 *
 * @param {TQueryKey} queryKey The key for the query.
 * @param {QueryFunction<TQueryFnData, TQueryKey>} queryFn The function to fetch the data.
 * @param {Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: () => undefined; }} options Options for the query,
 * excluding 'queryKey', 'queryFn', and 'initialData', with an optional initialData function.
 *
 * @returns {UseDataQueryResult<TData, TError>} The result of the query with a setData function to update the query data.
 */
const useDataQuery = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
        initialData?: () => undefined;
    }
): UseDataQueryResult<TData, TError> => {
    // Use the useQuery hook from React Query to handle the query
    const query = useQuery(queryKey, queryFn, options);

    // Access the query client from React Query
    const queryClient = useQueryClient();

    // Function to update the query data
    const setData = useCallback(
        (updater: DataUpdater<TData>) => {
            // Use the queryClient to set the query data with the provided updater
            queryClient.setQueryData<TData>(queryKey, updater);
        },
        // Dependencies for the setData callback
        [queryClient, queryKey]
    );

    // Return the query result along with the setData function
    return { ...query, setData };
};

export default useDataQuery;
