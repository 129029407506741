import { IAgency } from './agency';


export enum IAgentStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export interface IAgencyUser {
    _id: string;
    name: string;
    email: string;
    username: string;
    password: string;
    phoneNumber: string;
    designation: string;
    role: string;
    agency: IAgency;
    createdAt?: string;
    status: IAgentStatus;
}