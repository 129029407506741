import { DeleteIcon } from 'app/icons';
import moment from 'moment';
import { FC } from 'react';

interface AnnouncementCardProps {
    data: any;
    onDelete: () => void;
}

const AnnouncementCard: FC<AnnouncementCardProps> = (props) => {
    const { data, onDelete } = props;

    return (
        <div className="card flex flex-col h-full">
            <div className="relative">
                <button
                    className="absolute right-3 top-3 bg-white rounded-md w-8 h-8 flex items-center justify-center text-gray-500 drop-shadow"
                    onClick={onDelete}
                >
                    <DeleteIcon height={18} />
                </button>
                <img
                    className="rounded-t-lg border-b object-cover object-center w-full aspect-video"
                    src={data.imageUrl}
                    alt=""
                />
            </div>
            <div className="px-5 pt-4 pb-6 flex flex-col h-full">
                <h6>{data.heading}</h6>
                <p className="mt-3 leading-normal whitespace-pre-line">{data.description}</p>
                <div className="mt-auto">
                    <div className="mt-5 text-xs text-gray-500">
                        Posted on{' '}
                        <span className="text-gray-900 font-medium">
                            {moment(data.createdAt, 'YYYYMMDD').format('DD MMM, YY [at] hh:mm A')}
                        </span>
                    </div>
                    <div className="mt-2 text-xs text-gray-500">
                        Posted by <span className="text-gray-900 font-medium">{data.createdBy.name}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnnouncementCard;
