import { useQuery } from '@tanstack/react-query';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { IPlugin } from 'app/types';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';



interface ICategorizedPlugins {
    paymentGateways: Array<IPlugin>;
    communicationChannels: Array<IPlugin>;
    seriesAddons: Array<IPlugin>;
    dashboardAddons: Array<IPlugin>;
}

interface PluginListProps {}



const PluginList: FC<PluginListProps> = (props) => {
    const navigate = useNavigate();

    const pluginsQuery = useQuery<IPlugin[]>(['plugins'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins`);

        return data;
    });

    const handleNavigate = (row: IPlugin) => {
        if (row._id === 'farepod-pay') return;

        if (!row.isActive) {
            return;
        }

        navigate(`/plugins/${row._id}`);
    };

    if (pluginsQuery.isLoading) return <PageLoader />;

    const plugins = pluginsQuery.data;

    const categorizedPlugins: ICategorizedPlugins = {
        paymentGateways: [],
        communicationChannels: [],
        seriesAddons: [],
        dashboardAddons: [],
    };

    plugins.forEach((plugin) => {
        switch (plugin.identifier) {
            case 'CCAVENUE_PAYMENT':
            case 'FAREPOD_PAY':
            case 'HDFC_GATEWAY_PAYMENT':
            case 'RAZORPAY_PAYMENT':
                categorizedPlugins.paymentGateways.push(plugin);
                break;
            case 'TAWK_TO_CHAT':
            case 'TWILIO_SMS':
            case 'WHATSAPP_BOT':
                categorizedPlugins.communicationChannels.push(plugin);
                break;
            case 'DYNAMIC_AI_MARKUP':
            case 'OUTWARD_API':
            case 'MY_SERIES':
                categorizedPlugins.seriesAddons.push(plugin);
                break;
            case 'OFFERS_AND_UPDATES':
            case 'ANNOUNCEMENTS':
            case 'LOGIN_IMAGES':
                categorizedPlugins.dashboardAddons.push(plugin);
                break;
            default:
                break;
        }
    });

    const Category = ({ title, plugins }: { title: string; plugins: IPlugin[] }) => {
        return (
            <div>
                <div className="mt-12 text-lg font-medium"> {title}</div>
                <div className="grid grid-cols-4 gap-6 mt-8">
                    {plugins.map((row) => (
                        <div
                            key={row._id}
                            className="card col-span-4 sm:col-span-2 xl:col-span-1 px-5 pt-4 pb-6 hover:cursor-pointer "
                            onClick={() => handleNavigate(row)}
                        >
                            <div className="flex justify-end">
                                <div
                                    className={`inline-flex text-xs px-3 py-1 rounded-full font-semibold  ${
                                        row.isEnabled ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-900'
                                    }`}
                                >
                                    {row.isActive ? (row.isEnabled ? 'ACTIVE' : 'NOT ACTIVE') : 'COMING SOON'}
                                </div>
                            </div>
                            <img className="max-h-10 max-w-[140px] mt-6" src={row.logoUrl} alt="" />
                            <div className="font-bold mt-4">{row.name}</div>
                            <p className="mt-2">{row.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="px-6 py-4">
            <h5 className="font-bold">Plugins</h5>
            <div className="overflow-auto">
                <Category title="Payment Gateways" plugins={categorizedPlugins.paymentGateways} />
                <Category title="Communication Channels" plugins={categorizedPlugins.communicationChannels} />
                <Category title="Series Add-ons" plugins={categorizedPlugins.seriesAddons} />
                <Category title="Dashboard Add-ons" plugins={categorizedPlugins.dashboardAddons} />
            </div>
        </div>
    );
};

export default PluginList;
