import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import Modal from 'app/components/Modal/Modal';
import http from 'app/config/http';
import { useParams } from 'react-router-dom';
import Spinner from 'app/components/Spinner/Spinner';

interface PaymentNotesProps {
    show: boolean;
    onClose: () => void;
    transactionId: string;
    type: string;
}

const PaymentNotes: FC<PaymentNotesProps> = (props) => {
    const { show, onClose, transactionId, type } = props;

    const { subAgencyId } = useParams();

    const paymentNoteQuery = useQuery(['PaymentNote', transactionId], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/reference-details/${transactionId}`
        );
        return data;
    });

    const paymentNote = paymentNoteQuery.data;

    return (
        <Modal show={show} onClose={onClose} className="px-10 py-10 w-[880px] max-w-full">
            <div className="text-2xl font-bold">{type} Note </div>

            {paymentNoteQuery.isLoading ? (
                <div className=" pt-8  flex justify-center ">
                    <Spinner />
                </div>
            ) : (
                <div>
                    <div className="grid grid-cols-3 gap-8 mt-7 mb-6">
                        <div className="col-span-2">
                            <div className="text-xs font-normal text-gray-500">Remark</div>
                            <div className="text-lg font-semibold mt-4">{paymentNote.remark}</div>
                        </div>
                        <div className="col-span-1">
                            <div className="text-xs font-normal text-gray-500">Amount</div>
                            <div className="text-lg font-semibold mt-4 ">{paymentNote.amount}</div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default PaymentNotes;
