import http from 'app/config/http';
import { CommissionPlanTypeEnum } from 'app/enums/commission-plan-type.enum';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';

export const getCommissionPlansOfSubAgency = async (subAgencyId: string) => {
    const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/agent/commission-plans/sub-agencies/${subAgencyId}`
    );
    return (data as AgencyCommissionPlans).data.subAgency.commissionPlans;
};

export type CommissionPlan = {
    _id: string;
    agency: string;
    name: string;
    subagencies: Array<string>;
    commissionPlanType: CommissionPlanTypeEnum;
    commissionFareSupplier: FareSupplierEnum;
    commissionBreakdown: any;
    status: string;
    createdAt: string;
    updatedAt: string;
    id: string;
};

type AgencyCommissionPlans = {
    message: string;
    data: {
        subAgency: {
            commissionPlans: Array<CommissionPlan>;
        };
    };
};
