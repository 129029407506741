import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'app/store';

interface PageHeadingProps {
    message: string;
}

const PageHeading: FC<PageHeadingProps> = (props) => {
    const { user } = useSelector((state: TRootState) => state.auth);

    return (
        <div className="flex items-center gap-2">
            <img className="h-8" src="/icons/hand-emoji.png" alt="" />
            <span className="font-bold">Hey {user.name} - </span>
            <span className="text-gray-500">{props.message}</span>
        </div>
    );
};

export default PageHeading;
