import { FC } from 'react';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import useDialog from 'app/hooks/use-dialog';
import InfoModal from 'app/components/InfoModal/InfoModal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DocumentUploadModal from '../DocumentUploadModal/DocumentUploadModal';

interface DocumentListProps {
    documents: any;
    subAgencyId: string;
}

const DocumentList: FC<DocumentListProps> = (props) => {
    const { documents, subAgencyId } = props;
    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();
    const queryClient = useQueryClient();
    const documentUploaddialog = useDialog();

    const deleteMutation = useMutation(async (values: any) => {
        await http.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/documents/${values._id}`
        );
    });

    const handleDocumentDelete = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Document deleted successfully.');

            const index = documents.findIndex((r: any) => r._id === _id);

            queryClient.setQueryData(['subAgency', subAgencyId, 'documents'], (prev: any) => {
                const newList = update(prev, {
                    $splice: [[index, 1]],
                });

                return newList;
            });
        } catch (ex: any) {
            console.log(ex);
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    const uploadMutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/documents`,
            payload
        );

        return data;
    });

    const handleDocumentUpload = async (values: any) => {
        try {
            const newDocuments = await uploadMutation.mutateAsync(values);
            toast.success('Document  added successfully.');
            queryClient.setQueryData(['subAgency', subAgencyId, 'documents'], newDocuments);
            documentUploaddialog.hide();
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        }
    };

    return (
        <div className="overflow-auto ">
            <div className="flex justify-end px-3">
                <button
                    onClick={documentUploaddialog.show}
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add Document
                </button>
            </div>

            <table className="card-table text-sm mt-6">
                <thead>
                    <tr className="bg-gray-50">
                        <th className="th-1">Name</th>
                        <th className="th-1"></th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((row: any) => (
                        <tr key={row._id} className="border-b border-gray-200 last:border-none">
                            <td className="td-1">
                                <div className="font-semibold">{row.name}</div>
                            </td>
                            <td className="td-1">
                                <div className="text-primary">
                                    <button onClick={() => window.open(`${row.url}`)}>View</button>
                                </div>
                            </td>

                            <td className="td-1">
                                <button className="text-red-500" onClick={() => deleteDialog.show(row._id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this Document'}
                onClose={deleteDialog.hide}
                onConfirm={handleDocumentDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />

            <DocumentUploadModal
                onClose={documentUploaddialog.hide}
                show={documentUploaddialog.isOpen}
                onSubmit={handleDocumentUpload}
            />
        </div>
    );
};

export default DocumentList;
