import { formatDOB, getGenderFromTitle } from 'app/utils/common-utils';
import React, { FC } from 'react';
import contryList from 'country-list';
import moment from 'moment';
interface PassengerDetailProps {
    index: number;
    type: string;
    row: any;
    isInternational: boolean;
}

const PassengerDetail: FC<PassengerDetailProps> = (props) => {
    const { index, row, type, isInternational } = props;
    return (
        <>
            <div key={index} className="grid grid-cols-5 gap-8 mb-3">
                <div className="col-span-1">
                    <div className="font-medium"> {`${type} ${index + 1}`} </div>
                </div>
                <div className="col-span-1">
                    <div className="font-medium">
                        {row.title} {row.firstName} {row.lastName}
                    </div>
                    <div className="text-xs">
                        {getGenderFromTitle(row.title)}, {type}
                    </div>
                    {(type === 'Infant' || isInternational) && (
                        <div className="text-xs font-normal">(DOB - {formatDOB(row.dob, 'YYYY-MM-DD')})</div>
                    )}
                </div>
                <div className="col-span-1">
                    <div className="space-y-1">
                        {row.pnrs.map((pnr: any, i: number) => (
                            <div key={i}>
                                <div className="font-medium">{pnr.number}</div>
                                <div className="text-xs">{pnr.sector}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-span-1">
                    {/* <div className="font-medium">{row.eTicketNumber || row.pnrs[0]?.eTicketNumber}</div> //old */}

                    {row.ticketNumbers.map((ticket: any, i: number) => (
                        <div key={i}>
                            <div className="font-medium">{ticket.number}</div>
                            <div className="text-xs">{ticket.sector}</div>
                        </div>
                    ))}

                    {row.ticketNumbers.length === 0 && <div className="font-medium">-</div>}
                </div>
                <div className="col-span-1">
                    <div className="font-medium">-</div>
                </div>
            </div>

            {isInternational && (
                <div key={index} className="grid grid-cols-5 gap-8 mb-2 min-w-[650px] text-xs mt-1">
                    <div className="col-span-1"></div>
                    <div className="col-span-1 flex flex-col justify-between">
                        <div className="text-xs font-normal text-gray-500">Nationality :</div>
                        <div className="text-sm font-normal"> {contryList.getName(row.passport.nationality)} </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Passport :</div>
                        <div className="text-sm font-normal"> {row.passport.number} </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Issue :</div>
                        <div className="text-sm font-normal">
                            {moment(row.passport.issueDate, 'YYYYMMDD').format('DD MMM YYYY')}
                        </div>
                    </div>
                    <div className="col-span-1 flex flex-col justify-between ">
                        <div className="text-xs font-normal text-gray-500">Expiry :</div>
                        {moment(row.passport.expiry, 'YYYYMMDD').format('DD MMM YYYY')}
                    </div>
                </div>
            )}
        </>
    );
};

export default PassengerDetail;
