import { useQuery } from '@tanstack/react-query';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import TableSearch from 'app/components/TableSearch/TableSearch';
import http from 'app/config/http';
import { IBankTransaction } from 'app/types';
import { FC, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TransactionTable from './components/TransactionTable/TransactionTable';
import { debounce } from 'lodash';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';

interface BankTransactionListProps {}

const BankTransactionList: FC<BankTransactionListProps> = (props) => {
    const [filter, setFilter] = useState({
        search: '',
        date1: '',
        date2: '',
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [params, setParams] = useState<String>(`?page=${currentPage}`);

    const transactionQuery = useQuery<IBankTransaction[]>(['bankTransactions', params], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/accounts${params}`);
        setPageCount(data.pageCount);

        return data.data;
    });

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.date1 !== '') newUrl += `&fromDate=${filter.date1}`;
            if (filter.date2 !== '') newUrl += `&toDate=${filter.date2}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;

            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const debouncedSearch = useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleDateFilter = (date1: string, date2: string) => {
        setFilter((prev) => ({ ...prev, date1, date2 }));
        setCurrentPage(() => 1);
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Transactions</h5>
                <Link
                    to="/account/transactions/add"
                    className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                >
                    Add Transaction
                </Link>
            </div>

            <div className="card mt-8">
                <div className="flex justify-between items-center px-6 pt-5 bg-white">
                    <TableSearch placeholder="Search for transaction" onSearch={handleSearch} />

                    <TableDateFilter onChange={handleDateFilter} />
                </div>

                <div className="mt-6">
                    <TransactionTable
                        isLoading={transactionQuery.isLoading}
                        params={params}
                        transactions={transactionQuery.data}
                    />
                    <div className="px-4 pb-3 ">
                        <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankTransactionList;
