import { ISeriesTicket } from 'app/types/series-ticket';
import { FC, useLayoutEffect, useRef } from 'react';
import { formatDate } from 'app/utils/date-utils';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { formatCurrency } from 'app/utils/currency-utils';
import { MenuDotIcon } from 'app/icons';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import update from 'immutability-helper';
import moment from 'moment';
import { toast } from 'react-toastify';
import useDialog from 'app/hooks/use-dialog';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import InfoModal from 'app/components/InfoModal/InfoModal';
import Spinner from 'app/components/Spinner/Spinner';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

interface SeriesInventoryTableProps {
    seriesTickets: ISeriesTicket[];
    isLoading: boolean;
    QueryIdentifier: any[];
    handleIsLiveToggle: any;
    showIsLive: boolean;
}

const SeriesInventoryTable: FC<SeriesInventoryTableProps> = (props) => {
    const { seriesTickets, isLoading, QueryIdentifier, handleIsLiveToggle, showIsLive } = props;

    const navigate = useNavigate();

    const tableTop = useRef();

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();

    const queryClient = useQueryClient();

    const deleteMutation = useMutation(async (values: any) => {
        // delete api here this needs to be checked
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${values._id}`);
    });

    const handleSeriesDelete = async () => {
        try {
            const _id = deleteDialog.data;

            // uncommit this
            await deleteMutation.mutateAsync({ _id });

            toast.success('Series deleted successfully.');

            const index = seriesTickets.findIndex((r) => r._id === _id);

            queryClient.setQueryData(QueryIdentifier, (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                });
            });
        } catch (ex: any) {
            console.log(ex);
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    const changeLiveNetworkMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${values._id}/is-live-on-network`,
            { isLiveOnNetwork: values.isLiveOnNetwork }
        );
        return data;
    });

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'yellow';
            case 'COMPLETED':
            case 'SUBMITTED':
                return 'green';
            case 'CANCELLED':
                return 'red';
        }
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Naming Pending';
            case 'COMPLETED':
                return 'Completed';
            case 'SUBMITTED':
                return 'Submitted';
            case 'CANCELLED':
                return 'Cancelled';
        }
    };

    const handleLiveOnNetworkChange = async (row: ISeriesTicket, isChecked: boolean) => {
        queryClient.setQueryData(QueryIdentifier, (prevValues: any) => {
            const index = prevValues.findIndex((val: any) => val._id === row._id);

            return update(prevValues, {
                [index]: { isLiveOnNetwork: { $set: isChecked } },
            });
        });

        try {
            await changeLiveNetworkMutation.mutateAsync({ _id: row._id, isLiveOnNetwork: isChecked });

            toast.success(`Series inventory  ${isChecked ? 'enabled' : 'disabled'} from network`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            queryClient.setQueryData(QueryIdentifier, (prevValues: any) => {
                const index = prevValues.findIndex((val: any) => val._id === row._id);

                return update(prevValues, {
                    [index]: { isLiveOnNetwork: { $set: !isChecked } },
                });
            });
        }
    };

    return (
        <div className='overflow-auto'>
            <table className='card-table text-sm overflow-auto '>
                <thead>
                    <tr className='bg-gray-50' ref={tableTop}>
                        <th className='th-1'>Inventory ID</th>
                        <th className='th-1'>Travel & Date</th>
                        <th className='th-1'>Fare & Seats</th>
                        <th className='th-1'>Flight Details</th>
                        <th className='th-1'>Airline PNR</th>
                        <th className='th-1'>Net Selling Price</th>
                        <th className='th-1'>Created At</th>
                        <th className='th-1 group'>
                            <div>Live On Network</div>
                            <div
                                onClick={() => handleIsLiveToggle(showIsLive)}
                                className='cursor-pointer  hidden text-blue-500 text-xs font-semibold  group-hover:block'
                            >
                                {showIsLive ? 'Show all' : 'Show live only'}
                            </div>
                        </th>
                        <th className='th-1'></th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && seriesTickets.length === 0 && (
                        <tr>
                            <td align='center' colSpan={10} className='py-12 font-medium text-base text-[#98A2B3]'>
                                - No data available -
                            </td>
                        </tr>
                    )}
                    {!isLoading &&
                        seriesTickets.map((row) => (
                            <tr key={row._id} className='border-b border-gray-200 last:border-none'>
                                <td className='td-1'>
                                    <div
                                        className='font-semibold text-blue-500 cursor-pointer  '
                                        onClick={() => navigate(`/series-inventory/${row._id}/bookings/AG`)}
                                    >
                                        {row._id}
                                    </div>
                                    <StatusBadge className='mt-1' color={getStatusClass(row.status)}>
                                        {getStatusText(row.status)}
                                    </StatusBadge>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold text-nowrap'>
                                        {row.trip.departure.airport.city} to {row.trip.arrival.airport.city}
                                    </div>
                                    <p className='mt-1'>{formatDate(row.trip.departure.date, 'DD MMM, YYYY')}</p>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold text-nowrap'>{`${formatCurrency(
                                        row.farePerSeat
                                    )} / Seat`}</div>
                                    <p className='mt-1 text-nowrap'>
                                        {row.bookings.length}/{row.totalSeats} Seats Sold
                                    </p>
                                </td>
                                <td className='td-1'>
                                    <div className='flex items-center gap-3'>
                                        <img className='w-9 h-9' crossOrigin='anonymous' src={row.flight.logo} alt='' />
                                        <div>
                                            <div className='font-semibold text-nowrap '>{row.flight.name}</div>
                                            <p className='mt-1 text-nowrap'>
                                                {row.flight.code} {row.flight.number}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className='td-1 px-8'>
                                    <div className='font-semibold text-nowrap '>{row.pnr}</div>
                                </td>
                                <td className='td-1'>
                                    {row.selling ? (
                                        <>
                                            <div className='font-semibold'>{formatCurrency(row.selling.price)}</div>
                                            <p className='mt-1'>
                                                {row.selling.earnings >= 0 ? (
                                                    <span className='text-green-500'>
                                                        + {formatCurrency(row.selling.earnings)}
                                                    </span>
                                                ) : (
                                                    <span className='text-red-500'>
                                                        {formatCurrency(row.selling.earnings)}
                                                    </span>
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <span className='font-bold'>-</span>
                                    )}
                                </td>
                                <td className='td-1'>
                                    <div className='min-w-max'>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold'>
                                        <input
                                            type='checkbox'
                                            className='form-switch success'
                                            checked={row.isLiveOnNetwork}
                                            onChange={(e) => handleLiveOnNetworkChange(row, e.target.checked)}
                                        />
                                    </div>
                                </td>
                                <td className='td-1'>
                                    <Dropdown
                                        target={
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className='dropdown-action'>
                                                <MenuDotIcon />{' '}
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/series-inventory/${row._id}/bookings/AG`}>
                                                    View Bookings
                                                </DropdownItem>
                                                <DropdownItem to={`/series-inventory/${row._id}/edit`}>
                                                    Edit
                                                </DropdownItem>
                                                <DropdownItem to={`/series-inventory/${row._id}/revise-markup`}>
                                                    <span className='text-blue-500'>Revise Markup</span>
                                                </DropdownItem>
                                                <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                    <span className='text-red-500'>Delete</span>
                                                </DropdownItem>
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className='flex justify-center items-center min-h-[500px]'>
                    <Spinner />
                </div>
            )}

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this series?'}
                onClose={deleteDialog.hide}
                onConfirm={handleSeriesDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </div>
    );
};

export default SeriesInventoryTable;
