/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction } from 'react';

interface AmendmentNavProps {
    activeTab: string;
    setActiveTab: Dispatch<SetStateAction<string>>;
}

const AmendmentNav: FC<AmendmentNavProps> = (props) => {
    const { activeTab, setActiveTab } = props;

    return (
        <ul className="flex text-base font-normal px-6 pt-6 pb-2">
            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '1' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('1')}
                >
                    Amendments
                </a>
            </li>

            <li className="tab-item">
                <a
                    className={`mr-14 cursor-pointer ${activeTab === '2' ? 'font-bold' : ''}`}
                    onClick={() => setActiveTab('2')}
                >
                    Unsettled
                </a>
            </li>
        </ul>
    );
};

export default AmendmentNav;
