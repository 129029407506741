import moment from 'moment';
import { FC, useEffect, useState } from 'react';

const txDate = (date: string) => {
    if (!date) return '';

    return moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
};

interface TableDateFilterProps {
    onChange?: (date1: string, date2: string) => void;
    fromDate?: string;
    toDate?: string;
}

const TableDateFilter: FC<TableDateFilterProps> = (props) => {
    const { onChange, fromDate = '', toDate = '' } = props;

    const [date1, setDate1] = useState<string>('');
    const [date2, setDate2] = useState<string>('');

    useEffect(() => {
        try {
            let txFromDate = fromDate ? moment(fromDate, 'YYYYMMDD').format('YYYY-MM-DD') : '';
            setDate1(txFromDate);
            let txToDate = toDate ? moment(toDate, 'YYYYMMDD').format('YYYY-MM-DD') : '';
            setDate2(txToDate);
        } catch {}
    }, [fromDate, toDate]);

    const handleDate1Change = (e: any) => {
        const val = e.target.value;
        setDate1(val);

        onChange?.(txDate(val), txDate(date2));
    };

    const handleDate2Change = (e: any) => {
        const val = e.target.value;
        setDate2(val);

        onChange?.(txDate(date1), txDate(val));
    };

    return (
        <div className="flex items-center gap-3">
            <label className="text-xs" htmlFor="">
                FILTER BY DATE
            </label>
            <input
                type="date"
                className="border border-gray-300 rounded-xl text-sm px-4 py-2 outline-none font-medium"
                value={date1}
                onChange={handleDate1Change}
            />

            <label className="text-xs" htmlFor="">
                TO
            </label>
            <input
                type="date"
                className="border border-gray-300 rounded-xl text-sm px-4 py-2 outline-none font-medium"
                value={date2}
                onChange={handleDate2Change}
            />
        </div>
    );
};

export default TableDateFilter;
