import { useEffect } from 'react';
import Modal from 'app/components/Modal/Modal';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { PaymentStatusEnum } from 'app/enums/payment-status.enum';
import { IGatewayPayment } from '../../PaymentListGateway';
import { getPaymentEnumLabel } from 'app/utils/payment-gateway.utils';
import { formatCurrency } from 'app/utils/currency-utils';

type IConfirmationModal = {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
    actionType: PaymentStatusEnum.APPROVED | PaymentStatusEnum.REJECTED;
    payment: IGatewayPayment;
};

const ConfirmationModal = (props: IConfirmationModal) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const GenerateMessage = () => {
        const action = props.actionType === PaymentStatusEnum.APPROVED ? 'Approve' : 'Reject';
        const amount = formatCurrency(props.payment.amount);
        const method = getPaymentEnumLabel(props.payment.method);

        return (
            <div className='text-gray-900 mt-4 text-center'>
                Are you sure you want to <span className='font-semibold'>{action}</span> payment of{' '}
                <span className='font-semibold'>{amount}</span> made via <span className='font-semibold'>{method}</span>{' '}
            </div>
        );
    };

    return (
        <Modal className='px-10 py-10' show={props.show} onClose={props.onClose}>
            <GenerateMessage />
            <div className='flex justify-center gap-4 mt-6'>
                <LoaderButton
                    className={` ${
                        props.actionType === PaymentStatusEnum.APPROVED ? 'bg-green-500' : 'bg-red-500'
                    } text-white px-6 text-sm rounded-lg`}
                    onClick={props.onConfirm}
                    isLoading={props.isLoading}
                    disabled={props.isLoading}
                >
                    {props.actionType === PaymentStatusEnum.APPROVED ? 'Approve' : 'Reject'}
                </LoaderButton>
                <button className='bg-gray-200 text-gray-600 px-6 text-sm rounded-lg' onClick={props.onClose}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
