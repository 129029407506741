import BackLink from 'app/components/BackLink/BackLink';
import { FC } from 'react';
import StaticPageAddForm from './components/StaticPageAddForm/StaticPageAddForm';

interface StaticPageAddProps {}

const StaticPageAdd: FC<StaticPageAddProps> = (props) => {
    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Add Page</div>
                <StaticPageAddForm />
            </div>
        </>
    );
};
export default StaticPageAdd;
