import { ChevronDownIcon } from 'app/icons';
import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import { ITicketTermsAndConditions } from '../FareRulesEditor';
import moment from 'moment';

interface ITicketRulesTable {
    history: ITicketTermsAndConditions[];
}

const TicketRulesTable: FC<ITicketRulesTable> = (props) => {
    const { history } = props;
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const handleRowClick = (index: number) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    console.log({ history });

    return (
        <div className='card px-2 py-4'>
            <table className='card-table text-sm'>
                <thead>
                    <tr className='bg-gray-50'>
                        <th className='th-1'>ID</th>
                        <th className='th-1'>Created by</th>
                        <th className='th-1'>Created On</th>
                        <th className='th-1' />
                    </tr>
                </thead>
                <tbody className=''>
                    {/* {!isLoading && bookings.length === 0 && ( */}
                    {false && (
                        <tr>
                            <td align='center' colSpan={10} className='py-12 font-medium text-base text-[#98A2B3]'>
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {/* {!isLoading && */}

                    {history.map((row, index) => (
                        <>
                            <tr
                                className='border-b border-gray-200 last:border-none hover:cursor-pointer'
                                onClick={() => handleRowClick(index)}
                            >
                                <td className='td-1'>
                                    <div className='font-semibold  text-nowrap'>{row._id}</div>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold text-nowrap'>{row.createdBy.name}</div>
                                </td>
                                <td className='td-1'>
                                    <div className='font-semibold text-nowrap'>
                                        {moment(row.createdAt, 'YYYYMMDDHHmmss').format('dddd D MMM YYYY hh:MM A')}
                                    </div>
                                </td>
                                <td className='td-1'>
                                    <div className='flex gap-2 mb-4'>
                                        {/* <div>View </div> */}
                                        <motion.div
                                            className='self-center inline-block items-center justify-center  p-[6px] rounded-full '
                                            animate={{
                                                rotate: expandedRowIndex === index ? 180 : 0,
                                            }}
                                            transition={{
                                                duration: 0.35,
                                            }}
                                        >
                                            <ChevronDownIcon height='12' width='12' />
                                        </motion.div>
                                    </div>
                                </td>
                            </tr>
                            {expandedRowIndex === index && (
                                <tr>
                                    <td colSpan={5}>
                                        <div
                                            className='custom-ticket-html-styles px-4 py-3 pb-8 border-b !mt-6 shadow-sm'
                                            dangerouslySetInnerHTML={{ __html: row.content }}
                                        />
                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TicketRulesTable;
