import http from 'app/config/http';
import { FareSupplierAirlineFilterStatusEnum } from 'app/enums/fare-supplier-airline-filter-status.enum';
import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';
import { IAirline, IFareSupplier } from 'app/types';

export const getAllFaresuppliers = async () => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers`);
    return data as IFareSupplier[];
};

export const getFareSupplierAirlineFilter = async (fareSupplier: FareSupplierIdEnum) => {
    const { data } = await http.get(
        `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/${fareSupplier}/fare-supplier-airline-filter`
    );
    return data;
};

export const updateFareSupplierAirlineFilter = async (payload: {
    payload: {
        status: FareSupplierAirlineFilterStatusEnum;
        includeAirlines: Array<IAirline>;
        excludeAirlines: Array<IAirline>;
    };
    fareSupplier: FareSupplierIdEnum;
}) => {
    const { data } = await http.put(
        `${process.env.REACT_APP_API_URL}/api/v1/agent/fare-suppliers/${payload.fareSupplier}/fare-supplier-airline-filter`,
        payload.payload
    );

    console.log({ data });
    return data.commissionPlans;
};




