import http from 'app/config/http';
import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import SubAgencyRequestTable from './components/SubAgencyRequestTable/SubAgencyRequestTable';
import { SUB_AGENCY_REQUEST_STATUS_LIST } from 'app/utils/constants';

interface SubAgencyRequestListProps {}

const SubAgencyRequestList: FC<SubAgencyRequestListProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageCount, setPageCount] = useState<number>(0);
    let page = searchParams.get('page') || 1;
    let status = searchParams.get('status') || '';

    const subAgencyRequestQuery = useQuery(['subAgencyRequestListPaginated', status, page], async () => {
        let params = '?';
        page ? (params += `page=${page}`) : (params += '');
        status ? (params += `&status=${status}`) : (params += '');
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agency-registrations${params}`
        );
        setPageCount(data.pageCount);
        return data.data;
    });

    const handleRequestStatusChangeFilter = (status: string) => {
        if (status === '') {
            searchParams.delete('status');
        } else {
            searchParams.set('status', status);
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams, {
            replace: true,
        });
    };

    const handlePageChange = (val: any) => {
        let newPage = val.selected + 1;
        searchParams.set('page', newPage);
        setSearchParams(searchParams, { replace: true });
    };

    return (
        <>
            <div className='flex justify-between items-center'>
                <h5 className='font-bold'>Sub Agency Requests</h5>
            </div>

            <div className='card mt-6'>
                <div className='flex justify-end items-center gap-8 px-6'>
                    <select
                        className='border border-gray-300 rounded-xl text-sm pl-4 pr-8 py-2 outline-none font-medium mt-6'
                        onChange={(e) => handleRequestStatusChangeFilter(e.target.value)}
                        value={status}
                    >
                        <option value=''>Filter by Status</option>
                        {SUB_AGENCY_REQUEST_STATUS_LIST.map((status) => (
                            <option key={status.value} value={status.value}>
                                {status.label}
                            </option>
                        ))}
                    </select>
                </div>

                <SubAgencyRequestTable
                    subAgencyRequests={subAgencyRequestQuery.data}
                    isLoading={subAgencyRequestQuery.isLoading}
                    params={status}
                />

                <div className='px-4 pb-3 '>
                    <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
                </div>
            </div>
        </>
    );
};

export default SubAgencyRequestList;
