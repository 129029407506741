import debounce from 'lodash.debounce';
import Spinner from '../Spinner/Spinner';
import useSearchQuery from './hooks/use-search-query';
import { SearchEntityEnum } from './search-entity.enum';
import { useLayoutEffect, useRef, useState } from 'react';
import RenderSubAgency from './components/RenderSubAgency';
import RenderAgencySeries from './components/RenderAgencySeries';
import RenderBookingSearch from './components/RenderBookingSearch';
import RenderSubAgencySeries from './components/RenderSubAgencySeries';

const onClickOutside = (element: any, callback: () => void) => {
    document.addEventListener('click', (e) => {
        if (!element.contains(e.target)) callback();
    });
};

export const SearchBar = () => {
    const { fetch, groups, isEmpty, isLoading, resetResults } = useSearchQuery();

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [show, setShow] = useState(false);
    const handleKeyUp = debounce((e: any) => {
        const val = e.target.value;

        if (val.length > 1) {
            setShow(true);
            fetch(val);
        } else {
            handleCloseSearch();
        }
    }, 500);

    // BookingStatusEnum;

    useLayoutEffect(() => {
        if (ref.current) {
            // ref.current.addEventListener('click', () => setShow(true));
            onClickOutside(ref.current, handleCloseSearch);
        }
    }, []);

    const handleCloseSearch = () => {
        setShow(false);
        inputRef.current.value = '';
        resetResults();
    };

    return (
        <div ref={ref} className='relative w-[620px] '>
            <input
                ref={inputRef}
                onKeyUp={handleKeyUp}
                className='form-control placeholder:text-base'
                placeholder='Search for Inventories, Bookings and Sub Agencies'
            />
            {show && (
                <div className='absolute w-full -bottom-30 min-h-[320px] max-h-[85vh] bg-white rounded-sm flex flex-col gap-6 shadow-xl py-1 pt-2'>
                    <div className='my-2 overflow-auto'>
                        {isEmpty && !isLoading && (
                            <div className='px-2 py-4 flex justify-center'> No Results found </div>
                        )}

                        {groups.map(
                            (group) =>
                                (group.isLoading || group.data.length > 0) && (
                                    <div key={group.type}>
                                        <div className='text-grayscale-500 tracking-[0.8px] text-[0.65rem] uppercase px-4 py-1'>
                                            {group.label}
                                        </div>
                                        {group.isLoading ? (
                                            <div className='flex items-center gap-3 py-2 px-4 text-sm text-grayscale-500'>
                                                <Spinner size={16} />
                                                Loading...
                                            </div>
                                        ) : (
                                            group.data.map((row) => {
                                                if (group.type === SearchEntityEnum.Bookings) {
                                                    return (
                                                        <RenderBookingSearch
                                                            booking={row}
                                                            handlePreNavigate={handleCloseSearch}
                                                        />
                                                    );
                                                } else if (group.type === SearchEntityEnum.SubAgencySeriesTicket) {
                                                    return (
                                                        <RenderSubAgencySeries
                                                            subAgencyInventory={row}
                                                            handlePreNavigate={handleCloseSearch}
                                                        />
                                                    );
                                                } else if (group.type === SearchEntityEnum.AgencySeriesTicket) {
                                                    return (
                                                        <RenderAgencySeries
                                                            seriesInventory={row}
                                                            handlePreNavigate={handleCloseSearch}
                                                        />
                                                    );
                                                } else if (group.type === SearchEntityEnum.SubAgency) {
                                                    return (
                                                        <RenderSubAgency
                                                            subAgency={row}
                                                            handlePreNavigate={handleCloseSearch}
                                                        />
                                                    );
                                                } else return '';
                                            })
                                        )}
                                    </div>
                                )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchBar;