/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useMemo } from 'react';
import { createPassengerTemplate } from './manualFlight.utils';
import { PassengerTypeEnum } from 'app/enums/passenger-type.enum';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

import { IPassenger } from 'app/types/IPassenger';
import { PASSENGER_TITLES } from 'app/shared/constants/passenger-titles';
import { IManualBookingDetailForm, IManualBookingForm } from '../types/IManualBooking';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';

import * as Yup from 'yup';

const passengerSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    dob: Yup.date().required('Date of birth is required').nullable(),
});

interface IStep2Form {
    onSubmit: (values: Partial<IManualBookingForm>) => void;
    onBack: () => void;
    passengers: { adult: number; infant: number; child: number };
    isMutating: boolean;
}

const Step2Form: FC<IStep2Form> = (props) => {
    const { onBack, onSubmit, passengers, isMutating } = props;

    /////////////////////////////////////////////////\\\
    const validationSchema = Yup.object().shape({
        // Adults validation (Array of adults)
        adults: Yup.array().of(passengerSchema).min(1, 'At least one adult is required'),

        // Children validation (Array of children)
        children: Yup.array().of(passengerSchema).min(0, 'Invalid number of children'),

        // Infants validation (Array of infants)
        infants: Yup.array().of(passengerSchema).min(0, 'Invalid number of infants'),

        // Contact Details validation
        contactDetails: Yup.object().shape({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            mobileNumber: Yup.string()
                .matches(/^[0-9]+$/, 'Mobile number must be numeric')
                .required('Mobile number is required'),
        }),

        // Price Details validation
        priceDetails: Yup.object().shape({
            managementFeesPerSeat: Yup.number()
                .required('Management fee per seat is required')
                .typeError('Must be a valid number')
                .min(0, 'Management fee must be a positive number'),
            managementFeesGstRate: Yup.number()
                .required('GST rate is required')
                .typeError('Must be a valid number')
                .min(0, 'GST rate must be a positive number')
                .max(100, 'GST rate cannot exceed 100'),

            // Adult pricing
            adult: Yup.object().shape({
                base: Yup.number()
                    .required('Base fare for adults is required')
                    .typeError('Must be a valid number')
                    .min(0, 'Base fare must be a positive number'),
                taxAndFees: Yup.number()
                    .required('Tax and fees for adults is required')
                    .typeError('Must be a valid number')
                    .min(0, 'Tax and fees must be a positive number'),
            }),

            // context: { childCount: passengers.child, infantCount: passengers.infant },

            // Child pricing (conditionally required)
            child: Yup.object()
                .nullable()
                .when({
                    is: () => {
                        return passengers.child > 0;
                    },
                    then: Yup.object().shape({
                        base: Yup.number()
                            .required('Base fare for children is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Base fare must be a positive number'),
                        taxAndFees: Yup.number()
                            .required('Tax and fees for children is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Base fare must be a positive number'),
                    }),
                }),

            // Infant pricing (conditionally required)
            infant: Yup.object()
                .nullable()
                .when({
                    is: () => {
                        return passengers.infant > 0;
                    },
                    then: Yup.object().shape({
                        base: Yup.number()
                            .required('Base fare for infants is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Base fare must be a positive number'),
                        taxAndFees: Yup.number()
                            .required('Tax and fees for infants is required')
                            .typeError('Must be a valid number')
                            .min(0, 'Tax and fees must be a positive number'),
                    }),
                }),
        }),
    });

    const initialValues = useCallback((): IManualBookingDetailForm => {
        const createArrayOfPassengers = (count: number, passengerType: PassengerTypeEnum): Array<IPassenger> => {
            const passengerList: Array<IPassenger> = [];
            for (let i = 0; i < count; i++) {
                passengerList.push(createPassengerTemplate(passengerType));
            }
            return passengerList;
        };

        return {
            adults: createArrayOfPassengers(passengers.adult, PassengerTypeEnum.ADULT),
            children: createArrayOfPassengers(passengers.child, PassengerTypeEnum.CHILD),
            infants: createArrayOfPassengers(passengers.infant, PassengerTypeEnum.INFANT),
            priceDetails: {
                adult: {
                    base: 0,
                    taxAndFees: 0,
                },
                child:
                    passengers.child === 0
                        ? null
                        : {
                              base: 0,
                              taxAndFees: 0,
                          },
                infant:
                    passengers.infant === 0
                        ? null
                        : {
                              base: 0,
                              taxAndFees: 0,
                          },
                managementFeesGstRate: 0,
                managementFeesPerSeat: 0,
            },

            contactDetails: {
                email: '',
                mobileNumber: '',
            },
        };
    }, [passengers.adult, passengers.child, passengers.infant]);

    const handleSubmit = async (
        values: IManualBookingDetailForm,
        formikHelpers: FormikHelpers<IManualBookingDetailForm>
    ) => {
        // console.log({ step2Form: values });
        onSubmit({ booking: values });
    };

    return (
        <Formik
            initialValues={initialValues()}
            enableReinitialize={false}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, errors }: FormikProps<IManualBookingDetailForm>) => (
                <Form>
                    <div className="pb-4">
                        {/* <button onClick={() => console.log({ errors, values })}>click</button> */}
                        <FieldArray name="adults">
                            {({ push, remove }) => (
                                <>
                                    {values.adults.map((passenger, passengerIndex) => (
                                        <div className="mt-6" key={`adult-${passengerIndex}`}>
                                            <PassengerDetailsForm
                                                passengerIndex={passengerIndex}
                                                passengerType={PassengerTypeEnum.ADULT}
                                                key={`adults-${passengerIndex}`}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                        <FieldArray name="children">
                            {({ push, remove }) => (
                                <>
                                    {values.children.map((passenger, passengerIndex) => (
                                        <div className="mt-6" key={`child-${passengerIndex}`}>
                                            {' '}
                                            <PassengerDetailsForm
                                                passengerIndex={passengerIndex}
                                                passengerType={PassengerTypeEnum.CHILD}
                                                key={`children-${passengerIndex}`}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                        <FieldArray name="infants">
                            {({ push, remove }) => (
                                <>
                                    {values.infants.map((passenger, passengerIndex) => (
                                        <div className="mt-6" key={`infant-${passengerIndex}`}>
                                            {' '}
                                            <PassengerDetailsForm
                                                passengerIndex={passengerIndex}
                                                passengerType={PassengerTypeEnum.INFANT}
                                                key={`infants-${passengerIndex}`}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        {/* contact details */}
                        <div className="mt-12">
                            <div className="font-semibold col-span-full">Contact Details</div>
                            <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`contactDetails.mobileNumber`}>
                                        MOBILE NUMBER
                                    </label>
                                    <Field
                                        type="text"
                                        id={`contactDetails.mobileNumber`}
                                        name={`contactDetails.mobileNumber`}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={`contactDetails.mobileNumber`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`contactDetails.email`}>
                                        E MAIL
                                    </label>
                                    <Field
                                        type="text"
                                        id={`contactDetails.email`}
                                        name={`contactDetails.email`}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={`contactDetails.email`} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Price Details */}
                        <div className="mt-12">
                            <div className="font-semibold col-span-full">Pricing Details</div>
                            <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`priceDetails.managementFeesPerSeat`}>
                                        MANAGEMENT FEE PER SEAT
                                    </label>
                                    <Field
                                        type="text"
                                        id={`priceDetails.managementFeesPerSeat`}
                                        name={`priceDetails.managementFeesPerSeat`}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={`priceDetails.managementFeesPerSeat`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`priceDetails.managementFeesGstRate`}>
                                        MANAGEMENT GST PER SEAT
                                    </label>
                                    <Field
                                        type="text"
                                        id={`priceDetails.managementFeesGstRate`}
                                        name={`priceDetails.managementFeesGstRate`}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={`priceDetails.managementFeesGstRate`} />
                                    </div>
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={`priceDetails.adult.base`}>
                                        ADULT BASE FARE
                                    </label>
                                    <Field
                                        type="text"
                                        id={`priceDetails.adult.base`}
                                        name={`priceDetails.adult.base`}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={`priceDetails.adult.base`} />
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label className="text-xs mb-2" htmlFor={'priceDetails.adult.taxAndFees'}>
                                        ADULT TAX & FEE
                                    </label>
                                    <Field
                                        type="text"
                                        id={'priceDetails.adult.taxAndFees'}
                                        name={'priceDetails.adult.taxAndFees'}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name={'priceDetails.adult.taxAndFees'} />
                                    </div>
                                </div>
                            </div>

                            {values.children.length > 0 && (
                                <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={`priceDetails.child.base`}>
                                            CHILD BASE FARE
                                        </label>
                                        <Field
                                            type="text"
                                            id={`priceDetails.child.base`}
                                            name={`priceDetails.child.base`}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={`priceDetails.child.base`} />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={'priceDetails.child.taxAndFees'}>
                                            CHILD TAX & FEE
                                        </label>
                                        <Field
                                            type="text"
                                            id={'priceDetails.child.taxAndFees'}
                                            name={'priceDetails.child.taxAndFees'}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={'priceDetails.child.taxAndFees'} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {values.infants.length > 0 && (
                                <div className="grid sm:grid-cols-12 sm:gap-4  mt-8">
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={`priceDetails.infant.base`}>
                                            INFANT BASE FARE
                                        </label>
                                        <Field
                                            type="text"
                                            id={`priceDetails.infant.base`}
                                            name={`priceDetails.infant.base`}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={`priceDetails.infant.base`} />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <label className="text-xs mb-2" htmlFor={'priceDetails.infant.taxAndFees'}>
                                            INFANT TAX & FEE
                                        </label>
                                        <Field
                                            type="text"
                                            id={'priceDetails.infant.taxAndFees'}
                                            name={'priceDetails.infant.taxAndFees'}
                                            className="form-control"
                                        />
                                        <div className="form-error">
                                            <ErrorMessage name={'priceDetails.infant.taxAndFees'} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center justify-between ">
                        <LoaderButton
                            type="button"
                            onClick={onBack}
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12  disabled:cursor-not-allowed"
                            disabled={isMutating}
                        >
                            Back
                        </LoaderButton>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12 "
                            isLoading={isMutating}
                        >
                            Next
                        </LoaderButton>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Step2Form;

interface IPassengerDetailsForm {
    passengerIndex: number;
    passengerType: PassengerTypeEnum;
}

const PassengerDetailsForm: FC<IPassengerDetailsForm> = (props) => {
    const { passengerIndex, passengerType } = props;

    const paxType = useMemo(() => {
        if (passengerType === PassengerTypeEnum.ADULT) return 'adults';
        if (passengerType === PassengerTypeEnum.CHILD) return 'children';
        if (passengerType === PassengerTypeEnum.INFANT) return 'infants';
    }, [passengerType]);

    const paxLabel = useMemo(() => {
        if (passengerType === PassengerTypeEnum.ADULT) return 'Adult';
        if (passengerType === PassengerTypeEnum.CHILD) return 'Child';
        if (passengerType === PassengerTypeEnum.INFANT) return 'Infant';
    }, [passengerType]);

    return (
        <div>
            <div className="col-span-12 mt-6 text-base leading-5 font-bold">{`${paxLabel} ${passengerIndex + 1}`}</div>
            <div className="mt-2  grid grid-cols-1 gap-2 sm:grid-cols-12 sm:gap-4">
                <div className="sm:col-span-2">
                    <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].title`}>
                        TITLE
                    </label>
                    <Field
                        as="select"
                        name={`${paxType}[${passengerIndex}].title`}
                        type="text"
                        id={`${paxType}[${passengerIndex}].title`}
                        className="form-control"
                    >
                        {PASSENGER_TITLES[paxType].map((title) => (
                            <option key={title} value={title}>
                                {title}
                            </option>
                        ))}
                    </Field>
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].title`} />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].firstName`}>
                        FIRST NAME
                    </label>
                    <Field
                        type="text"
                        id={`${paxType}[${passengerIndex}].firstName`}
                        name={`${paxType}[${passengerIndex}].firstName`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].firstName`} />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="text-xs mb-2" htmlFor={`${paxType}[${passengerIndex}].lastName`}>
                        LAST NAME
                    </label>
                    <Field
                        type="text"
                        id={`${paxType}[${passengerIndex}].lastName`}
                        name={`${paxType}[${passengerIndex}].lastName`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].lastName`} />
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <label className="text-xs mb-2  " htmlFor={`${paxType}[${passengerIndex}].dob`}>
                        D.O.B
                    </label>
                    <Field
                        type="date"
                        id={`${paxType}[${passengerIndex}].dob`}
                        name={`${paxType}[${passengerIndex}].dob`}
                        className="form-control"
                    />
                    <div className="form-error">
                        <ErrorMessage name={`${paxType}[${passengerIndex}].dob`} />
                    </div>
                </div>
            </div>
        </div>
    );
};
