import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import useDialog from 'app/hooks/use-dialog';
import React, { FC } from 'react';
import AddAnnouncementModal from './components/AddAnnouncementModal/AddAnnouncementModal';
import AnnouncementCard from './components/AnnouncementCard/AnnouncementCard';
import update from 'immutability-helper';
import { toast } from 'react-toastify';

interface AnnouncementsProps {}

const Announcements: FC<AnnouncementsProps> = (props) => {
    const addDialog = useDialog();
    const deleteDialog = useDialog<string>();

    const queryClient = useQueryClient();

    const query = useQuery<any[]>(['announcementList'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/announcements`);

        return data;
    });

    const deleteMutation = useMutation(async (values: any) => {
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/announcements/${values._id}`);
    });

    if (query.isLoading) return <PageLoader />;

    const announcements = query.data;

    const handleDeleteAnnouncement = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            const index = announcements.findIndex((r) => r._id === _id);

            queryClient.setQueryData(['announcementList'], (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                });
            });
        } catch (err) {
            toast.error('Some error occured while deleting the announcement, please try again');
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <h5 className="font-bold">Announcements</h5>
                {announcements.length < 3 && (
                    <button
                        className="text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56"
                        onClick={addDialog.show}
                    >
                        Make Announcement
                    </button>
                )}
            </div>

            <div className="mt-8">
                {announcements.length === 0 && <p className="text-center text-base">No announcements yet</p>}

                <div className="grid grid-cols-3 gap-6">
                    {announcements.map((row) => (
                        <div key={row._id} className="col-span-1">
                            <AnnouncementCard data={row} onDelete={() => deleteDialog.show(row._id)} />
                        </div>
                    ))}
                </div>
            </div>

            <AddAnnouncementModal show={addDialog.isOpen} onClose={addDialog.hide} />

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this announcement?'}
                onClose={deleteDialog.hide}
                onConfirm={handleDeleteAnnouncement}
                isLoading={deleteMutation.isLoading}
            />
        </>
    );
};

export default Announcements;
