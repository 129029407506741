import { useNavigate } from 'react-router-dom';
import { ISubAgencySearch } from '../types/sub-agency-search';

interface IRenderSubAgency {
    subAgency: ISubAgencySearch;
    handlePreNavigate: () => void;
}
const RenderSubAgency = (props: IRenderSubAgency) => {
    const { subAgency, handlePreNavigate } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        handlePreNavigate();
        navigate(`/sub-agencies/${subAgency._id}/detials?tab=1`);
    };

    return (
        <div className='hover:bg-gray-50 px-4 py-2 flex gap-4 cursor-pointer' onClick={handleClick}>
            <div className='flex flex-col justify-between'>
                <div className='font-semibold text-primary'>{subAgency._id}</div>
                <div>{subAgency.name}</div>
            </div>
            <div className='flex items-center gap-3' />
            <div className='flex flex-col justify-center items-center' />
        </div>
    );
};

export default RenderSubAgency;
