import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { MenuDotIcon } from 'app/icons';
import moment from 'moment';
import { FC, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { formatCurrency } from 'app/utils/currency-utils';
import Spinner from 'app/components/Spinner/Spinner';
import useDialog from 'app/hooks/use-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import InfoModal from 'app/components/InfoModal/InfoModal';
import update from 'immutability-helper';
import { useNavigate } from 'react-router-dom';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import { IBankTransaction } from 'app/types';
import { TRANSACTION_TYPES } from 'app/utils/constants';
import Tooltip from 'app/components/Tooltip/Tooltip';
import RemarkModal from '../RemarkModal/RemarkModal';

interface TransactionTableProps {
    transactions: IBankTransaction[];
    isLoading: boolean;
    params: any; //used in refetch for refetching the query
}

const TransactionTable: FC<TransactionTableProps> = (props) => {
    const { isLoading, params, transactions } = props;

    const tableTop = useRef();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();
    const remarkDialog = useDialog({ id: '', remark: '' });

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const deleteMutation = useMutation(async (values: any) => {
        // delete api here
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/accounts/${values._id}`);
    });

    const handleTransactionDelete = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Transaction deleted successfully.');

            const index = transactions.findIndex((r) => r._id === _id);

            queryClient.setQueryData(['bankTransactions', params], (prev: any) => {
                return update(prev, {
                    $splice: [[index, 1]],
                });
            });
        } catch (ex: any) {
            console.log(ex);
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50" ref={tableTop}>
                            <th className="th-1"> Transaction ID</th>
                            <th className="th-1">Amount</th>
                            <th className="th-1">Bank</th>
                            <th className="th-1">Account No.</th>
                            <th className="th-1">Date</th>
                            <th className="th-1">Type</th>
                            <th className="th-1">Agent</th>
                            <th className="th-1">Remarks</th>
                            <th className="th-1">Created At</th>
                            <th className="th-1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && transactions.length === 0 && (
                            <tr>
                                <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                    - No data available -
                                </td>
                            </tr>
                        )}
                        {!isLoading &&
                            transactions.map((row) => (
                                <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                    <td className="td-1">
                                        <div
                                            className="font-semibold  text-blue-500 cursor-pointer"
                                            onClick={() => navigate(`/account/transactions/${row._id}`)}
                                        >
                                            {row._id}
                                        </div>
                                        <Tooltip
                                            content={
                                                row.mapped ? (
                                                    <div className="px-4 py-3">
                                                        <p className="text-xs">Mapped By</p>
                                                        <div className="text-sm font-semibold mt-1">
                                                            {row.payment?.actionBy?.name}
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            placement="bottom-start"
                                        >
                                            <StatusBadge className="mt-1" color={row.mapped ? 'green' : 'yellow'}>
                                                {row.mapped ? 'Mapped' : 'Unmapped'}
                                            </StatusBadge>
                                        </Tooltip>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold whitespace-nowrap">
                                            {formatCurrency(row.balanceUploaded)}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{row.bankAccount.name}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">{row.bankAccount.account.number}</div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            {moment(row.transactionDate, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="flex flex-col items-center">
                                            <div className="font-semibold">{TRANSACTION_TYPES[row.type].label}</div>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold whitespace-nowrap">
                                            {row.agencyUser ? row.agencyUser.name : 'User deleted'}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div
                                            className="font-semibold text-blue-500 cursor-pointer"
                                            onClick={() =>
                                                remarkDialog.show({
                                                    id: row._id,
                                                    remark: row.transactionRemark,
                                                })
                                            }
                                        >
                                            view
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="min-w-max">
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                            <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <Dropdown
                                            target={
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a className="dropdown-action">
                                                    <MenuDotIcon />
                                                </a>
                                            }
                                            menu={
                                                <DropdownList>
                                                    <DropdownItem to={`/account/transactions/${row._id}`}>
                                                        View
                                                    </DropdownItem>
                                                    {!row.mapped && (
                                                        <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                            <span className="text-red-500">Delete</span>
                                                        </DropdownItem>
                                                    )}
                                                </DropdownList>
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {isLoading && (
                    <div className="flex justify-center items-center min-h-[500px]">
                        <Spinner />
                    </div>
                )}
            </div>

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this transaction?'}
                onClose={deleteDialog.hide}
                onConfirm={handleTransactionDelete}
                isLoading={deleteMutation.isLoading}
            />

            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />

            <RemarkModal
                onClose={remarkDialog.hide}
                remark={remarkDialog.data.remark}
                id={remarkDialog.data.id}
                show={remarkDialog.isOpen}
            />
        </>
    );
};

export default TransactionTable;
