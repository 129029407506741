import moment from 'moment';
import { produce } from 'immer';
import ReactDOM from 'react-dom';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { IAgencyUser } from 'app/types';
import { MenuDotIcon } from 'app/icons';
import useDialog from 'app/hooks/use-dialog';
import { useNavigate } from 'react-router-dom';
import { FC, useLayoutEffect, useRef } from 'react';
import { getInitials } from 'app/utils/common-utils';
import Spinner from 'app/components/Spinner/Spinner';
import InfoModal from 'app/components/InfoModal/InfoModal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';
import { mapAgentUserStatusToBoolean, mapBooleanToAgentUserStatus } from 'app/pages/AgencyUser/utils/common';

interface AgencyUserTableProps {
    users: IAgencyUser[];
    isLoading: boolean;
    params: any;
}

const AgencyUserTable: FC<AgencyUserTableProps> = (props) => {
    const { users, isLoading, params } = props;
    const navigate = useNavigate();
    const tableTop = useRef();

    useLayoutEffect(() => {
        let node = ReactDOM.findDOMNode(tableTop.current) as Element;
        node.scrollIntoView({ block: 'end', behavior: 'auto' });
    });

    const deleteDialog = useDialog<string>();
    const infoDialog = useDialog<string>();
    const queryClient = useQueryClient();

    const deleteMutation = useMutation(async (values: any) => {
        await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/${values._id}`);
    });

    const changeEnabledMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/${values._id}/is-enabled`,
            { status: values.status }
        );

        return data;
    });

    const handleUserDelete = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('User deleted successfully.');

            const index = users.findIndex((r) => r._id === _id);

            queryClient.setQueryData<IAgencyUser[]>(['agencyUserList', params], (prev) => {
                return produce(prev, (draft) => {
                    draft.splice(index, 1);
                    return draft;
                });
            });
        } catch (ex: any) {
            console.log(ex);
            infoDialog.show(ex.data.message);
        } finally {
            deleteDialog.hide();
        }
    };

    const handleEnabledChange = async (row: IAgencyUser) => {
        const previousStatus = row.status;

        queryClient.setQueryData<IAgencyUser[]>(['agencyUserList', params], (prevValues) => {
            const index = prevValues.findIndex((val: any) => val._id === row._id);
            return produce(prevValues, (draft) => {
                draft[index].status = mapBooleanToAgentUserStatus(!mapAgentUserStatusToBoolean(draft[index].status));
            });
        });

        try {
            await changeEnabledMutation.mutateAsync({
                _id: row._id,
                status: mapBooleanToAgentUserStatus(!mapAgentUserStatusToBoolean(previousStatus)),
            });
            toast.success(`User status updated successfully`);
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            queryClient.setQueryData<IAgencyUser[]>(['agencyUserList', params], (prevValues) => {
                const index = prevValues.findIndex((val: any) => val._id === row._id);

                return produce(prevValues, (draft) => {
                    draft[index].status = mapBooleanToAgentUserStatus(mapAgentUserStatusToBoolean(previousStatus));
                    return draft;
                });
            });
        }
    };

    return (
        <div className="overflow-auto">
            <table className="card-table text-sm">
                <thead>
                    <tr className="bg-gray-50" ref={tableTop}>
                        <th className="th-1">Name</th>
                        <th className="th-1">Username</th>
                        <th className="th-1">Email Address</th>
                        <th className="th-1">Role</th>
                        <th className="th-1">Created At</th>
                        <th className="th-1">User Status</th>
                        <th className="th-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && users.length === 0 && (
                        <tr>
                            <td align="center" colSpan={10} className="py-12 font-medium text-base text-[#98A2B3]">
                                - No data available -
                            </td>
                        </tr>
                    )}

                    {!isLoading &&
                        users.map((row) => (
                            <tr key={row._id} className="border-b border-gray-200 last:border-none">
                                <td className="td-1">
                                    <div className="flex items-center gap-3">
                                        <div className="w-10 h-10 bg-primary/10 rounded-full flex items-center justify-center">
                                            <div className="text-primary font-semibold">{getInitials(row.name)}</div>
                                        </div>

                                        <div>
                                            <div
                                                className="font-semibold text-blue-500 cursor-pointer"
                                                onClick={() => navigate(`/users/${row._id}/edit`)}
                                            >
                                                {row.name}
                                            </div>
                                            <p>{row.designation}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <p>{row.username}</p>
                                </td>
                                <td className="td-1">
                                    <p>{row.email}</p>
                                </td>
                                <td className="td-1">
                                    <p>{row.role || 'Admin'}</p>
                                </td>
                                <td className="td-1">
                                    <div className="min-w-max">
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}</p>
                                        <p>{moment(row.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}</p>
                                    </div>
                                </td>
                                <td className="td-1">
                                    <input
                                        type="checkbox"
                                        className="form-switch success"
                                        checked={mapAgentUserStatusToBoolean(row.status)}
                                        // onChange={(e) => handleEnabledChange(row, e.target.checked)}
                                        onChange={(e) => handleEnabledChange(row)}
                                    />
                                </td>
                                <td className="td-1">
                                    <Dropdown
                                        target={
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className="dropdown-action">
                                                <MenuDotIcon />
                                            </a>
                                        }
                                        menu={
                                            <DropdownList>
                                                <DropdownItem to={`/users/${row._id}/edit`}>Edit</DropdownItem>
                                                <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                    <span className="text-red-500">Delete</span>
                                                </DropdownItem>
                                            </DropdownList>
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {isLoading && (
                <div className="flex justify-center items-center min-h-[500px]">
                    <Spinner />
                </div>
            )}

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this user?'}
                onClose={deleteDialog.hide}
                onConfirm={handleUserDelete}
                isLoading={deleteMutation.isLoading}
            />
            <InfoModal message={infoDialog.data} show={infoDialog.isOpen} onClose={infoDialog.hide} />
        </div>
    );
};

export default AgencyUserTable;
