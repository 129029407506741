import BackLink from 'app/components/BackLink/BackLink';
import FareruleForm from 'app/components/FareruleForm/FareruleForm';
import MarkupControlForm from 'app/components/MarkupControlForm/MarkupControlForm';
import TravelBoutiqueCredentialsForm from '../shared/components/TravelBoutiqueCredentialsForm/TravelBoutiqueCredentialsForm';
import PageLoader from 'app/components/PageLoader/PageLoader';
import MarkupHistoryTable from 'app/components/MarkupHistoryTable/MarkupHistoryTable';
import useFareSupplierStatus from '../shared/hooks/useFareSupplierStatus';
import { FareSupplierEnum } from 'app/enums/fare-supplier.enum';
import { mapFareSupplierStatusToBoolean } from '../shared/utils';
import { Status } from 'app/enums/status.enum';
import { IMarkUpHistory } from '../shared/types/mark-up-history';
import { IKafilaDetails } from '../shared/types/kafila';
import KafilaCredentialsForm from '../shared/components/KafilaCredentialsForm/KafiilaCredentialsForm';
import { FareSupplierIdEnum } from 'app/enums/FareSupplierIdEnum.enum';
import FareSupplierAirlineFilter from 'app/components/FareSupplierAirlineFilter/FareSupplierAirlineFilter';

const FareSupplierTravelKafila = () => {
    const {
        data: queryData,
        isError,
        isLoading,
        queryIdentifier,
        handleToggleStatus,
    } = useFareSupplierStatus<IKafilaDetails>({
        fareIdentifier: FareSupplierEnum.KAFILA,
    });

    if (isLoading) return <PageLoader />;

    const data: IKafilaDetails = isError
        ? {
              agentId: '',
              username: '',
              password: '',
              markup: 0,
              fareRules: [] as string[],
              markupHistory: [] as IMarkUpHistory[],
              status: Status.DISABLED,
              baseUrl: '',
          }
        : queryData;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="flex justify-between items-center">
                    <div className="font-bold">Kafila Details</div>
                    <div>
                        <input
                            type="checkbox"
                            className="form-switch success"
                            checked={mapFareSupplierStatusToBoolean(data.status)}
                            onChange={(e) => handleToggleStatus(e.target.checked)}
                        />
                    </div>
                </div>

                <KafilaCredentialsForm
                    baseUrl={data.baseUrl}
                    agentId={data.agentId}
                    username={data.username}
                    password={data.password}
                />
            </div>

            <FareSupplierAirlineFilter fareSupplier={FareSupplierIdEnum.KAFILA} />

            {/* {!query.isError && ( */}
            {true && (
                <div className="card pt-5 mt-8">
                    <div className="px-6 font-bold">Markup Control & Fare Rules</div>

                    <div className="px-6 mt-8">
                        <MarkupControlForm
                            currentMarkup={data.markup}
                            fareSupplier={'kafila'}
                            refetchQueryIdentifier={queryIdentifier}
                        />
                    </div>

                    <div className="px-6 mt-8">
                        <FareruleForm
                            fareRules={data.fareRules}
                            fareSupplier={'kafila'}
                            refetchQueryIdentifier={queryIdentifier}
                        />
                    </div>

                    <div className="mt-10">
                        {data.markupHistory.length > 0 && <MarkupHistoryTable markupHistory={data.markupHistory} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default FareSupplierTravelKafila;
