import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { FC } from 'react';
import { toast } from 'react-toastify';

interface FileUploadFormProps {}

const FileUploadForm: FC<FileUploadFormProps> = (props) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/login-images`, payload);

        return data;
    });

    const handleChange = async (files: File[]) => {
        if (files && files.length > 0) {
            const formData = new FormData();

            const file = files[0];
            formData.append('file', file);

            if (file.size > 1024 * 1024) {
                toast.error('Maximum image size is 1MB per image.');
                return;
            }

            try {
                const res = await mutation.mutateAsync(formData);

                queryClient.setQueryData(['loginImageList'], res);
            } catch (err) {
                toast.error('Some error occured while uploading the image, please try again');
            }
        }
    };

    return (
        <label
            htmlFor="dropzone-file"
            className="flex flex-col justify-center items-center w-full relative h-56 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100"
        >
            {mutation.isLoading ? (
                <div className="absolute">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                            aria-hidden="true"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mb-3 w-10 h-10 text-gray-400"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6h.1a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">Click to upload</span> or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">SVG, PNG or JPG (Images upto 1MB)</p>
                    </div>
                    <input
                        id="dropzone-file"
                        type="file"
                        accept="image/*"
                        className="absolute top-0 left-0 w-full h-full opacity-0"
                        onChange={(e: any) => handleChange(e.target.files)}
                        onDrop={(e: any) => handleChange(e.dataTransfer.files)}
                        onDragOver={(e: any) => e.preventDefault()}
                    />
                </>
            )}
        </label>
    );
};

export default FileUploadForm;
