/* eslint-disable jsx-a11y/anchor-is-valid */
import AirlineSearchField from 'app/components/AirlineSearchField/AirlineSearchField';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import * as Yup from 'yup';
import { FC } from 'react';
import moment from 'moment';
import { DeleteIcon } from 'app/icons';
import { createBlankSegment } from './manualFlight.utils';
import { IAirline, IAirport, ISubAgency } from 'app/types';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { getSubAgencies } from 'app/api/services/sub-agecny.service';
import SearchComponent from 'app/components/SearchComponent/SearchComponent';
import { IManualBookingForm, IManualTicketForm } from '../types/IManualBooking';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { json } from 'stream/consumers';

const validationSchema = Yup.object().shape({
    subAgency: Yup.object().required('Sub Agency is required').nullable(),
    ticketNumber: Yup.string().required('Ticket Number is required'),
    bookingId: Yup.string().required('Booking ID is required'),
    pnr: Yup.string().nullable(),
    segments: Yup.array()
        .of(
            Yup.object().shape({
                flight: Yup.object().required('Flight selection is required').nullable(),
                flightNumber: Yup.string().required('Flight number is required'),
                departure: Yup.object().shape({
                    date: Yup.string().required('Departure date is required'),
                    time: Yup.string().required('Departure time is required'),
                    airport: Yup.object().required('Departure airport is required').nullable(),
                }),
                arrival: Yup.object()
                    .shape({
                        date: Yup.string().required('Arrival date is required'),
                        time: Yup.string().required('Arrival time is required'),
                        airport: Yup.object().required('Arrival airport is required').nullable(),
                    })
                    .test('is-after-departure', 'Arrival date and time must be after departure', function (arrival) {
                        const { departure } = this.parent;

                        // Combine departure and arrival dates and times into a single moment object
                        const departureDateTime = moment(`${departure.date} ${departure.time}`, 'YYYY-MM-DD HH:mm');
                        const arrivalDateTime = moment(`${arrival.date} ${arrival.time}`, 'YYYY-MM-DD HH:mm');

                        // Return true if arrival is after departure, otherwise false
                        return arrivalDateTime.isAfter(departureDateTime);
                    })
                    .test(
                        'is-different-airport',
                        'Arrival and departure airports cannot be the same',
                        function (arrival) {
                            const { departure } = this.parent;
                            return arrival?.airport?._id !== departure?.airport?._id; // Ensure departure and arrival airports are different
                        }
                    ),
                stopsOverAirport: Yup.array(),
            })
        )
        .min(1, 'At least one segment is required')
        .test(
            'is-sequential',
            'Each segment’s departure must be after the previous segment’s arrival',
            function (segments) {
                if (!segments || segments.length <= 1) return true; // No need to test if only one segment

                // Check that each segment's departure is after the previous segment's arrival
                for (let i = 1; i < segments.length; i++) {
                    const previousArrivalDateTime = moment(
                        `${segments[i - 1].arrival.date} ${segments[i - 1].arrival.time}`,
                        'YYYY-MM-DD HH:mm'
                    );
                    const currentDepartureDateTime = moment(
                        `${segments[i].departure.date} ${segments[i].departure.time}`,
                        'YYYY-MM-DD HH:mm'
                    );

                    if (!currentDepartureDateTime.isAfter(previousArrivalDateTime)) {
                        return false; // Validation fails if any segment’s departure is not after the previous arrival
                    }
                }

                return true;
            }
        ),

    passengers: Yup.object().shape({
        adult: Yup.number()
            .required('At least 1 adult is required')
            .min(1, 'Minimum 1 adult')
            .max(9, 'Maximum 9 adults'),
        child: Yup.number().min(0, 'Minimum 0 children').max(9, 'Maximum 9 children'),
        infant: Yup.number()
            .min(0, 'Minimum 0 infants')
            .max(9, 'Maximum 9 infants')
            .test('infant-less-than-adult', 'Number of infants cannot exceed number of adults', function (value) {
                const { adult } = this.parent;
                return value <= adult;
            }),
    }),

    baggage: Yup.object().shape({
        checkIn: Yup.string().required('Check-in baggage is required'),
        cabin: Yup.string().required('Cabin baggage is required'),
    }),
});

export const generateNumberArray = (start: number, end: number) => {
    return Array.from(Array(end - start + 1).keys()).map((val) => val + start);
};

interface IStep1Form {
    onSubmit: (values: Partial<IManualBookingForm>) => void;
    formKey: string;
}

const Step1Form: FC<IStep1Form> = (props) => {
    const { onSubmit, formKey } = props;

    const prevData = localStorage.getItem(formKey);
    const initialValues: IManualTicketForm = prevData
        ? JSON.parse(prevData)
        : {
              subAgency: null,
              bookingId: '',
              pnr: '',
              ticketNumber: '',
              segments: [
                  {
                      flight: null,
                      flightNumber: '',
                      arrival: {
                          date: moment().format('YYYY-MM-DD'),
                          time: moment().format('HH:mm'),
                          airport: null,
                      },
                      departure: {
                          date: moment().format('YYYY-MM-DD'),
                          time: moment().format('HH:mm'),
                          airport: null,
                      },
                      stopsOverAirport: [],
                  },
              ],
              isDirectFlight: false,
              baggage: {
                  checkIn: '15',
                  cabin: '7',
              },
              passengers: {
                  adult: 1,
                  child: 0,
                  infant: 0,
              },
          };

    const handleSubmit = async (values: IManualTicketForm, formikHelpers: FormikHelpers<IManualTicketForm>) => {
        localStorage.setItem(formKey, JSON.stringify(values));

        const valuesWithFormattedDateTime = {
            ...values,
            segments: values.segments.map((segment) => {
                return {
                    ...segment,

                    arrival: {
                        ...segment.arrival,
                        date: moment(segment.arrival.date, 'YYYY-MM-DD').format('YYYYMMDD'),
                        time: moment(segment.arrival.time, 'HH:mm').format('HHmmss'),
                    },

                    departure: {
                        ...segment.departure,
                        date: moment(segment.departure.date, 'YYYY-MM-DD').format('YYYYMMDD'),
                        time: moment(segment.departure.time, 'HH:mm').format('HHmmss'),
                    },
                    flight: { ...segment.flight, number: segment.flightNumber },
                };
            }),
            baggage: {
                checkIn: values.baggage.checkIn.trim() + ' Kg',
                cabin: values.baggage.cabin.trim() + ' Kg',
            },
        };
        // console.log({ Step1Form: valuesWithFormattedDateTime });
        onSubmit({
            ticket: valuesWithFormattedDateTime,
        });
    };

    interface IErr {
        error: any;
        index?: number;
    }

    const ErrorMsg: FC<IErr> = (props) => {
        const { error, index } = props;

        const generateErrorMessage = () => {
            if (error.segments && index >= 0) {
                if (Array.isArray(error.segments)) {
                    if (error.segments[index]) {
                        if (error.segments[index].arrival) {
                            if (typeof error.segments[index].arrival === 'string') {
                                return error.segments[index].arrival;
                            }
                        }
                    }
                }
            } else if (typeof error.segments === 'string') {
                return 'Each segment’s departure must be after the previous segment’s arrival';
            }
        };

        const message = generateErrorMessage();

        return <div> {message} </div>;
    };

    ErrorMsg.defaultProps = {
        index: -1,
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, errors }: FormikProps<IManualTicketForm>) => (
                <Form>
                    <div className="grid grid-cols-12 gap-4">
                        {/* <button onClick={() => console.log({ values, errors })}>click</button> */}
                        <div className="col-span-12">
                            <label className="text-xs mb-2 label-required" htmlFor="subAgency">
                                SUB AGENCY
                            </label>
                            <SearchComponent
                                fetchSuggestions={getSubAgencies}
                                setField={(subAgency: ISubAgency) => setFieldValue('subAgency', subAgency)}
                                selectedItem={values?.subAgency}
                                placeholder="Search Sub Agency"
                                initialSuggestions={[]}
                                getSuggestionValue={(subAgency: ISubAgency) => `${subAgency._id} - ${subAgency.name}`}
                                renderSuggestion={(subAgency: ISubAgency) => <div> {subAgency.name} </div>}
                                clearInputOnSelection={true}
                            />

                            <div className="form-error">
                                <ErrorMessage name="subAgency" />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="ticketNumber">
                                TICKET NUMBER
                            </label>
                            <div className="input-group">
                                <Field type="text" id="ticketNumber" name={'ticketNumber'} className="form-control" />
                            </div>

                            <div className="form-error">
                                <ErrorMessage name={'ticketNumber'} />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="bookingId">
                                BOOKING ID
                            </label>
                            <div className="input-group">
                                <Field type="text" id="bookingId" name="bookingId" className="form-control" />
                            </div>

                            <div className="form-error">
                                <ErrorMessage name="bookingId" />
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="text-xs mb-2" htmlFor="pnr">
                                PNR
                            </label>
                            <div className="input-group">
                                <Field type="text" id="pnr" name="pnr" className="form-control" />
                            </div>

                            <div className="form-error">
                                <ErrorMessage name="pnr" />
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">
                        <FieldArray name="segments">
                            {({ push, remove }) => (
                                <>
                                    {values.segments.map((segment, segmentIndex) => (
                                        <div className="mt-6" key={segmentIndex}>
                                            <div className="flex justify-between items-center">
                                                <div className="font-semibold">{`Segment ${segmentIndex + 1}`}</div>
                                                {values.segments.length > 1 && (
                                                    <div
                                                        className="p-6 rounded-full cursor-pointer text-danger"
                                                        onClick={() => remove(segmentIndex)}
                                                    >
                                                        <DeleteIcon />
                                                    </div>
                                                )}
                                            </div>

                                            <div className="">
                                                <div className="grid grid-cols-2 gap-6 mt-2">
                                                    <div className="col-span-1">
                                                        <label className="text-xs mb-2 label-required" htmlFor="from">
                                                            FROM
                                                        </label>
                                                        <div className="flex">
                                                            <AirportSearchField
                                                                id="from"
                                                                className="form-control rounded-r-none"
                                                                placeholder={'Select From Airport'}
                                                                selectedAirport={segment.departure.airport}
                                                                onSelectedAirportChange={(airport: IAirport) =>
                                                                    setFieldValue(
                                                                        `segments[${segmentIndex}].departure.airport`,
                                                                        airport
                                                                    )
                                                                }
                                                            />
                                                            <div>
                                                                <Field
                                                                    type="text"
                                                                    id="fromTerminal"
                                                                    name="fromTerminal"
                                                                    className="form-control rounded-l-none"
                                                                    placeholder="Terminal"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].departure.airport`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label className="text-xs mb-2 label-required " htmlFor="to">
                                                            TO
                                                        </label>
                                                        <div className="flex">
                                                            <AirportSearchField
                                                                id="to"
                                                                className="form-control rounded-r-none"
                                                                placeholder={'Select From Airport'}
                                                                selectedAirport={segment.arrival.airport}
                                                                onSelectedAirportChange={(airport: IAirport) =>
                                                                    setFieldValue(
                                                                        `segments[${segmentIndex}].arrival.airport`,
                                                                        airport
                                                                    )
                                                                }
                                                            />
                                                            <div>
                                                                <Field
                                                                    type="text"
                                                                    id="toTerminal"
                                                                    name="toTerminal"
                                                                    className="form-control rounded-l-none"
                                                                    placeholder="Terminal"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].arrival.airport`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-4 gap-6 mt-8">
                                                    <div className="col-span-1">
                                                        <label
                                                            className="text-xs mb-2 "
                                                            htmlFor={`segments[${segmentIndex}].departure.date`}
                                                        >
                                                            DEPART ON
                                                        </label>
                                                        <Field
                                                            type="date"
                                                            id={`segments[${segmentIndex}].departure.date`}
                                                            name={`segments[${segmentIndex}].departure.date`}
                                                            className="form-control"
                                                        />
                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].departure.date`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label
                                                            className="text-xs mb-2 "
                                                            htmlFor={`segments[${segmentIndex}].departure.time`}
                                                        >
                                                            DEPARTURE TIME
                                                        </label>
                                                        <Field
                                                            type="time"
                                                            id={`segments[${segmentIndex}].departure.time`}
                                                            name={`segments[${segmentIndex}].departure.time`}
                                                            className="form-control"
                                                        />
                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].departure.time`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label
                                                            className="text-xs mb-2  "
                                                            htmlFor={`segments[${segmentIndex}].arrival.date`}
                                                        >
                                                            ARRIVES ON
                                                        </label>
                                                        <Field
                                                            type="date"
                                                            id={`segments[${segmentIndex}].arrival.date`}
                                                            name={`segments[${segmentIndex}].arrival.date`}
                                                            className="form-control"
                                                        />
                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].arrival.date`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label
                                                            className="text-xs mb-2 "
                                                            htmlFor={`segments[${segmentIndex}].arrival.time`}
                                                        >
                                                            ARRIVAL TIME
                                                        </label>
                                                        <Field
                                                            type="time"
                                                            id={`segments[${segmentIndex}].arrival.time`}
                                                            name={`segments[${segmentIndex}].arrival.time`}
                                                            className="form-control"
                                                        />
                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].arrival.time`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-4">
                                                        <div className="form-error">
                                                            <ErrorMsg error={errors} index={segmentIndex} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-3 gap-6 mt-8">
                                                    <div className="col-span-1">
                                                        <label
                                                            className="text-xs mb-2 label-required "
                                                            htmlFor="flight"
                                                        >
                                                            SELECT FLIGHT
                                                        </label>
                                                        <AirlineSearchField
                                                            id="flight"
                                                            className="form-control"
                                                            placeholder={'Select Flight'}
                                                            selectedAirline={values.segments[segmentIndex].flight}
                                                            onSelectedAirlineChange={(airline: IAirline) =>
                                                                setFieldValue(
                                                                    `segments[${segmentIndex}].flight`,
                                                                    airline
                                                                )
                                                            }
                                                        />
                                                        <div className="form-error">
                                                            <ErrorMessage name={`segments[${segmentIndex}].flight`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label
                                                            className="text-xs mb-2"
                                                            htmlFor={`segments[${segmentIndex}].flightNumber`}
                                                        >
                                                            FLIGHT NUMBER
                                                        </label>
                                                        <div className="input-group">
                                                            <Field
                                                                type="text"
                                                                id={`segments[${segmentIndex}].flightNumber`}
                                                                name={`segments[${segmentIndex}].flightNumber`}
                                                                className="form-control"
                                                            />
                                                        </div>

                                                        <div className="form-error">
                                                            <ErrorMessage
                                                                name={`segments[${segmentIndex}].flightNumber`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1 flex items-end" />
                                                    {values.segments.length - 1 === segmentIndex && (
                                                        <LoaderButton
                                                            type="button"
                                                            className="bg-primary text-white text-sm font-semibold rounded-lg w-36 mt-auto ml-auto "
                                                            onClick={() => push(createBlankSegment())}
                                                        >
                                                            Add Segment
                                                        </LoaderButton>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                        <div className="form-error">
                            <ErrorMsg error={errors} />
                        </div>
                    </div>

                    <div className="mt-8">
                        <div className="text-gray-900 font-bold">Travelers</div>
                        <div className="grid grid-cols-12 gap-4 mt-4 ">
                            <div className="col-span-4">
                                <label htmlFor="adult" className="form-label text-secondary-fg">
                                    Adult
                                </label>

                                <Field as="select" name="passengers.adult" className="form-control">
                                    {generateNumberArray(1, 9).map((val) => {
                                        return (
                                            <option key={val} value={val}>
                                                {val}
                                            </option>
                                        );
                                    })}
                                </Field>
                                <div className="form-error text-secondary-fg/60">
                                    <ErrorMessage name="passengers.adult" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <div className="relative">
                                    <label htmlFor="children" className="form-label text-secondary-fg">
                                        Children
                                    </label>
                                    <Field as="select" name="passengers.child" className="form-control">
                                        {generateNumberArray(0, 8).map((val) => {
                                            return (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    <div className="form-error text-secondary-fg/60">
                                        <ErrorMessage name="passengers.child" />
                                    </div>

                                    <div className="text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em] left-[1em]">
                                        2 - 11 Years
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <div className="relative">
                                    <label htmlFor="infants" className="form-label text-secondary-fg">
                                        Infants
                                    </label>

                                    <Field as="select" name="passengers.infant" className="form-control">
                                        {generateNumberArray(0, 9).map((val) => {
                                            return (
                                                <option key={val} value={val}>
                                                    {val}
                                                </option>
                                            );
                                        })}
                                    </Field>

                                    <div className="form-error text-secondary-fg/60">
                                        <ErrorMessage name="passengers.infant" />
                                    </div>

                                    <div className="text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em]">
                                        Below 2 Years
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">
                        <div className="text-gray-900 font-bold">Baggage Information</div>

                        <div className="grid grid-cols-12 gap-6 mt-4">
                            <div className="col-span-4">
                                <label className="text-xs mb-2  " htmlFor="cabin">
                                    CABIN
                                </label>
                                <div className="input-group">
                                    <Field type="text" id="cabin" name="baggage.cabin" className="form-control" />

                                    <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">Kg</div>
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="baggage.cabin" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2 " htmlFor="checkIn">
                                    CHECK IN
                                </label>
                                <div className="input-group">
                                    <Field type="text" id="checkIn" name="baggage.checkIn" className="form-control" />

                                    <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">Kg</div>
                                </div>

                                <div className="form-error">
                                    <ErrorMessage name="baggage.checkIn" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoaderButton
                        type="submit"
                        className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12 ml-auto"
                    >
                        Next
                    </LoaderButton>
                </Form>
            )}
        </Formik>
    );
};

export default Step1Form;
