/* eslint-disable jsx-a11y/anchor-is-valid */
import useDialog from 'app/hooks/use-dialog';
import { ChevronDownIcon } from 'app/icons';
import { IPlugin } from 'app/types';
import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Modal from '../Modal/Modal';
import styles from './Sidebar.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

const collapseAnimation = {
    variants: {
        collapsed: { opacity: 0, height: 0 },
        expanded: { opacity: 1, height: 'auto' },
    },
    transition: { duration: 0.35, type: 'tweeen' },
    initial: 'collapsed',
    animate: 'expanded',
    exit: 'collapsed',
};



interface INestedRoutes {
    label: string;
    to: string;
    countLabel: any;
}

interface SidebarProps {
    unsoldSeats: number;
    pendingPayments: number;
    navPlugins: Partial<IPlugin>[];
    subAgencyRequest: number;
    pendingAmendments: number;
}

const Sidebar: FC<SidebarProps> = (props) => {
    const { unsoldSeats, pendingPayments, navPlugins, subAgencyRequest, pendingAmendments } = props;

    const [openPlugins, setOpenPlugins] = useState<boolean>(false);
    const [accountOpen, setAccountOpen] = useState<boolean>(false);

    const [fareManagementOpen, setFareManagementOpen] = useState<boolean>(false);
    const [bookingsOpen, setBookingsOpen] = useState<boolean>(false);

    const dialog = useDialog();

    const bookingNestedRoutes: INestedRoutes[] = [
        {
            label: 'All Bookings',
            to: '/bookings?tab=1',
            countLabel: null,
        },
        {
            label: 'Agency Bookings',
            to: '/bookings?tab=2',
            countLabel: null,
        },
        {
            label: 'Sub Agency Series Bookings',
            to: '/bookings?tab=3',
            countLabel: null,
        },
        {
            label: 'Manual Bookings',
            to: '/bookings?tab=4',
            countLabel: null,
        },
        {
            label: 'Passenger Calender',
            to: 'bookings/passenger-calender',
            countLabel: null,
        },
    ];

    const fareManagementRoutes: INestedRoutes[] = [
        {
            label: 'Fare Suppliers',
            to: '/fare-management/fare-suppliers',
            countLabel: null,
        },
        {
            label: 'Commission Plan',
            to: '/fare-management/commission-plan',
            countLabel: null,
        },
    ];

    const accountRoutes: INestedRoutes[] = [
        {
            label: 'Gateway Transactions',
            to: '/account/payments-online',
            countLabel: null,
        },
        {
            label: 'Balance Update',
            to: '/account/payments',
            countLabel:
                pendingPayments != null && pendingPayments > 0 ? (
                    <span className="ml-auto text-white bg-red-400 rounded-xl text-[0.65rem] font-semibold leading-none px-2 py-1">
                        {pendingPayments}
                    </span>
                ) : null,
        },
        {
            label: 'Bank Transactions',
            to: '/account/transactions',
            countLabel: null,
        },
        {
            label: 'Reports',
            to: '/account/reports',
            countLabel: null,
        },
        {
            label: 'Payment Note Approval',
            to: '/account/payment-note-approval',
            countLabel: null,
        },
        {
            label: 'Sub Agency Top Up',
            to: '/account/top-up',
            countLabel: null,
        },
        {
            label: 'Supplier Report',
            to: '/account/supplier-report',
            countLabel: null,
            // /account/supplier-report
        },
    ];

    const getPaymentPluginsNavigation = (pluginId: string): string => {
        if (pluginId === 'ccavenue-payment') return '/plugins/ccavenue-payment';

        if (pluginId === 'hdfc-payment') return '/plugins/hdfc-payment';
    };

    return (
        <>
            <div
                className={`${styles['sidebar']} fixed top-[var(--navbar-height)] left-0  w-[var(--sidebar-width)] z-10 scrollbar`}
            >
                <div className="flex flex-col pl-8 pr-4 py-8 h-full">
                    <ul>
                        <li>
                            <NavLink to="/" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/home.svg" alt="" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                    </ul>

                    <ul className="mt-10">
                        <li>
                            <NavLink
                                to="/bookings"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setBookingsOpen((val) => !val);
                                }}
                                className={`${styles['sidebar-link']} `}
                            >
                                <img src="/icons/sidebar/graph.svg" alt="" />
                                <span>Bookings</span>

                                <motion.div
                                    className="self-center ml-auto p-[6px] rounded-full hover:bg-slate-100"
                                    animate={{
                                        rotate: bookingsOpen ? 180 : 0,
                                    }}
                                    transition={{
                                        duration: 0.35,
                                    }}
                                >
                                    <ChevronDownIcon height="8" width="5" />
                                </motion.div>
                            </NavLink>

                            <AnimatePresence initial={false}>
                                {bookingsOpen && (
                                    <motion.ul className="ml-7 overflow-hidden" {...collapseAnimation}>
                                        {bookingNestedRoutes.map((route) => (
                                            <li key={route.to}>
                                                <NavLink
                                                    to={route.to}
                                                    className={`${styles['sidebar-sub-link']}`}
                                                    style={{ fontWeight: 'unset' }}
                                                >
                                                    <span>{route.label}</span>
                                                    {route.countLabel && route.countLabel}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </li>

                        {/* <li>
                            <NavLink to='/bookings' className={`${styles['sidebar-link']}`}>
                                <img src='/icons/sidebar/bars.svg' alt='' />
                                <span>Bookings</span>
                            </Nav
                            
                            
                            Link>
                        </li> */}
                        <li>
                            <NavLink to="/sub-agencies" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/users.svg" alt="" />
                                <span>Sub Agencies</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/sub-agency-requests" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/users.svg" alt="" />
                                <span>Registration Requests</span>
                                {subAgencyRequest != null && subAgencyRequest > 0 && (
                                    <span className="ml-auto text-white bg-gray-500 rounded-xl text-[0.65rem] font-semibold leading-none px-2 py-1">
                                        {subAgencyRequest}
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/fare-management"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setFareManagementOpen((val) => !val);
                                }}
                                className={`${styles['sidebar-link']} `}
                            >
                                <img src="/icons/sidebar/graph.svg" alt="" />
                                <span>Fare Management</span>

                                <motion.div
                                    className="self-center ml-auto p-[6px] rounded-full hover:bg-slate-100"
                                    animate={{
                                        rotate: fareManagementOpen ? 180 : 0,
                                    }}
                                    transition={{
                                        duration: 0.35,
                                    }}
                                >
                                    <ChevronDownIcon height="8" width="5" />
                                </motion.div>
                            </NavLink>

                            <AnimatePresence initial={false}>
                                {fareManagementOpen && (
                                    <motion.ul className="ml-7 overflow-hidden" {...collapseAnimation}>
                                        {fareManagementRoutes.map((route) => (
                                            <li key={route.to}>
                                                <NavLink to={route.to} className={`${styles['sidebar-sub-link']} `}>
                                                    <span>{route.label}</span>
                                                    {route.countLabel && route.countLabel}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </li>
                        {/* <li>
                            <NavLink to='/fare-suppliers' className={`${styles['sidebar-link']}`}>
                                <img src='/icons/sidebar/folder.svg' alt='' />
                                <span>Fare Suppliers</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/series-inventory" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/bars.svg" alt="" />
                                <span>Series Inventory</span>
                                {unsoldSeats != null && unsoldSeats > 0 && (
                                    <span className="ml-auto text-white bg-gray-500 rounded-xl text-[0.65rem] font-semibold leading-none px-2 py-1">
                                        {unsoldSeats}
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/account"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setAccountOpen((val) => !val);
                                }}
                                className={`${styles['sidebar-link']} `}
                            >
                                <img src="/icons/sidebar/graph.svg" alt="" />
                                <span>Accounts</span>

                                <motion.div
                                    className="self-center ml-auto p-[6px] rounded-full hover:bg-slate-100"
                                    animate={{
                                        rotate: accountOpen ? 180 : 0,
                                    }}
                                    transition={{
                                        duration: 0.35,
                                    }}
                                >
                                    <ChevronDownIcon height="8" width="5" />
                                </motion.div>
                            </NavLink>

                            <AnimatePresence initial={false}>
                                {accountOpen && (
                                    <motion.ul className="ml-7 overflow-hidden" {...collapseAnimation}>
                                        {accountRoutes.map((route) => (
                                            <li key={route.to}>
                                                <NavLink to={route.to} className={`${styles['sidebar-sub-link']} `}>
                                                    <span>{route.label}</span>
                                                    {route.countLabel && route.countLabel}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </li>
                        <li>
                            <NavLink to="tour-query" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/bars.svg" alt="" />
                                <span>Tour Queries</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/amendments" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/cursor.svg" alt="" />
                                <span>Amendments</span>
                                {pendingAmendments != null && pendingAmendments > 0 && (
                                    <span className="ml-auto text-white bg-gray-500 rounded-xl text-[0.65rem] font-semibold leading-none px-2 py-1">
                                        {pendingAmendments}
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/static-pages" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/cursor.svg" alt="" />
                                <span>Pages</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/plugins" className={`${styles['sidebar-link']} `}>
                                <img src="/icons/sidebar/cursor.svg" alt="" />
                                <span>Plugins</span>

                                <motion.div
                                    onClick={() => setOpenPlugins((val) => !val)}
                                    className="self-center ml-auto p-[6px] rounded-full hover:bg-slate-100"
                                    animate={{
                                        rotate: openPlugins ? 180 : 0,
                                    }}
                                    transition={{
                                        duration: 0.35,
                                    }}
                                >
                                    <ChevronDownIcon height="8" witdth="5" />
                                </motion.div>
                            </NavLink>

                            <AnimatePresence initial={false}>
                                {openPlugins && (
                                    <motion.ul className="ml-7 overflow-hidden" {...collapseAnimation}>
                                        {navPlugins.map((plugin) => (
                                            <li key={plugin._id}>
                                                <NavLink
                                                    to={
                                                        plugin._id === 'ccavenue-payment' ||
                                                        plugin._id === 'hdfc-payment'
                                                            ? getPaymentPluginsNavigation(plugin._id)
                                                            : `/${plugin._id}`
                                                    }
                                                    className={`${styles['sidebar-sub-link']} `}
                                                >
                                                    <span>{plugin.name}</span>
                                                </NavLink>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </li>
                    </ul>

                    <ul className="mt-auto">
                        <li>
                            <div className="text-xs text-gray-400 uppercase mb-3 mt-6">Agency Account</div>
                        </li>
                        <li>
                            <NavLink to="/users" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/folder.svg" alt="" />
                                <span>Users</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings" className={`${styles['sidebar-link']}`}>
                                <img src="/icons/sidebar/settings.svg" alt="" />
                                <span>Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <Modal
                className="px-10 py-10 w-[480px] max-w-full h-[280px] max-h-full flex items-center justify-center"
                show={dialog.isOpen}
                onClose={dialog.hide}
            >
                <h3 className="text-gray-900 text-center">Coming Soon</h3>
            </Modal>
        </>
    );
};

//let x = ['/reports', '/transactions', '/payments'];

export default Sidebar;
