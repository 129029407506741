import { FC } from 'react';
import moment from 'moment';
import capitalize from 'lodash.capitalize';
import useDialog from 'app/hooks/use-dialog';
import StatusBadge from 'app/components/StatusBadge/StatusBadge';
import AmendmentChat from 'app/components/AmendmentChat/AmendmentChat';
import { getAmendmentStatusColor, getAmendmentStatusText } from 'app/utils/common-utils';

interface IOpenAmendment {
    bookingId: string;
    amendmentId: string;
}
interface AmendmentTableProps {
    list: any[];
    refetchQueryIdentifier: any;
}

const AmendmentTable: FC<AmendmentTableProps> = (props) => {
    const { list, refetchQueryIdentifier } = props;

    const amendmentDialog = useDialog<IOpenAmendment>({ amendmentId: '', bookingId: '' });

    const getFirstPassengerName = (passengers: any) => {
        const firstPassenger = passengers[0];
        return `${firstPassenger.title} ${firstPassenger.firstName} ${firstPassenger.lastName}`;
    };

    const openChatDialog = (row: any) => {
        amendmentDialog.show({
            amendmentId: row._id,
            bookingId: row.booking,
        });
    };

    return (
        <>
            <div className="overflow-auto mt-5">
                <table className="card-table text-sm">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="th-1">Request ID</th>
                            <th className="th-1">Request Date & Time</th>
                            <th className="th-1">Change Type</th>
                            <th className="th-1">Passenger(s)</th>
                            <th className="th-1">Change Amount</th>
                            <th className="th-1">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((amendment) => {
                            return (
                                <tr>
                                    <td className="td-1">
                                        <div>
                                            <div className="font-medium text-blue-500 cursor-pointer">
                                                <div onClick={() => openChatDialog(amendment)}>{amendment._id}</div>
                                            </div>
                                            {amendment.actionNeeded && (
                                                <StatusBadge className="mt-1" color={'red'}>
                                                    Action needed
                                                </StatusBadge>
                                            )}
                                        </div>
                                    </td>
                                    <td className="td-1">
                                        <div className="font-semibold">
                                            <div>
                                                {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('DD MMM, YYYY')}
                                            </div>
                                            <div className="text-xs font-medium text-gray-500">
                                                {moment(amendment.createdAt, 'YYYYMMDDHHmmss').format('hh:mm A')}
                                            </div>
                                        </div>
                                    </td>

                                    <td className="td-1">
                                        <div className="font-semibold">{capitalize(amendment.type.toLowerCase())}</div>
                                    </td>
                                    <td className="td-1">
                                        {amendment.all === true
                                            ? 'All'
                                            : getFirstPassengerName(amendment.detail.passengers)}

                                        {amendment.all !== true && amendment.detail.passengers.lenght > 1 && (
                                            <div className="bg-gray-100 px-3 py-1 rounded-full">
                                                + {amendment.detail.passengers.lenght - 1}
                                            </div>
                                        )}
                                    </td>
                                    <td className="td-1">
                                        <div className="font-medium">{amendment.amount ? amendment.amount : '-'}</div>
                                    </td>
                                    <td>
                                        <StatusBadge className="mt-1" color={getAmendmentStatusColor(amendment.status)}>
                                            {getAmendmentStatusText(amendment.status, amendment.subAgency.name)}
                                        </StatusBadge>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {amendmentDialog.isOpen && (
                <AmendmentChat
                    show={amendmentDialog.isOpen}
                    onClose={amendmentDialog.hide}
                    amendmentId={amendmentDialog.data.amendmentId}
                    bookingId={amendmentDialog.data.bookingId}
                    refetchQueryIdentifier={refetchQueryIdentifier}
                />
            )}
        </>
    );
};

export default AmendmentTable;
