export enum FareSupplierEnum {
    TRIP_JACK = 'TRIP_JACK',
    TRAVEL_BOUTIQUE = 'TRAVEL_BOUTIQUE',
    UNIVERSAL_API = 'UNIVERSAL_API',
    SPICE_JET = 'SPICE_JET',
    AIR_IQ = 'AIR_IQ',
    AGENCY_SERIES = 'AGENCY_SERIES',
    MY_SERIES = 'MY_SERIES',
    KAFILA = 'KAFILA',
    FARE_BOUTIQUE = 'FARE_BOUTIQUE',
}

export enum FareSupplierUrlEnum {
    AIR_IQ = 'air-iq',
    TRIP_JACK = 'trip-jack',
    TRAVEL_BOUTIQUE = 'tbo',
    UNIVERSAL_API = 'UNIVERSAL_API',
    SPICE_JET = 'SPICE_JET',
    AGENCY_SERIES = 'AGENCY_SERIES',
    MY_SERIES = 'MY_SERIES',
    KAFILA = 'kafila',
    FARE_BOUTIQUE = 'fare-boutique',
}
