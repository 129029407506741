import React, { FC, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import PaginationRange from 'app/components/PaginationRange/PaginationRange';
import TableSearch from 'app/components/TableSearch/TableSearch';
import TableDateFilter from 'app/components/TableDateFilter/TableDateFilter';
import UnsettledPaymentsTable from '../UnsettledPaymentsTable/UnsettledPaymentsTable';

interface UnsettledAmenmentListProps {}

const UnsettledAmenmentList: FC<UnsettledAmenmentListProps> = (props) => {
    const [filter, setFilter] = useState({
        search: '',
        date1: '',
        date2: '',
    });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [params, setParams] = useState<String>(`?page=${currentPage}`);

    const allUnsettledAmendmentsQuery = useQuery(['all unsettled Amendments', params], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/amendments${params}&accountSettled=false`
        );
        setPageCount(data.pageCount);
        return data.data;
    });

    useEffect(() => {
        setParams(() => {
            let newUrl = `?page=${currentPage}`;
            if (filter.date1 !== '') newUrl += `&fromDate=${filter.date1}`;
            if (filter.date2 !== '') newUrl += `&toDate=${filter.date2}`;
            if (filter.search !== '') newUrl += `&searchParam=${filter.search}`;

            return newUrl;
        });
    }, [currentPage, filter]);

    const handlePageChange = (val: any) => {
        setCurrentPage(val.selected + 1);
    };

    const debouncedSearch = React.useRef(
        debounce((q) => {
            setFilter((prev) => ({ ...prev, search: q }));
            setCurrentPage(() => 1);
        }, 300)
    ).current;

    const handleSearch = (q: string) => {
        debouncedSearch(q);
    };

    const handleDateFilter = (date1: string, date2: string) => {
        setFilter((prev) => ({ ...prev, date1, date2 }));
        setCurrentPage(() => 1);
    };

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div className="relative">
            <div className="flex items-center justify-between px-6 py-5">
                <TableSearch placeholder="Search for a Unsettled Ammendment Payment by id" onSearch={handleSearch} />
                <TableDateFilter onChange={handleDateFilter} />
            </div>

            <UnsettledPaymentsTable
                UnsettledPaymentList={allUnsettledAmendmentsQuery.data}
                isLoading={allUnsettledAmendmentsQuery.isLoading}
                refetchQueryIdentifier={['all unsettled Amendments', params]}
            />
            <div className="px-4 pb-3 ">
                <PaginationRange handlePageChange={handlePageChange} pageCount={pageCount} />
            </div>
        </div>
    );
};

export default UnsettledAmenmentList;
