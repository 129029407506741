import http from 'app/config/http';
import { FC, useEffect } from 'react';
import useNavTab from 'app/hooks/use-nav-tab';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader/PageLoader';
import AgencySeriesList from './components/AgencySeriesList/AgencySeriesList';
import SeriesInventoryNav from './components/SeriesInventoryNav/SeriesInventoryNav';
import SubAgencySeriesList from './components/SubAgencySeriesList/SubAgencySeriesList';
import AgencySeriesTopSection from './components/AgencySeriesTopSection/AgencySeriesTopSection';
import SubAgencySeriesTopSection from './components/SubAgencySeriesTopSection/SubAgencySeriesTopSection';

interface SeriesInventoryListProps {}

const SeriesInventoryList: FC<SeriesInventoryListProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useNavTab('1');
    const filterKeys = ['search', 'fromDate', 'toDate', 'sector', 'isLive', 'page'];

    useEffect(() => {
        if (activeTab !== searchParams.get('tab')) {
            filterKeys.forEach((filterKey) => {
                searchParams.delete(filterKey);
            });
            searchParams.set('tab', activeTab);
            console.log('reached', activeTab);
            setSearchParams(searchParams, {
                replace: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const statsQuery = useQuery(['seriesTicketStats'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/stats`);

        return data;
    });

    const subStatsQuery = useQuery(['subSeriesTicketStats'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/series-supplier-ticket/stats`
        );

        return data;
    });

    if (statsQuery.isLoading || subStatsQuery.isLoading) return <PageLoader />;

    return (
        <div>
            <div>
                {activeTab === '1' && <AgencySeriesTopSection stats={statsQuery.data} />}
                {activeTab === '2' && <SubAgencySeriesTopSection stats={subStatsQuery.data} />}
                <div className="card mt-8">
                    <SeriesInventoryNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    {activeTab === '1' && <AgencySeriesList />}
                    {activeTab === '2' && <SubAgencySeriesList />}
                </div>
            </div>
        </div>
    );
};

export default SeriesInventoryList;
