/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from '@tanstack/react-query';
import AirlineSearchField from 'app/components/AirlineSearchField/AirlineSearchField';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import http from 'app/config/http';
import { ISeriesInventoryForm } from 'app/pages/SeriesInventory/SeriesInventoryEdit/types';
import { IAirline, IAirport } from 'app/types';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FC } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useMemo } from 'react';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';

const InventoryFormSchema = Yup.object({
    from: Yup.object().nullable().required('This field is required'),
    to: Yup.object().nullable().required('This field is required'),
    departureDate: Yup.string().required('This field is required'),
    departureTime: Yup.string().required('This field is required'),
    arrivalDate: Yup.string()
        .required('This field is required')
        .test(
            'is-arrival-date-after-departure-date',
            'Arrival date should be after departure date',
            function (arrivalDate) {
                const { departureDate } = this.parent;

                const arrival = moment(arrivalDate, 'YYYY-MM-DD');
                const departure = moment(departureDate, 'YYYY-MM-DD');

                return arrival.isSameOrAfter(departure);
            }
        ),
    arrivalTime: Yup.string()
        .required('This field is required')
        .test(
            'is-arrival-time-after-departure-time',
            'Arrival time should be after departure time',
            function (arrivalTime) {
                const { departureDate, departureTime, arrivalDate } = this.parent;

                const arrival = moment(`${arrivalDate}${arrivalTime}`, 'YYYY-MM-DDHH:mm');
                const departure = moment(`${departureDate}${departureTime}`, 'YYYY-MM-DDHH:mm');

                return arrival.isAfter(departure);
            }
        ),
    flight: Yup.object().nullable().required('This field is required'),
    flightNumber: Yup.string().required('This field is required'),
    pnr: Yup.string().required('This field is required'),
    costPrice: Yup.string().required('This field is required'),
    tax: Yup.string().required('This field is required'),
    fareIdentifier: Yup.string().required('This field is required'),
    isDirectFlight: Yup.string().required('This field is required'),
    isLiveOnNetwork: Yup.string().required('This field is required'),
    fareRules: Yup.string().required('This field is required'),
    baggage: Yup.object({
        checkIn: Yup.string().required('This field is required'),
        cabin: Yup.string().required('This field is required'),
    }),
    infant: Yup.object({
        costPrice: Yup.number().required('This field is required').typeError('You must specify a number'),
        tax: Yup.number().required('This field is required').typeError('You must specify a number'),
    }),
    stopsOverAirport: Yup.array().of(Yup.object().nullable().required('This field is required')),
});

interface SeriesInventoryFormProps {
    ticket: any;
}

const SeriesInventoryForm: FC<SeriesInventoryFormProps> = (props) => {
    const { ticket } = props;

    const mutation = useMutation(async (payload: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/series-tickets/${ticket._id}`,
            payload
        );

        return data;
    });

    const initialValues: ISeriesInventoryForm = useMemo(() => {
        if (!ticket)
            return {
                from: null,
                fromTerminal: '',
                to: null,
                toTerminal: '',
                departureDate: '',
                departureTime: '',
                arrivalDate: '',
                arrivalTime: '',
                flight: null,
                flightNumber: '',
                pnr: '',
                costPrice: 0,
                tax: 0,
                fareIdentifier: '',
                isDirectFlight: true,
                isLiveOnNetwork: true,
                fareRules: '',
                baggage: {
                    checkIn: '',
                    cabin: '',
                },
                infant: {
                    costPrice: 0,
                    tax: 0,
                },
                stopsOverAirport: [],
            };

        return {
            from: ticket.trip.departure.airport,
            fromTerminal: ticket.trip.departure.airport.terminal,
            to: ticket.trip.arrival.airport,
            toTerminal: ticket.trip.arrival.airport.terminal,
            departureDate: moment(ticket.trip.departure.date, 'YYYYMMDD').format('YYYY-MM-DD'),
            departureTime: moment(ticket.trip.departure.time, 'HHmmss').format('HH:mm'),
            arrivalDate: moment(ticket.trip.arrival.date, 'YYYYMMDD').format('YYYY-MM-DD'),
            arrivalTime: moment(ticket.trip.arrival.time, 'HHmmss').format('HH:mm'),
            flight: { code: ticket.flight.code, name: ticket.flight.name },
            flightNumber: ticket.flight.number,
            pnr: ticket.pnr,
            costPrice: ticket.costPrice,
            tax: ticket.tax,
            fareIdentifier: ticket.fareIdentifier,
            isDirectFlight: true,
            isLiveOnNetwork: ticket.isLiveOnNetwork,
            baggage: {
                checkIn: ticket.baggage.checkIn.replace('Kg', '').trim(),
                cabin: ticket.baggage.cabin.replace('Kg', '').trim(),
            },
            infant: {
                costPrice: ticket.infant.costPrice,
                tax: ticket.infant.tax,
            },
            fareRules: ticket.fareRules,
            stopsOverAirport: ticket.stopsOverAirport,
        };
    }, [ticket]);

    const handleSubmit = async (values: ISeriesInventoryForm) => {
        const payload = {
            departure: {
                date: moment(values.departureDate, 'YYYY-MM-DD').format('YYYYMMDD'),
                time: moment(values.departureTime, 'HH:mm').format('HHmmss'),
                airport: { ...values.from, terminal: values.fromTerminal },
            },
            arrival: {
                date: moment(values.arrivalDate, 'YYYY-MM-DD').format('YYYYMMDD'),
                time: moment(values.arrivalTime, 'HH:mm').format('HHmmss'),
                airport: { ...values.to, terminal: values.toTerminal },
            },
            flight: {
                name: values.flight.name,
                code: values.flight.code,
                number: values.flightNumber,
            },
            costPrice: +values.costPrice,
            tax: +values.tax,
            fareIdentifier: values.fareIdentifier,
            pnr: values.pnr,
            isLiveOnNetwork: values.isLiveOnNetwork,
            isDirectFlight: values.isDirectFlight,
            baggage: {
                checkIn: values.baggage.checkIn.trim() + ' Kg',
                cabin: values.baggage.cabin.trim() + ' Kg',
            },
            fareRules: values.fareRules,
            infant: {
                costPrice: +values.infant.costPrice,
                tax: +values.infant.tax,
            },
            stopsOverAirport: values.stopsOverAirport,
        };

        try {
            await mutation.mutateAsync(payload);

            toast.success('Series Inventory updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={InventoryFormSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }: FormikProps<ISeriesInventoryForm>) => (
                    <Form>
                        <div className="grid grid-cols-2 gap-6 mt-8">
                            <div className="col-span-1">
                                <label className="text-xs mb-2 label-required" htmlFor="from">
                                    FROM
                                </label>
                                <div className="flex">
                                    <AirportSearchField
                                        id="from"
                                        className="form-control rounded-r-none"
                                        placeholder={'Select From Airport'}
                                        selectedAirport={values.from}
                                        onSelectedAirportChange={(airport: IAirport) => setFieldValue('from', airport)}
                                    />
                                    <div>
                                        <Field
                                            type="text"
                                            id="fromTerminal"
                                            name="fromTerminal"
                                            className="form-control rounded-l-none"
                                            placeholder="Terminal"
                                        />
                                    </div>
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="from" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label className="text-xs mb-2 label-required" htmlFor="to">
                                    TO
                                </label>
                                <div className="flex">
                                    <AirportSearchField
                                        id="to"
                                        className="form-control rounded-r-none"
                                        placeholder={'Select From Airport'}
                                        selectedAirport={values.to}
                                        onSelectedAirportChange={(airport: IAirport) => setFieldValue('to', airport)}
                                    />
                                    <div>
                                        <Field
                                            type="text"
                                            id="toTerminal"
                                            name="toTerminal"
                                            className="form-control rounded-l-none"
                                            placeholder="Terminal"
                                        />
                                    </div>
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="to" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-6 mt-8">
                            <div className="col-span-1">
                                <div className="font-semibold">Stops</div>
                                <FieldArray
                                    name="stopsOverAirport"
                                    render={(arrayHelpers) => (
                                        <div>
                                            {values.stopsOverAirport.map((_, index) => (
                                                <div key={index} className="flex gap-4 items-center mt-2">
                                                    <div className="w-full">
                                                        <AirportSearchField
                                                            id="index"
                                                            className="form-control "
                                                            placeholder={'Select Airport'}
                                                            selectedAirport={values.stopsOverAirport[index]}
                                                            onSelectedAirportChange={(airport: IAirport) =>
                                                                setFieldValue(`stopsOverAirport[${index}]`, airport)
                                                            }
                                                        />
                                                        <div className="form-error">
                                                            <ErrorMessage name={`stopsOverAirport[${index}]`} />
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="text-3xl text-red-500"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="text-sm text-blue-500 mt-4"
                                                onClick={() => arrayHelpers.push(null)}
                                            >
                                                + Add Stop
                                            </button>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-4 gap-6 mt-8">
                            <div className="col-span-1">
                                <label className="text-xs mb-2 " htmlFor="departureDate">
                                    DEPART ON
                                </label>
                                <Field type="date" id="departureDate" name="departureDate" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="departureDate" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label className="text-xs mb-2 " htmlFor="departureTime">
                                    DEPARTURE TIME
                                </label>
                                <Field type="time" id="departureTime" name="departureTime" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="departureTime" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label className="text-xs mb-2 " htmlFor="arrivalDate">
                                    ARRIVES ON
                                </label>
                                <Field type="date" id="arrivalDate" name="arrivalDate" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="arrivalDate" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label className="text-xs mb-2 " htmlFor="arrivalTime">
                                    ARRIVAL TIME
                                </label>
                                <Field type="time" id="arrivalTime" name="arrivalTime" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="arrivalTime" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-6 mt-6">
                            <div className="col-span-1">
                                <label className="text-xs mb-2 label-required" htmlFor="flight">
                                    SELECT FLIGHT
                                </label>
                                <AirlineSearchField
                                    id="flight"
                                    className="form-control"
                                    placeholder={'Select Flight'}
                                    selectedAirline={values.flight}
                                    onSelectedAirlineChange={(airline: IAirline) => setFieldValue('flight', airline)}
                                />
                                <div className="form-error">
                                    <ErrorMessage name="flight" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label className="text-xs mb-2 label-required" htmlFor="flightNumber">
                                    ENTER FLIGHT NUMBER
                                </label>
                                <Field type="text" id="flightNumber" name="flightNumber" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="flightNumber" />
                                </div>
                            </div>

                            <div className="col-span-1">
                                <label className="text-xs mś-2 label-required" htmlFor="pnr">
                                    ENTER AIRLINE PNR
                                </label>
                                <Field type="text" id="pnr" name="pnr" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="pnr" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-9 gap-6 mt-6">
                            <div className="col-span-2">
                                <label className="text-xs mb-2 " htmlFor="costPrice">
                                    COST PRICE PER SEAT
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field type="text" id="costPrice" name="costPrice" className="form-control pl-8" />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="costPrice" />
                                </div>
                            </div>
                            <div className="col-span-2">
                                <label className="text-xs mb-2 " htmlFor="taxPerSeat">
                                    TAXES PER SEAT
                                </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">₹</div>
                                    <Field type="text" id="taxPerSeat" name="tax" className="form-control pl-8" />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="tax" />
                                </div>
                            </div>
                            <div className="col-span-2">
                                <label className="text-xs mb-2 label-required" htmlFor="fareIdentifier">
                                    FARE IDENTIFIER
                                </label>
                                <Field type="text" id="fareIdentifier" name="fareIdentifier" className="form-control" />
                                <div className="form-error">
                                    <ErrorMessage name="fareIdentifier" />
                                </div>
                            </div>

                            <div className="col-span-2 self-center">
                                <div className="flex items-center gap-2 mt-6">
                                    <Field
                                        type="checkbox"
                                        id="directFlight"
                                        name="isDirectFlight"
                                        className="form-switch success"
                                        disabled
                                    />

                                    <label htmlFor="directFlight">Direct Flight</label>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 mt-3">
                            <div className="col-span-8">
                                <div className="mt-4">
                                    <label className="text-xs mb-2 " htmlFor="fareRules">
                                        FARE RULES
                                    </label>
                                    <Field as="textarea" id="name" name="fareRules" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="fareRules" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* baggage */}
                        <div className="mt-8">
                            <div className="text-gray-900 font-bold">Baggage Information</div>

                            <div className="grid grid-cols-12 gap-6 mt-4">
                                <div className="col-span-4">
                                    <label className="text-xs mb-2 " htmlFor="cabin">
                                        CABIN
                                    </label>
                                    <div className="input-group">
                                        <Field type="text" id="cabin" name="baggage.cabin" className="form-control" />

                                        <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">
                                            Kg
                                        </div>
                                    </div>

                                    <div className="form-error">
                                        <ErrorMessage name="baggage.cabin" />
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <label className="text-xs mb-2 " htmlFor="checkIn">
                                        CHECK IN
                                    </label>
                                    <div className="input-group">
                                        <Field
                                            type="text"
                                            id="checkIn"
                                            name="baggage.checkIn"
                                            className="form-control"
                                        />

                                        <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">
                                            Kg
                                        </div>
                                    </div>

                                    <div className="form-error">
                                        <ErrorMessage name="baggage.checkIn" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* infant */}
                        <div className="mt-8">
                            <div className="text-gray-900 font-bold">Infant Information</div>

                            <div className="grid grid-cols-12 gap-6 mt-4">
                                <div className="col-span-4">
                                    <label className="text-xs mb-2 " htmlFor="infant.costPrice">
                                        COST PRICE
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="text"
                                            id="infant.costPrice"
                                            name="infant.costPrice"
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="infant.costPrice" />
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <label className="text-xs mb-2 " htmlFor="infant.tax">
                                        TAX
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="text"
                                            id="infant.tax"
                                            name="infant.tax"
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="infant.tax" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8">
                            <div className="flex items-center gap-2">
                                <Field
                                    id="liveOnNetwork"
                                    name="isLiveOnNetwork"
                                    type="checkbox"
                                    className="form-switch success"
                                />

                                <label htmlFor="liveOnNetwork">Live on network</label>
                            </div>
                        </div>

                        <LoaderButton
                            type="submit"
                            className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                            isLoading={mutation.isLoading}
                        >
                            Save Changes
                        </LoaderButton>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SeriesInventoryForm;
