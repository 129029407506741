import { FC } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import Modal from 'app/components/Modal/Modal';
import { IAirline, IAirport, IFlightSegment } from 'app/types';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import AirlineSearchField from 'app/components/AirlineSearchField/AirlineSearchField';

interface EditBookingFlightDialogProps {
    show: boolean;
    onClose: () => void;
    booking: any;
    handleUpdate: (val: any) => void;
}

interface EditFlightForm {
    segments: IFlightSegment[];
}

const EditBookingFlightDialog: FC<EditBookingFlightDialogProps> = (props) => {
    const { booking } = props;

    const initialValues: EditFlightForm = {
        segments: booking.segments.map((segment: IFlightSegment) => {
            return {
                ...segment,
                arrival: {
                    ...segment.arrival,
                    date: moment(segment.arrival.date, 'YYYYMMDD').format('YYYY-MM-DD'),
                    time: moment(segment.arrival.time, 'HHmmss').format('HH:mm'),
                },
                departure: {
                    ...segment.departure,
                    date: moment(segment.departure.date, 'YYYYMMDD').format('YYYY-MM-DD'),
                    time: moment(segment.departure.time, 'HHmmss').format('HH:mm'),
                },
            };
        }),
    };

    // const calculateDuration = (arivalTime: string, departureTime: string) => {
    //     return moment(arivalTime, 'yyyy/MM/dd hh:mm').diff(moment(departureTime, 'yyyy/MM/dd hh:mm'), 'minutes');
    // };

    // const initialValues: any = {
    //     from: booking.departure.airport,
    //     fromTerminal: booking.departure.airport.terminal,
    //     to: booking.arrival.airport,
    //     toTerminal: booking.arrival.airport.terminal,
    //     departureDate: moment(booking.departure.date, 'YYYYMMDD').format('YYYY-MM-DD'),
    //     departureTime: moment(booking.departure.time, 'HHmmss').format('HH:mm'),
    //     arrivalDate: moment(booking.arrival.date, 'YYYYMMDD').format('YYYY-MM-DD'),
    //     arrivalTime: moment(booking.arrival.time, 'HHmmss').format('HH:mm'),
    //     baggage: {
    //         checkIn: booking.priceDetail.fareDescription.adult.baggage.checkIn,
    //         cabin: booking.priceDetail.fareDescription.adult.baggage.cabin,
    //     },

    //     flight: null,
    //     flightNumber: '',
    //     pnr: '',
    //     costPrice: 0,
    //     tax: 0,
    //     fareIdentifier: '',
    //     totalSeats: 1,
    //     markup: 0,
    //     isLiveOnNetwork: true,
    //     fareRules: 'NON-REFUNDABLE',
    //     infant: {
    //         costPrice: 0,
    //         tax: 0,
    //     },
    //     stopsOverAirport: [],
    // };

    const InventoryFormSchema = Yup.object({});

    const handleSubmit = (values: any) => {
        props.onClose();
        props.handleUpdate(values.segments);
    };

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-8 pt-8 pb-10 w-[1280px]">
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={InventoryFormSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, errors, touched }: FormikProps<EditFlightForm>) => (
                        <Form>
                            <FieldArray
                                name="segments"
                                render={({ remove }) => {
                                    return (
                                        <>
                                            {values.segments.map((segment, index) => (
                                                <div key={index}>
                                                    <div className="mt-6 text-base leading-5 font-bold">
                                                        Segment {index + 1}
                                                        <span
                                                            onClick={() => remove(index)}
                                                            className="ml-4 text-2xl leading-5 text-red-500 cursor-pointer"
                                                        >
                                                            &times;
                                                        </span>
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-6 mt-8">
                                                        <div className="col-span-1">
                                                            <label
                                                                className="text-xs mb-2 label-required"
                                                                htmlFor="from"
                                                            >
                                                                FROM
                                                            </label>
                                                            <div className="flex">
                                                                <AirportSearchField
                                                                    id="from"
                                                                    className="form-control rounded-r-none"
                                                                    placeholder={'Select From Airport'}
                                                                    selectedAirport={
                                                                        values.segments[index].departure.airport
                                                                    }
                                                                    onSelectedAirportChange={(airport: IAirport) =>
                                                                        setFieldValue(
                                                                            `values.segments[${index}].departure.airport`,
                                                                            airport
                                                                        )
                                                                    }
                                                                />
                                                                <div>
                                                                    <Field
                                                                        type="text"
                                                                        id="fromTerminal"
                                                                        name={`segments[${index}].departure.airport.terminal`}
                                                                        className="form-control rounded-l-none"
                                                                        placeholder="Terminal"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-error">
                                                                <ErrorMessage name="from" />
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label
                                                                className="text-xs mb-2 label-required "
                                                                htmlFor="to"
                                                            >
                                                                TO
                                                            </label>
                                                            <div className="flex">
                                                                <AirportSearchField
                                                                    id="to"
                                                                    className="form-control rounded-r-none"
                                                                    placeholder={'Select From Airport'}
                                                                    selectedAirport={
                                                                        values.segments[index].arrival.airport
                                                                    }
                                                                    onSelectedAirportChange={(airport: IAirport) =>
                                                                        setFieldValue(
                                                                            `values.segments[${index}].arrival.airport`,
                                                                            airport
                                                                        )
                                                                    }
                                                                />
                                                                <div>
                                                                    <Field
                                                                        type="text"
                                                                        id="toTerminal"
                                                                        name={`segments[${index}].arrival.airport.terminal`}
                                                                        className="form-control rounded-l-none"
                                                                        placeholder="Terminal"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-error">
                                                                <ErrorMessage name="to" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-4 gap-6 mt-8">
                                                        <div className="col-span-1">
                                                            <label className="text-xs mb-2 " htmlFor="departureDate">
                                                                DEPART ON
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                id="departureDate"
                                                                name={`segments[${index}].departure.date`}
                                                                className="form-control"
                                                            />
                                                            <div className="form-error">
                                                                <ErrorMessage name="departureDate" />
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label className="text-xs mb-2 " htmlFor="departureTime">
                                                                DEPARTURE TIME
                                                            </label>
                                                            <Field
                                                                type="time"
                                                                id="departureTime"
                                                                name={`segments[${index}].departure.time`}
                                                                className="form-control"
                                                            />
                                                            <div className="form-error">
                                                                <ErrorMessage name="departureTime" />
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label className="text-xs mb-2  " htmlFor="arrivalDate">
                                                                ARRIVES ON
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                id="arrivalDate"
                                                                name={`segments[${index}].arrival.date`}
                                                                className="form-control"
                                                            />
                                                            <div className="form-error">
                                                                <ErrorMessage name="arrivalDate" />
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label className="text-xs mb-2 " htmlFor="arrivalTime">
                                                                ARRIVAL TIME
                                                            </label>
                                                            <Field
                                                                type="time"
                                                                id="arrivalTime"
                                                                name={`segments[${index}].arrival.time`}
                                                                className="form-control"
                                                            />
                                                            <div className="form-error">
                                                                <ErrorMessage name="arrivalTime" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="grid grid-cols-3 gap-6 mt-6">
                                                        <div className="col-span-1">
                                                            <label
                                                                className="text-xs mb-2 label-required "
                                                                htmlFor="flight"
                                                            >
                                                                SELECT FLIGHT
                                                            </label>
                                                            <AirlineSearchField
                                                                id="flight"
                                                                className="form-control"
                                                                placeholder={'Select Flight'}
                                                                selectedAirline={values.segments[index].flight}
                                                                onSelectedAirlineChange={(airline: IAirline) =>
                                                                    setFieldValue('flight', airline)
                                                                }
                                                            />
                                                            <div className="form-error">
                                                                <ErrorMessage name="flight" />
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label
                                                                className="text-xs mb-2 label-required"
                                                                htmlFor="flightNumber"
                                                            >
                                                                ENTER FLIGHT NUMBER
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="flightNumber"
                                                                name={`segments[${index}].flight.number`}
                                                                className="form-control"
                                                            />
                                                            <div className="form-error">
                                                                <ErrorMessage name="flightNumber" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    );
                                }}
                            />

                            {/* <div className="grid grid-cols-2 gap-6 mt-8">
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 label-required" htmlFor="from">
                                        FROM
                                    </label>
                                    <div className="flex">
                                        <AirportSearchField
                                            id="from"
                                            className="form-control rounded-r-none"
                                            placeholder={'Select From Airport'}
                                            selectedAirport={values.from}
                                            onSelectedAirportChange={(airport: IAirport) =>
                                                setFieldValue('from', airport)
                                            }
                                        />
                                        <div>
                                            <Field
                                                type="text"
                                                id="fromTerminal"
                                                name="fromTerminal"
                                                className="form-control rounded-l-none"
                                                placeholder="Terminal"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="from" />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 label-required " htmlFor="to">
                                        TO
                                    </label>
                                    <div className="flex">
                                        <AirportSearchField
                                            id="to"
                                            className="form-control rounded-r-none"
                                            placeholder={'Select From Airport'}
                                            selectedAirport={values.to}
                                            onSelectedAirportChange={(airport: IAirport) =>
                                                setFieldValue('to', airport)
                                            }
                                        />
                                        <div>
                                            <Field
                                                type="text"
                                                id="toTerminal"
                                                name="toTerminal"
                                                className="form-control rounded-l-none"
                                                placeholder="Terminal"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="to" />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-6 mt-8">
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 " htmlFor="departureDate">
                                        DEPART ON
                                    </label>
                                    <Field
                                        type="date"
                                        id="departureDate"
                                        name="departureDate"
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="departureDate" />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 " htmlFor="departureTime">
                                        DEPARTURE TIME
                                    </label>
                                    <Field
                                        type="time"
                                        id="departureTime"
                                        name="departureTime"
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="departureTime" />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label className="text-xs mb-2  " htmlFor="arrivalDate">
                                        ARRIVES ON
                                    </label>
                                    <Field type="date" id="arrivalDate" name="arrivalDate" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="arrivalDate" />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 " htmlFor="arrivalTime">
                                        ARRIVAL TIME
                                    </label>
                                    <Field type="time" id="arrivalTime" name="arrivalTime" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="arrivalTime" />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-3 gap-6 mt-6">
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 label-required " htmlFor="flight">
                                        SELECT FLIGHT
                                    </label>
                                    <AirlineSearchField
                                        id="flight"
                                        className="form-control"
                                        placeholder={'Select Flight'}
                                        selectedAirline={values.flight}
                                        onSelectedAirlineChange={(airline: IAirline) =>
                                            setFieldValue('flight', airline)
                                        }
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="flight" />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label className="text-xs mb-2 label-required" htmlFor="flightNumber">
                                        ENTER FLIGHT NUMBER
                                    </label>
                                    <Field type="text" id="flightNumber" name="flightNumber" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="flightNumber" />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <label className="text-xs mb-2 label-required " htmlFor="pnr">
                                        ENTER AIRLINE PNR
                                    </label>
                                    <Field type="text" id="pnr" name="pnr" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="pnr" />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-9 gap-6 mt-6">
                                <div className="col-span-2">
                                    <label className="text-xs mb-2 " htmlFor="costPrice">
                                        COST PRICE PER SEAT
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field
                                            type="text"
                                            id="costPrice"
                                            name="costPrice"
                                            className="form-control pl-8"
                                        />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="costPrice" />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <label className="text-xs mb-2  " htmlFor="taxPerSeat">
                                        TAXES PER SEAT
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">₹</div>
                                        <Field type="text" id="taxPerSeat" name="tax" className="form-control pl-8" />
                                    </div>
                                    <div className="form-error">
                                        <ErrorMessage name="tax" />
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <label className="text-xs mb-2 label-required " htmlFor="fareIdentifier">
                                        FARE IDENTIFIER
                                    </label>
                                    <Field
                                        type="text"
                                        id="fareIdentifier"
                                        name="fareIdentifier"
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="fareIdentifier" />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <label className="text-xs mb-2  " htmlFor="totalSeats">
                                        TOTAL SEATS
                                    </label>
                                    <Field id="totalSeats" name="totalSeats" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="totalSeats" />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-6 mt-3">
                                <div className="col-span-8">
                                    <div className="mt-4">
                                        <label className="text-xs mb-2 " htmlFor="fareRules">
                                            FARE RULES
                                        </label>
                                        <Field as="textarea" id="name" name="fareRules" className="form-control" />
                                        <div className="form-error">
                                            <ErrorMessage name="fareRules" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="text-gray-900 font-bold">Baggage Information</div>

                                <div className="grid grid-cols-12 gap-6 mt-4">
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2  " htmlFor="cabin">
                                            CABIN
                                        </label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                id="cabin"
                                                name="baggage.cabin"
                                                className="form-control"
                                            />

                                            <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">
                                                Kg
                                            </div>
                                        </div>

                                        <div className="form-error">
                                            <ErrorMessage name="baggage.cabin" />
                                        </div>
                                    </div>
                                    <div className="col-span-4">
                                        <label className="text-xs mb-2 " htmlFor="checkIn">
                                            CHECK IN
                                        </label>
                                        <div className="input-group">
                                            <Field
                                                type="text"
                                                id="checkIn"
                                                name="baggage.checkIn"
                                                className="form-control"
                                            />

                                            <div className="absolute right-1 py-2 text-lg font-medium px-3 bg-white">
                                                Kg
                                            </div>
                                        </div>

                                        <div className="form-error">
                                            <ErrorMessage name="baggage.checkIn" />
                                        </div>
                                    </div>
                                </div>
                            </div>
 */}
                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                // isLoading={mutation.isLoading}
                                isLoading={false}
                            >
                                Add Ticket
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};

export default EditBookingFlightDialog;
