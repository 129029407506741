import React, { FC, ReactNode } from 'react';

interface StatsCardProps {
    title: string;
    content: ReactNode;
    aside?: ReactNode;
}

const StatsCard: FC<StatsCardProps> = (props) => {
    return (
        <div className="card px-5 py-4">
            <div className="uppercase text-xs text-gray-500">{props.title}</div>
            <div className="flex items-end justify-between mt-5">
                <h6 className="text-xl font-bold">{props.content}</h6>
                {props.aside && <div className="text-sm font-medium">{props.aside}</div>}
            </div>
        </div>
    );
};

export default StatsCard;
