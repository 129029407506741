import React, { FC, useMemo } from 'react';
import { IHdfcCredentials, IHdfcPayment } from '../hdfcPayment.enum';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UseMutationResult } from '@tanstack/react-query';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import * as Yup from 'yup';

interface IHdfcCredentialsCardProps {
    hdfcGatewayInfo: IHdfcPayment;
    credentialUpdateMutation: UseMutationResult;
}

const hdfcCredentialsValidationSchema = Yup.object().shape({
    baseUrl: Yup.string().url('must be a valid URL').required('This field is required'),
    merchantId: Yup.string()
        .trim()
        .required('This field is required')
        .test('merchantId', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    keyId: Yup.string()
        .trim()
        .required('This field is required')
        .test('keyId', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    paymentPageClientId: Yup.string()
        .trim()
        .required('This field is required')
        .test('paymentPageClientId', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    publicKey: Yup.string()
        .trim()
        .required('This field is required')
        .test('publicKey', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
    privateKey: Yup.string()
        .trim()
        .required('This field is required')
        .test('privateKey', 'Invalid key', (value) => {
            const substr = '**';
            return !value.includes(substr);
        }),
});

const HdfcCredentialsCard: FC<IHdfcCredentialsCardProps> = (props) => {
    const { hdfcGatewayInfo, credentialUpdateMutation } = props;

    const initialValues: IHdfcCredentials = useMemo(() => {
        if (hdfcGatewayInfo) {
            return {
                baseUrl: hdfcGatewayInfo.details.credentials.baseUrl,
                keyId: hdfcGatewayInfo.details.credentials.keyId,
                merchantId: hdfcGatewayInfo.details.credentials.merchantId,
                paymentPageClientId: hdfcGatewayInfo.details.credentials.paymentPageClientId,
                privateKey: hdfcGatewayInfo.details.credentials.privateKey,
                publicKey: hdfcGatewayInfo.details.credentials.publicKey,
            };
        } else
            return {
                baseUrl: '',
                keyId: '',
                merchantId: '',
                paymentPageClientId: '',
                privateKey: '',
                publicKey: '',
            };
    }, [hdfcGatewayInfo]);

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: 'privateKey' | 'publicKey',
        setFieldValue: (field: string, value: any) => void
    ) => {
        const file = event.target.files?.[0];
        if (file && (file.type === 'application/x-pem-file' || file.type === 'application/x-x509-ca-cert')) {
            const reader = new FileReader();
            reader.onload = () => {
                setFieldValue(field, reader.result as string);
            };
            reader.readAsText(file);
        } else {
            toast.error('Please select a valid PEM file.');
        }
    };

    const handleSubmit = async (values: IHdfcCredentials) => {
        try {
            await credentialUpdateMutation.mutateAsync({
                credentials: { ...values },
            });
            toast.success('HDFC payment gateway keys updated successfully');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occurred, please try again.');
        }
    };

    return (
        <div className="card mt-8 p-6">
            <div className="font-semibold">Credentials</div>
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={hdfcCredentialsValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <div className="grid grid-cols-12 gap-6 mt-8">
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="baseUrl">
                                        Payment Initiation Url
                                    </label>
                                    <Field type="text" id="baseUrl" name="baseUrl" className="form-control" />
                                    <div className="form-error">
                                        <ErrorMessage name="baseUrl" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="merchantId">
                                        Merchant ID
                                    </label>
                                    <Field
                                        type="text"
                                        id="merchantId"
                                        name="merchantId"
                                        className="form-control"
                                        onFocus={() => setFieldValue('merchantId', '')}
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="merchantId" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="keyId">
                                        Key ID
                                    </label>
                                    <Field
                                        type="text"
                                        id="keyId"
                                        name="keyId"
                                        className="form-control"
                                        onFocus={() => setFieldValue('keyId', '')}
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="keyId" />
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <label className="text-xs mb-2" htmlFor="paymentPageClientId">
                                        Payment Page Client ID
                                    </label>
                                    <Field
                                        type="text"
                                        id="paymentPageClientId"
                                        name="paymentPageClientId"
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="paymentPageClientId" />
                                    </div>
                                </div>
                                <div className="col-span-12">
                                    <label className="text-xs " htmlFor="publicKeyFile">
                                        Upload Public Key File
                                    </label>
                                    <input
                                        type="file"
                                        id="publicKeyFile"
                                        accept=".pem"
                                        onChange={(e) => handleFileChange(e, 'publicKey', setFieldValue)}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="publicKey" />
                                    </div>
                                    {/* <label className="text-xs mb-2 mt-2" htmlFor="publicKey">
                                        Public Key
                                    </label> */}
                                    <Field
                                        as="textarea"
                                        type="text"
                                        id="publicKey"
                                        name="publicKey"
                                        className="form-control mt-2"
                                        rows="5"
                                        disabled
                                    />
                                </div>
                                <div className="col-span-12">
                                    <label className="text-xs" htmlFor="privateKeyFile">
                                        Upload Private Key File
                                    </label>
                                    <input
                                        type="file"
                                        id="privateKeyFile"
                                        accept=".pem"
                                        onChange={(e) => handleFileChange(e, 'privateKey', setFieldValue)}
                                        className="form-control"
                                    />
                                    <div className="form-error">
                                        <ErrorMessage name="privateKey" />
                                    </div>

                                    {/* <label className="text-xs mb-2 mt-2" htmlFor="privateKey">
                                        Private Key
                                    </label> */}
                                    <Field
                                        as="textarea"
                                        type="text"
                                        id="privateKey"
                                        name="privateKey"
                                        className="form-control mt-2"
                                        rows="5"
                                        disabled
                                    />
                                </div>
                            </div>

                            <LoaderButton
                                type="submit"
                                className="bg-primary text-white text-base font-semibold rounded-lg w-32 mt-8"
                                isLoading={credentialUpdateMutation.isLoading}
                            >
                                Save
                            </LoaderButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default HdfcCredentialsCard;
