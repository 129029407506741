import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { IAgencyUser } from 'app/types';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import AgencyUserForm from './components/AgencyUserForm/AgencyUserForm';
import ChangePasswordForm from './components/ChangePasswordForm/ChangePasswordForm';

interface AgencyUserEditProps {}

const AgencyUserEdit: FC<AgencyUserEditProps> = (props) => {
    const { agentId } = useParams();

    const query = useQuery<IAgencyUser>(['agentDetail'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/${agentId}`);

        return data;
    });

    if (query.isLoading) return <PageLoader />;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Edit User</div>

                <AgencyUserForm agent={query.data} />
            </div>

            <div className="card px-6 pt-5 pb-10 mt-6">
                <div className="font-bold">Change Password</div>

                <ChangePasswordForm agent={query.data} />
            </div>
        </>
    );
};

export default AgencyUserEdit;
