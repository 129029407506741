import { FC, useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Modal from 'app/components/Modal/Modal';
import * as Yup from 'yup';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { produce } from 'immer';
import Spinner from 'app/components/Spinner/Spinner';

const mySeriesSchema = Yup.object().shape({
    markup: Yup.number().required('This field is required').min(0, 'Value cannot be less than zero'),
});

interface IMySeries {
    _id: string;
    name: string;
    description: string;
    identifier: string;
    logo: string;
    isActive: boolean;
    logoUrl: string;
    id: string;
    details: {
        markup: number;
    };
    isEnabled: boolean;
}

interface MySeriesPluginModalProps {
    show: boolean;
    onClose: () => void;
    mySeries: any;
    subAgencyId: string;
}

const MySeriesPluginModal: FC<MySeriesPluginModalProps> = (props) => {
    const { mySeries, subAgencyId } = props;
    const queryClient = useQueryClient();

    const mySeriesQuery = useQuery<IMySeries>(['List', 'my-series', 'plugin'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${mySeries._id}/${subAgencyId}`
        );
        return data;
    });

    const mySeriesToggleMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${mySeries._id}/${subAgencyId}`,
            { ...values }
        );

        return data;
    });

    const handleToggle = async (chx: boolean, resetForm: any) => {
        queryClient.setQueryData(['List', 'my-series', 'plugin'], (prevValues) => {
            return produce(prevValues, (draftState: any) => {
                draftState.isEnabled = chx;
            });
        });

        try {
            const result = await mySeriesToggleMutation.mutateAsync({ isEnabled: chx });
            toast.success(`Plugin ${result.isEnabled ? 'enabled' : 'disabled'} successfully`);
            queryClient.fetchQuery(['List', 'my-series', 'plugin']);
            resetForm();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            queryClient.setQueryData(['List', 'my-series', 'plugin'], (prevValues) => {
                return produce(prevValues, (draftState: any) => {
                    draftState.isEnabled = !chx;
                });
            });
        } finally {
        }
        queryClient.fetchQuery(['subAgency', subAgencyId, 'plugins']);
    };

    const outwardBodyMutation = useMutation(async (values: any) => {
        const { data } = await http.put(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/${mySeries._id}/${subAgencyId}/details`,
            { ...values }
        );

        return data;
    });

    const initialValues = useMemo(() => {
        if (mySeriesQuery.isLoading)
            return {
                markup: 0,
            };

        console.log({ hey: mySeriesQuery.data.details.markup });

        return {
            markup: mySeriesQuery.data.details.markup,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySeriesQuery.data?.isEnabled]);

    if (mySeriesQuery.isLoading)
        return (
            <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10 w-[520px]">
                <div className="font-bold">My Series Plugin</div>
                <div className="flex justify-center py-4">
                    <div>
                        <Spinner />
                    </div>
                </div>
            </Modal>
        );

    // const initialValues = {
    //     markup: mySeriesQuery.data.details.markup,
    // };

    const handleSubmit = async (values: any) => {
        try {
            await outwardBodyMutation.mutateAsync(values);
            toast.success('Plugin Update Successfully');
            queryClient.invalidateQueries({ queryKey: ['subAgency', subAgencyId, 'plugins'] });
            props.onClose();
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.data.message || 'Some error occured, please try again.');
        }
    };

    console.log(initialValues);

    return (
        <Modal show={props.show} onClose={props.onClose} className="px-10 pt-8 pb-10 w-[520px]">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={mySeriesSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, resetForm, setTouched, touched }) => (
                    <Form>
                        <div className="flex items-center gap-6">
                            <div className="font-bold">My Series Plugin</div>
                            <input
                                type="checkbox"
                                className="form-switch success"
                                onChange={(e) => {
                                    setTouched({ ...touched, markup: true });
                                    handleToggle(e.target.checked, resetForm);
                                }}
                                checked={mySeriesQuery.data.isEnabled}
                            />
                        </div>

                        {mySeriesQuery.isLoading ? (
                            <div className="flex justify-center py-4">
                                <div>
                                    <Spinner />
                                </div>
                            </div>
                        ) : (
                            <div className="mt-8">
                                <>
                                    <div className="grid grid-cols-12 gap-6 mt-4">
                                        <div className="col-span-8">
                                            <label className="text-xs mb-2" htmlFor="markup">
                                                MARK UP
                                            </label>
                                            <Field
                                                type="text"
                                                id="markup"
                                                name="markup"
                                                className="form-control"
                                                disabled={!mySeriesQuery.data.isEnabled}
                                            />
                                            <div className="form-error">
                                                <ErrorMessage name="markup" />
                                            </div>
                                        </div>
                                    </div>
                                </>

                                <LoaderButton
                                    type="submit"
                                    className="bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12"
                                    isLoading={outwardBodyMutation.isLoading}
                                    isDisabled={!mySeriesQuery.data.isEnabled}
                                >
                                    Save
                                </LoaderButton>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default MySeriesPluginModal;
