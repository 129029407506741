import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { IPlugin } from 'app/types';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import Spinner from 'app/components/Spinner/Spinner';

interface PluginDetailProps {}

const PluginDetail: FC<PluginDetailProps> = (props) => {
    const { pluginId } = useParams();

    const queryClient = useQueryClient();

    const pluginQuery = useQuery<IPlugin>(['pluginDetail', pluginId], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/${pluginId}`);

        return data;
    });

    const updateMutation = useMutation(async (values: any) => {
        await http.put(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/details/${pluginId}/is-enabled`, values);
    });

    const handlePluginActivationChange = async (e: any) => {
        const isChecked = e.target.checked;

        try {
            await updateMutation.mutateAsync({ isEnabled: isChecked });

            queryClient.setQueryData(['pluginDetail', pluginId], (prev: IPlugin) => {
                return update(prev, {
                    isEnabled: { $set: isChecked },
                });
            });

            queryClient.fetchQuery(['navItemPlugins']);
        } catch (err) {
            toast.error(
                `Some error occured while ${isChecked ? 'deactivating' : 'activating'} the plugin, please try again`
            );
        }
    };

    if (pluginQuery.isLoading) return <PageLoader />;

    const plugin = pluginQuery.data;

    return (
        <div>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="flex items-center gap-8">
                <h5 className="font-bold">{plugin.name}</h5>
                <img className="max-h-10" src={plugin.logoUrl} alt="" />
            </div>

            <p className="mt-4 whitespace-pre-line">{plugin.description}</p>

            <div className="flex items-center gap-4 mt-8">
                <input
                    id="isEnabled"
                    type="checkbox"
                    className="form-switch success"
                    checked={plugin.isEnabled}
                    onChange={handlePluginActivationChange}
                />
                <label htmlFor="isEnabled">{plugin.isEnabled ? 'Deactivate' : 'Activate'}</label>
                {updateMutation.isLoading && <Spinner size={24} />}
            </div>
        </div>
    );
};

export default PluginDetail;
