import { FC } from 'react';
import { formatCurrency } from 'app/utils/currency-utils';

interface IFareDetails {
    booking: any;
}

const FareDetails: FC<IFareDetails> = (props) => {
    const { booking } = props;
    const { priceDetail } = booking;

    return (
        <div className=' px-10'>
            <div className='text-base font-semibold text-primary'>Fare Details</div>
            <div className='max-w-xs mt-4'>
                {booking.isTaxed && (
                    <>
                        <div className='mt-8'>
                            <div className='flex justify-between items-center text-sm'>
                                <div>Base Fare</div>
                                <div>{formatCurrency(booking.taxDetails.totalBaseFare)}</div>
                            </div>
                            <div className='flex justify-between items-center text-sm mt-4'>
                                <div>Fee & Surcharges</div>
                                <div>{formatCurrency(booking.taxDetails.totalTaxesAndFees)}</div>
                            </div>

                            <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
                                <div>Original Fare</div>
                                <div>{formatCurrency(booking.taxDetails.originalFare)}</div>
                            </div>

                            <div className='flex justify-between items-center text-sm mt-4'>
                                <div>Mgt. Fee</div>
                                <div>{formatCurrency(booking.taxDetails.managementFees)}</div>
                            </div>
                            <div className='flex justify-between items-center text-sm mt-4 '>
                                <div>Mgt. Fee GST</div>
                                <div>{formatCurrency(booking.taxDetails.managementFeesGST)}</div>
                            </div>
                            <div className='flex justify-between items-center text-sm mt-4 font-semibold'>
                                <div>Mgt. Fee Total</div>
                                <div>{formatCurrency(booking.taxDetails.managementFeesTotal)}</div>
                            </div>
                        </div>

                        <div className='font-semibold flex justify-between items-center mt-8'>
                            <div>Total Amount </div>
                            <div>{formatCurrency(booking.taxDetails.totalFare)}</div>
                        </div>
                    </>
                )}

                {!booking.isTaxed && (
                    <>
                        <div className='text-sm flex justify-between'>
                            <div>Base Fare</div>
                            <div>{formatCurrency(priceDetail.totalBaseFare)}</div>
                        </div>
                        <div className='text-sm flex justify-between mt-2'>
                            <div>Fees & Surcharges</div>
                            <div>{formatCurrency(priceDetail.totalTaxesAndFees + priceDetail.additionalMarkup)}</div>
                        </div>
                        <div className=' text-sm flex justify-between mt-4 font-semibold'>
                            <div>Total Amount</div>
                            <div>{formatCurrency(priceDetail.totalFare + priceDetail.additionalMarkup)}</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FareDetails;
