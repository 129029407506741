import { useMutation, useQuery } from '@tanstack/react-query';
import Spinner from 'app/components/Spinner/Spinner';
import http from 'app/config/http';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    pricingStepIncrement: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.')
        .max(100, 'The maximum value you can enter is 100.'),
    isLowBalanceAlertEnabled: Yup.boolean(),
    lowBalanceAlert: Yup.number().required('This field is required').min(0, 'The minimum value you can enter is 0.'),

    gstRate: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.')
        .max(100, 'The maximum value you can enter is 100.'),
    commissionTdsRate: Yup.number()
        .required('This field is required')
        .min(0, 'The minimum value you can enter is 0.')
        .max(100, 'The maximum value you can enter is 100.'),
});

interface IAgencySettingForm {}

const AgencySettingForm: FC<IAgencySettingForm> = (props) => {
    const agencySettingFormQuery = useQuery(['agency config'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency-settings`);
        return data;
    });

    const agencySettingFormMutation = useMutation(async (values: any) => {
        const { data } = await http.patch(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency-settings`, values);

        return data;
    });

    if (agencySettingFormQuery.isLoading)
        return (
            <div className="mt-12 flex justify-center items-center">
                <Spinner />
            </div>
        );

    const initialValues = {
        pricingStepIncrement: agencySettingFormQuery.data.pricingStepIncrement,
        gstRate: agencySettingFormQuery.data.gstRate,
        commissionTdsRate: agencySettingFormQuery.data.commissionTdsRate,
        lowBalanceAlert: agencySettingFormQuery.data.lowBalanceAlert,
        isLowBalanceAlertEnabled: agencySettingFormQuery.data.isLowBalanceAlertEnabled,
    };

    const handleSubmit = async (values: any) => {
        try {
            await agencySettingFormMutation.mutateAsync({ ...values });
            toast.success('Agency config updated successfully');
        } catch (e) {
            toast.error('Something went wrong.');
        }
    };

    return (
        <div className="mt-8">
            {/* <div className="font-bold">Agency Config Settings</div> */}
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ values }) => (
                    <Form>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="pricingStepIncrement">
                                    Sub Agency Series Increment Step
                                </label>
                                <Field
                                    name="pricingStepIncrement"
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                />
                                <div className="form-error">
                                    <ErrorMessage name="pricingStepIncrement" />
                                </div>
                            </div>
                            <div className="col-span-8" />

                            <div className="col-span-2 self-center">
                                <div className="flex items-center gap-2 mt-4">
                                    <Field
                                        type="checkbox"
                                        id="isLowBalanceAlertEnabled"
                                        name="isLowBalanceAlertEnabled"
                                        className="form-switch success"
                                    />

                                    <label htmlFor="isLowBalanceAlertEnabled">Low Balance Alert</label>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="lowBalanceAlert">
                                    Low Balance Alert
                                </label>
                                <Field
                                    name="lowBalanceAlert"
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    disabled={!values.isLowBalanceAlertEnabled}
                                />
                                <div className="form-error">
                                    <ErrorMessage name="lowBalanceAlert" />
                                </div>
                            </div>

                            <div className="col-span-6" />
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="gstRate">
                                    GST Rate
                                </label>
                                <div className="input-group">
                                    <div className="input-group-postpend">%</div>
                                    <Field name="gstRate" type="number" className="form-control" placeholder="" />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="gstRate" />
                                </div>
                            </div>
                            <div className="col-span-4">
                                <label className="text-xs mb-2 label-required " htmlFor="commissionTdsRate">
                                    Commission TDS Rate
                                </label>
                                <div className="input-group">
                                    <div className="input-group-postpend">%</div>
                                    <Field
                                        name="commissionTdsRate"
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                    />
                                </div>
                                <div className="form-error">
                                    <ErrorMessage name="commissionTdsRate" />
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={agencySettingFormMutation.isLoading}
                            type="submit"
                            className="flex justify-center items-center h-12 px-4 bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-14 disabled:bg-primary/50"
                        >
                            Save
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AgencySettingForm;



