import http from 'app/config/http';
import { IRequestFeature } from 'app/types/feature-request';

export const createFeatureRequest = async (payload: any) => {
    const { data } = await http.post(`${process.env.REACT_APP_API_URL}/api/v1/agent/agent-feature-request`, payload);
    console.log({ data });
    return data.commissionPlans;
};

export const getFeatureRequest = async () => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agent-feature-request`);

    return data.data as IRequestFeature[];
};


export const getFeatureRequestById = async (id: string) => {
    const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agent-feature-request/${id}`);

    return data as IRequestFeature;
};


export const deleteFeatureRequest = async (id: string) => {
    await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/agent-feature-request/${id}`);
};
