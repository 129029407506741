import { useQuery } from '@tanstack/react-query';
import BackLink from 'app/components/BackLink/BackLink';
import PageLoader from 'app/components/PageLoader/PageLoader';
import http from 'app/config/http';
import { IStaticPage } from 'app/types';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import StaticPageEditForm from './components/StaticPageEditForm/StaticPageEditForm';

interface StaticPageEditProps {}

const StaticPageEdit: FC<StaticPageEditProps> = (props) => {
    const { id } = useParams();

    const staticPageQuery = useQuery<IStaticPage>(['StaticPage', id], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/static-page/${id}`);

        return data;
    });

    if (staticPageQuery.isLoading) return <PageLoader />;

    const staticPage = staticPageQuery.data;

    return (
        <>
            <div className="mb-5">
                <BackLink />
            </div>

            <div className="card px-6 pt-5 pb-10">
                <div className="font-bold">Edit Page </div>
                <StaticPageEditForm staticPage={staticPage} />
            </div>
        </>
    );
};

export default StaticPageEdit;
