import React, { FC } from 'react';
import { IBank } from 'app/types';
import http from 'app/config/http';
import { toast } from 'react-toastify';
import { MenuDotIcon } from 'app/icons';
import update from 'immutability-helper';
import useDialog from 'app/hooks/use-dialog';
import Modal from 'app/components/Modal/Modal';
import DeleteModal from 'app/components/DeleteModal/DeleteModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dropdown, DropdownItem, DropdownList } from 'app/components/Dropdown/Dropdown';

interface BankListModalProps {
    show: boolean;
    onClose: () => void;
    banks: IBank[];
}

const BankListModal: FC<BankListModalProps> = (props) => {
    const { banks } = props;

    const queryClient = useQueryClient();
    const deleteDialog = useDialog<string>();

    const deleteMutation = useMutation(async (values: any) => {
        const { data } = await http.delete(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/banks/${values._id}`);

        return data;
    });

    const handleDeleteBank = async () => {
        try {
            const _id = deleteDialog.data;

            await deleteMutation.mutateAsync({ _id });

            toast.success('Bank deleted successfully.');

            const index = banks.findIndex((b) => b._id === _id);

            queryClient.setQueryData(['agencyDetail'], (prev: any) => {
                return update(prev, {
                    banks: {
                        $splice: [[index, 1]],
                    },
                });
            });
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
        } finally {
            deleteDialog.hide();
        }
    };

    return (
        <>
            <Modal show={props.show} onClose={props.onClose} className="py-3 min-h-[25rem]">
                <div className="px-10 mt-4">
                    <div className="font-bold">Bank Accounts</div>
                </div>
                <div className="overflow-auto mt-8">
                    {banks.length > 0 ? (
                        <table className="w-full text-sm">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">
                                        Bank Name
                                    </th>
                                    <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">
                                        Account Number
                                    </th>
                                    <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">IFSC</th>
                                    <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">
                                        Account Type
                                    </th>
                                    <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10">
                                        Account Name
                                    </th>
                                    <th className="px-3 py-2 text-gray-500 font-medium first:pl-10 last:pr-10"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {banks.map((row) => (
                                    <tr key={row._id}>
                                        <td className="px-3 py-4 first:pl-10 last:pr-10">{row.name}</td>
                                        <td className="px-3 py-4 first:pl-10 last:pr-10">{row.account.number}</td>
                                        <td className="px-3 py-4 first:pl-10 last:pr-10">{row.ifsc}</td>
                                        <td className="px-3 py-4 first:pl-10 last:pr-10">{row.account.type}</td>
                                        <td className="px-3 py-4 first:pl-10 last:pr-10">{row.account.type}</td>
                                        <td className="px-3 py-4 first:pl-10 last:pr-10">
                                            <Dropdown
                                                target={
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className="dropdown-action">
                                                        <MenuDotIcon />
                                                    </a>
                                                }
                                                menu={
                                                    <DropdownList>
                                                        <DropdownItem onClick={() => deleteDialog.show(row._id)}>
                                                            <span className="text-red-500">Delete</span>
                                                        </DropdownItem>
                                                    </DropdownList>
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="px-10 w-[480px] text-center max-w-full">No Banks added yet</p>
                    )}
                </div>
            </Modal>

            <DeleteModal
                show={deleteDialog.isOpen}
                message={'Are you sure you want to delete this bank?'}
                onClose={deleteDialog.hide}
                onConfirm={handleDeleteBank}
                isLoading={deleteMutation.isLoading}
            />
        </>
    );
};

export default BankListModal;
