import { FC, useEffect } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Sidebar from 'app/components/Sidebar/Sidebar';
import Navbar from 'app/components/Navbar/Navbar';
import { useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'app/store/auth';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { TRootState } from 'app/store';

interface IMe {
    user: {
        _id: string;
        name: string;
    };
    agency: {
        _id: string;
        name: string;
        logoUrl: string;
    };
}

interface MainLayoutProps {}

const MainLayout: FC<MainLayoutProps> = (props) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state: TRootState) => state.auth);

    const unsoldSeatsQuery = useQuery(['unsoldSeats'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/unsold-seats`);

        return data;
    });

    const pendingPaymentsQuery = useQuery(['pendingPayments'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/pending-payments`);

        return data;
    });

    const navPluginsQuery = useQuery<any[]>(['navItemPlugins'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/plugins/nav-items`);

        return data;
    });

    const pendingSubAgencyRequestQuery = useQuery(['pendingSubAgencyRequestQuery'], async () => {
        const { data } = await http.get(
            `${process.env.REACT_APP_API_URL}/api/v1/agent/dashboard/pending-registration-requests`
        );

        return data;
    });

    const pendingAmendmentsQuery = useQuery(['pendingAmendments'], async () => {
        const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/bookings/pending-amendments`);

        return data;
    });

    const meQuery = useQuery<IMe>(
        ['me'],
        async () => {
            const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/agent/agency/agency-users/me`);
            return data;
        },
        {
            refetchInterval: 30 * 60 * 1000,
        }
    );

    const sidebarProps = {
        unsoldSeats: unsoldSeatsQuery.isLoading ? null : unsoldSeatsQuery.data.unsoldSeats,
        pendingPayments: pendingPaymentsQuery.isLoading ? null : pendingPaymentsQuery.data.pendingPayments,
        navPlugins: navPluginsQuery.isLoading ? [] : navPluginsQuery.data,
        subAgencyRequest: pendingSubAgencyRequestQuery.isLoading
            ? null
            : pendingSubAgencyRequestQuery.data.pendingRequestCount,
        pendingAmendments: pendingAmendmentsQuery.isLoading ? null : pendingAmendmentsQuery.data.pendingAmendmentCount,
    };

    useEffect(() => {
        if (meQuery.data) {
            const user = {
                ...meQuery.data.user,
                agency: {
                    ...meQuery.data.agency,
                },
            };
            dispatch(authActions.setUser(user));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meQuery.data]);

    if (!user) return <PageLoader />;

    return (
        <div className='min-h-screen bg-[#FAFAFB]'>
            <ScrollRestoration />

            <Navbar />

            <Sidebar {...sidebarProps} />

            <div className='pt-[var(--navbar-height)] ml-[var(--sidebar-width)]'>
                <div className='py-8 px-8'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
