// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subAgencyRequestView_vertical-text__GUmmq {\n  writing-mode: vertical-rl;\n  text-orientation: mixed;\n  white-space: nowrap;\n}\n\n.subAgencyRequestView_card-shadow__H\\+BCT {\n  box-shadow: 0px -2px 50px 10px rgba(0, 0, 0, 0.1);\n}\n\n.subAgencyRequestView_file-card__K5Bds {\n  display: flex;\n  width: 10rem;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 0.5rem;\n  border-width: 2px;\n  --tw-border-opacity: 1;\n  border-color: rgb(236 233 233 / var(--tw-border-opacity));\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  padding-left: 0.75rem;\n  padding-right: 0.75rem;\n  --tw-text-opacity: 1;\n  color: rgb(78 78 78 / var(--tw-text-opacity));\n}", "",{"version":3,"sources":["webpack://./src/app/pages/SubAgencyRequest/SubAgencyRequestView/subAgencyRequestView.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iDAAA;AACJ;;AAGI;EAAA,aAAA;EAAA,YAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,8BAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".vertical-text {\n    writing-mode: vertical-rl;\n    text-orientation: mixed;\n    white-space: nowrap;\n}\n\n.card-shadow {\n    box-shadow: 0px -2px 50px 10px rgba(0, 0, 0, 0.1);\n}\n\n.file-card {\n    @apply border-2 border-[#ECE9E9] rounded-lg py-4 px-3 flex flex-col items-center justify-between text-[#4E4E4E] w-40;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical-text": "subAgencyRequestView_vertical-text__GUmmq",
	"card-shadow": "subAgencyRequestView_card-shadow__H+BCT",
	"file-card": "subAgencyRequestView_file-card__K5Bds"
};
export default ___CSS_LOADER_EXPORT___;
