import useDialog from 'app/hooks/use-dialog';
import { FC, useEffect, useState } from 'react';
import TransactionTable from '../TransactionTable/TransactionTable';
import AddPaymentNoteModal from '../AddPaymentNoteModal/AddPaymentNoteModal';
import { ExcelIcon } from 'app/icons';
import http from 'app/config/http';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

interface TransactionListProps {
    transaction: any[];
    subAgencyId: string;
}

const TransactionList: FC<TransactionListProps> = (props) => {
    const { transaction, subAgencyId } = props;
    const paymentNoteDialog = useDialog();

    const [filteredTransactionList, setFilteredTransactionList] = useState([]);
    // const [filter, setFilter] = useState({
    //     search: '',
    // });

    const subAgencyTransationDownloadMutation = useMutation(async () => {
        const { data } = await http.get(
            // `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/transactions/excel?fromDate=20230101&toDate=2023060`
            `${process.env.REACT_APP_API_URL}/api/v1/agent/agency/sub-agencies/${subAgencyId}/transactions/excel?`,
            {
                responseType: 'blob',
            }
        );
        return data;
    });

    const handleDownnloadExcel = async () => {
        try {
            const res = await subAgencyTransationDownloadMutation.mutateAsync();
            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `${subAgencyId}-ledger.xlsx`);

            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);
        } catch (e: any) {
            toast.error('Something went wrong.');
        }
    };

    useEffect(() => {
        setFilteredTransactionList(transaction);
    }, [transaction]);

    return (
        <div>
            <div className=''>
                {/* <div className="flex justify-between items-center px-6 pt-5">
                    <TableSearch placeholder="Search for a booking by name, PNR, reference" onSearch={handleSearch} />
                    <TableDateFilter onChange={handleDateFilter} />
                </div> */}

                <div className='mt-6'>
                    <div className='flex justify-end px-6 gap-2'>
                        <button
                            onClick={paymentNoteDialog.show}
                            className='text-center bg-primary text-white text-sm font-semibold px-4 py-3 rounded-lg w-56'
                        >
                            Add Payment Note
                        </button>
                        <button
                            className='cursor-pointer flex items-center justify-center rounded-full'
                            onClick={handleDownnloadExcel}
                            disabled={subAgencyTransationDownloadMutation.isLoading}
                        >
                            <ExcelIcon width='30' height='30' />
                        </button>
                    </div>
                    <div className='mt-6'>
                        <TransactionTable transactions={filteredTransactionList} />
                    </div>
                </div>
            </div>
            <AddPaymentNoteModal
                onClose={paymentNoteDialog.hide}
                show={paymentNoteDialog.isOpen}
                subAgencyId={subAgencyId}
            />
        </div>
    );
};

export default TransactionList;
